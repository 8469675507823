import bc from "../constants/voucher.constant";

export default (dispatch, state) => {
  const dispatchSetStatus = data => {
    dispatch({
      type: bc.ACT_SET_STATUS,
      data: data
    });
  };

  const consume = (qrcode, authorization, loader) => {
    var args = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization
      },
      body: JSON.stringify({ qrcode: qrcode }),
      credentials: "same-origin"
    };

    if (loader) loader.start();

    return fetch(process.env.domainVoucherConsume, args)
      .then(res => res.json())
      .then(res => {
        if (loader) loader.finish();
        return res;
      })
      .catch(error => {
        if (loader) loader.finish();
        return error;
      });
  };

  const remove = (values, authorization) => {
    var args = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization
      },
      body: JSON.stringify(values),
      credentials: "same-origin"
    };

    dispatchSetStatus(bc.STS_DELETE_REQUEST);

    return fetch(process.env.domainVoucherDelete, args)
      .then(res => res.json())
      .then(res => {
        dispatchSetStatus(bc.STS_DELETE_RESPONSE);
        dispatchSetStatus(bc.STS_DELETE_SUCCESS);

        setTimeout(function() {
          dispatchSetStatus(bc.STS_IDLE);
        }, 1000);

        return res;
      })
      .catch(error => {
        dispatchSetStatus(bc.STS_DELETE_ERROR);

        setTimeout(function() {
          dispatchSetStatus(bc.STS_IDLE);
        }, 1000);

        return error;
      });
  };

  const update = (values, authorization) => {
    const updateFetch = args => {
      return fetch(process.env.domainVoucherUpdate, args)
        .then(res => res.json())
        .then(res => {
          dispatchSetStatus(bc.STS_UPDATE_RESPONSE);
          dispatchSetStatus(bc.STS_UPDATE_SUCCESS);

          setTimeout(function() {
            dispatchSetStatus(bc.STS_IDLE);
          }, 1000);

          return res;
        })
        .catch(error => {
          dispatchSetStatus(bc.STS_UPDATE_ERROR);

          setTimeout(function() {
            dispatchSetStatus(bc.STS_IDLE);
          }, 1000);

          return error;
        });
    };

    dispatchSetStatus(bc.STS_UPDATE_REQUEST);

    if (values.bannerUrlBlob) {
      const formData = new FormData();
      formData.append("file", values.bannerUrlBlob);

      const args = {
        method: "POST",
        headers: {
          Authorization: authorization
        },
        body: formData,
        credentials: "same-origin"
      };

      return fetch(process.env.domainDocumentUploadImage, args)
        .then(res => res.json())
        .then(res => {
          values.bannerUrl = res.result.image;
          delete values.bannerUrlBlob;

          return updateFetch({
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: authorization
            },
            body: JSON.stringify(values),
            credentials: "same-origin"
          });
        })
        .catch(error => {
          return error;
        });
    }

    return updateFetch({
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization
      },
      body: JSON.stringify(values),
      credentials: "same-origin"
    });
  };

  const create = (values, authorization) => {
    const updateFetch = args => {
      return fetch(process.env.domainVoucherCreate, args)
        .then(res => res.json())
        .then(res => {
          dispatchSetStatus(bc.STS_CREATE_RESPONSE);
          dispatchSetStatus(bc.STS_CREATE_SUCCESS);

          setTimeout(function() {
            dispatchSetStatus(bc.STS_IDLE);
          }, 1000);

          return res;
        })
        .catch(error => {
          dispatchSetStatus(bc.STS_CREATE_ERROR);

          setTimeout(function() {
            dispatchSetStatus(bc.STS_IDLE);
          }, 1000);

          return error;
        });
    };

    dispatchSetStatus(bc.STS_CREATE_REQUEST);

    if (values.bannerUrlBlob) {
      const formData = new FormData();
      formData.append("file", values.bannerUrlBlob);

      const args = {
        method: "POST",
        headers: {
          Authorization: authorization
        },
        body: formData,
        credentials: "same-origin"
      };

      return fetch(process.env.domainDocumentUploadImage, args)
        .then(res => res.json())
        .then(res => {
          values.bannerUrl = res.result.image;
          delete values.bannerUrlBlob;

          return updateFetch({
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: authorization
            },
            body: JSON.stringify(values),
            credentials: "same-origin"
          });
        })
        .catch(error => {
          return error;
        });
    }

    return updateFetch({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization
      },
      body: JSON.stringify(values),
      credentials: "same-origin"
    });
  };

  const find = (filters, options, authorization, loader) => {
    var args = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization
      },
      body: JSON.stringify({ ...filters, options: { sort: { _id: -1 }, ...options } }),
      credentials: "same-origin"
    };

    if (loader) loader.start();

    dispatchSetStatus(bc.STS_FIND_REQUEST);

    return fetch(process.env.domainVoucherFind, args)
      .then(res => res.json())
      .then(res => {
        dispatchSetStatus(bc.STS_FIND_RESPONSE);
        dispatchSetStatus(bc.STS_FIND_SUCCESS);

        if (loader) loader.finish();

        setTimeout(function() {
          dispatchSetStatus(bc.STS_IDLE);
        }, 1000);

        if (res.result.values) {
          res.result.values.map(b => {
            if (b.bannerUrl) {
              b.bannerUrl = process.env.bannerUrl + b.bannerUrl;
            }
            return b;
          });
        }

        return res;
      })
      .catch(error => {
        dispatchSetStatus(bc.STS_FIND_ERROR);

        if (loader) loader.finish();

        setTimeout(function() {
          dispatchSetStatus(bc.STS_IDLE);
        }, 1000);

        return error;
      });
  };

  const getList = (filters, options, authorization, loader) => {
    var args = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization
      },
      body: JSON.stringify({ ...filters, options }),
      credentials: "same-origin"
    };

    if (loader) loader.start();

    dispatchSetStatus(bc.STS_FIND_REQUEST);

    return fetch(process.env.domainVoucherGetList, args)
      .then(res => res.json())
      .then(res => {
        dispatchSetStatus(bc.STS_FIND_RESPONSE);
        dispatchSetStatus(bc.STS_FIND_SUCCESS);

        if (loader) loader.finish();

        setTimeout(function() {
          dispatchSetStatus(bc.STS_IDLE);
        }, 1000);

        return res;
      })
      .catch(error => {
        dispatchSetStatus(bc.STS_FIND_ERROR);

        if (loader) loader.finish();

        setTimeout(function() {
          dispatchSetStatus(bc.STS_IDLE);
        }, 1000);

        return error;
      });
  };

  const downloadCSV = (filters, options, authorization, loader) => {
    var args = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization
      },
      body: JSON.stringify({ ...filters, options }),
      credentials: "same-origin"
    };

    if (loader) loader.start();

    dispatchSetStatus(bc.STS_FIND_REQUEST);

    return fetch(process.env.domainVoucherGetListExport, args)
      .then(res => res.blob())
      .then(res => {
        dispatchSetStatus(bc.STS_FIND_RESPONSE);
        dispatchSetStatus(bc.STS_FIND_SUCCESS);

        if (loader) loader.finish();

        setTimeout(function() {
          dispatchSetStatus(bc.STS_IDLE);
        }, 1000);

        return res;
      })
      .catch(error => {
        dispatchSetStatus(bc.STS_FIND_ERROR);

        if (loader) loader.finish();

        setTimeout(function() {
          dispatchSetStatus(bc.STS_IDLE);
        }, 1000);

        return error;
      });
  };

  return {
    find,
    create,
    update,
    remove,
    consume,
    getList,
    downloadCSV
  };
};
