import React from "react";
import { Map, Marker, Circle, InfoWindow, GoogleApiWrapper as GoogleMapsApiWrapper } from 'google-maps-react';

class GoogleMaps extends React.Component {

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  moveMarker = (props, marker) => {
    this.props.onChange({
      latitude: marker.position.lat(), 
      longitude: marker.position.lng()
    })
  }

  render() {

    const mapStyle = {
      width: "100%",
      height: "300px",
    }

    return (
      <Map google={this.props.google}
        zoom={17}
        style={mapStyle}
        center={{
          lat: this.props.state ? this.props.state.latitude : 0,
          lng: this.props.state ? this.props.state.longitude : 0
        }}
        initialCenter={{
          lat: this.props.state ? this.props.state.latitude : 0,
          lng: this.props.state ? this.props.state.longitude : 0
        }}>
        <Marker
          title={"NSC"}
          name={'NSC'}
          position={{ 
            lat: this.props.state ? this.props.state.latitude : 0,
            lng: this.props.state ? this.props.state.longitude : 0
          }}
          draggable={true}
          onDragend={this.moveMarker.bind(this)} />
      </Map>
    );
  }
}

export { 
  GoogleMaps, 
  GoogleMapsApiWrapper 
}
