import React, { Fragment, useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router-dom";
import Access from "@cthulhi/comp-accs";
import Context from "@context";
import { useFields, useForms } from "@cthulhi/comp-form";
import { schema as partnerFormSchema } from "@schm/partner/create.form.schema";
import { schema as branchPlaceFormSchema } from "@schm/branch/create.place.form.schema";
import { schema as branchVirtualFormSchema } from "@schm/branch/create.virtual.form.schema";

import {
  events as partnerFormEvents,
  values as partnerFormValues,
  actions as partnerFormActions
} from "./components/PartnerForm.config";

import {
  events as branchPlaceFormEvents,
  values as branchPlaceFormValues,
  actions as branchPlaceFormActions
} from "./components/PartnerBranchPlaceForm.config";

import {
  events as branchVirtualFormEvents,
  values as branchVirtualFormValues,
  actions as branchVirtualFormActions
} from "./components/PartnerBranchVirtualForm.config";

import { FieldLabelWrapper } from "../../../wrappers/FormWrappers.react";

import Notification from "@cthulhi/comp-noty";
import NotificationComponent from "../../../utils/Notification.react";

import LoaderComponent from "../../../utils/LoaderCustomComponent.react";

const PartnerFormPage = ({ match, parentPath, history, ...props }) => {
  const { state: accState } = Access.useState();
  const { action: ctxAction, state: ctxtState } = Context.useState();
  const { action: ntfAction } = Notification.useState();
  const [ partnerSchema, ] = useState(partnerFormSchema);
  const [ branchPlaceSchema, ] = useState(branchPlaceFormSchema);
  const [ branchVirtualSchema, ] = useState(branchVirtualFormSchema);
  const [validation, setValidation] = useState({})
  const [branchForms, setBranchForms] = useState([]);
  const [ready, setReady] = useState(false);

  const { 
    form: branchVirtualForm, 
    fields: branchVirtualFields, 
    values: branchVirtualValues, 
    updater: branchVirtualUpdater, 
    cleaner: branchVirtualCleaner,
    addFields: branchVirtualAddFields,
    removeFields: branchVirtualRemoveFields,
  } = useFields({
    schema: branchVirtualSchema,
    events: branchVirtualFormEvents({ ctxAction }),
    values: branchVirtualFormValues({
      ctxAction,
      token: `Bearer ${accState.access.session.context.token}`
    })
  });

  const { 
    form: branchPlaceForm, 
    fields: branchPlaceFields, 
    values: branchPlaceValues, 
    updater: branchPlaceUpdater, 
    cleaner: branchPlaceCleaner,
    addFields: branchPlaceAddFields,
    removeFields: branchPlaceRemoveFields,
  } = useFields({
    schema: branchPlaceSchema,
    events: branchPlaceFormEvents({ ctxAction }),
    values: branchPlaceFormValues({
      ctxAction,
      token: `Bearer ${accState.access.session.context.token}`
    })
  });

  const { form, fields, values, updater, cleaner, addFields } = useFields({
    schema: partnerSchema,
    events: partnerFormEvents({ ctxAction }),
    values: partnerFormValues({
      ctxAction,
      token: `Bearer ${accState.access.session.context.token}`
    })
  });

  const { find, update, create } = useForms(
    partnerFormActions({
      ctxAction,
      ntfAction,
      updater,
      history,
      path: parentPath,
      token: `Bearer ${accState.access.session.context.token}`
    })
  );

  const removeChild = parent => {
    setBranchForms(v => v.map(form => {
      const { child } = form;
      if (form.key == parent && child) {
        child.type = "none";
        child.removed = true;
      }

      return {
        ...form,
        child
      }
    }));
  }
  
  const addLocal = (parent, type = 'virtual', values = {}) => {
    const key = new Date().getTime();
    
    if (type == 'virtual') {
      branchVirtualAddFields(key, values);
    } else {
      branchPlaceAddFields(key, values);
    }

    setBranchForms(v => {
      if (parent) {
        return v.map(form => {
          if (form.key == parent) {
            form.child = {
              _id: values._id,
              key,
              parent,
              type
            }
          }

          return form
        })
      }

      return [...v, { key, type, _id: values._id }]
    });

    return key;
  }


  const toggleBranchType = (key, { target }) => {
    const { value } = target;
    const newKey = new Date().getTime();

    setBranchForms(v => v.map(form => {
      if (form.child && form.child.key == key) {
        form.child = { ...form.child, type: value, key: newKey };
      }

      if (form.key == key) {
        form = { ...form, type: value, key: newKey };
      }

      return form;
    }));

    if (value == "place") {
      branchPlaceAddFields(newKey);
      branchVirtualRemoveFields(key);
    }

    if (value == "virtual") {
      branchVirtualAddFields(newKey);
      branchPlaceRemoveFields(key);
    }

    removeChild(newKey);
    removeChild(key);
  }

  const removeForm = key => {
    setBranchForms(v => v.map(form => ({
      ...form,
      removed: key == form.key || form.removed 
    })));
    branchVirtualRemoveFields(key);
    branchPlaceRemoveFields(key);
  }

  const normalizeBranch = form => {
    const vals = form.type == 'virtual' ? branchVirtualValues : branchPlaceValues;
    const vs = {};
    const prefix = `${form.key}.`;

    Object.entries(vals).filter(([key]) => key.includes(form.key)).map(([key, value]) => {
      const hasValue = typeof value.value != "undefined" && value.value !== ""
      const hasRawValue = typeof value.rawValue != "undefined" && value.rawValue !== ""
      
      vs[key.replace(prefix, '')] = hasRawValue ? value.rawValue : (hasValue ? value.value : value.defaultValue)
    });

    if (form.child) {
      vs.branch = normalizeBranch(form.child)
    }

    vs.type = form.type == "virtual" ? form.type : "fisico";
    vs.removed = form.removed;
    vs._id = form._id;

    delete vs.children;
    delete vs.parent;
    delete vs.key;

    return vs;
  } 

  const validateBranchForm = form => {
    const required = [];
    if (!form || form.removed) return required;

    const { key, type } = form;
    const vals = type == 'virtual' ? branchVirtualValues : branchPlaceValues;

    if (!vals[`${key}.name`].value)  
      required.push({ field: `${key}.name` });
    
    if (!vals[`${key}.contact`].value)  
      required.push({ field: `${key}.contact` });
    
    if (type == 'virtual') {
      if (!vals[`${key}.site`].value) required.push({ field: `${key}.site` });
    } else {
      if (!vals[`${key}.address`].value) required.push({ field: `${key}.address` });
    }

    return required;
  }

  const partnerFormSubmit = async () => {
    let vs = {};

    Object.entries(values).forEach(([key, value]) => {
      vs[key] = value.value || value.defaultValue
    });
    
    vs.branches = branchForms.map(normalizeBranch);

    const validate = action => {
      action.then(() => {}).catch(setValidation)
    }

    let required = [];
    
    if (!vs.name) required.push({ field: 'name', friendly: 'Nome' });
    if (!vs.about) required.push({ field: 'about', friendly: 'Sobre' });
    if (!vs.site) required.push({ field: 'site', friendly: 'Site' });
    if (!vs.categories) required.push({ field: 'categories', friendly: 'Categories' });
    
    if (required.length) {
      setValidation(required.reduce((obj, { field }) => ({ ...obj, [field]: false }), {}))
      return;
    } 
    
    if (!vs.branches || !vs.branches.filter(({ removed }) => !removed).length) {
      ntfAction("notification").add(
        1,
        "partnerForm",
        "error",
        "É necessário definir um local de uso",
        undefined,
        process.env.notificationTimeOut
      )
      return;
    } else {
      branchForms.forEach(form => {
        required = [
          ...required, 
          ...validateBranchForm(form), 
          ...validateBranchForm(form.child)
        ];
      })

      if (required.length) {
        setValidation(required.reduce((obj, { field }) => ({ ...obj, [field]: false }), {}));
        return;
      }
    }

    match.params.id ? validate(update(vs)) : validate(create(vs));
  };

  useEffect(() => {
    if (match.params.id) {
      find({ filters: { _id: match.params.id } }).then(res => {
        res.result.values[0].branches.map(branch => {
          const type = branch.type == "virtual" ? branch.type : "place";
          const parent = addLocal(undefined, type, branch);

          const { children } = branch;
          if (children && children[0]) {
            const childType = children[0].type == "virtual" ? children[0].type : "place";
            addLocal(parent, childType, children[0]);
          }
        })
      })
    }

    return () => {
      cleaner();
    };
  }, [match.params.id]);

  const renderPayment = ({ parentKey, type, child }) => {
    const hasType  = child && child.type;

    return (
      <div>
        
        <br/>
        <a className="title title-lead-small">
          Local de pagamento
        </a>
        <br/>

        <input className="input-radio" checked={!hasType || child.type === "none"} onChange={() => removeChild(parentKey)} type="radio" id={`${parentKey}.none`} name={`${parentKey}.paymentType`} value="none" />
        <label htmlFor={`${parentKey}.none`}>Mesmo local de uso</label>
        {["place"].includes(type) && (
          <Fragment>
            <input className="input-radio" checked={hasType && child.type === "place"} onChange={() => addLocal(parentKey, "place", child)} type="radio" id={`${parentKey}.place`} name={`${parentKey}.paymentType`} value="place" />
            <label htmlFor={`${parentKey}.place`}>Físico</label>
            <input className="input-radio" checked={hasType && child.type === "virtual"} onChange={() => addLocal(parentKey, "virtual", child)} type="radio" id={`${parentKey}.virtual`} name={`${parentKey}.paymentType`} value="virtual" />
            <label htmlFor={`${parentKey}.virtual`}>Online</label>
          </Fragment>
        )}
      </div>
    )
  }

  const renderVirtualForm = ({ key, child, parent, type, classCard }) => {
    return (
      <div key={key} className="uk-accordion-content">
        <div className={classCard}>
          <form className="uk-grid-small" data-uk-grid>
            <Fragment>
                {!parent && <FieldLabelWrapper onChange={e => toggleBranchType(key, e)} name={`${key}.type`} fields={branchVirtualFields} values={branchVirtualValues} />}
                <FieldLabelWrapper name={`${key}.name`} validation={validation[`${key}.name`]} fields={branchVirtualFields} values={branchVirtualValues} />
                <FieldLabelWrapper name={`${key}.site`} validation={validation[`${key}.site`]} fields={branchVirtualFields} values={branchVirtualValues} />
                <FieldLabelWrapper name={`${key}.contact`} validation={validation[`${key}.contact`]} fields={branchVirtualFields} values={branchVirtualValues} />
              </Fragment> 
          </form>
          {!parent && renderPayment({ parentKey: key, type, child })}
          {child && renderForm(child)}
          {!parent && <button className="button-add-form" onClick={() => removeForm(key)}>Remover</button>}
        </div>
      </div>
    );
  }
  
  const renderPlaceForm = ({ key, child, parent, type, classCard }) => {
    return (
      <div key={key} className="uk-accordion-content">
        <div className={classCard}>
          <form className="uk-grid-small" data-uk-grid>
            <Fragment>
              {!parent && <FieldLabelWrapper onChange={e => toggleBranchType(key, e)} name={`${key}.type`} fields={branchPlaceFields} values={branchPlaceValues} />}
              <FieldLabelWrapper name={`${key}.name`} fields={branchPlaceFields} values={branchPlaceValues} validation={validation[`${key}.name`]}/>
              <FieldLabelWrapper name={`${key}.address`} fields={branchPlaceFields} values={branchPlaceValues} validation={validation[`${key}.address`]}/>
              <FieldLabelWrapper name={`${key}.contact`} fields={branchPlaceFields} values={branchPlaceValues} validation={validation[`${key}.contact`]}/>
            </Fragment> 
          </form>
          {!parent && renderPayment({ parentKey: key, type, child })}
          {child && renderForm(child)}
          {!parent && <button className="button-add-form" onClick={() => removeForm(key)}>Remover</button>}
        </div>
      </div>
    );
  }

  const renderForm = ({ key, child, parent, type }) => {
    let classCard = null;

    if (!parent) {
      classCard = "uk-card uk-card-default uk-card-body uk-padding-small";
    }

    switch (type) {
      case 'virtual':
        return renderVirtualForm({ key, child, parent, type, classCard });
      case 'place':
        return renderPlaceForm({ key, child, parent, type, classCard });
    }
  }

  return (
    <Fragment>
      <h2 className="title">{match.params.id ? "Editar Parceiro" : "Novo Parceiro"}</h2>
      <div className="uk-margin-top">
        <Notification.NotificationWrapper group="partnerForm" component={NotificationComponent} />
        <LoaderComponent from="partnerForm" />

        <ul data-uk-accordion="multiple: true">
          <li className="uk-open">
            <a className="title title-lead-small title-accordion uk-accordion-title">
              Configurações gerais
            </a>
            <div className="uk-accordion-content">
              <div className="uk-card uk-card-default uk-card-body uk-padding-small">
                <form className="uk-grid-small" data-uk-grid>
                  <FieldLabelWrapper name="name" fields={fields} values={values} validation={validation.name} />
                  <FieldLabelWrapper name="about" fields={fields} values={values} validation={validation.about} />
                  <FieldLabelWrapper name="site" fields={fields} values={values} validation={validation.site} />
                  <FieldLabelWrapper name="categories" fields={fields} values={values} validation={validation.categories} />
                  <FieldLabelWrapper name="email" fields={fields} values={values} validation={validation.email} />
                  <FieldLabelWrapper name="document" fields={fields} values={values} validation={validation.document} />
                </form>
              </div>
            </div>
          </li>
          {branchForms
            .filter(form => !form.removed)
            .map(form => (
              <li className="uk-open">
                <a className="title title-lead-small title-accordion uk-accordion-title">
                  Local de uso
                </a>
                {renderForm(form)}
              </li>
            ))
          }
        </ul>

        <button className="button-add-form" onClick={() => addLocal()}>Adicionar local de uso</button>
        <div className="uk-margin-top">
          <button
              onClick={partnerFormSubmit}
              className="uk-button uk-button-large uk-button-secondary"
              style={{ float: "right" }}
              disabled={typeof ctxtState.loader.list["partnerForm"] != "undefined" && ctxtState.loader.list["partnerForm"].length > 0}
          >
            {match.params.id ? "Atualizar" : "Salvar"}
          </button>
        </div>
      </div>
    </Fragment>
  );
};

// @ts-ignore
export default withRouter(PartnerFormPage);
