import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

import moment from "moment";

import Context from "./../../../context";
import Loader from "../../utils/LoaderSiteComponent.react.js";
import Mask from "../../../../comp-mask";

const BenefitPage = ({ match, location }) => {
  const { state: ctxState, action: ctxAction, dispatch: ctxDispatch } = Context.useState();
  const [benefit, setBenefitState] = useState();

  const [windowEncode, setWindowEncode] = useState("");
  const [textEncode, setTextEncode] = useState("");
  const [windowHref, setWindowHref] = useState("");

  const [cepMask, setCepMask] = useState(new Mask(["99.999-999"]));
  const [phoneMask, setPhoneMask] = useState(
    new Mask(["(99) 9999-9999", "(99) 99999-9999", "+99 (99) 9999-9999", "+99 (99) 99999-9999"])
  );

  const badgeType = (discountType, discount, badgeColor = "black") => {
    return discount ? (
      <span className={`badge badge-${badgeColor}`}>
        {`${discountType == "plain" ? "R$ " : ""}
        ${discount}
        ${discountType == "percent" || discountType === undefined ? "%" : ""}`}
      </span>
    ) : null;
  };

  const SalespointRender = ({ branch, ...options }) => {
    const [getCity, setCity] = useState("");
    const [getUf, setUf] = useState("");

    useEffect(() => {
      ctxAction("address")
        .findCityByListId(branch.city)
        .then(res => {
          console.log(res);
          setCity(res.microrregiao.nome), setUf(res.microrregiao.mesorregiao.UF.sigla);
        });
    }, []);

    return (
      <div key={branch._id} className="detail">
        <h3>{branch.name}</h3>
        {branch.type != "virtual" && (
          <Fragment>
            <div className="line">
              <i className="icn icn-map-pin" aria-hidden="true"></i>
              <span>Endereço de Venda</span>
            </div>
            {branch.name ? <p>{branch.name}</p> : null}
            <p>
              {branch.address}, {branch.number}
            </p>
            <p>{branch.district ? `${branch.district}, ` : ""}</p>
            <p>{cepMask.applyMask(branch.zipcode)}</p>
          </Fragment>
        )}
        <div className="line">
          <i className="icn icn-phone" aria-hidden="true"></i>
          <span>Telefone</span>
        </div>
        <p>{phoneMask.applyMask(branch.phone)}</p>
      </div>
    );
  };

  const UsepointRender = ({ branch, ...props }) => {
    const [getCity, setCity] = useState("");
    const [getUf, setUf] = useState("");

    useEffect(() => {
      ctxAction("address")
        .findCityByListId(branch.city)
        .then(res => {
          setCity(res.nome); 
          setUf(res.microrregiao.mesorregiao.UF.sigla);
        });
    }, []);

    return (
      <Fragment>
        {branch.type != "virtual" ? (
          <Fragment>
            <div key={benefit._id} className="line">
              <i className="icn icn-map-pin" aria-hidden="true"></i>
              <span>Endereço de Uso</span>
            </div>
            {branch.name ? <p>{branch.name}</p> : null}
            <p>
              {branch.address}, {branch.number}{branch.complement ? `, ${branch.complement}.` : "."}
            </p>
            <p>
              {branch.district ? `${branch.district}, ` : ""}
              {getCity} - {getUf}
            </p>
            <p>{cepMask.applyMask(branch.zipcode)}</p>
          </Fragment>
        ) : null}
      </Fragment>
    );
  };

  const eventDateRender = events => {
    return (
      <Fragment>
        <div className="line">
          <i className="icn icn-clock" aria-hidden="true"></i>
          <span>Agenda</span>
        </div>
        {events.map(event => {
          var date = moment(event);
          date.locale("pt-br");
          return <p>{date.format("LLL")}</p>;
        })}
        {moment}
      </Fragment>
    );
  };

  const eventInfoRender = eventDescription => {
    return (
      <Fragment>
        <div className="line">
          <i className="icn icn-ticket" aria-hidden="true"></i>
          <span>Ingressos</span>
        </div>
        <p>{eventDescription}</p>
      </Fragment>
    );
  };

  useEffect(() => {
    if (match.params.id) {
      ctxAction("website")
        .getBenefitInfo({ slug: match.params.id })
        .then(res => {
          const state = res.result ? res.result : {};
          setBenefitState(state);
        });
    }
  }, [match.params.id]);

  useEffect(() => {
    setTextEncode("Veja só esse beneficio super irado e maneiro :D");
    setWindowEncode(encodeURIComponent(window.location.href));
    setWindowHref(window.location.href);
  }, []);

  const share = (event, link) => {
    var width = window.innerWidth;
    var height = window.innerHeight;

    var modal = "top=200,left=" + width / 2 / 2 + ",width=" + width / 2 + ",height=" + height / 2;

    window.open(link, "", modal);
    event.preventDefault();
  };

  return (
    <Fragment>
      <Loader show={!benefit} height="120" width="120" />
      {benefit ? (
        <div key={benefit._id} className="cnt">
          <div id="fb-root"></div>
          <div id="bft-grid">
            <div id="bft-info">
              <div id="bft-info-banner">
                <img
                  src={
                    benefit.bannerUrl
                      ? `${process.env.bannerUrl + benefit.bannerUrl.replace("-full", "-large")}`
                      : `${process.env.bannerUrl}large_beneficio20190220035556.png`
                  }
                  alt={benefit.title}
                />
                {benefit.discountType &&
                benefit.discountType === "percent" &&
                +benefit.discount === 100 ? (
                  <span className={`badge badge-orange`}>
                    <strong>FREE</strong>
                  </span>
                ) : (
                  badgeType(benefit.discountType, benefit.discount)
                )}
              </div>
              <div id="bft-info-content">
                <h2>{benefit.title}</h2>
                <p>{benefit.description}</p>
              </div>
            </div>
            <div id="bft-about">
              <div id="bft-about-title">
                <h1>{benefit.title}</h1>
                <p>{benefit.resume}</p>
              </div>

              <div id="bft-about-buyin">
                <h2>Ponto de venda</h2>

                {benefit.salespointList != null
                  ? benefit.salespointList.map(branch => <UsepointRender branch={branch} />)
                  : null}

                {benefit.tinySiteUrl && benefit.siteUrl ? (
                  <Fragment>
                    <div className="line">
                      <span>Endereço web</span>
                    </div>
                    <a href={benefit.tinySiteUrl ? benefit.tinySiteUrl : benefit.siteUrl}>
                      {benefit.tinySiteUrl ? benefit.tinySiteUrl : benefit.siteUrl}
                    </a>
                  </Fragment>
                ) : null}
              </div>

              <div id="app-store">
                <h2>Gerar Voucher</h2>
                <span>
                  Para gerar seu Voucher de desconto, baixe o aplicativo do Clube NSC para celular.
                </span>
                <div className="store-buttons">
                  <a href={process.env.playStoreLink} target="_blank">
                    <img src={`${process.env.domain}assets/website/images/btn-google-play.png`} />
                  </a>
                  <a href={process.env.appStoreLink} target="_blank">
                    <img src={`${process.env.domain}assets/website/images/btn-app-store.png`} />
                  </a>
                </div>
              </div>

              <div id="bft-about-social">
                <h2>Conte para seus amigos</h2>
                <ul className="list">
                  <li>
                    <a
                      style={{}}
                      href="#"
                      onClick={e => share(e, `http://www.facebook.com/share.php?u=${windowEncode}`)}
                    >
                      <i className="icn icn-facebook" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href={`https://twitter.com/intent/tweet?text=${textEncode}&url=${windowEncode}`}
                      data-size="large"
                    >
                      <i className="icn icn-twitter" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={`https://api.whatsapp.com/send?phone=&text=${textEncode}${windowEncode}&source=&data=`}
                    >
                      <i className="icn icn-whatsapp" aria-hidden="true"></i>
                    </a>
                  </li>
                </ul>
              </div>

              <div id="bft-about-date">
                <h4>
                  Válido até <strong>{moment(benefit.shelfLifeEnd).format("DD/MM/YYYY")}</strong>
                </h4>
              </div>

              {(benefit.usepointList &&
                benefit.usepointList.filter(b => b.type != "virtual").length > 0) ||
              (benefit.events && benefit.events.length > 0) ||
              (benefit.eventDescription && benefit.eventDescription != "") ? (
                <div id="bft-about-more">
                  <h2>Outras informações</h2>
                  {benefit.usepointList && benefit.usepointList.length > 0
                    ? benefit.usepointList
                        .filter(b => b.type != "virtual")
                        .map(branch => <UsepointRender branch={branch} />)
                    : null}

                  {benefit.events && benefit.events.length > 0
                    ? eventDateRender(benefit.events)
                    : null}

                  {benefit.eventDescription && benefit.eventDescription != ""
                    ? eventInfoRender(benefit.eventDescription)
                    : null}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ) : (
        <div style={{ height: "550px" }}></div>
      )}
    </Fragment>
  );
};

export default withRouter(BenefitPage);
