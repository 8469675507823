const view = {
  group: {
    title: "Discounts",
    slug: "discount"
  },
  slug: "discount-view",
  title: "Ver descontos",
  description: "Ver os próprios descontos",
  list: [
    "prn::1:discount-view-roles:discount-view:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

const viewAny = {
  group: {
    title: "Discounts",
    slug: "discount"
  },
  slug: "discount-view-any",
  title: "Ver qualquer desconto",
  description: "Ver todos os descontos",
  list: [
    "prn::1:discount-view-any-roles:discount-view-any:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

const edit = {
  group: {
    title: "Discounts",
    slug: "discount"
  },
  slug: "discount-edit",
  title: "Editar descontos",
  description: "Editar os próprios descontos",
  list: [
    "prn::1:discount-edit-roles:discount-edit:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

const editAny = {
  group: {
    title: "Discounts",
    slug: "discount"
  },
  slug: "discount-edit-any",
  title: "Editar qualquer desconto",
  description: "Editar todos os descontos",
  list: [
    "prn::1:discount-edit-any-roles:discount-edit-any:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

const remove = {
  group: {
    title: "Discounts",
    slug: "discount"
  },
  slug: "discount-remove",
  title: "Remover discounts",
  description: "Remover os próprios descontos"
}

const removeAny = {
  group: {
    title: "Discount",
    slug: "discount"
  },
  slug: "discount-remove-any",
  title: "Remover qualquer desconto",
  description: "Remover todos os desconto"
}

const create = {
  group: {
    title: "Discounts",
    slug: "discount"
  },
  slug: "discount-create",
  title: "Criar descontos",
  description: "Criar os próprios descontos"
}

const createAny = {
  group: {
    title: "Discounts",
    slug: "discount"
  },
  slug: "discount-create-any",
  title: "Criar qualquer desconto",
  description: "Criar todos os descontos"
}

module.exports = {
  view,
  viewAny,
  edit,
  editAny,
  remove,
  removeAny,
  create,
  createAny
}