import React, { Fragment } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import styled, { css, keyframes } from "styled-components";

import Access from "@cthulhi/comp-accs";
import Context from "@context";

import reducers from "./reducers";
import actions from "./actions";

import Bootstrap from "./bootstrap";
import AccessView from "./views/access/AccessView.react";
import WebView from "./views/website/WebView.react";
import IntranetView from "./views/intranet/IntranetView.react";
import PwaView from "./views/pwa/PwaView.react";

import permissions from "@perm";

const Intranet = ({ match }) => {
  const preLoadedPermissions = Object.values(permissions).reduce((a, b) => {
    if (a && b) return [...Object.values(a), ...Object.values(b)];
    if (b) return [...Object.values(b)];
    return [];
  });

  return (
    <Switch>
      <Access.Session onDeny={() => <Redirect to="/access" />}>
        <Access.Permission preLoaded={preLoadedPermissions}>
          <Access.RestrictRoute onAllow={IntranetView} />
        </Access.Permission>
      </Access.Session>
    </Switch>
  );
};

const WebRoutes = props => {
  return (
    <Switch>
      <Route path="/parceiroapp" component={PwaView} />
      <Route path="/access" component={AccessView} />
      <Route path="/parceiro" component={Intranet} />
      <Route component={WebView} />
    </Switch>
  );
};

const App = props => {
  return (
    <Context.Provider reducers={reducers} actions={actions}>
      <Bootstrap>
        <Helmet>
          <html lang="pt-br" />
          <meta charset="utf-8" />
          <meta http-equiv="X-UA-Compatible" content="ie=edge" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <title>{process.env.head_title}</title>
          <link
            href="https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900"
            rel="stylesheet"
          ></link>
          <script async defer src="https://connect.facebook.net/en_US/sdk.js"></script>
        </Helmet>
        <WebRoutes />
      </Bootstrap>
    </Context.Provider>
  );
};

const AppStyled = styled(App)`
  position: relative;
`;

export default AppStyled;
