const optionLoading = { value: "", label: "Carregando..." };
const optionSelectState = { value: "", label: "Selecione um estado..." };
const optionSelectStateToCity = { value: "", label: "Aguardando seleção do estado..." };
const optionSelectCity = { value: "", label: "Selecione uma cidade..." };
const optionSelectType = { value: "", label: "Selecione um tipo..." };
const optionSelectStage = { value: "", label: "Selecione um status..." };

const events = ({ ctxAction, ...props }) => {
  return {
    zipcode: {
      onChange: (value, rawValue, update) => {
        if (value.length == 10) {
          ctxAction("address")
            .findAddressByZipCode(value.replace(/\./g, "").replace(/\-/g, ""))
            .then(resAddress => {
              update("district", { value: resAddress.district }, resAddress.district);
              update("address", { value: resAddress.address }, resAddress.address);

              update("state", { disabled: true, options: [optionLoading] });
              ctxAction("address")
                .findState(resAddress.uf)
                .then(resState => {
                  if (resState) {
                    const cityId = resState.filter(u => {
                      return u.selected;
                    })[0].value;

                    update(
                      "state",
                      { disabled: false, value: cityId, options: [optionSelectState, ...resState] },
                      cityId
                    );

                    update("city", { disabled: true, options: [optionLoading] });
                    ctxAction("address")
                      .findCity(cityId)
                      .then(resCity => {
                        update(
                          "city",
                          {
                            disabled: false,
                            value: resAddress.city,
                            options: [optionSelectCity, ...resCity]
                          },
                          resAddress.city
                        );
                      });
                  }
                });
            });
        }

        return update("zipcode", { value }, rawValue);
      }
    },
    city: {
      onChangeFromState: (field, rawValue, update, get) => {
        const stateValue = field.value ? field.value : get("state");
        update("city", { value: "", options: [{ value: "", label: "Carregando..." }] });
        ctxAction("address")
          .findCity(stateValue)
          .then(res => {
            update("city", { value: get("city") ? get("city") : "", disabled: false, options: [optionSelectCity, ...res] })
          });
      }
    }
  };
};

const values = ({ ctxAction, ...props }) => {
  let initValues = {
    alias: {
      className: "uk-input",
      placeholder: "Razão social",
      disabled: true,
      value: "",
      label: {
        text: "Razão social",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      }
    },
    stage: {
      className: "uk-select",
      label: {
        text: "Status",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      },
      value: "toapproval",
      disabled: true,
      options: [
        optionSelectStage,
        { value: "toapproval", label: "À aprovar" },
        { value: "onapproval", label: "Em aprovação" },
        { value: "approved", label: "Aprovado" },
        { value: "denied", label: "Negado" }
      ]
    },
    name: {
      className: "uk-input",
      placeholder: "Nome fantasia",
      disabled: true,
      value: "",
      label: {
        text: "Nome fantasia",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-1"
      }
    },
    cnpj: {
      className: "uk-input",
      placeholder: "CNPJ",
      value: "",
      masks: ["99.999.999/9999-99"],
      disabled: true,
      label: {
        text: "CNPJ",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      }
    },
    zipcode: {
      className: "uk-input",
      placeholder: "CEP",
      masks: ["99.999-999"],
      value: "",
      label: {
        text: "CEP",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      }
    },
    address: {
      className: "uk-input",
      placeholder: "Endereço",
      value: "",
      label: {
        text: "Endereço",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-1"
      }
    },
    district: {
      className: "uk-input",
      placeholder: "Bairro",
      value: "",
      label: {
        text: "Bairro",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-2-5@m"
      }
    },
    number: {
      className: "uk-input",
      placeholder: "Número",
      value: 0,
      label: {
        text: "Número",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-5@m"
      }
    },
    complement: {
      className: "uk-input",
      placeholder: "Complemento",
      value: "",
      label: {
        text: "Complemento",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-2-5@m"
      }
    },
    state: {
      className: "uk-select",
      label: {
        text: "Estado",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      },
      value: "",
      options: update => {
        update("state", { value: "", disabled: true, options: [optionLoading] });
        ctxAction("address")
          .findState()
          .then(res => update("state", { disabled: false, options: [optionSelectState, ...res] }));
      }
    },
    city: {
      className: "uk-select",
      label: {
        text: "Cidade",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      },
      value: "",
      disabled: true,
      options: [optionSelectStateToCity]
    },
    phone: {
      className: "uk-input",
      placeholder: "Telefone",
      masks: ["(99) 9999-9999", "(99) 99999-9999", "+99 (99) 9999-9999", "+99 (99) 99999-9999"],
      value: "",
      label: {
        text: "Telefone",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-3@m"
      },
      options: [
        optionSelectType,
        { value: "fisico", label: "Físico" },
        { value: "virtual", label: "Virtual" }
      ]
    },
    contact: {
      className: "uk-input",
      placeholder: "Contato",
      value: "",
      label: {
        text: "Contato",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      }
    },
    email: {
      className: "uk-input",
      placeholder: "E-mail",
      value: "",
      label: {
        text: "E-mail",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-2-3@m"
      }
    },
    site: {
      className: "uk-input",
      placeholder: "Site",
      value: "",
      label: {
        text: "Site",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      }
    },
    status: {
      className: "uk-checkbox",
      value: false,
      label: {
        position: "below",
        text: "Ativo",
        className: "uk-form-label uk-margin-small-left"
      },
      wrapper: {
        className: "field-checkbox uk-width-1-3@m"
      }
    }
  };

  return initValues;
};

const actions = ({ token, ctxAction, ntfAction, updater, history, path, ...props }) => {
  return {
    entity: {
      name: "business",
      token: token,
      form: ctxAction("form")
    },
    actions: {
      entity: ctxAction,
      notification: ntfAction,
      updater: updater
    },
    find: {
      success: {
        name: "BusinessFoundResult",
        action: { notify: false, redirect: false }
      },
      error: {
        name: "BusinessErrorResult",
        message: "Houve um erro ao buscar o parceiro",
        action: { notify: "businessForm", redirect: false }
      }
    },
    update: {
      success: {
        name: "BusinessUpdateResult",
        message: "Parceiro atualizado com sucesso",
        action: { notify: "businessForm", redirect: () => history.push(path) }
      },
      error: {
        name: "BusinessErrorResult",
        message: "Houve um erro ao atualizar o parceiro",
        action: { notify: "businessForm", redirect: false }
      }
    },
    create: {}
  };
};

export { events, values, actions };
