import React, { Fragment, useEffect, useState } from "react";
import { Link, Switch, Route, withRouter, Redirect } from "react-router-dom";

import Access from "@cthulhi/comp-accs";

import BranchListPage from "./BranchListPage.react";
import BranchProfileListPage from "./BranchProfileListPage.react";
import BranchFormPage from "./BranchFormPage.react";
import BranchProfileFormPage from "./BranchProfileFormPage.react";

import permissions from "@perm";

const BranchPage = ({ match, ...props }) => {
  return (
    <Switch>
      <Access.RestrictRoute
        key="branch-list"
        onAccess={[permissions.branch.viewAny]}
        parentPath={match.path}
        path={`${match.path}`}
        exact
        onAllow={BranchListPage}
      />
      <Access.RestrictRoute
        key="branch-list-profile"
        onAccess={[permissions.branch.view]}
        parentPath={match.path}
        path={`${match.path}/profile`}
        exact
        onAllow={BranchProfileListPage}
      />
      <Access.RestrictRoute
        key="branch-create"
        onAccess={[permissions.branch.createAny]}
        parentPath={match.path}
        path={`${match.path}/create`}
        onAllow={BranchFormPage}
      />
      <Access.RestrictRoute
        key="branch-create-profile"
        onAccess={[permissions.branch.create]}
        parentPath={`${match.path}/profile`}
        path={`${match.path}/profile/create`}
        onAllow={BranchProfileFormPage}
      />
      <Access.RestrictRoute
        key="branch-edit"
        onAccess={[permissions.branch.editAny]}
        parentPath={match.path}
        path={`${match.path}/edit/:id`}
        onAllow={BranchFormPage}
      />
      <Access.RestrictRoute
        key="branch-edit-profile"
        onAccess={[permissions.branch.edit]}
        parentPath={`${match.path}/profile`}
        path={`${match.path}/profile/edit/:id`}
        onAllow={BranchProfileFormPage}
      />
    </Switch>
  );
};

export default withRouter(BranchPage);
