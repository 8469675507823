import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import { List } from "./../../../wrappers/ListWrappers.react";

import { useFilter } from "@cthulhi/comp-list"

import Access from "@cthulhi/comp-accs";
import Context from "@context";

import Notification from "@cthulhi/comp-noty";
import NotificationComponent from "../../../utils/Notification.react";
import ModalConfirmRemoval from "./../../../wrappers/ModalConfirmRemoval.react";

import permissions from "@perm";

import {
  events as userListEvents,
  values as userListValues,
  actions as userListActions,
  options as userListOptions,
  schema as userListSchema
} from "./components/UserList.config";

const UserListPage = ({ match, history, ...props }) => {

  const { state: accState, action: accAction, consts: accConsts } = Access.useState();
  const { action: ctxAction } = Context.useState();
  const { state: ntfState, action: ntfAction } = Notification.useState();
  const [ modal, setModal ] = useState({ show: false, Content: () => null });

  const token = `Bearer ${accState.access.session.context.token}`

  const onEdit = value => {
    history.push(`${match.path}/edit/${value._id}`);
  };

  const onRemoveAction = (value, refresh, loader) => {
    loader(true)
    ctxAction("user")
      .remove({ _id: value._id }, token)
      .then(res => {
        if (res.name != "UserDeleteResult") throw res;

        refresh();

        ntfAction("notification").add(
          res.code,
          "userListPage",
          "success",
          "Usuário removido com sucesso.",
          undefined,
          process.env.notificationTimeOut
        );

      })
      .catch(err => {
        loader(false)

        ntfAction("notification").add(
          err.code,
          "userListPage",
          "danger",
          "Houve um erro ao remover o usuário.",
          undefined,
          process.env.notificationTimeOut
        );
      });
  };

  const onRemove = (value, refresh, loader) => {
    setModal({
      show: true,
      Content: props => {
        return (
          <ModalConfirmRemoval
            onConfirm={() => onRemoveAction(value, refresh, loader)}
            onClose={() => setModal({ show: false, Content: () => null })}
          />
        );
      }
    });
  }

  const { filter, list, page, limit, options } = useFilter({ 
    schema: userListSchema, 
    events: userListEvents({ ctxAction }), 
    options: userListOptions({ onEdit, onRemove }), 
    values: userListValues({ ctxAction, token }), 
    actions: userListActions({ ctxAction, token })
  })

  useEffect(() => {
    if (
      accAction("access").getPermissionListStatus([permissions.user.createAny]) ==
      accConsts.STS_PERMS_ALLOWED
    ) {
      filter.updater("roles", {
        options: [...filter.values.roles.options, { label: "Administrador", value: "admin" }].sort((a, b) => a.value > b.value)
      });
    }
  }, [accAction("access").getPermissionListStatus([permissions.user.createAny])]);

  return (
    <Fragment>
      <h2 className="title">Listar usuários</h2>
      <div className="uk-margin-top">
        <Notification.NotificationWrapper group="userListPage" component={NotificationComponent} />
        <List { ...{ filter, list, page, limit, options } } />
        <div className="container-modal">{modal.show ? <modal.Content /> : null}</div>
      </div>
    </Fragment>
  );
};

export default withRouter(UserListPage);
