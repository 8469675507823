import React, { Fragment } from "react"
import { withRouter } from "react-router-dom"

const Page = ({ ...props }) => {

  return (
    <Fragment>
      <h2 className="title">Consumo de vouchers</h2>
    </Fragment>
  )
} 

export default withRouter(Page) 