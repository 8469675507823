import { schema, types } from "@cthulhi/pack-schm";
import { schema as commonSchema } from "./common.schema";
import { types as fields } from "@cthulhi/comp-form";

const schm = schema("event", {
  ...commonSchema.schema.props,
  name: {
    type: types.string.type,
    field: fields.textfield.type,
    required: { contains: true }
  },
  branch: {
    type: types.string.type,
    required: { contains: true },
  },
  available: {
    type: types.boolean.type,
    field: fields.checkbox.type,
    required: true
  },
  startDate: {
    type: types.datetime.type,
    field: fields.datetime.type,
    required: { contains: true }
  },
  endDate: {
    type: types.datetime.type,
    field: fields.datetime.type,
    required: { contains: true }
  },
  about: {
    type: types.string.type,
    field: fields.textarea.type,
    required: { contains: true }
  },
  site: {
    type: types.string.type,
    field: fields.textfield.type,
    required: { contains: true }
  },
  categories: {
    type: types.array.type,
    field: "ant-select",
    required: { contains: true },
    items: {
        type: types.string.type
    }
  },
});

export { schm as schema };
