import React, { Fragment } from "react";
import { schema as schm, types } from "@cthulhi/pack-schm";
import { types as fields } from "@cthulhi/comp-form";

const schema = schm("filter", {
  name: {
    type: types.string.type,
    field: fields.textfield.type
  },
  email: {
    type: types.string.type,
    field: fields.textfield.type
  },
  stage: {
    type: types.string.type,
    field: fields.select.type
  }
});

const events = ({ ...props }) => {
  return {};
};

const values = ({ ...props }) => {
  return {
    name: {
      filter: "text",
      className: "uk-input",
      placeholder: "Nome fantasia",
      label: {
        text: "Nome Fantasia",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-3@m"
      }
    },
    email: {
      filter: "text",
      className: "uk-input",
      placeholder: "E-mail",
      label: {
        text: "E-mail",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-3@m"
      }
    },
    stage: {
      className: "uk-select",
      label: {
        text: "Status",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-3@m"
      },
      options: update => {
        update("stage", {
          options: [
            { value: "", label: "Selecione um status..." },
            { value: "toapproval", label: "À aprovar" },
            { value: "onapproval", label: "Em aprovação" },
            { value: "approved", label: "Aprovado" },
            { value: "denied", label: "Negado" }
          ]
        });
      }
    }
  };
};

const options = ({ onEdit, onRemove, ...props }) => {
  return {
    filter: {
      fields: true,
      submit: true
    },
    pagination: {
      next: {
        value: "Próximo",
        className: "page-next"
      },
      prev: {
        value: "Anterior",
        className: "page-prev"
      },
      numbers: {
        className: "page-numbers"
      },
      total: true
    },
    limit: {
      value: 5,
      limits: [
        { value: 5, label: "5 Items" },
        { value: 10, label: "10 Items" },
        { value: 20, label: "20 Items" }
      ]
    },
    items: {
      name: {
        header: {
          value: "Nome fantasia",
          className: ""
        },
        column: {
          className: "",
          value: "name"
        }
      },
      email: {
        header: "E-mail",
        column: "email"
      },
      stage: {
        header: "Status",
        column: "stage",
        parse: value => {
          switch (value) {
            case "toapproval":
              return "À aprovar";
            case "onapproval":
              return "Em aprovação";
            case "approved":
              return "Aprovado";
            case "denied":
              return "Negado";
            default:
              return "-";
          }
        }
      },
      actions: {
        header: "Ações",
        column: (item, refresh, loader) => {
          return {
            value: (
              <Fragment>
                <div className="uk-grid-small uk-child-width-auto" data-uk-grid>
                  <div className="uk-text-nowrap">
                    <button
                      className="uk-icon-button uk-button-primary"
                      onClick={() => onEdit(item, refresh, loader)}
                    >
                      <i className="icon icon-edit" aria-hidden="true"></i>
                    </button>
                    <button
                      className="uk-icon-button uk-button-danger uk-margin-small-left"
                      onClick={() => onRemove(item, refresh, loader)}
                    >
                      <i className="icon icon-delete" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </Fragment>
            )
          };
        }
      }
    }
  };
};

const actions = ({ ctxAction, token, ...props }) => {
  return {
    find: async (filters, options) => {
      return ctxAction("business").find({ ...filters }, { sort: { _id: -1 }, ...options }, token);
    }
  };
};

export { events, values, actions, options, schema };
