const events = ({ ctxAction, ...props }) => {
  return {};
};

const values = ({ ctxAction, token, ...props }) => {
  let initValues = {
    body: {
      className: "uk-textarea",
      placeholder: "Conteúdo",
      value: "",
      label: {
        text: "Conteúdo",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-1"
      }
    },
    name: {
      className: "uk-input",
      placeholder: "Nome Descritivo",
      value: "",
      label: {
        text: "Nome Descritivo",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-1"
      }
    },
    component: {
      disabled: true,
      className: "uk-input",
      placeholder: "Content slug",
      value: "",
      label: {
        text: "Content slug",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      }
    },
    identifier: {
      disabled: true,
      className: "uk-input",
      placeholder: "Identifier slug",
      value: "",
      label: {
        text: "Identifier slug",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      }
    }
  };

  return initValues;
};

const actions = ({ token, ctxAction, ntfAction, updater, history, path, ...props }) => {
  return {
    entity: {
      name: "content",
      token: token,
      form: ctxAction("form")
    },
    actions: {
      entity: ctxAction,
      notification: ntfAction,
      updater: updater
    },
    find: {
      success: {
        name: "ContentFoundResult",
        action: { notify: false, redirect: false }
      },
      error: {
        name: "ContentErrorResult",
        message: "Houve um erro ao buscar o content",
        action: { notify: "contentListPage", redirect: () => history.push(path) }
      }
    },
    create: {
      success: {
        name: "ContentCreateResult",
        message: "Content adicionado com sucesso",
        action: { notify: "contentListPage", redirect: () => history.push(path) }
      },
      error: {
        name: "ContentErrorResult",
        message: "Houve um erro ao adicionar o content",
        action: { notify: "contentForm", redirect: false }
      }
    },
    update: {
      success: {
        name: "ContentUpdateResult",
        message: "Content atualizado com sucesso",
        action: { notify: "contentListPage", redirect: () => history.push(path) }
      },
      error: {
        name: "ContentErrorResult",
        message: "Houve um erro ao atualizar o content",
        action: { notify: "contentForm", redirect: false }
      }
    }
  };
};

export { events, values, actions };
