import pt from "../constants/partner.constant";

export default (dispatch, state) => {
  const dispatchSetStatus = data => {
    dispatch({
      type: pt.ACT_SET_STATUS,
      data: data
    });
  };

  const remove = (values, authorization) => {
    var args = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization
      },
      body: JSON.stringify(values),
      credentials: "same-origin"
    };

    dispatchSetStatus(pt.STS_DELETE_REQUEST);

    return fetch(process.env.domainPartnerDelete, args)
      .then(res => res.json())
      .then(res => {
        dispatchSetStatus(pt.STS_DELETE_RESPONSE);
        dispatchSetStatus(pt.STS_DELETE_SUCCESS);

        setTimeout(function() {
          dispatchSetStatus(pt.STS_IDLE);
        }, 1000);

        return res;
      })
      .catch(error => {
        dispatchSetStatus(pt.STS_DELETE_ERROR);

        setTimeout(function() {
          dispatchSetStatus(pt.STS_IDLE);
        }, 1000);

        return error;
      });
  };

  const deleteBranch = async (values, authorization) => {
    if (!values._id) return new Promise(() => null);
    
    const args = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization
      },
      body: JSON.stringify(values),
      credentials: "same-origin"
    };

    return await fetch(process.env.domainBranchDelete, args)
  }

  const updateBranch = async (values, authorization) => {
    var args = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization
      },
      body: JSON.stringify({
        ...values,
        branch: undefined
      }),
      credentials: "same-origin"
    };

    if (values.removed) {
      await deleteBranch(values, authorization);
      return;
    }

    if (!values._id) {
      return await createBranch(values, authorization);
    }

    const res = await fetch(process.env.domainBranchUpdate, args);
    const { result } = await res.json();

    if (values.branch) {
      await updateBranch({ ...values.branch, parentId: result._id }, authorization);
    } 
    
    return result._id;
  }

  const update = async (values, authorization) => {
    dispatchSetStatus(pt.STS_UPDATE_REQUEST);
    
    if (values.branches) {
      values.branches = await Promise.all(values.branches.map(async branch => updateBranch(branch, authorization)));
      values.branches = values.branches.filter(branch => !!branch);
    }
    
    var args = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization
      },
      body: JSON.stringify(values),
      credentials: "same-origin"
    };

    return fetch(process.env.domainPartnerUpdate, args)
      .then(res => res.json())
      .then(res => {
        dispatchSetStatus(pt.STS_UPDATE_RESPONSE);
        dispatchSetStatus(pt.STS_UPDATE_SUCCESS);

        setTimeout(function() {
          dispatchSetStatus(pt.STS_IDLE);
        }, 1000);
        return res;
      })
      .catch(error => {
        dispatchSetStatus(pt.STS_UPDATE_ERROR);

        setTimeout(function() {
          dispatchSetStatus(pt.STS_IDLE);
        }, 1000);

        return error;
      });
  };

  const createBranch = async (values, authorization) => {
    var args = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization
      },
      body: JSON.stringify({
        ...values,
        branch: undefined
      }),
      credentials: "same-origin"
    };

    const res = await fetch(process.env.domainBranchCreate, args);
    const { result } = await res.json();

    if (values.branch) {
      await createBranch({ ...values.branch, parentId: result._id }, authorization);
    } 
    
    return result._id;
  }
  
  const create = async (values, authorization) => {
    dispatchSetStatus(pt.STS_CREATE_REQUEST);

    if (values.branches) {
      values.branches = await Promise.all(values.branches.map(async branch => createBranch(branch, authorization)));
      values.branches = values.branches.filter(branch => !!branch);
    }
    
    var args = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization
      },
      body: JSON.stringify(values),
      credentials: "same-origin"
    };

    return fetch(process.env.domainPartnerCreate, args)
      .then(res => res.json())
      .then(res => {
        dispatchSetStatus(pt.STS_CREATE_RESPONSE);
        dispatchSetStatus(pt.STS_CREATE_SUCCESS);

        setTimeout(function() {
          dispatchSetStatus(pt.STS_IDLE);
        }, 1000);
        return res;
      })
      .catch(error => {
        dispatchSetStatus(pt.STS_CREATE_ERROR);

        setTimeout(function() {
          dispatchSetStatus(pt.STS_IDLE);
        }, 1000);

        return error;
      });
  };

  const findBranch = (filters, authorization) => {
    if (!filters || !filters._id) return new Promise(() => null);
    
    const args = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization
      },
      body: JSON.stringify({ filters, options: { sort: { _id: -1 }} }),
      credentials: "same-origin"
    };

    console.log('=======> POS STRINGFADA: ', { filters, options: { sort: { _id: -1 }} });
    return fetch(process.env.domainBranchFindList, args)
      .then(res => res.json())
      .then(res => res.result.map(r => ({
        ...r,
        children: r.payment
      }
    )))
  }

  const find = async (filters, options, authorization, loader) => {
    var args = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization
      },
      body: JSON.stringify({ ...filters, options: { sort: { _id: -1 }, ...options } }),
      credentials: "same-origin"
    };

    dispatchSetStatus(pt.STS_FIND_REQUEST);

    if (loader) loader.start();

    return fetch(process.env.domainPartnerFind, args)
      .then(res => res.json())
      .then(async res => {
        if (filters.filters && filters.filters._id && res.result) {
          res.result.values = res.result.values.map(async value => ({
            ...value,
            branches: await findBranch({ _id: value.branches }, authorization)
            
          }));
          
          res.result.values = await Promise.all(res.result.values);
        }

        return res;
      })
      .then(async res => {
        dispatchSetStatus(pt.STS_FIND_RESPONSE);
        dispatchSetStatus(pt.STS_FIND_SUCCESS);

        if (loader) loader.finish();

        setTimeout(function() {
          dispatchSetStatus(pt.STS_IDLE);
        }, 1000);

        return res;
      })
      .catch(error => {
        dispatchSetStatus(pt.STS_FIND_ERROR);

        if (loader) loader.finish();

        setTimeout(function() {
          dispatchSetStatus(pt.STS_IDLE);
        }, 1000);

        return error;
      });
  };

  return {
    find,
    create,
    update,
    remove
  };
};
