import lc from "./../constants/loader.constant"

export default (dispatch, state) => {

  const addLoading = (event, place = "window") => {
    return dispatch({ type: lc.ACT_ADD_LOADING, data: { event, place } })
  }
  
  const removeLoading = (event, place = "window") => {
    return dispatch({ type: lc.ACT_DEL_LOADING, data: { event, place } })
  }

  const isLoading = (event, place = "window") => {
    if(event) return typeof state.list[place][event] != 'undefined' ? true : false;

    return state.list[place].length > 0
  }

  return { 
    addLoading,
    removeLoading,
    isLoading
  }
}