import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import Access from "@cthulhi/comp-accs";
import Context from "@context";
import { useFields, useForms } from "@cthulhi/comp-form";

import { schema as bannerFormSchema } from "@schm/banner/create.form.schema";
import { schema as bannerLinkedToBenefitFormSchema } from "@schm/banner/create.linked.form.schema";
import { TYPE_VALUES } from "@schm/banner/values.const";

import {
  events as bannerFormEvents,
  values as bannerFormValues,
  actions as bannerFormActions
} from "./components/BannerForm.config";

import { FieldLabelWrapper } from "../../../wrappers/FormWrappers.react";

import Notification from "@cthulhi/comp-noty";
import NotificationComponent from "../../../utils/Notification.react";

import LoaderComponent from "../../../utils/LoaderCustomComponent.react";

const BannerPage = ({ match, parentPath, history, ...props }) => {
  const { state: accState, action: accAction } = Access.useState();
  const { action: ctxAction, state: ctxtState } = Context.useState();
  const { state: ntfState, action: ntfAction } = Notification.useState();
  const [bannerSchema, setBannerSchema] = useState(bannerFormSchema);
  const [validation, setValidation] = useState({})

  const { form, fields, values, updater, cleaner } = useFields({
    schema: bannerSchema,
    events: bannerFormEvents({ ctxAction }),
    values: bannerFormValues({
      ctxAction,
      token: `Bearer ${accState.access.session.context.token}`
    })
  });

  const { find, update, create } = useForms(
    bannerFormActions({
      ctxAction,
      ntfAction,
      updater,
      history,
      path: parentPath,
      token: `Bearer ${accState.access.session.context.token}`
    })
  );

  const bannerFormSubmit = async () => {
    let vs = {};

    Object.entries(values).map(([key, value]) => {
      const hasValue = typeof value.value != "undefined" && value.value !== "";
      const hasRawValue = typeof value.rawValue != "undefined" && value.rawValue !== "";

      vs[key] = hasRawValue ? value.rawValue : hasValue ? value.value : value.defaultValue;
    });

    vs.benefitId = vs.benefitId ? vs.benefitId : vs.benefit
    delete vs.benefit;

    if (vs.type === TYPE_VALUES.NO_BENEFIT) {
      vs.benefitId = null;
    }

    if (vs.imageUrl && vs.imageUrl.includes("blob:")) {
      await fetch(values.imageUrl.rawValue)
        .then(res => res.blob())
        .then(blob => {
          vs.imageUrlBlob = blob;
        });
    }
    
    const validate = action => {
      action.then(() => {}).catch(err => {
        console.log(err);
        setValidation(err)
      })
    }

    match.params.id ? validate(update(vs)) : validate(create(vs));
  };

  const updateSchema = isLinkedToBenefit => {
    setBannerSchema(isLinkedToBenefit ? bannerLinkedToBenefitFormSchema : bannerFormSchema);
  };

  useEffect(() => {
    updateSchema(typeof values.type != "undefined" && values.type.value == TYPE_VALUES.BENEFIT);
  }, [JSON.stringify(typeof values.type != "undefined" && values.type.value)]);

  useEffect(() => {
    if (match.params.id) {
      find({
        filters: { _id: match.params.id },
        aggregates: [
          {
            lookup: {
              from: "benefit",
              field: "benefitId",
              match: "_id",
              result: "benefit",
              op: "$eq",
            }
          }
        ] 
      });
    }

    return () => {
      cleaner();
    };
  }, [match.params.id]);

  return (
    <Fragment>
      <h2 className="title">{match.params.id ? "Editar banner" : "Novo banner"}</h2>
      <div className="uk-margin-top">
        <ul data-uk-accordion="multiple: true">
          <li className="uk-open">
            <a className="title title-lead-small title-accordion uk-accordion-title">
              Dados do banner
            </a>
            <div className="uk-accordion-content">
              <Notification.NotificationWrapper
                group="bannerForm"
                component={NotificationComponent}
              />
              <div className="uk-card uk-card-default uk-card-body uk-padding-small">
                <LoaderComponent from="bannerForm" />
                <form className="uk-grid-small" data-uk-grid>
                  <FieldLabelWrapper name="type" fields={fields} values={values} validation={validation.type}/>
                  <FieldLabelWrapper name="weight" fields={fields} values={values} validation={validation.weight}/>
                  <FieldLabelWrapper name="benefit" fields={fields} values={values} validation={validation.benefitId}/>
                  <FieldLabelWrapper name="title" fields={fields} values={values} validation={validation.title}/>
                  <FieldLabelWrapper name="description" fields={fields} values={values} validation={validation.description}/>
                  <FieldLabelWrapper name="redirectUrl" fields={fields} values={values} validation={validation.redirectUrl}/>
                  <FieldLabelWrapper name="imageUrl" fields={fields} values={values} validation={validation.imageUrl}/>
                </form>
              </div>
            </div>
          </li>
        </ul>
        <div className="uk-margin-top">
          <button
            onClick={bannerFormSubmit}
            className="uk-button uk-button-large uk-button-secondary"
            style={{ float: "right" }}
            disabled={
              typeof ctxtState.loader.list["bannerForm"] != "undefined" &&
              ctxtState.loader.list["bannerForm"].length > 0
            }
          >
            {match.params.id ? "Atualizar" : "Salvar"}
          </button>
        </div>
      </div>
    </Fragment>
  );
};

// @ts-ignore
export default withRouter(BannerPage);
