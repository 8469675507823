import moment from "moment";
import { TYPE_VALUES } from "@schm/banner/values.const";

const optionSelectBenefit = [{ value: "", label: "Selecione um benefício..." }];
const optionLoading = { value: "", label: "Carregando...", disabled: true };

const events = ({ ctxAction, ...props }) => {
  return {
    type: {
      onChange: (value, rawValue, update, get, vals, nodeElement) => {
        update("type", { value }, rawValue);
        if (value === TYPE_VALUES.NO_BENEFIT) {
          update("benefitId", { value: undefined });
        }
      }
    }
  };
};

const values = ({ ctxAction, token, ...props }) => {
  let initValues = {
    type: {
      defaultValue: TYPE_VALUES.NO_BENEFIT,
      className: "uk-select",
      placeholder: "Tipo",
      label: {
        text: "Tipo",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-4-5@m"
      },
      options: [
        { value: TYPE_VALUES.NO_BENEFIT, label: "Banner sem vínculo à um benefício específico" },
        { value: TYPE_VALUES.BENEFIT, label: "Banner atrelado à um benefício" }
      ]
    },
    weight: {
      className: "uk-input",
      placeholder: "Ordem",
      label: {
        text: "Ordem",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-5@m"
      }
    },
    benefit: {
      className: "uk-select",
      defaultValue: "",
      label: {
        text: "Benefício",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-1@m"
      },
      value: [{ _id: "", title: "Digite o título do benefício" }],
      search: (update) => (input) => {
        update("benefitId", { value: "", disabled: true, options: [optionLoading] });
        return ctxAction("benefit").find(
          {
            filters: {
              flagHidden: false,
              shelfLifeStart: {
                $lte: `${moment().format()}Z`,
              },
              shelfLifeEnd: {
                $gte: `${moment().format()}Z`,
              },
            },
          },
          { sort: { title: 1 } },
          token,
        )
          .then((res) => {
            if (res.name != "BenefitFoundResult" || res.result.values.length == 0) {
              return [{
                _id: "", title: "Nenhum benefício encontrado"
              }];
            }
            return res.result.values;
          });
      },
      optionSchema: (benefit) => ({
        label: benefit.title,
        value: benefit._id,
        key: benefit._id
      })
    },
    title: {
      className: "uk-input",
      placeholder: "Título",
      defaultValue: "",
      label: {
        text: "Título",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-1@m"
      }
    },
    description: {
      className: "uk-input",
      placeholder: "Descrição",
      defaultValue: "",
      label: {
        text: "Descrição",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-1@m"
      }
    },
    redirectUrl: {
      className: "uk-input",
      placeholder: "https://www.assinensc.com.br/?url_state=https://clubensc.com.br/",
      defaultValue: "",
      label: {
        text: "Redirecionamento para...",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-1@m"
      }
    },
    imageUrl: {
      input: {
        wrapper: {
          className: "uk-width-1-1@m"
        },
        field: {
          className: "uk-input",
          placeholder: "Imagem"
        }
      },
      crops: {
        wrapper: {
          className: "uk-margin-top uk-grid uk-margin-top uk-margin-small"
        },
        cropper: {
          className: "uk-width-1-2@m uk-first-column"
        },
        croppeds: {
          className: "uk-width-1-2@m uk-grid"
        },
        images: [
          { wrapper: { className: "uk-width-1-2@m" }, image: { style: { width: "100%" } } },
          { wrapper: { className: "uk-width-1-2@m" }, image: { style: { width: "50%" } } }
        ]
      },
      locked: false,
      keepSelection: false,
      unit: "%",
      height: 100,
      aspect: 16 / 9,
      label: {
        text: "Imagem",
        className: "uk-form-label"
      },
      description: {
        text: "Para uma boa aparência, a imagem devem conter no mínimo 1280 pixels de largura."
      },
      wrapper: {
        className: "field-wrapper uk-width-3-5@m"
      }
    }
  };

  return initValues;
};

const actions = ({ token, ctxAction, ntfAction, updater, history, path, ...props }) => {
  return {
    entity: {
      name: "banner",
      token: token,
      form: ctxAction("form")
    },
    actions: {
      entity: ctxAction,
      notification: ntfAction,
      updater: updater
    },
    find: {
      success: {
        name: "BannerFoundResult",
        action: { notify: false, redirect: false }
      },
      error: {
        name: "BannerErrorResult",
        message: "Houve um erro ao buscar o banner",
        action: { notify: "bannerListPage", redirect: () => history.push(path) }
      }
    },
    create: {
      success: {
        name: "BannerCreateResult",
        message: "Banner adicionado com sucesso",
        action: { notify: "bannerListPage", redirect: () => history.push(path) }
      },
      error: {
        name: "BannerErrorResult",
        message: "Houve um erro ao adicionar o banner",
        action: { notify: "bannerForm", redirect: false }
      }
    },
    update: {
      success: {
        name: "BannerUpdateResult",
        message: "Banner atualizado com sucesso",
        action: { notify: "bannerListPage", redirect: () => history.push(path) }
      },
      error: {
        name: "BannerErrorResult",
        message: "Houve um erro ao atualizar o banner",
        action: { notify: "bannerForm", redirect: false }
      }
    }
  };
};

export { events, values, actions };
