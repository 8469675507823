import React from "react";
import { Switch, withRouter } from "react-router-dom";

import Access from "@cthulhi/comp-accs";

import FaqFormPage from "./FaqFormPage.react";
import FaqListPage from "./FaqListPage.react";

import permissions from "@perm";

const FaqPage = ({ match, ...props }) => {
  return (
    <Switch>
      <Access.RestrictRoute
        key="faq-list"
        onAccess={[permissions.faq.viewAny, permissions.faq.editAny]}
        parentPath={match.path}
        path={`${match.path}`}
        exact
        onAllow={FaqListPage}
      />
      <Access.RestrictRoute
        key="faq-create"
        onAccess={[permissions.faq.viewAny, permissions.faq.editAny]}
        parentPath={match.path}
        path={`${match.path}/create`}
        onAllow={FaqFormPage}
      />
      <Access.RestrictRoute
        key="faq-edit"
        onAccess={[permissions.faq.editAny, permissions.faq.edit]}
        parentPath={match.path}
        path={`${match.path}/edit/:id`}
        onAllow={FaqFormPage}
      />
    </Switch>
  );
};

export default withRouter(FaqPage);
