import React, { Fragment, useState } from "react";
import { withRouter } from "react-router-dom";

import { List } from "./../../../wrappers/ListWrappers.react";

import { useFilter } from "@cthulhi/comp-list"

import Access from "@cthulhi/comp-accs";
import Context from "@context";

import Notification from "@cthulhi/comp-noty";
import NotificationComponent from "../../../utils/Notification.react";
import ModalConfirmRemoval from "./../../../wrappers/ModalConfirmRemoval.react";

import {
  events as bannerListEvents,
  values as bannerListValues,
  actions as bannerListActions,
  options as bannerListOptions,
  schema as bannerListSchema
} from "./components/BannerList.config";

const BannerListPage = ({ match, history, ...props }) => {

  const { state: accState, action: accAction } = Access.useState();
  const { action: ctxAction } = Context.useState();
  const { state: ntfState, action: ntfAction } = Notification.useState();
  const [ modal, setModal ] = useState({ show: false, Content: () => null });

  const token = `Bearer ${accState.access.session.context.token}`

  const onEdit = value => {
    history.push(`${match.path}/edit/${value._id}`);
  };

  const onRemoveAction = (value, refresh, loader) => {
    loader(true)
    ctxAction("banner")
      .remove({ _id: value._id }, token)
      .then(res => {
        if (res.name != "BannerDeleteResult") throw res;

        refresh();

        ntfAction("notification").add(
          res.code,
          "bannerListPage",
          "success",
          "Banner removido com sucesso.",
          undefined,
          process.env.notificationTimeOut
        );

      })
      .catch(err => {
        loader(false)

        ntfAction("notification").add(
          err.code,
          "bannerListPage",
          "danger",
          "Houve um erro ao remover o Banner.",
          undefined,
          process.env.notificationTimeOut
        );
      });
  };

  const onRemove = (value, refresh, loader) => {
    setModal({
      show: true,
      Content: props => {
        return (
          <ModalConfirmRemoval
            onConfirm={() => onRemoveAction(value, refresh, loader)}
            onClose={() => setModal({ show: false, Content: () => null })}
          />
        );
      }
    });
  }

  const { filter, list, page, limit, options } = useFilter({ 
    schema: bannerListSchema, 
    events: bannerListEvents({ ctxAction }), 
    options: bannerListOptions({ onEdit, onRemove }), 
    values: bannerListValues({ ctxAction, token }), 
    actions: bannerListActions({ ctxAction, token })
  })

  return (
    <Fragment>
      <h2 className="title">Listar banners</h2>
      <div className="uk-margin-top">
        <Notification.NotificationWrapper group="bannerListPage" component={NotificationComponent} />
        <List { ...{ filter, list, page, limit, options } } />
        <div className="container-modal">{modal.show ? <modal.Content /> : null}</div>
      </div>
    </Fragment>
  );
};

export default withRouter(BannerListPage);
