import { schema, types } from "@cthulhi/pack-schm";
import { types as fields } from "@cthulhi/comp-form";

const schm = schema("user", {
  businessId: {
    type: types.string.type,
    field: fields.select.type,
    required: { contains: true }
  },
  name: {
    type: types.string.type,
    field: fields.textfield.type,
    required: { contains: true }
  },
  password: {
    type: types.string.type,
    field: fields.textfield.type,
    required: { contains: true }
  },
  email: {
    type: types.string.type,
    field: fields.textfield.type,
    required: { contains: true }
  },
  roles: {
    type: types.string.type,
    field: fields.select.type,
    required: { contains: true }
  },
  status: {
    type: types.boolean.type,
    field: fields.checkbox.type,
    required: { contains: true }
  }
});

export { schm as schema };
