import React from 'react'

const InputComponent = ({value, ...props}) => {
  
  return (
    <input value={value.replace(/\:/g, "")} { ...props } />
  )
}

export default InputComponent
