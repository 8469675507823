import React, { Fragment } from "react";
import CategoryMenu from "../components/CategoryMenu.react";

const Sobre = () => {
  return (
    <Fragment>
      <main id="about">
        <div id="bnr">
          <div id="bnr-content">
            <h1>Ops...</h1>
            <p>Parece que a página ou o link que você entrou não está mais disponível.</p>
            <p>Acesse outras categorias ou faça uma busca por mais benefícios exclusivos.</p>
          </div>
        </div>
        <CategoryMenu />

        <div className="cnt"></div>
      </main>
    </Fragment>
  );
};

export default Sobre;
