import React, { Fragment, useEffect, useState } from 'react'
import { Link, Switch, Route, withRouter, Redirect } from "react-router-dom"

import Access from "@cthulhi/comp-accs"

import BusinessFormPage from "./BusinessFormPage.react";
import BusinessProfileFormPage from "./BusinessProfileFormPage.react";
import BusinessListPage from "./BusinessListPage.react";

import permissions from "@perm"

const BusinessPage = ({ match, ...props }) => {
  return (
    <Switch>
      <Access.RestrictRoute
        key="business-list"
        onAccess={[
          permissions.business.viewAny,
          permissions.business.editAny
        ]} 
        parentPath={match.path}
        path={`${match.path}`} exact
        onAllow={BusinessListPage} 
      />
      <Access.RestrictRoute
        key="business-create"
        onAccess={[
          permissions.business.viewAny,
          permissions.business.editAny
        ]} 
        parentPath={match.path}
        path={`${match.path}/create`} 
        onAllow={BusinessFormPage} 
      />
      <Access.RestrictRoute
        key="business-edit"
        onAccess={[
          permissions.business.editAny,
          permissions.business.edit
        ]} 
        parentPath={match.path}
        path={`${match.path}/edit/:id`} 
        onAllow={BusinessFormPage} 
      />
      <Access.RestrictRoute
        key="business-profile"
        onAccess={[
          permissions.business.edit
        ]} 
        parentPath={`${match.path}/profile`}
        path={`${match.path}/profile`}
        onAllow={BusinessProfileFormPage} 
      />
    </Switch>
  )

} 

export default withRouter(BusinessPage)