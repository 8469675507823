import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import BenefitList from "../utils/BenefitList.react";
import Context from "./../../../context";
import Loader from "../../utils/LoaderSiteComponent.react.js";
import formatBenefitLocation from "@html/views/website/utils/formatBenefitLocation";

// {
//   $or: [
//     {
//       title: {
//         $regex: match.params.text,
//         $options: "-i"
//       }
//     },
//     {
//       resume: {
//         $regex: match.params.text,
//         $options: "-i"
//       }
//     }
//   ]
// },

const BenefitSearchPage = ({ match, props }) => {
  const { state: ctxState, action: ctxAction, dispatch: ctxDispatch } = Context.useState();

  const [seeMoreIsLoading, setSeeMoreIsLoading] = useState();
  const [benefitObject, setBenefitObject] = useState({
    values: undefined,
    isDone: false
  });

  const loadBenefitObject = (currentList = []) => {
    setBenefitObject({
      values: undefined,
      isDone: false
    });
    setSeeMoreIsLoading(true);
    
    ctxAction("website")
      .getTermList(
        {
          term: match.params && match.params.text && match.params.text.length > 0 ? match.params.text : "",
          partial: true
        },
        {
          skip: currentList.length,
          limit: 16
        }
      )
      .then(eventResult => {
        formatBenefitLocation(eventResult, ctxAction).then(newEventValues => {
          setSeeMoreIsLoading(false);
          setBenefitObject({
            values: newEventValues.values,
            isDone: newEventValues.values.length < 16
          });
        });
      })
      .catch(err => {
        setBenefitObject({
          values: [],
          isDone: true
        });
      });
  };

  useEffect(() => {
    loadBenefitObject();
  }, [match.params.text]);

  return (
    <Fragment>
      <Loader show={!benefitObject.values} height="120" width="120" />
      <div className="cnt">
        <section className="dsp" style={{ paddingBottom: "20px" }}>
          <div className="dsp-title">
            <h2>Resultado da Pesquisa</h2>
          </div>
          {benefitObject.isDone && benefitObject.values.length > 0 ? (
            <h2 style={{ marginTop: 16, marginBottom: 16, display: 'flex', justifyContent: "center" }}>
              {`Encontramos ${benefitObject.values.length} resultado${benefitObject.values.length > 1 ? 's' : ''} para "${match.params.text}"`}.
            </h2>
          ) : null}

          <div className="dsp-grid">
            <BenefitList benefitList={benefitObject.values} />
          </div>

          {!benefitObject.isDone && benefitObject.values !== undefined && (
            <div className={"loader-container"}>
              <Loader show={seeMoreIsLoading} height="60" width="60" />
              <button className="dsp-more" onClick={() => loadBenefitObject(benefitObject.values)}>
                <span>Mostrar mais</span>
                <i className="icn icn-caret-down" aria-hidden="true"></i>
              </button>
            </div>
          )}
        </section>
      </div>
    </Fragment>
  );
};

export default withRouter(BenefitSearchPage);
