import { schema, types } from "@cthulhi/pack-schm";
import { types as fields } from "@cthulhi/comp-form";

const schm = schema("user_vip", {
  cpf: {
    type: types.string.type,
    field: fields.textfield.type,
    required: { contains: true }
  },
  name: {
    type: types.string.type,
    field: fields.textfield.type,
    required: { contains: true }
  },
  email: {
    type: types.string.type,
    field: fields.textfield.type,
    required: { contains: true }
  },
  expiracy: {
    type: types.datetime.type,
    field: fields.datetime.type
  },
  perfil: {
    type: types.string.type,
    field: fields.select.type
  },
  createdAt: {
    type: types.datetime.type,
    field: fields.textfield.type
  },
  updatedAt: {
    type: types.datetime.type,
    field: fields.textfield.type
  },
  status: {
    type: types.boolean.type,
    field: fields.checkbox.type
  }
});

export { schm as schema };
