import React, { Fragment } from "react";

import Access from "@cthulhi/comp-accs";

import permissions from "@perm";

const AdmGroup = ({ path, options = {} }) => {
  const { action: accAction } = Access.useState();

  const isAllowed = accAction("access").permissionIsAllowed(permissions.user.viewAny);

  const logout = () => {
    accAction("access").removeSessionCookie();
    accAction("access").removeSession();
  };

  return (
    <Access.RestrictComponent
      onDeny={() => null}
      onLoading={() => null}
      onAccess={[
        permissions.user.view,
        permissions.user.viewAny,
        permissions.user.edit,
        permissions.user.editAny,
        permissions.user.viewPassword,
        permissions.user.editPassword,
        permissions.perm.view,
        permissions.perm.viewAny,
        permissions.perm.edit,
        permissions.perm.editAny
      ]}
    >
      <li>
        <span className="menu-title uk-margin-top">Administrador</span>
      </li>
      <li>
        <Access.RestrictNavLink
          onAccess={[permissions.user.viewPassword, permissions.user.editPassword]}
          onDeny={() => null}
          to={`${path}/user/password`}
          className="menu-link"
          {...options}
        >
          <i className={`icon icon-${isAllowed ? "bullet" : "cog"}`} aria-hidden="true"></i>
          <span>Alterar senha</span>
        </Access.RestrictNavLink>
      </li>
      <li>
        <Access.RestrictNavLink
          onAccess={[
            permissions.user.view,
            permissions.user.edit
          ]}
          onDeny={() => null}
          to={`${path}/user/profile`}
          className="menu-link"
          activeClassName="active"
          { ...options }
        >
          <i className={`icon icon-${isAllowed ? "bullet" : "document"}`} aria-hidden="true"></i>
          <span>Usuários</span>
        </Access.RestrictNavLink>
        <Access.RestrictNavLink
          { ...options }
          onAccess={[
            permissions.user.create
          ]}
          onDeny={() => null}
          to={`${path}/user/profile/create`}
          className="menu-link"
          activeClassName="active"
          data-uk-tooltip="title: Adicionar Usuário; pos: right"
        >
          <i className="icon icon-plus-circle" aria-hidden="true"></i>
        </Access.RestrictNavLink>
      </li>
      <li>
        <Access.RestrictNavLink
          { ...options }
          onAccess={[
            permissions.perm.view,
            permissions.perm.viewAny,
            permissions.perm.edit,
            permissions.perm.editAny
          ]}
          to={`${path}/permission`}
          onDeny={() => null}
          className="menu-link"
          activeClassName="active"
        >
          <i className="icon icon-bullet" aria-hidden="true"></i>
          <span>Permissões</span>
        </Access.RestrictNavLink>
      </li>
      {isAllowed ? (
        <li>
          <a className="menu-link" onClick={logout}>
            <i className={`icon icon-bullet`} aria-hidden="true"></i>
            <span>Sair</span>
          </a>
        </li>
      ) : null}
    </Access.RestrictComponent>
  );
};

export default AdmGroup;
