import React, { Fragment, useEffect, useState } from "react";
import { Link, Switch, Route, withRouter, Redirect } from "react-router-dom";

import Access from "@cthulhi/comp-accs";

import BenefitListPage from "./BenefitListPage.react";
import BenefitProfileListPage from "./BenefitProfileListPage.react";

import BenefitFormPage from "./BenefitFormPage.react";
import BenefitProfileFormPage from "./BenefitProfileFormPage.react";

import permissions from "@perm";

const BenefitPage = ({ match, ...props }) => {
  return (
    <Switch>
      <Access.RestrictRoute
        key="benefit-list"        
        onAccess={[permissions.benefit.viewAny]}
        parentPath={match.path}
        path={`${match.path}`} exact
        onAllow={BenefitListPage}
      />
      <Access.RestrictRoute
        key="benefit-list-profile"        
        onAccess={[permissions.benefit.view]}
        parentPath={`${match.path}/profile`}
        path={`${match.path}/profile`} exact
        onAllow={BenefitProfileListPage}
      />
      <Access.RestrictRoute
        key="benefit-view-profile"
        onAccess={[permissions.benefit.view]}
        parentPath={`${match.path}/profile`}
        path={`${match.path}/profile/view/:id`} exact
        onAllow={BenefitProfileFormPage}
      />
      <Access.RestrictRoute
        key="benefit-create"
        onAccess={[permissions.benefit.createAny]}
        parentPath={match.path}
        path={`${match.path}/create`} exact
        onAllow={BenefitFormPage}
      />
      <Access.RestrictRoute
        key="benefit-edit"      
        onAccess={[permissions.benefit.editAny, permissions.benefit.edit]}
        parentPath={match.path}
        path={`${match.path}/edit/:id`}
        onAllow={BenefitFormPage}
      />
    </Switch>
  );
};

export default withRouter(BenefitPage);
