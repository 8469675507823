import { schema, types } from "@cthulhi/pack-schm";
import { schema as commonSchema } from "./common.schema"

import { types as fields } from "@cthulhi/comp-form"

const schm = schema("business", {
  ...commonSchema.schema.props,
  name: {
    type: types.string.type,
    field: fields.textfield.type,
    required: { contains: true }
  },
  title: {
    type: types.integer.type,
    field: fields.textfield.type,
    required: { contains: true }
  },
  cpf: {
    type: types.integer.type,
    field: fields.textfield.type,
    required: { contains: true }
  },
  email: {
    type: types.string.type,
    field: fields.textfield.type,
    required: { contains: true }
  },
  phone: {
    type: types.string.type,
    field: fields.textfield.type,
    required: { contains: true }
  },
  subject: {
    type: types.string.type,
    field: fields.select.type,
    required: { contains: true }
  },
  news: {
    type: types.string.type,
    field: fields.select.type,
    required: { contains: true }
  },
  message: {
    type: types.string.type,
    field: fields.textarea.type,
    required: { contains: true }
  },
});

export { schm as schema };
