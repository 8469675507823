
import bc from '../constants/branch.constant'

const state = {
  status: bc.STS_IDLE
};

const reducer = (state, action) => {
  switch (action.type) {

    case bc.ACT_SET_STATUS:
      const sals = { ...state }
      
      sals.status = action.data

      return sals
      
    default:
      return state
  }
};

export default { state, reducer }