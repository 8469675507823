// @ts-ignore
import React, { Component, Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useFilter } from "@cthulhi/comp-list";
import Notification from "@cthulhi/comp-noty";
import { List } from "../../../wrappers/ListWrappers.react";
import NotificationComponent from "../../../utils/Notification.react";
import Access from "@cthulhi/comp-accs";
import Context from "@context";

import {
  events as voucherListEvents,
  values as voucherListValues,
  actions as voucherListActions,
  options as voucherListOptions,
  schema as voucherListSchema
} from "./components/ConsumerDashboard.config";

const Page = ({ match, history, ...props }) => {

  const [modal, setModal] = useState({ show: false, Content: () => null });
  const { state: accState, action: accAction } = Access.useState();
  const { action: ctxAction } = Context.useState();
  const token = `Bearer ${accState.access.session.context.token}`;

  const { filter, list, page, limit, options } = useFilter({
    schema: voucherListSchema,
    events: voucherListEvents({ ctxAction }),
    options: voucherListOptions({}),
    values: voucherListValues({ ctxAction, token }),
    actions: voucherListActions({ ctxAction, token })
  });

  return (
    <Fragment>
      <h2 className="title">Perfil do consumidor</h2>
      <div className="uk-margin-top">
       
        <Notification.NotificationWrapper
          group="voucherListPage"
          component={NotificationComponent}
        />
        <List {...{ filter, list, page, limit, options }} />
        <div className="container-modal">{modal.show ? <modal.Content /> : null}</div>
      </div>
    </Fragment>
  );
};

export default withRouter(Page);
