import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const PreFooter = ({ contentCollection }) => {
  return (
    <Fragment>
      <section id="ntw">
        <div className="cnt">
          <div className="ntw-content">
            <h2>{contentCollection.title}</h2>
            <p>{contentCollection.text}</p>
            <a href="https://www.assinensc.com.br/?url_state=https://clubensc.com.br/&_ga=2.211826964.1430302169.1569866132-1352988405.1564521988">
              Associe-se agora
            </a>
          </div>
          <ul className="ntw-list">
            <li>
              <a href="https://www.lojansc.com.br/diario-catarinense-s9/" target="_blank">
                <img
                  src={`${process.env.domain}assets/website/images/logo-diario-catarinense.png`}
                  alt="A dummy image"
                />
              </a>
            </li>
            <li>
              <a href="https://www.lojansc.com.br/a-noticia-s10/" target="_blank">
                <img
                  src={`${process.env.domain}assets/website/images/logo-a-noticia.png`}
                  alt="A dummy image"
                />
              </a>
            </li>
            <li>
              <a href="https://www.lojansc.com.br/santa-s11/" target="_blank">
                <img
                  src={`${process.env.domain}assets/website/images/logo-jornal-de-santa-catarina.png`}
                  alt="A dummy image"
                />
              </a>
            </li>
            <li>
              <a href="https://www.nsctotal.com.br/home" target="_blank">
                <img
                  src={`${process.env.domain}assets/website/images/logo-nsc-total.png`}
                  alt="A dummy image"
                />
              </a>
            </li>
          </ul>
        </div>

        <div className="cnt">
          <div className="ntw-footer">
            <ul className="ntw-footer-nav">
              <li>
                <Link to="/sobre">Sobre o Clube</Link>
              </li>
              <li>
                <Link to="/duvidas">Dúvidas frequentes</Link>
              </li>
              <li>
                <Link to="/parceiros">Seja um parceiro</Link>
              </li>
              <li>
                <Link to="/regulamento">Regulamento</Link>
              </li>
              <li>
                <a href="https://negociossc.com.br/politica-de-privacidade/">
                  Política de privacidade
                </a>
              </li>
            </ul>

            <div className="ntw-footer-social">
              <span>
                O clube
                <br />
                nas redes:
              </span>
              <ul>
                <li>
                  <a href="https://www.facebook.com/clubensc" target="_blank">
                    <img
                      src={`${process.env.domain}assets/website/images/social-facebook.png`}
                      alt="Facebook"
                      aria-hidden="true"
                    />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/clubensc/" target="_blank">
                    <img
                      src={`${process.env.domain}assets/website/images/social-instagram.png`}
                      alt="Instagram"
                      aria-hidden="true"
                    />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/clubensc" target="_blank">
                    <img
                      src={`${process.env.domain}assets/website/images/social-twitter.png`}
                      alt="Twitter"
                      aria-hidden="true"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default PreFooter;
