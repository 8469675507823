const view = {
  group: {
    title: "Users Types",
    slug: "userType"
  },
  slug: "userType-view",
  title: "Ver tipos usuários",
  description: "Ver os próprios tipos de usuários",
  list: ["prn::1:userVip-view-roles:userVip-view:allow:lcnt:schema.user.roles:custom.static.admin"]
};

const viewAny = {
  group: {
    title: "Users Types",
    slug: "userType"
  },
  slug: "userType-view-any",
  title: "Ver qualquer tipo de usuário",
  description: "Ver todos os tipos de usuários",
  list: [
    "prn::1:userType-view-any-roles:userType-view-any:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
};

const edit = {
  group: {
    title: "Users Types",
    slug: "userType"
  },
  slug: "userType-edit",
  title: "Editar tipos de usuários",
  description: "Editar os próprios tipos de usuários",
  list: ["prn::1:userType-edit-roles:userType-edit:allow:lcnt:schema.user.roles:custom.static.admin"]
};

const editAny = {
  group: {
    title: "Users Types",
    slug: "userType"
  },
  slug: "userType-edit-any",
  title: "Editar qualquer tipo de usuário",
  description: "Editar todos os tipos de usuários",
  list: [
    "prn::1:userType-edit-any-roles:userType-edit-any:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
};

const remove = {
  group: {
    title: "Users Types",
    slug: "userType"
  },
  slug: "userType-remove",
  title: "Remover os tipos de usuários",
  description: "Remover os próprios tipos de usuários"
};

const removeAny = {
  group: {
    title: "Users Types",
    slug: "userType"
  },
  slug: "userType-remove-any",
  title: "Remover qualquer tipos de usuário",
  description: "Remover todos os tipos de usuários"
};

const create = {
  group: {
    title: "Users Types",
    slug: "userType"
  },
  slug: "userType-create",
  title: "Criar tipos de usuários",
  description: "Criar os próprios tipos de  usuários"
};

const createAny = {
  group: {
    title: "Users Types",
    slug: "userType"
  },
  slug: "userType-create-any",
  title: "Criar qualquer tipos de usuário",
  description: "Criar todos os tipos usuários"
};

module.exports = {
  view,
  viewAny,
  edit,
  editAny,
  remove,
  removeAny,
  create,
  createAny
};
