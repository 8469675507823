import React, { Fragment } from "react";
import { schema as schm, types } from "@cthulhi/pack-schm";
import { types as fields } from "@cthulhi/comp-form";

const currency = (amount, decimalCount = 2, decimal = ".", thousands = ",") => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    return amount;
  }
};

const schema = schm("filter", {
  search: {
    type: types.string.type,
    field: fields.textfield.type
  },
  quantity: {
    type: types.string.type,
    field: fields.textfield.type
  },
});
const events = ({ ctxAction, ...props }) => {
  return {};
};

const values = ({ ctxAction, ...props }) => {
  return {
    search: {
      filter: "text",
      className: "uk-input",
      placeholder: "Parceiro",
      label: {
        text: "Nome do parceiro",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-3@m"
      }
    },
    quantity: {
      filter: "text",
      className: "uk-input",
      placeholder: "R$ ou % de desconto",
      label: {
        text: "R$ ou % de desconto",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-3@m"
      }
    },
  };
};

const options = ({ ...props }) => {
  return {
    filter: {
      fields: true,
      submit: true
    },
    pagination: {
      next: {
        value: "Próximo",
        className: "page-next"
      },
      prev: {
        value: "Anterior",
        className: "page-prev"
      },
      numbers: {
        className: "page-numbers"
      },
      total: true
    },
    limit: {
      value: 5,
      limits: [
        { value: 5, label: "5 Items" },
        { value: 10, label: "10 Items" },
        { value: 20, label: "20 Items" }
      ]
    },
    items: {
      parceiro: {
        header: {
          value: "Parceiro",
          className: ""
        },
        column: {
          className: "",
          value: "partner"
        }
      },
      quantity: {
        header: {
          value: "Quantidade",
          className: ""
        },
        column: {
          className: "",
          value: "quantity"
        },
        parse: (value, item) => {
          return `${(item.type == "absolute") ? "R$ " : ""}${currency(value)}${(item.type == "percentage" || item.type == "percent") ? " %" : ""}`
        }
      },
      coupons: {
        header: {
          value: "Cupons gerados",
          className: ""
        },
        column: {
          className: "",
          value: "coupons"
        }
      },
    }
  };
};

const actions = ({ ctxAction, token, ...props }) => {
  return {
    find: async (filters, options) => {
      if (filters.filters && filters.filters.search) filters.filters.search = filters.filters.search.$regex;
      if (filters.filters && filters.filters.quantity) filters.filters.quantity = filters.filters.quantity.$regex;
      return ctxAction("discount").findCostumerDashboardData(filters, options, token);
    }
  };
};

export { events, values, actions, options, schema };
