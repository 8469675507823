import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import Access from "@cthulhi/comp-accs";
import Context from "@context";
import { useFields, useForms } from "@cthulhi/comp-form";

import { schema as contentFormSchema } from "@schm/content/create.form.schema";

import {
  events as contentFormEvents,
  values as contentFormValues,
  actions as contentFormActions
} from "./components/ContentForm.config";

import { FieldLabelWrapper } from "../../../wrappers/FormWrappers.react";

import Notification from "@cthulhi/comp-noty";
import NotificationComponent from "../../../utils/Notification.react";

import LoaderComponent from "../../../utils/LoaderCustomComponent.react";

const ContentPage = ({ match, parentPath, history, ...props }) => {
  const { state: accState, action: accAction } = Access.useState();
  const { action: ctxAction, state: ctxtState } = Context.useState();
  const { state: ntfState, action: ntfAction } = Notification.useState();
  const [contentSchema, setContentSchema] = useState(contentFormSchema);

  const { form, fields, values, updater, cleaner } = useFields({
    schema: contentSchema,
    events: contentFormEvents({ ctxAction }),
    values: contentFormValues({
      ctxAction,
      token: `Bearer ${accState.access.session.context.token}`
    })
  });

  const { find, update, create } = useForms(
    contentFormActions({
      ctxAction,
      ntfAction,
      updater,
      history,
      path: parentPath,
      token: `Bearer ${accState.access.session.context.token}`
    })
  );

  const contentFormSubmit = () => {
    let vs = {};

    Object.entries(values).map(([key, value]) => {
      const hasValue = typeof value.value != "undefined" && value.value !== "";
      const hasRawValue = typeof value.rawValue != "undefined" && value.rawValue !== "";

      vs[key] = hasRawValue ? value.rawValue : hasValue ? value.value : value.defaultValue;
    });

    match.params.id ? update(vs) : create(vs);
  };

  useEffect(() => {
    if (match.params.id) {
      find({ filters: { _id: match.params.id } });
    }

    return () => {
      cleaner();
    };
  }, [match.params.id]);

  return (
    <Fragment>
      <h2 className="title">{match.params.id ? "Editar content" : "Novo content"}</h2>
      <div className="uk-margin-top">
        <Notification.NotificationWrapper group="contentForm" component={NotificationComponent} />
        <div className="uk-card uk-card-default uk-card-body uk-padding-small">
          <LoaderComponent from="contentForm" />
          <form className="uk-grid-small" data-uk-grid>
            <FieldLabelWrapper name="body" fields={fields} values={values} />
            <FieldLabelWrapper name="name" fields={fields} values={values} />
            <FieldLabelWrapper name="component" fields={fields} values={values} />
            <FieldLabelWrapper name="identifier" fields={fields} values={values} />
          </form>
        </div>
        <div className="uk-margin-top">
          <button
            onClick={contentFormSubmit}
            className="uk-button uk-button-large uk-button-secondary"
            style={{ float: "right" }}
            disabled={
              typeof ctxtState.loader.list["contentForm"] != "undefined" &&
              ctxtState.loader.list["contentForm"].length > 0
            }
          >
            {match.params.id ? "Atualizar" : "Salvar"}
          </button>
        </div>
      </div>
    </Fragment>
  );
};

// @ts-ignore
export default withRouter(ContentPage);
