import React, { Component, Fragment, useEffect } from "react";
import { Redirect } from "react-router-dom";

import BackButton from "../../../access/components/BackButton.react";
import Context from "@context";

const Register = () => {
  const { state: ctxState, action: ctxAction, dispatch: ctxDispatch } = Context.useState();
  // TO DO
  return (
    <Fragment>
      <div className="uk-flex uk-flex-column uk-padding-large">
        <img
          src={`${process.env.domain}assets/pwa/images/logo-color.svg`}
          alt="NSC"
          className="auth-logo"
          aria-hidden="true"
        />
        <h1 className="auth-title">Criar Cadastro</h1>
      </div>

      <div
        className="uk-flex uk-flex-middle uk-padding uk-padding-remove-top"
      >
        <div className="uk-width-1-1">
          <form className="form" id="" action="register-sent.html">
            <div className="uk-margin">
              <input
                name="email"
                id="email"
                className="uk-input uk-width-1-1"
                type="email"
                placeholder="Digite seu e-mail"
                required
              />
            </div>
            <div className="uk-margin uk-text-center">
              <span className="uk-text-small">
                Insira seu email para receber as instruções sobre como se cadastrar.
              </span>
            </div>
            <div className="uk-margin-medium-top">
              <button className="uk-button uk-button-primary uk-button-large uk-width-1-1">
                Enviar e-mail
              </button>
            </div>
          </form>

          <BackButton />
        </div>
      </div>
    </Fragment>
  );
};

export default Register;
