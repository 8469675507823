import React, { Fragment } from "react";

import Access from "@cthulhi/comp-accs";

import permissions from "@perm";

const VoucherGroup = ({ path, options = {} }) => {
  return (
    <Access.RestrictComponent
      onDeny={() => null}
      onLoading={() => null}
      onAccess={[permissions.redemption.viewAny]}
    >
      <li>
        <span className="menu-title uk-margin-top">Vouchers</span>
      </li>
      <li>
        <Access.RestrictNavLink
          {...options}
          onAccess={[permissions.redemption.viewAny]}
          to={`${path}/redemption/consumer`}
          onDeny={() => null}
          className="menu-link"
          activeClassName="active"
        >
          <i className="icon icon-bullet" aria-hidden="true"></i>
          <span>Perfil do consumidor</span>
        </Access.RestrictNavLink>
      </li>
    </Access.RestrictComponent>
  );
};

export default VoucherGroup;
