import { schema, types } from "@cthulhi/pack-schm";
import { schema as commonSchema } from "./common.schema";
import { types as fields } from "@cthulhi/comp-form";

const schm = schema("category", {
  ...commonSchema.schema.props,
  name: {
    type: types.string.type,
    field: fields.textfield.type,
    required: true
  },
  showMenu: {
    type: types.boolean.type,
    field: fields.checkbox.type,
    required: true
  },
  showApp: {
    type: types.boolean.type,
    field: fields.checkbox.type,
    required: true
  },
  status: {
    type: types.boolean.type,
    field: fields.checkbox.type,
    required: true
  },
  weight: {
    type: types.integer.type,
    field: fields.number.type,
    required: { contains: true }
  },
  weightByDate: {
    type: types.boolean.type,
    field: fields.checkbox.type,
    required: true
  },
  parentId: {
    type: types.string.type,
    field: fields.select.type
  },
  iconAppUrl: {
    type: types.string.type,
    field: fields.fileimage.type
  },
  backgroundAppUrl: {
    type: types.string.type,
    field: fields.fileimagecrop.type
  },
  iconWebsiteUrl: {
    type: types.string.type,
    field: fields.fileimage.type
  },
});

export { schm as schema };
