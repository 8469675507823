import React, { Fragment, useState } from "react";
import { withRouter } from "react-router-dom";

import { List } from "./../../../wrappers/ListWrappers.react";

import { useFilter } from "@cthulhi/comp-list"

import Access from "@cthulhi/comp-accs";
import Context from "@context";

import Notification from "@cthulhi/comp-noty";
import NotificationComponent from "../../../utils/Notification.react";
import ModalConfirmRemoval from "./../../../wrappers/ModalConfirmRemoval.react";

import {
  events as benefitListEvents,
  values as benefitListValues,
  actions as benefitListActions,
  options as benefitListOptions,
  schema as benefitListSchema
} from "./components/BenefitProfileList.config";

const BenefitProfileListPage = ({ match, history, ...props }) => {

  const { state: accState, action: accAction } = Access.useState();
  const { action: ctxAction } = Context.useState();

  const token = `Bearer ${accState.access.session.context.token}`

  const onView = value => { 
    history.push(`${match.path}/view/${value._id}`);
  };

  const { filter, list, page, limit, options } = useFilter({ 
    schema: benefitListSchema, 
    events: benefitListEvents({ ctxAction }), 
    options: benefitListOptions({ onView }), 
    values: benefitListValues({ ctxAction, token }), 
    actions: benefitListActions({ ctxAction, token })
  })

  return (
    <Fragment>
      <h2 className="title">Listar benefícios</h2>
      <div className="uk-margin-top">
        <Notification.NotificationWrapper group="benefitListPage" component={NotificationComponent} />
        <List { ...{ filter, list, page, limit, options } } />
      </div>
    </Fragment>
  );
};

export default withRouter(BenefitProfileListPage);
