
const getCategorySpecificParams = (categoryName) => {
  let category = {};

  switch (categoryName) {
    case "Cinema":
    case "5d72c4a26b66285947927c1a":
      category.icone = "movies";
      category.isPseudoCategory = false;
      break;
    case "Entretenimento":
    case "5d72c4a26b66285947927c1b":
      category.icone = "entertainment";
      category.isPseudoCategory = false;
      break;
    case "Gastronomia":
    case "5d72c4a26b66285947927c1c":
      category.icone = "gastronomy";
      category.isPseudoCategory = false;
      break;
    case "Lojas":
    case "5d72c4a26b66285947927c1d":
      category.icone = "stores";
      category.isPseudoCategory = false;
      break;
    case "Saúde e Bem-estar":
    case "5d72c4a26b66285947927c1e":
      category.icone = "health";
      category.isPseudoCategory = false;
      break;
    case "Serviços":
    case "5d72c4a26b66285947927c1f":
      category.icone = "services";
      category.isPseudoCategory = false;
      break;
    case "Destaques":
    case "5d7bd8f9a88d473691a49fa3":
      category.icone = "featured";
      category.isPseudoCategory = true;
      break;
    case "Agenda":
    case "5d7bd8f9a88d473691a49fa2":
      category.icone = "schedule";
      category.isPseudoCategory = true;
      break;

    case "qr-code":
    default:
      category.icone = "qr-code-drop";
      category.isPseudoCategory = false;
  }


  return category;
};

export default getCategorySpecificParams;
