import ev from "../constants/events.constant";

export default (dispatch, state) => {
    const dispatchSetStatus = data => {
        dispatch({
            type: ev.ACT_SET_STATUS,
            data: data
        });
    };

    const remove = (values, authorization) => {
        var args = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: authorization
            },
            body: JSON.stringify(values),
            credentials: "same-origin"
        };

        dispatchSetStatus(ev.STS_DELETE_REQUEST);

        return fetch(process.env.domainEventDelete, args)
            .then(res => res.json())
            .then(res => {
                dispatchSetStatus(ev.STS_DELETE_RESPONSE);
                dispatchSetStatus(ev.STS_DELETE_SUCCESS);

                setTimeout(function() {
                    dispatchSetStatus(ev.STS_IDLE);
                }, 1000);

                return res;
            })
            .catch(error => {
                dispatchSetStatus(ev.STS_DELETE_ERROR);

                setTimeout(function() {
                    dispatchSetStatus(ev.STS_IDLE);
                }, 1000);

                return error;
            });
    };

  const deleteBranch = async (values, authorization) => {
    if (!values._id) return new Promise(() => null);
    
    const args = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization
      },
      body: JSON.stringify(values),
      credentials: "same-origin"
    };

    return await fetch(process.env.domainBranchDelete, args)
  }

	const findBranch = (filters, authorization) => {
    if (!filters._id) return new Promise(() => null);
    
    const args = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization
      },
      body: JSON.stringify({ filters, options: { sort: { _id: -1 }} }),
      credentials: "same-origin"
    };

    return fetch(process.env.domainBranchFindOne, args)
      .then(res => res.json())
      .then(res => res.result)
  }

	const updateBranch = async (values, authorization) => {
    var args = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization
      },
      body: JSON.stringify({
        ...values,
        branches: undefined
      }),
      credentials: "same-origin"
    };

    if (values.removed) {
      await deleteBranch(values, authorization);
      return;
    }

    if (!values._id) {
      return await createBranch(values, authorization);
    }

    const res = await fetch(process.env.domainBranchUpdate, args);
    const { result } = await res.json();
    
    if (values.branches) {
			await Promise.all(values.branches.map(async branch => updateBranch({ ...branch, parentId: result._id }, authorization)));
		} 
		
    return result._id;
	}
  
	const createBranch = async (values, authorization) => {
		var args = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: authorization
			},
			body: JSON.stringify({
        ...values,
        branches: undefined
      }),
			credentials: "same-origin"
		};

		const res = await fetch(process.env.domainBranchCreate, args);
		const { result } = await res.json();

		if (values.branches) {
			await Promise.all(values.branches.map(async branch => createBranch({ ...branch, parentId: result._id }, authorization)));
		} 
		
		return result._id;
	}

    const update = async (values, authorization) => {
      if (values.branches) {
        values.branch = await updateBranch(values.branches[0], authorization)
      }

      delete values.branches;

      var args = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: authorization
        },
        body: JSON.stringify(values),
        credentials: "same-origin"
      };

      dispatchSetStatus(ev.STS_UPDATE_REQUEST);

      return fetch(process.env.domainEventUpdate, args)
        .then(res => res.json())
        .then(res => {
          dispatchSetStatus(ev.STS_UPDATE_RESPONSE);
          dispatchSetStatus(ev.STS_UPDATE_SUCCESS);

          setTimeout(function() {
            dispatchSetStatus(ev.STS_IDLE);
          }, 1000);
          return res;
        })
        .catch(error => {
          dispatchSetStatus(ev.STS_UPDATE_ERROR);

          setTimeout(function() {
            dispatchSetStatus(ev.STS_IDLE);
          }, 1000);

          return error;
        });
    };

    const create = async (values, authorization) => {
			if (values.branches) {
				values.branch = await createBranch(values.branches[0], authorization)
			}
      delete values.branches;
      delete values.branches;

			var args = {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: authorization
				},
				body: JSON.stringify(values),
				credentials: "same-origin"
			};

			dispatchSetStatus(ev.STS_CREATE_REQUEST);

			return fetch(process.env.domainEventCreate, args)
				.then(res => res.json())
				.then(res => {
					dispatchSetStatus(ev.STS_CREATE_RESPONSE);
					dispatchSetStatus(ev.STS_CREATE_SUCCESS);

					setTimeout(function() {
						dispatchSetStatus(ev.STS_IDLE);
					}, 1000);
					return res;
				})
				.catch(error => {
					dispatchSetStatus(ev.STS_CREATE_ERROR);

					setTimeout(function() {
						dispatchSetStatus(ev.STS_IDLE);
					}, 1000);

					return error;
				});
    };

    const find = (filters, oevions, authorization, loader) => {
      var args = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authorization
        },
        body: JSON.stringify({ ...filters, oevions: { sort: { _id: -1 }, ...oevions } }),
        credentials: "same-origin"
      };

      dispatchSetStatus(ev.STS_FIND_REQUEST);

      if (loader) loader.start();

      return fetch(process.env.domainEventFind, args)
        .then(res => res.json())
        .then(async res => {
          if (filters.filters && filters.filters._id && res.result) {
            res.result.values = res.result.values.map(async value => ({
              ...value,
              branch: await findBranch({ _id: value.branch }, authorization)
            }));
            res.result.values = await Promise.all(res.result.values);
          }

          return res;
        })
        .then(res => {
          dispatchSetStatus(ev.STS_FIND_RESPONSE);
          dispatchSetStatus(ev.STS_FIND_SUCCESS);

          if (loader) loader.finish();

          setTimeout(function() {
            dispatchSetStatus(ev.STS_IDLE);
          }, 1000);

          return res;
        })
        .catch(error => {
          dispatchSetStatus(ev.STS_FIND_ERROR);

          if (loader) loader.finish();

          setTimeout(function() {
            dispatchSetStatus(ev.STS_IDLE);
          }, 1000);

          return error;
        });
    };

    return {
      find,
      create,
      update,
      remove
    };
};
