const view = {
  group: {
    title: "Unidades",
    slug: "branch"
  },
  slug: "branch-view",
  title: "Ver unidades",
  description: "Ver as próprias unidades",
  list: [
    "prn::1:branch-view-roles:branch-view:allow:lcnt:schema.user.roles:custom.static.manager"
  ]
}

const viewAny = {
  group: {
    title: "Unidades",
    slug: "branch"
  },
  slug: "branch-view-any",
  title: "Ver qualquer unidade",
  description: "Ver todas as unidades",
  list: [
    "prn::1:branch-view-any-roles:branch-view-any:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

const edit = {
  group: {
    title: "Unidades",
    slug: "branch"
  },
  slug: "branch-edit",
  title: "Editar unidades",
  description: "Editar as próprias unidades",
  list: [
    "prn::1:branch-edit-roles:branch-edit:allow:lcnt:schema.user.roles:custom.static.manager"
  ]
}

const editAny = {
  group: {
    title: "Unidades",
    slug: "branch"
  },
  slug: "branch-edit-any",
  title: "Editar qualquer unidade",
  description: "Editar todas as unidades",
  list: [
    "prn::1:branch-edit-any-roles:branch-edit-any:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

const remove = {
  group: {
    title: "Unidades",
    slug: "branch"
  },
  slug: "branch-remove",
  title: "Remover unidades",
  description: "Remover as próprias unidades",
  list: [
    "prn::1:branch-remove-roles:branch-remove:allow:lcnt:schema.user.roles:custom.static.manager"
  ]
}

const removeAny = {
  group: {
    title: "Unidades",
    slug: "branch"
  },
  slug: "branch-remove-any",
  title: "Remover qualquer unidade",
  description: "Remover todas as unidades",
  list: [
    "prn::1:branch-remove-any-roles:branch-remove-any:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

const create = {
  group: {
    title: "Unidades",
    slug: "branch"
  },
  slug: "branch-create",
  title: "Criar unidades",
  description: "Criar as próprias unidades",
  list: [
    "prn::1:branch-create-roles:branch-create:allow:lcnt:schema.user.roles:custom.static.manager"
  ]
}

const createAny = {
  group: {
    title: "Unidades",
    slug: "branch"
  },
  slug: "branch-create-any",
  title: "Criar qualquer unidade",
  description: "Criar todas as unidades",
  list: [
    "prn::1:branch-create-any-roles:branch-create-any:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

module.exports = {
  view,
  viewAny,
  edit,
  editAny,
  remove,
  removeAny,
  create,
  createAny
}