import moment from "moment";

const optionLoading = { value: "", label: "Carregando...", disabled: false };
const optionSelectEvent = { value: "", label: "Selecionar evento", name: "Selecionar evento" };

const events = ({ ctxAction, token, ...props }) => {
  return {
    shelfLifeEnd: {
      onChange: (value, rawValue, update) => {
        update("shelfLifeEnd", { value: value.endOf("day") }, rawValue ? moment(rawValue).toISOString() : rawValue);
      }
    },
    shelfLifeStart: {
      onChange: (value, rawValue, update) => {
        update("shelfLifeStart", { value: value.startOf("day") }, rawValue ? moment(rawValue).toISOString() : rawValue);
      }
    },
    hasCashback: {
      onChange: (value, rawValue, update) => {
        ///// MOSTRAR/OCULTAR CAMPOS NECESSÀRIOS
        update("hasCashback", { value, rawValue });
      }
    },
    refoundType: {
      onChange: (value, rawValue, update, get, vals, nodeElement) => {
        update("refoundType", { value });

        let howToUse = "";
        switch (value) {
          case "promocode": 
          howToUse = "Copie o código e cole na plataforma do parceiro";
          break;
          case "url": 
          howToUse = "Acesse o link para receber seu desconto";
          break;
          case "import": 
          howToUse = "Copie o código e cole na plataforma do parceiro";
          break;
        }

        update("howToUse", { value: howToUse });
      }
    },
    eventId: {
      onChange: (value, rawValue, update) => {
        // Verificar se o ID é create_new_event, e se sim, mostrar o form.
        update("eventId", { value, rawValue });
        update("branches", { 
          disabled: true, 
          find: async () => {
            return ctxAction("event").find(
              { filters: { _id: value } },
              { sort: { title: 1 } },
              token,
            )
            .then((res) => {
              if (Array.isArray(res.result.values)) {
                const event = res.result.values[0];
                if (event.branch) {
                  return [{ value: event.branch._id, label: event.branch.name }]
                }
                return event.branch.map(({ _id, name }) => ({
                  value: _id, 
                  label: name
                }))
              }
              return [];
            });
          } 
        });
      }
    }
  };
};

const values = ({ ctxAction, token, ...props }) => {
  const initValues = {
    description: {
      className: "uk-textarea",
      placeholder: "Descrição",
      label: {
        text: "Descrição",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-1"
      }
    },
    branches: {
      className: "uk-select",
      placeholder: "Unidades",
      label: {
        text: "Unidades",
        className: "uk-form-label"
      },
      wrapper: {
        className: "uk-width-1-1@m"
      },
      default: "",
      options: []
    },
    eventId: {
      className: "uk-select",
      label: {
        text: "Evento",
        className: "uk-form-label"
      },
      placeholder: "Selecione o evento",
      value: "",
      options: [optionLoading],
      wrapper: {
        className: "field-wrapper uk-width-expand@m"
      },
      search: (update) => (input) => {
        update("eventId", { placeholder: "Carregando...", disabled: false, options: [optionLoading] });
        const result = ctxAction("event")
        .find({}, { sort: { name: 1 } }, token)
        .then(res => {
          if (res.name != "EventFoundResult" || res.result.values.length == 0) {
            return [{
              _id: "", name: "Nenhum evento encontrado"
            }]
          }
          const result = [optionSelectEvent, ...res.result.values];
          update("eventId", { placeholder: "Selecione o evento" });
  
          return result;
        });
        return result;
      },
      optionSchema: (event) => ({
        label: event.name,
        value: event._id,
        key: event._id
      })
    },
    type: {
      className: "uk-select",
      placeholder: "Tipo",
      label: {
        text: "Tipo",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      },
      default: "",
      options: [
        { value: "", label: "Selecione um tipo de desconto *" },
        { value: "percentage", label: "Porcentagem do Valor" },
        { value: "absolute", label: "Valor Absoluto" }
      ]
    },
    limitType: {
      className: "uk-select",
      placeholder: "Tipo de limite",
      label: {
        text: "Tipo de limite",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-1@m"
      },
      default: "",
      options: [
        { value: "", label: "Sem limite" },
        { value: 1, label: "Quantidade total" },
        { value: 2, label: "Quantidade por pessoa" },
        { value: 3, label: "Quantidade por pessoa e dia" }
      ]
    },
    limitQuantity: {
      className: "uk-input",
      placeholder: "Valor limite",
      label: {
        text: "Quantidade do limite",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      }
    },
    limitDaysQuantity: {
      className: "uk-input",
      placeholder: "Quantidade de dias do limite",
      label: {
        text: "Quantidade de dias do limite",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      }
    },
    quantity: {
      className: "uk-input",
      placeholder: "Valor ou porcentagem do desconto",
      label: {
        text: "Quantidade",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      }
    },
    hasCashback: {
      className: "uk-checkbox",
      placeholder: "Possui cashback",
      value: false,
      label: {
        text: "Possui cashback",
        className: "uk-form-label uk-margin-small-left",
        position: "below"
      },
      wrapper: {
        className: "field-checkbox uk-width-1-1@m"
      }
    },
    cashbackType: {
      className: "uk-select",
      placeholder: "Tipo do cashback",
      label: {
        text: "Tipo do cashback",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-3@m"
      },
      options: [
        { value: "", label: "Selecione o tipo de cashback *" },
        { value: "percent", label: "% Percentual" },
        { value: "plain", label: "R$ Dinheiro" }
      ]
    },
    refoundType: {
      className: "uk-select",
      placeholder: "Tipo de resgate",
      label: {
        text: "Tipo de resgate",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      },
      options: [
        { value: "none", label: "Selecione um tipo de resgate *" },
        { value: "promocode", label: "Código promocional" },
        { value: "url", label: "URL" },
        { value: "import", label: "Importação em lotes" }
      ]
    },
    refoundCode: {
      className: "uk-input",
      placeholder: "Código de resgate",
      label: {
        text: "Código de resgate",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      }
    },
    refoundUrl: {
      className: "uk-input",
      placeholder: "URL",
      label: {
        text: "URL",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      }
    },
    refoundCodeFile: {
      className: "uk-input",
      placeholder: "Importar códigos",
      label: {
        text: "Importar códigos",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      }
    },
    cashbackLimit: {
      className: "uk-input",
      placeholder: "Limite do cashback",
      label: {
        text: "Limite",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-3@m"
      }
    },
    cashbackQuantity: {
      className: "uk-input",
      placeholder: "Quantidade do cashback",
      label: {
        text: "Quantidade",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-3@m"
      }
    },
    shelfLifeStart: {
      className: "datepicker uk-margin-small-right uk-input",
      inputProps: {
        className: "uk-input",
        placeholder: "Válido de *"
      },
      dateFormat: "DD / MM / YYYY",
      value: new Date(),
      rawValue: new Date(),
      label: {
        text: "Válido de",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      },
    },
    shelfLifeEnd: {
      className: "datepicker uk-margin-small-right uk-input",
      dateFormat: "DD / MM / YYYY",
      inputProps: {
        className: "uk-input",
        placeholder: "Válido até *"
      },
      value: "",
      label: {
        text: "Válido até",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      }
    },
    howToUse: {
      className: "uk-textarea",
      placeholder: "Forma de Uso",
      label: {
        text: "Forma de Uso",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-1@m"
      },
    },
    discountImage: {
      input: {
        wrapper: {
          className: "uk-width-1-1@m"
        },
        field: {
          className: "uk-input",
          placeholder: "Imagem"
        }
      },
      crops: {
        wrapper: {
          className: "uk-margin-top uk-grid uk-margin-top uk-margin-small"
        },
        cropper: {
          className: "uk-width-1-2@m uk-first-column"
        },
        croppeds: {
          className: "uk-width-1-2@m uk-grid"
        },
        images: [
          { wrapper: { className: "uk-width-1-2@m" }, image: { style: { width: "100%" } } },
          { wrapper: { className: "uk-width-1-2@m" }, image: { style: { width: "50%" } } }
        ]
      },
      locked: false,
      keepSelection: false,
      unit: "%",
      height: 100,
      aspect: 16 / 9,
      label: {
        text: "Imagem",
        className: "uk-form-label"
      },
      description: {
        text: "Para uma boa aparência, a imagem devem conter no mínimo 1280 pixels de largura."
      },
      wrapper: {
        className: "field-wrapper uk-width-expand@m"
      }
    },
    spotlight: {
      className: "uk-checkbox",
      placeholder: "Destaque",
      value: false,
      label: {
        text: "Destaque",
        className: "uk-form-label uk-margin-small-left",
        position: "below"
      },
      wrapper: {
        className: "field-checkbox uk-width-1-5@m"
      }
    },
    siteBanner: {
      className: "uk-checkbox",
      placeholder: "Mostrar no site",
      value: false,
      label: {
        text: "Mostrar no site",
        className: "uk-form-label uk-margin-small-left",
        position: "below"
      },
      wrapper: {
        className: "field-checkbox uk-width-1-5@m"
      }
    },
    available: {
      className: "uk-checkbox",
      placeholder: "Disponível",
      value: true,
      label: {
        text: "Disponível",
        className: "uk-form-label uk-margin-small-left",
        position: "below"
      },
      wrapper: {
        className: "field-checkbox uk-width-1-5@m"
      }
    },
    userTypes: {
      className: "uk-select",
      placeholder: "Tipos de usuário",
      label: {
        text: "Tipos de usuário",
        className: "uk-form-label"
      },
      wrapper: {
        className: "uk-width-1-1@m"
      },
      default: "",
      find: () => {
        return ctxAction("userType").find(
          {},
          { sort: { title: 1 } },
          token,
        )
        .then((res) => {
          if (Array.isArray(res.result.values)) {
            return res.result.values.map(({ _id, name }) => ({
              value: _id, 
              label: name
            }))
          }
          return [];
        });
      },
      options: []
    }
  };

  return initValues;
};

const actions = ({ token, ctxAction, ntfAction, updater, history, path, ...props }) => {
  return {
    entity: {
      name: "discount",
      token,
      form: ctxAction("form")
    },
    actions: {
      entity: ctxAction,
      notification: ntfAction,
      updater
    },
    find: {
      success: {
        name: "DiscountFoundResult",
        action: { notify: false, redirect: false }
      },
      error: {
        name: "DiscountErrorResult",
        message: "Houve um erro ao buscar a desconto",
        action: { notify: "discountListPage", redirect: false }
      }
    },
    create: {
      success: {
        name: "DiscountCreateResult",
        message: "Desconto adicionado com sucesso",
        action: { notify: "discountListPage", redirect: () => history.push(path) }
      },
      error: {
        name: "DiscountErrorResult",
        message: "Houve um erro ao adicionar o desconto",
        action: { notify: "discountForm", redirect: false }
      }
    },
    update: {
      success: {
        name: "DiscountUpdateResult",
        message: "Desconto atualizado com sucesso",
        action: { notify: "discountListPage", redirect: () => history.push(path) }
      },
      error: {
        name: "DiscountErrorResult",
        message: "Houve um erro ao atualizar o desconto",
        action: { notify: "discountForm", redirect: false }
      }
    }
  };
};

export { events, values, actions };
