import React, { Fragment, useEffect, useState } from "react";

import Context from "./../../../../context";
import pc from "./../../../../constants/permission.constant";

import PermissionListItem from "./PermissionListItem.react";

const PermissionList = props => {
  const { state: ctxState, action: ctxAction } = Context.useState();
  const [group, setGroup] = useState("-");

  useEffect(() => {
    ctxAction("permission").getList();
  }, []);

  const updateList = () => {
    if (ctxState.permission.list.status == pc.STS_IDLE) {
      ctxAction("permission").updateList();
    }
  };

  const addItem = (slug, index) => {
    ctxAction("permission").addItem(slug, index);
  };

  const onChange = e => {
    setGroup(e.target.value);
  };

  const isLoading =
    ctxState.permission.list.status == pc.STS_GET_REQUEST ||
    ctxState.permission.list.status == pc.STS_GET_RESPONSE;

  const isSaving =
    ctxState.permission.list.status == pc.STS_PATCH_REQUEST ||
    ctxState.permission.list.status == pc.STS_PATCH_RESPONSE;

  const sortAscAZ = (a, b) => {
    if (a.slug > b.slug) return 1;
    if (a.slug < b.slug) return -1;
    return 0;
  };

  return isLoading ? (
    " Carregando ..."
  ) : (
    <Fragment>
      <h2 className="title">Lista de permissões</h2>
      <div className="uk-card uk-card-small uk-card-default uk-card-body uk-margin-medium-top">
        <div className="uk-flex uk-flex-between">
          <select
            name="permissionList"
            id="permissionList"
            className="uk-select uk-width-expand uk-margin-large-right"
            value={group}
            onChange={onChange}
          >
            <option value="-" disabled>
              Selecione...
            </option>
            {ctxState.permission.list.items
              ? Object.keys(ctxState.permission.list.items)
                  .sort()
                  .map(gIndex => (
                    <option value={gIndex}>
                      {ctxState.permission.list.items[gIndex][0].group.title}
                    </option>
                  ))
              : null}
          </select>
          <button
            className="uk-button uk-button-secondary uk-border-pill perm-list"
            onClick={updateList}
          >
            {isSaving ? "Salvando..." : "Salvar lista"}
          </button>
        </div>
        {(typeof ctxState.permission.list.items[group] != "undefined"
          ? ctxState.permission.list.items[group]
          : []
        )
          .sort(sortAscAZ)
          .map((permissionItem, iIndex) => (
            <Fragment>
              <div
                className={
                  "uk-flex uk-flex-between" +
                  (iIndex == 0 ? " uk-margin-top" : " uk-margin-large-top")
                }
              >
                <div>
                  <h4 className="uk-margin-remove">{permissionItem.title}</h4>
                  <span className="uk-text-small">{permissionItem.description}</span>
                </div>
                <div>
                  <button
                    className="uk-button uk-button-primary uk-button-small uk-border-pill"
                    onClick={e => addItem([permissionItem.group.slug, iIndex])}
                    type="button"
                  >
                    Adicionar permissão
                  </button>
                </div>
              </div>
              <div className="uk-overflow-auto uk-margin-top">
                <div className="uk-overflow-auto">
                  <table className="uk-table uk-table-small uk-table-divider uk-table-justify uk-table-middle uk-table-responsive">
                    <thead>
                      <tr>
                        <th className="uk-table-expand">ID</th>
                        <th className="uk-table-expand">Ação</th>
                        <th className="uk-table-expand">Tipo</th>
                        <th className="uk-table-expand"></th>
                        <th className="uk-table-expand">Valor</th>
                        <th className="uk-table-expand">Operação</th>
                        <th className="uk-table-expand">Tipo</th>
                        <th className="uk-table-expand"></th>
                        <th className="uk-table-expand">Valor</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <Fragment>
                        {permissionItem.list && permissionItem.list.length > 0 ? (
                          permissionItem.list.map((permissionList, lIndex) =>
                            permissionList ? (
                              <PermissionListItem
                                key={"perm-list-" + lIndex}
                                index={[permissionItem.group.slug, iIndex, lIndex]}
                              />
                            ) : null
                          )
                        ) : (
                          <tr>
                            <td colSpan="10">Nenhuma permissão cadastrada.</td>
                          </tr>
                        )}
                      </Fragment>
                      {group == "-" ? (
                        <tr>
                          <td colSpan="10">Selecione um grupo de permissões acima.</td>
                        </tr>
                      ) : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </Fragment>
          ))}
      </div>
    </Fragment>
  );
};

export default PermissionList;
