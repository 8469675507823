import moment from "moment";

const optionLoading = { value: "", label: "Carregando...", disabled: true };
const optionSelectUserType = { value: "", label: "Selecione um tipo de usuário..." };

const events = ({ ctxAction, ...props }) => {
  return {
    endDate: {
      onChange: (value, rawValue, update) => {
        update("endDate", { value: value.endOf("day") }, rawValue ? moment(rawValue).toISOString() : rawValue);
      }
    },
    startDate: {
      onChange: (value, rawValue, update) => {
        update("startDate", { value: value.startOf("day") }, rawValue ? moment(rawValue).toISOString() : rawValue);
      }
    }
  };
};

const values = ({ ctxAction, token, ...props }) => {
  let initValues = {
    name: {
      className: "uk-input",
      placeholder: "Nome",
      value: "",
      label: {
        text: "Nome",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-1"
      }
    },
    description: {
      className: "uk-textarea",
      placeholder: "Descrição",
      label: {
        text: "Descrição",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-1"
      }
    },
    startDate: {
      className: "datepicker uk-margin-small-left uk-input",
      inputProps: {
        className: "uk-input",
        placeholder: "Válido de *"
      },
      dateFormat: "DD / MM / YYYY",
      value: new Date(),
      rawValue: new Date(),
      label: {
        text: "Válido de",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      },
    },
    endDate: {
      className: "datepicker uk-margin-small-right uk-input",
      inputProps: {
        className: "uk-input",
        placeholder: "Válido até *"
      },
      dateFormat: "DD / MM / YYYY",
      value: new Date(),
      rawValue: new Date(),
      label: {
        text: "Válido até",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      },
    },
    status: {
      className: "uk-checkbox",
      value: true,
      label: {
        text: "Tipo de usuário ativo",
        className: "uk-form-label uk-margin-small-left",
        position: "below"
      },
      wrapper: {
        className: "field-checkbox uk-width-1-2@m"
      }
    }
  };

  return initValues;
};

const actions = ({ token, ctxAction, ntfAction, updater, history, path, ...props }) => {
  return {
    entity: {
      name: "userType",
      token: token,
      form: ctxAction("form")
    },
    actions: {
      entity: ctxAction,
      notification: ntfAction,
      updater: updater
    },
    find: {
      success: {
        name: "UserTypeFoundResult",
        action: { notify: false, redirect: false }
      },
      error: {
        name: "UserTypeErrorResult",
        message: "Houve um erro ao buscar o tipo de usuário",
        action: { notify: "userTypeListPage", redirect: () => history.push(path) }
      }
    },
    create: {
      success: {
        name: "UserTypeCreateResult",
        message: "Tipo de usuário adicionado com sucesso",
        action: { notify: "userTypeListPage", redirect: () => history.push(path) }
      },
      error: {
        name: "UserTypeErrorResult",
        message: "Houve um erro ao adicionar o tipo de usuário",
        action: { notify: "userTypeForm", redirect: false }
      }
    },
    update: {
      success: {
        name: "UserTypeUpdateResult",
        message: "Tipo de usuário atualizado com sucesso",
        action: { notify: "userTypeListPage", redirect: () => history.push(path) }
      },
      error: {
        name: "UserTypeErrorResult",
        message: "Houve um erro ao atualizar o tipo de usuário",
        action: { notify: "userTypeForm", redirect: false }
      }
    }
  };
};

export { events, values, actions };
