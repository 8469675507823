import React, { Fragment } from "react";
import { Route, Switch, withRouter } from "react-router-dom";

import permissions from "@perm";

import Access from "@cthulhi/comp-accs";

import RedemptionConsumerReportPage from "./RedemptionConsumerReportPage.react";
import RedemptionVoucherReportPage from "./RedemptionVoucherReportPage.react";
import RedemptionListPage from "./RedemptionListPage.react";
import RedemptionViewPage from "./RedemptionViewPage.react";

const Page = ({ match, ...props }) => {
  return (
    <Fragment>
      <Switch>
        <Access.RestrictRoute
          onAccess={[permissions.redemption.viewAny]}
          path={`${match.path}`}
          exact
          onAllow={RedemptionListPage}
        />
        <Access.RestrictRoute
          onAccess={[permissions.redemption.viewAny]}
          path={`${match.path}/view/:id`}
          onAllow={RedemptionViewPage}
        />
        <Access.RestrictRoute
          onAccess={[permissions.redemption.view, permissions.redemption.viewAny]}
          path={`${match.path}/consumer`}
          onAllow={RedemptionConsumerReportPage}
        />
        <Access.RestrictRoute
          onAccess={[permissions.redemption.viewAny]}
          path={`${match.path}/voucher`}
          onAllow={RedemptionVoucherReportPage}
        />
      </Switch>
    </Fragment>
  );
};

export default withRouter(Page);
