import { schema, types } from "@cthulhi/pack-schm";
import { types as fields } from "@cthulhi/comp-form";

const schm = schema("user", {
  newPassword: {
    type: types.string.type,
    field: fields.password.type,
    required: { contains: true }
  },
  confirmPassword: {
    type: types.string.type,
    field: fields.password.type,
    required: { contains: true }
  }
});

export { schm as schema };
