import React, { Fragment } from "react";

import Access from "@cthulhi/comp-accs";

import permissions from "@perm";

const NegociosGroup = ({ path, toggleAsideMenu }) => {
  return (
    <Access.RestrictComponent
      onDeny={() => null}
      onLoading={() => null}
      onAccess={[permissions.redemption.view, permissions.voucher.validate]}
    >
      <li>
        <span className="menu-title uk-margin-top">Negócios</span>
      </li>
      <li>
        <Access.RestrictNavLink
          onAccess={[permissions.redemption.view]}
          onDeny={() => null}
          to={`${path}/redemption/consumer`}
          className="menu-link"
          onClick={toggleAsideMenu}
        >
          <i className="icon icon-profile" aria-hidden="true"></i>
          <span>Perfil do consumidor</span>
        </Access.RestrictNavLink>
      </li>

      <li>
        <Access.RestrictNavLink
          onAccess={[permissions.voucher.validate]}
          onDeny={() => null}
          to={`${path}/voucher/validate`}
          className="menu-link"
          onClick={toggleAsideMenu}
        >
          <i className="icon icon-qrcode" aria-hidden="true"></i>
          <span>Validar voucher</span>
        </Access.RestrictNavLink>
      </li>
    </Access.RestrictComponent>
  );
};

export default NegociosGroup;
