import { schema, types } from "@cthulhi/pack-schm";
import { schema as commonSchema } from "./common.schema";
import { types as fields } from "@cthulhi/comp-form";

const schm = schema("user_type", {
  ...commonSchema.schema.props,
  name: {
    type: types.string.type,
    field: fields.textfield.type,
    required: { contains: true }
  },
  description: {
    type: types.string.type,
    field: fields.textarea.type,
    required: { contains: true }
  },
  endDate: {
    type: types.datetime.type,
    field: fields.datetime.type,
    required: { contains: true }
  }
});

export { schm as schema };
