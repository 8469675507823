import { schema, types } from "@cthulhi/pack-schm";
import { types as fields } from "@cthulhi/comp-form";
import { schema as commonSchema } from "./common.form.schema";


const schm = schema("discount", {
  ...commonSchema.schema.props,
  partnerId: {
    type: types.string.type,
    field: fields.inputSearch.type,
    required: true
  },
});

export { schm as schema };
