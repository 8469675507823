import React, { Fragment, useEffect, useState } from "react";
import { Link, Switch, Route, withRouter } from "react-router-dom";

import Access from "@cthulhi/comp-accs";
import Context from "@context";
import { useFields, useForms } from "@cthulhi/comp-form";

import { schema as branchFormSchemaPlace } from "@schm/branch/create.place.form.schema";
import { schema as branchFormSchemaVirtual } from "@schm/branch/create.virtual.form.schema";

import {
  events as branchFormEvents,
  values as branchFormValues,
  actions as branchFormActions
} from "./components/BranchForm.config";

import { FieldLabelWrapper } from "../../../wrappers/FormWrappers.react";

import Notification from "@cthulhi/comp-noty";
import NotificationComponent from "../../../utils/Notification.react";

import LoaderComponent from "../../../utils/LoaderCustomComponent.react";

const BranchFormPage = ({ match, parentPath, history, isProfile, ...props }) => {
  const { state: accState, action: accAction } = Access.useState();
  const { action: ctxAction, state: ctxtState } = Context.useState();
  const { state: ntfState, action: ntfAction } = Notification.useState();
  const [branchSchema, setBranchSchema] = useState(branchFormSchemaVirtual);
  const [openMap, setOpenMap] = useState(false);
  const [validation, setValidation] = useState({})

  const { form, fields, values, updater, cleaner } = useFields({
    schema: branchSchema,
    events: branchFormEvents({ ctxAction }),
    values: branchFormValues({ ctxAction })
  });

  const { find, update, create } = useForms(
    branchFormActions({
      ctxAction,
      ntfAction,
      updater,
      history,
      path: parentPath,
      token: `Bearer ${accState.access.session.context.token}`
    })
  );

  const updateSchema = isPlace => {
    setBranchSchema(isPlace ? branchFormSchemaPlace : branchFormSchemaVirtual);
  };

  useEffect(() => {
    updateSchema(typeof values.type != "undefined" && values.type.value == "fisico");
  }, [JSON.stringify(typeof values.type != "undefined" && values.type.value)]);

  const branchFormSubmit = () => {
    let vs = {};

    Object.entries(values).map(([key, value]) => {
      const hasValue = typeof value.value != "undefined" && value.value !== "";
      const hasRawValue = typeof value.rawValue != "undefined" && value.rawValue !== "";

      if (branchSchema.schema.props[key]) {
        vs[key] = hasRawValue ? value.rawValue : hasValue ? value.value : value.defaultValue;
      }
    });

    if (vs["type"] == "fisico") {
      vs["location"] = {
        type: "Point",
        coordinates: [vs.longitude, vs.latitude]
      };

      vs["city"] = "" + vs["city"];
    }

    const validate = action => {
      action.then(() => {}).catch(setValidation)
    }

    delete vs.latitude;
    delete vs.longitude;
    delete vs.googlemaps;

    match.params.id ? validate(update(vs)) : validate(create(vs));
  };

  useEffect(() => {
    if (match.params.id) {
      find({ filters: { _id: match.params.id } });
    }

    return () => {
      cleaner();
    };
  }, [match.params.id]);

  return (
    <Fragment>
      <h2 className="title">{match.params.id ? "Editar unidade" : "Nova unidade"}</h2>

      <div className="uk-margin-top">
        <ul data-uk-accordion="multiple: true">
          <li className="uk-open">
            <a className="title title-lead-small title-accordion uk-accordion-title">
              Dados da unidade
            </a>
            <div className="uk-accordion-content">
              <Notification.NotificationWrapper
                group="branchForm"
                component={NotificationComponent}
              />
              <div className="uk-card uk-card-default uk-card-body uk-padding-small">
                <LoaderComponent from="branchForm" />
                <form className="uk-grid-small" data-uk-grid>
                  <FieldLabelWrapper name="name" fields={fields} values={values} validation={validation.name}/>
                  <FieldLabelWrapper name="stage" fields={fields} values={values} validation={validation.stage}/>

                  <FieldLabelWrapper name="type" fields={fields} values={values} validation={validation.type}/>
                  <FieldLabelWrapper name="phone" fields={fields} values={values} validation={validation.phone}/>
                  <FieldLabelWrapper name="status" fields={fields} values={values} validation={validation.status}/>
                  <FieldLabelWrapper name="site" fields={fields} values={values} validation={validation.site}/>

                  <FieldLabelWrapper name="zipcode" fields={fields} values={values} validation={validation.zipcode}/>
                  <FieldLabelWrapper name="address" fields={fields} values={values} validation={validation.address}/>
                  <FieldLabelWrapper name="district" fields={fields} values={values} validation={validation.district}/>
                  <FieldLabelWrapper name="number" fields={fields} values={values} validation={validation.number}/>
                  <FieldLabelWrapper name="complement" fields={fields} values={values} validation={validation.complement}/>
                  <FieldLabelWrapper name="state" fields={fields} values={values} validation={validation.state}/>
                  <FieldLabelWrapper name="city" fields={fields} values={values} validation={validation.city}/>

                  <FieldLabelWrapper name="latitude" fields={fields} values={values} validation={validation.latitude}/>
                  <FieldLabelWrapper name="longitude" fields={fields} values={values} validation={validation.longitude}/>

                  {/* {values.type.value == "fisico" ? (
                    openMap ? (
                      <Fragment>
                        <FieldLabelWrapper name="googlemaps" fields={fields} values={values} />
                        <div className="field-wrapper uk-width-1-1 uk-grid-margin">
                          <button className="uk-button uk-button-small uk-button-primary" onClick={e => setOpenMap(false)}>Fechar Mapa</button>
                        </div>
                      </Fragment>
                    ) : (
                      <div className="field-wrapper uk-width-1-1 uk-grid-margin">
                        <button className="uk-button uk-button-small uk-button-primary" onClick={e => setOpenMap(true)}>Abrir Mapa</button>
                      </div>
                    )
                  ) : null} */}
                </form>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div className="uk-margin-top">
        <button
          onClick={branchFormSubmit}
          className="uk-button uk-button-large uk-button-secondary"
          style={{ float: "right" }}
          disabled={
            typeof ctxtState.loader.list["branchForm"] != "undefined" &&
            ctxtState.loader.list["branchForm"].length > 0
          }
        >
          {match.params.id ? "Atualizar" : "Salvar"}
        </button>
      </div>
    </Fragment>
  );
};

// @ts-ignore
export default withRouter(BranchFormPage);
