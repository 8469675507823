import React, { Fragment, useEffect, useState } from "react";
import { Link, Switch, Route, withRouter } from "react-router-dom";

import Access from "@cthulhi/comp-accs";
import Context from "@context";
import { useFields, useForms } from "@cthulhi/comp-form";

import { schema as benefitFormSchema } from "@schm/benefit/create.form.schema";

import {
  events as benefitFormEvents,
  values as benefitFormValues,
  actions as benefitFormActions
} from "./components/BenefitForm.config";

import { FieldLabelWrapper } from "../../../wrappers/FormWrappers.react";

import Notification from "@cthulhi/comp-noty";
import NotificationComponent from "../../../utils/Notification.react";

import LoaderComponent from "../../../utils/LoaderCustomComponent.react";

import EventRelationList from "./components/EventRelationList.react";
import PreGeneratedModal from "./components/PreGeneratedModal.react";
import BranchRelationList from "../branch/components/BranchAndTypeRelationList.react";
import CategoryRelationList from "../category/components/CategoryRelationList.react";

const BenefitFormPage = ({ match, parentPath, history, ...props }) => {
  const { state: accState, action: accAction } = Access.useState();
  const { action: ctxAction, state: ctxtState } = Context.useState();
  const { state: ntfState, action: ntfAction } = Notification.useState();
  const [validation, setValidation] = useState({})

  const [branchList, setBranchList] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  const sortAscAZ = (a, b) => {
    if (a.label > b.label) return 1;
    if (a.label < b.label) return -1;
    return 0;
  };

  const { form, fields, values, updater, cleaner } = useFields({
    schema: benefitFormSchema,
    events: benefitFormEvents({ ctxAction }),
    values: benefitFormValues({
      ctxAction,
      token: `Bearer ${accState.access.session.context.token}`
    })
  });

  const { find, update, create } = useForms(
    benefitFormActions({
      ctxAction,
      ntfAction,
      updater,
      history,
      path: parentPath,
      token: `Bearer ${accState.access.session.context.token}`
    })
  );

  const onBranchRemove = value => {
    setBranchList([
      ...branchList.filter(b => b.event + b.type != value.event.value + value.type.value)
    ]);
  };

  const onBranchAdd = (event, type) => {
    setBranchList([...branchList, { event: event.value, type: type.value, location: event.location }]);
  };

  const onBranchInit = values => {
    setBranchList([
      ...values.map(item => {
        return { event: item.event.value, type: item.type.value, location: item.location };
      })
    ]);
  };

  const onBranchFind = () => {
    return ctxAction("branch")
      .find(
        { filters: { stage: "approved", status: true } },
        { sort: { name: 1 } },
        `Bearer ${accState.access.session.context.token}`,
        {
          start: () => ctxAction("loader").addLoading("find", "branchRelationList"),
          finish: () => ctxAction("loader").removeLoading("find", "branchRelationList")
        }
      )
      .then(res => {
        return res.result.values.map((item, index) => {
          return { value: item._id, label: item.name, location: item.location };
        });
      });
  };

  const onEventRemove = index => {
    setEventList([...eventList.filter((date, iIndex) => iIndex != index)]);
  };

  const onEventAdd = value => {
    setEventList([...eventList, value]);
  };

  const onEventInit = values => {
    setEventList([...values]);
  };

  const onCategoryRemove = value => {
    setCategoryList([...categoryList.filter(b => b.value != value.value)]);
  };

  const onCategoryAdd = value => {
    setCategoryList([...categoryList, value]);
  };

  const onCategoryInit = values => {
    setCategoryList([...values]);
  };

  const onCategoryFind = () => {
    return ctxAction("category")
      .find(
        { filters: { parentId: { $exists: true }, status: true } },
        undefined,
        `Bearer ${accState.access.session.context.token}`,
        {
          start: () => ctxAction("loader").addLoading("find", "categoryRelationList"),
          finish: () => ctxAction("loader").removeLoading("find", "categoryRelationList")
        }
      )
      .then(res => {
        return res.result.values
          .map(item => {
            return { value: item._id, label: item.name };
          })
          .sort(sortAscAZ);
      });
  };

  useEffect(() => {
    if (match.params.id) {
      find({
        filters: { _id: match.params.id },
        aggregates: [
          {
            lookup: {
              from: "branch",
              field: "branches.event",
              match: "_id",
              result: "branchesList"
            }
          },
          {
            lookup: {
              from: "category",
              field: "categories",
              match: "_id",
              result: "categoriesList"
            }
          },
          {
            lookup: {
              from: "business",
              field: "businessId",
              match: "_id",
              result: "business",
              op: "$eq"
            }
          }
        ]
      });
    }

    return () => {
      cleaner();
    };
  }, [match.params.id]);

  const benefitFormSubmit = async () => {
    let vs = {};

    Object.entries(values).map(([key, value]) => {
      const hasValue = typeof value.value != "undefined" && value.value !== "";
      const hasRawValue = typeof value.rawValue != "undefined" && value.rawValue !== "";

      vs[key] = hasRawValue ? value.rawValue : hasValue ? value.value : value.defaultValue;
    });

    vs.businessId = vs.businessId ? vs.businessId : vs.business
    delete vs.business;
    vs.events = eventList.sort();
    vs.branches = branchList;
    vs.categories = categoryList.map(item => item.value);

    if (vs.bannerUrl && vs.bannerUrl.includes("blob:")) {
      await fetch(values.bannerUrl.rawValue)
        .then(res => res.blob())
        .then(blob => {
          vs.bannerUrlBlob = blob;
        });
    }

    if(vs.bannerUrl && vs.bannerUrl.includes("https:")) {
      delete vs.bannerUrl
    }

    const validate = action => {
      action.then(() => {}).catch(setValidation)
    }

    match.params.id ? validate(update(vs)) : validate(create(vs));
  };

  // TO DO: merge fix params and avoid duplication
  const branchTypes = {
    salespoint: "Ponto de venda",
    usepoint: "Ponto de uso"
  };

  // TO DO: load from db agregate
  const branches =
    // @ts-ignore
    values && values.branches
      ? // @ts-ignore
        // @ts-ignore
        values.branches.rawValue.map((branch, index) => {
          const label =
            values.branchesList &&
            values.branchesList.rawValue.find(item => item._id == branch.event);
          return {
            event: {
              value: branch.event,
              label: typeof label != "undefined" ? label.name : branch.event,
            },
            type: { value: branch.type, label: branchTypes[branch.type] },
            location: typeof label != "undefined" ? label.location : null
          };
        })
      : [];

  // TO DO: load from db agregate
  const events =
    // @ts-ignore
    values && values.events
      ? // @ts-ignore
        // @ts-ignore
        values.events.rawValue
      : [];

  const categories =
    // @ts-ignore
    values && values.categoriesList
      ? // @ts-ignore
        // @ts-ignore
        values.categoriesList.rawValue.map(category => {
          return { value: category._id, label: category.name };
        })
      : [];

  return (
    <Fragment>
      <h2 className="title">{match.params.id ? "Editar benefício" : "Novo benefício"}</h2>
      <div className="uk-margin-top">
        <ul data-uk-accordion="multiple: true">
          <li>
            <a className="title title-lead-small title-accordion uk-accordion-title">
              Relacionar unidades
            </a>
            <div className="uk-accordion-content">
              <LoaderComponent from="benefitForm" />
              <BranchRelationList
                onFind={onBranchFind}
                onAdd={onBranchAdd}
                onRemove={onBranchRemove}
                onInit={onBranchInit}
                values={branches}
              />
            </div>
          </li>
          <li>
            <a className="title title-lead-small title-accordion uk-accordion-title">
              Relacionar categorias
            </a>
            <div className="uk-accordion-content">
              <LoaderComponent from="benefitForm" />
              <CategoryRelationList
                onFind={onCategoryFind}
                onAdd={onCategoryAdd}
                onRemove={onCategoryRemove}
                onInit={onCategoryInit}
                values={categories}
              />
            </div>
          </li>
          <li className="uk-open">
            <a className="title title-lead-small title-accordion uk-accordion-title">
              Dados do benefício
            </a>
            <div className="uk-accordion-content">
              <Notification.NotificationWrapper
                group="benefitForm"
                component={NotificationComponent}
              />
              <div className="uk-card uk-card-default uk-card-body uk-padding-small">
                <LoaderComponent from="benefitForm" />
                <div className="uk-grid-small" data-uk-grid>
                  <FieldLabelWrapper name="business" fields={fields} values={values} validation={validation.businessId}/>
                  <FieldLabelWrapper name="type" fields={fields} values={values} validation={validation.type}/>
                  <FieldLabelWrapper name="flagPromotion" fields={fields} values={values} validation={validation.flagPromotion}/>
                  <FieldLabelWrapper name="flagSpotlight" fields={fields} values={values} validation={validation.flagSpotlight}/>
                  <FieldLabelWrapper name="flagHidden" fields={fields} values={values} validation={validation.flagHidden}/>
                  <FieldLabelWrapper name="status" fields={fields} values={values} validation={validation.status}/>

                  <FieldLabelWrapper name="title" fields={fields} values={values} validation={validation.title}/>
                  <FieldLabelWrapper name="slug" fields={fields} values={values} validation={validation.slug}/>
                  <FieldLabelWrapper name="description" fields={fields} values={values} validation={validation.description}/>
                  <FieldLabelWrapper name="resume" fields={fields} values={values} validation={validation.resume}/>
                  <FieldLabelWrapper name="discountType" fields={fields} values={values} validation={validation.discountType}/>
                  <FieldLabelWrapper name="discount" fields={fields} values={values} validation={validation.discount}/>

                  {values.type.value == "event" ? (
                    <div className="uk-margin-top uk-width-1-1">
                      <ul data-uk-accordion="multiple: true">
                        <li>
                          <a className="title title-lead-small title-accordion uk-accordion-title">
                            Dados do evento
                          </a>
                          <div className="uk-accordion-content">
                            <div className="uk-card uk-card-default uk-card-body uk-padding-small">
                              <div className="uk-grid-small" data-uk-grid>
                                <EventRelationList
                                  onAdd={onEventAdd}
                                  onRemove={onEventRemove}
                                  onInit={onEventInit}
                                  values={events}
                                />
                                <FieldLabelWrapper
                                  name="eventDescription"
                                  fields={fields}
                                  values={values}
                                />
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  ) : null}

                  <FieldLabelWrapper name="siteUrl" fields={fields} values={values} validation={validation.siteUrl}/>
                  <FieldLabelWrapper name="tinySiteUrl" fields={fields} values={values} validation={validation.tinySiteUrl}/>
                  <FieldLabelWrapper name="footerNote" fields={fields} values={values} validation={validation.footerNote}/>
                  <FieldLabelWrapper name="shelfLifeStart" fields={fields} values={values} validation={validation.shelfLifeStart}/>
                  <FieldLabelWrapper name="shelfLifeEnd" fields={fields} values={values} validation={validation.shelfLifeEnd}/>
                  <FieldLabelWrapper name="redemptionType" fields={fields} values={values} validation={validation.redemptionType}/>

                  {["promocode", "limitedvoucher"].includes(values.redemptionType.value) ? (
                    <FieldLabelWrapper name="voucherCode" fields={fields} values={values} validation={validation.voucherCode}/>
                  ) : null}

                  {["limitedvoucher"].includes(values.redemptionType.value) ? (
                    <Fragment>
                      <FieldLabelWrapper name="voucherAmount" fields={fields} values={values} validation={validation.voucherAmount}/>
                      <FieldLabelWrapper name="preGeneratedCode" fields={fields} values={values} validation={validation.preGeneratedCode}/>
                    </Fragment>
                  ) : null}

                  <FieldLabelWrapper name="voucherShelfLife" fields={fields} values={values} validation={validation.voucherShelfLife}/>
                  <FieldLabelWrapper name="limitVoucherByUser" fields={fields} values={values} validation={validation.limitVoucherByUser}/>

                  {values.limitVoucherByUser.value ? (
                    <Fragment>
                      <FieldLabelWrapper name="voucherUserLimit" fields={fields} values={values} validation={validation.voucherUserLimit}/>
                      <FieldLabelWrapper
                        name="voucherPeriodLimit"
                        fields={fields}
                        values={values}
                      />
                    </Fragment>
                  ) : null}

                  <FieldLabelWrapper name="howToUseVoucher" fields={fields} values={values} validation={validation.howToUseVoucher}/>
                </div>
              </div>
            </div>
          </li>
          <li>
            <a className="title title-lead-small title-accordion uk-accordion-title">
              Banner & Vídeo
            </a>
            <div className="uk-accordion-content">
              <div className="uk-card uk-card-default uk-card-body uk-padding-small">
                <LoaderComponent from="benefitForm" />
                <div className="uk-grid-small" data-uk-grid>
                  <FieldLabelWrapper name="bannerUrl" fields={fields} values={values} validation={validation.bannerUrl}/>
                  <FieldLabelWrapper name="videoUrl" fields={fields} values={values} validation={validation.videoUrl}/>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div className="uk-margin-top">
        <button
          onClick={benefitFormSubmit}
          className="uk-button uk-button-large uk-button-secondary"
          style={{ float: "right" }}
          disabled={
            typeof ctxtState.loader.list["benefitForm"] != "undefined" &&
            ctxtState.loader.list["benefitForm"].length > 0
          }
        >
          {match.params.id ? "Atualizar" : "Salvar"}
        </button>
      </div>
    </Fragment>
  );
};

export default withRouter(BenefitFormPage);
