import uc from "../constants/coupon.constant";

export default (dispatch, state) => {
  const dispatchSetStatus = (data) => {
    dispatch({
      type: uc.ACT_SET_STATUS,
      data,
    });
  };

  const remove = (values, authorization) => {
    const args = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization,
      },
      body: JSON.stringify(values),
      credentials: "same-origin",
    };

    dispatchSetStatus(uc.STS_DELETE_REQUEST);

    return fetch(process.env.domainCouponDelete, args)
      .then((res) => res.json())
      .then((res) => {
        dispatchSetStatus(uc.STS_DELETE_RESPONSE);
        dispatchSetStatus(uc.STS_DELETE_SUCCESS);

        setTimeout(() => {
          dispatchSetStatus(uc.STS_IDLE);
        }, 1000);

        return res;
      })
      .catch((error) => {
        dispatchSetStatus(uc.STS_DELETE_ERROR);

        setTimeout(() => {
          dispatchSetStatus(uc.STS_IDLE);
        }, 1000);

        return error;
      });
  };

  const update = (values, authorization) => {
    const args = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization,
      },
      body: JSON.stringify(values),
      credentials: "same-origin",
    };

    dispatchSetStatus(uc.STS_UPDATE_REQUEST);

    return fetch(process.env.domainCouponUpdate, args)
      .then((res) => res.json())
      .then((res) => {
        dispatchSetStatus(uc.STS_UPDATE_RESPONSE);
        dispatchSetStatus(uc.STS_UPDATE_SUCCESS);

        setTimeout(() => {
          dispatchSetStatus(uc.STS_IDLE);
        }, 1000);
        return res;
      })
      .catch((error) => {
        dispatchSetStatus(uc.STS_UPDATE_ERROR);

        setTimeout(() => {
          dispatchSetStatus(uc.STS_IDLE);
        }, 1000);

        return error;
      });
  };

  const create = (values, authorization) => {
    const args = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization,
      },
      body: JSON.stringify(values),
      credentials: "same-origin",
    };

    dispatchSetStatus(uc.STS_CREATE_REQUEST);

    return fetch(process.env.domainCouponCreate, args)
      .then((res) => res.json())
      .then((res) => {
        dispatchSetStatus(uc.STS_CREATE_RESPONSE);
        dispatchSetStatus(uc.STS_CREATE_SUCCESS);

        setTimeout(() => {
          dispatchSetStatus(uc.STS_IDLE);
        }, 1000);
        return res;
      })
      .catch((error) => {
        dispatchSetStatus(uc.STS_CREATE_ERROR);

        setTimeout(() => {
          dispatchSetStatus(uc.STS_IDLE);
        }, 1000);

        return error;
      });
  };

  const splitBulk = (arr) => {
    const chunks = [];
    let i = 0;

    while (i < arr.length) {
      chunks.push(arr.slice(i, i += 1000));
    }
    return chunks;
  };

  const createBulk = (values, authorization, loader) => {
    const splitted = splitBulk(values.codeList);

    const create = splitted.map((array) => {
      const args = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authorization,
        },
        body: JSON.stringify({ codeList: array }),
        credentials: "same-origin",
      };

      if (loader) loader.start();

      dispatchSetStatus(uc.STS_CREATE_BULK_REQUEST);

      return fetch(process.env.domainCouponCreateBulk, args)
        .then((res) => res.json());
    });

    return Promise.all(create)
      .then(() => {
        dispatchSetStatus(uc.STS_CREATE_BULK_RESPONSE);
        dispatchSetStatus(uc.STS_CREATE_BULK_SUCCESS);
        if (loader) loader.finish();

        setTimeout(() => {
          dispatchSetStatus(uc.STS_IDLE);
        }, 1000);
      })
      .catch((error) => {
        dispatchSetStatus(uc.STS_CREATE_BULK_ERROR);

        if (loader) loader.finish();

        setTimeout(() => {
          dispatchSetStatus(uc.STS_IDLE);
        }, 1000);

        return error;
      });
  };

  const invalidateBulk = (values, authorization, loader) => {

    const args = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization,
      },
      body: JSON.stringify(values),
      credentials: "same-origin",
    };

    dispatchSetStatus(uc.STS_UPDATE_REQUEST);

    return fetch(process.env.domainCouponInvalidateBulk, args)
      .then((res) => res.json())
      .then((res) => {
        dispatchSetStatus(uc.STS_UPDATE_RESPONSE);
        dispatchSetStatus(uc.STS_UPDATE_SUCCESS);

        setTimeout(() => {
          dispatchSetStatus(uc.STS_IDLE);
        }, 10000);
        return res;
      })
      .catch((error) => {
        dispatchSetStatus(uc.STS_UPDATE_ERROR);

        setTimeout(() => {
          dispatchSetStatus(uc.STS_IDLE);
        }, 10000);

        return error;
      });

  };

  const find = (filters, options, authorization, loader) => {
    const args = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization,
      },
      body: JSON.stringify({ ...filters, options: { sort: { _id: -1 }, limit: 100000, ...options } }),
      credentials: "same-origin",
    };

    if (loader) loader.start();

    dispatchSetStatus(uc.STS_FIND_REQUEST);

    return fetch(process.env.domainCouponFind, args)
      .then((res) => res.json())
      .then((res) => {
        dispatchSetStatus(uc.STS_FIND_RESPONSE);
        dispatchSetStatus(uc.STS_FIND_SUCCESS);
        if (loader) loader.finish();

        setTimeout(() => {
          dispatchSetStatus(uc.STS_IDLE);
        }, 1000);

        return res;
      })
      .catch((error) => {
        dispatchSetStatus(uc.STS_FIND_ERROR);

        if (loader) loader.finish();

        setTimeout(() => {
          dispatchSetStatus(uc.STS_IDLE);
        }, 1000);

        return error;
      });
  };

  return {
    find,
    create,
    createBulk,
    update,
    remove,
    invalidateBulk
  };
};
