const events = ({ ctxAction, ...props }) => {
  return {};
};

const values = ({ ctxAction, token, ...props }) => {
  let initValues = {
    type: {
      wrapper: {
        className: "uk-width-1-1@m"
      },
      label: {
        text: ""
      },
      options: [
        {
          label: "Físico",
          id: "place",
          val: "place"
        },
        {
          label: "Online",
          id: "virtual",
          val: "virtual",
          checked: true
        }
      ]
    },
    name: {
      className: "uk-input",
      placeholder: "Nome",
      label: {
        text: "Nome",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      },
      default: "",
    },
    site: {
      className: "uk-input",
      placeholder: "Site",
      label: {
        text: "Site",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      },
      default: "",
    },
    contact: {
      className: "uk-input",
      placeholder: "Contato",
      label: {
        text: "Contato",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      },
      default: "",
    }
  };

  return initValues;
};

const actions = ({ token, ctxAction, ntfAction, updater, history, path, ...props }) => {
  return {
    entity: {
      name: "branch",
      token: token,
      form: ctxAction("form")
    },
    actions: {
      entity: ctxAction,
      notification: ntfAction,
      updater: updater
    }
  };
};

export { events, values, actions };
