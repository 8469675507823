import React, { Fragment, useState, useEffect } from "react";
import moment from "moment";

import LoaderComponent from "../../../../utils/LoaderCustomComponent.react";
import { DateTime } from "@cthulhi/comp-form";

const EventRelationList = ({ onRemove, onAdd, onInit, values = [], ...props }) => {
  const [selectedItems, setSelectedItems] = useState(values);
  const [selectedDate, setSelectedDate] = useState("");

  useEffect(() => {
    if(values) {
      onInit(values);
      setSelectedItems(values);
    }
  }, [JSON.stringify(values)]);

  const onClickAdd = () => {
    onAdd(selectedDate);
    setSelectedItems([...selectedItems, selectedDate]);
  };

  const onChange = date => {
    const isDate =
      typeof date.includes != "function" ? true : date.includes("Invalid date") ? true : false;

    if (!isDate) {
      setSelectedDate("");
    } else {
      setSelectedDate(moment(date).toISOString());
    }
  };

  const onClickRemove = index => {
    onRemove(index);
    setSelectedItems([...selectedItems.filter((i, iIndex) => index != iIndex)]);
  };

  return (
    <Fragment>
      <LoaderComponent from="categoryRelationList" />

      <div className="field-wrapper uk-width-2-3@m">
        <label className={`uk-form-label ${selectedDate && selectedDate != "" ? " show" : ""}`}>
          Data do evento
        </label>
        <DateTime
          name="EventRelationList"
          masker={null}
          options={null}
          onChange={setSelectedDate}
          value={selectedDate}
          rawValue={selectedDate}
          className="datepicker uk-margin-small-right"
          inputProps={{
            className: "uk-input",
            placeholder: "Data do evento"
          }}
          dateFormat="DD / MM / YYYY"
          timeFormat="HH:mm"
          label={{
            text: "Data do evento",
            className: "uk-form-label"
          }}
          wrapper={{
            className: "field-wrapper uk-width-1-2@m"
          }}
        />
      </div>
      <div className="uk-width-1-3@m">
        <button
          className="uk-button uk-button-secondary"
          onClick={onClickAdd}
          disabled={!selectedDate}
        >
          Adicionar
        </button>
      </div>
      <div className="uk-width-1-1">
        {selectedItems.length > 0 ? (
          <div className="uk-overflow-auto">
            <table className="uk-table uk-table-small uk-table-striped uk-margin-remove">
              <thead>
                <tr>
                  <td>Data do evento</td>
                  <td width="20%">Ação</td>
                </tr>
              </thead>
              <tbody>
                {selectedItems.map((item, index) => (
                  <tr key={"brl-item-" + index}>
                    <td>{moment(item).format(" DD / MM / YYYY  hh:mm A")}</td>
                    <td>
                      <button
                        className="uk-icon-button uk-button-danger"
                        onClick={e => onClickRemove(index)}
                      >
                        <i className="icon icon-delete" aria-hidden="true"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}
      </div>
    </Fragment>
  );
};

export default EventRelationList;
