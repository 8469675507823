import { schema, types } from "@cthulhi/pack-schm";
import { types as fields } from "@cthulhi/comp-form";

const schm = schema("banner", {
  benefit: {
    type: types.string.type,
    field: fields.inputSearch.type,
    required: { contains: true }
  },
  weight: {
    type: types.integer.type,
    field: fields.textfield.type,
    required: { contains: true }
  },
  type: {
    type: types.string.type,
    field: fields.select.type
  },
  title: {
    type: types.string.type,
    field: fields.textfield.type
  },
  description: {
    type: types.string.type,
    field: fields.textfield.type
  },
  redirectUrl: {
    type: types.string.type,
    field: fields.textfield.type
  },
  imageUrl: {
    type: types.string.type,
    field: fields.fileimagecrop.type
  }
});

export { schm as schema };
