import React, { Fragment } from "react";
import { schema as schm, types } from "@cthulhi/pack-schm";
import { types as fields } from "@cthulhi/comp-form";

const schema = schm("filter", {
  title: {
    type: types.string.type,
    field: fields.textfield.type
  },
  type: {
    type: types.string.type,
    field: fields.select.type
  }
});

const events = ({ ctxAction, ...props }) => {
  return {};
};

const values = ({ ctxAction, ...props }) => {
  return {
    title: {
      filter: "text",
      className: "uk-input",
      placeholder: "Título",
      label: {
        text: "Nome",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      }
    },
    type: {
      className: "uk-select",
      value: "",
      defaultValue: "",
      label: {
        text: "Tipo",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      },
      options: update => {
        update("type", {
          options: [
            { value: "", label: "Selecione um Tipo..." },
            { value: "perennial", label: "Perene" },
            { value: "event", label: "Evento" }
          ]
        });
      }
    }
  };
};

const options = ({ onView, ...props }) => {
  return {
    filter: {
      fields: true,
      submit: true
    },
    pagination: {
      next: {
        value: "Próximo",
        className: "page-next"
      },
      prev: {
        value: "Anterior",
        className: "page-prev"
      },
      numbers: {
        className: "page-numbers"
      },
      total: true
    },
    limit: {
      value: 5,
      limits: [
        { value: 5, label: "5 Items" },
        { value: 10, label: "10 Items" },
        { value: 20, label: "20 Items" }
      ]
    },
    items: {
      title: {
        header: {
          value: "Título",
          className: ""
        },
        column: {
          className: "",
          value: "title"
        }
      },
      type: {
        header: "Tipo",
        column: "type",
        parse: (value) => {
          if(value == "perennial") return "Perene";
          return "Evento";
        }
      },
      discountType: {
        header: "Desconto",
        column: "discountType",
        parse: (value, item) => {

          const currency = (amount, decimalCount = 2, decimal = ".", thousands = ",") => {
            try {
              decimalCount = Math.abs(decimalCount);
              decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
          
              const negativeSign = amount < 0 ? "-" : "";
          
              let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
              let j = (i.length > 3) ? i.length % 3 : 0;
          
              return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
            } catch (e) {
              return amount;
            }
          };

          switch (value) {
            case "plain":
              return `R$ ${currency(item.discount, 2, ",", ".")}`;
            case "percent":
              return `${item.discount} %`;
          }
        }
      },
      actions: {
        header: "Ações",
        column: (item, refresh, loader) => {
          return {
            value: (
              <Fragment>
                <div className="uk-grid-small uk-child-width-auto" data-uk-grid>
                  <div>
                    <button
                      className="uk-icon-button uk-button-primary"
                      onClick={() => onView(item, refresh, loader)}
                    >
                      <i className="icon icon-view" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </Fragment>
            )
          };
        }
      }
    }
  };
};

const actions = ({ ctxAction, token, ...props }) => {
  return {
    find: async (filters, options) => {
      return ctxAction("benefit").find({ ...filters }, options, token);
    }
  };
};

export { events, values, actions, options, schema };
