import { schema, types } from "@cthulhi/pack-schm";

const schm = schema("branch", {
  name: {
    type: types.string.type,
    required: { contains: true }
  },
  type: {
    type: types.string.type
  },
  createdAt: {
    type: types.datetime.type
  },
  updatedAt: {
    type: types.datetime.type
  },
  createdBy: {
    type: types.string.type
  },
  contact: {
    type: types.string.type,
    required: { contains: true }
  }
});

export { schm as schema };
