const view = {
  group: {
    title: "Parceiros",
    slug: "business"
  },
  slug: "business-view",
  title: "Ver parceiros",
  description: "Ver os próprios parceiros",
  list: [
    "prn::1:business-view-roles:business-view:allow:lcnt:schema.user.roles:custom.static.manager"
  ]
}

const viewAny = {
  group: {
    title: "Parceiros",
    slug: "business"
  },
  slug: "business-view-any",
  title: "Ver qualquer parceiro",
  description: "Ver todos os parceiros",
  list: [
    "prn::1:business-view-any-roles:business-view-any:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

const edit = {
  group: {
    title: "Parceiros",
    slug: "business"
  },
  slug: "business-edit",
  title: "Editar parceiros",
  description: "Editar os próprios parceiros",
  list: [
    "prn::1:business-edit-roles:business-edit:allow:lcnt:schema.user.roles:custom.static.manager"
  ]
}

const editAny = {
  group: {
    title: "Parceiros",
    slug: "business"
  },
  slug: "business-edit-any",
  title: "Editar qualquer parceiro",
  description: "Editar todos os parceiros",
  list: [
    "prn::1:business-edit-any-roles:business-edit-any:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

const create = {
  group: {
    title: "Parceiros",
    slug: "business"
  },
  slug: "business-create",
  title: "Criar parceiros",
  description: "Criar os próprios parceiros",
  list: [
    "prn::1:business-create-roles:business-create:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

const createAny = {
  group: {
    title: "Parceiros",
    slug: "business"
  },
  slug: "business-create-any",
  title: "Criar qualquer parceiro",
  description: "Criar todos os parceiros",
  list: [
    "prn::1:business-create-any-roles:business-create-any:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

const remove = {
  group: {
    title: "Parceiros",
    slug: "business"
  },
  slug: "business-remove",
  title: "Remover parceiros",
  description: "Remover os próprios parceiros"
}

const removeAny = {
  group: {
    title: "Parceiros",
    slug: "business"
  },
  slug: "business-remove-any",
  title: "Remover qualquer parceiro",
  description: "Remover todos os parceiros"
}

module.exports = {
  view,
  viewAny,
  edit,
  editAny,
  create,
  createAny,
  remove,
  removeAny
}