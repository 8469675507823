import { schema, types } from "@cthulhi/pack-schm";
import { types as fields } from "@cthulhi/comp-form";

const schm = schema("benefit", {
  business: {
    type: types.string.type,
    field: fields.inputSearch.type,
    required: { contains: true }
  },
  type: {
    type: types.string.type,
    field: fields.select.type,
    required: { contains: true }
  },
  flagPromotion: {
    type: types.boolean.type,
    field: fields.checkbox.type
  },
  flagSpotlight: {
    type: types.boolean.type,
    field: fields.checkbox.type
  },
  flagHidden: {
    type: types.boolean.type,
    field: fields.checkbox.type
  },
  status: {
    type: types.boolean.type,
    field: fields.checkbox.type
  },
  title: {
    type: types.string.type,
    field: fields.textfield.type,
    required: { contains: true }
  },
  slug: {
    type: types.string.type,
    field: fields.textfield.type,
    required: { contains: true }
  },
  description: {
    type: types.string.type,
    field: fields.textarea.type,
    required: { contains: true }
  },
  resume: {
    type: types.string.type,
    field: fields.textarea.type,
    required: { contains: true }
  },
  videoUrl: {
    type: types.string.type,
    field: fields.textfield.type
  },
  bannerUrl: {
    type: types.string.type,
    field: fields.fileimagecrop.type
  },
  discountType: {
    type: types.string.type,
    field: fields.select.type,
    required: { contains: true }
  },
  discount: {
    type: types.float.type,
    field: fields.number.type,
    required: { contains: true }
  },
  eventDescription: {
    type: types.string.type,
    field: fields.textarea.type
  },
  siteUrl: {
    type: types.string.type,
    field: fields.textfield.type
  },
  tinySiteUrl: {
    type: types.string.type,
    field: fields.textfield.type
  },
  footerNote: {
    type: types.string.type,
    field: fields.textarea.type
  },
  shelfLifeStart: {
    type: types.datetime.type,
    field: fields.datetime.type,
    required: { contains: true }
  },
  shelfLifeEnd: {
    type: types.datetime.type,
    field: fields.datetime.type,
    required: { contains: true }
  },
  redemptionType: {
    type: types.string.type,
    field: fields.select.type,
    required: { contains: true }
  },
  voucherCode: {
    type: types.string.type,
    field: fields.textfield.type
  },
  preGeneratedCode: {
    type: types.boolean.type,
    field: fields.checkbox.type
  },
  voucherAmount: {
    type: types.integer.type,
    field: fields.number.type,
  },
  limitVoucherByUser: {
    type: types.boolean.type,
    field: fields.checkbox.type
  },
  voucherUserLimit: {
    type: types.integer.type,
    field: fields.number.type,
  },
  voucherShelfLife: {
    type: types.integer.type,
    field: fields.number.type,
  },
  voucherPeriodLimit: {
    type: types.integer.type,
    field: fields.number.type
  },
  howToUseVoucher: {
    type: types.string.type,
    field: fields.textarea.type
  }
});

export { schm as schema };
