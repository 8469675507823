import React, { Fragment, useEffect, useState } from "react";
import { Link, Switch, Route, withRouter, Redirect } from "react-router-dom";

import Access from "@cthulhi/comp-accs";

import UserVipFormPage from "./UserVipFormPage.react";
import UserVipListPage from "./UserVipListPage.react";

import permissions from "@perm";

const UserVipPage = ({ match, ...props }) => {
  return (
    <Switch>
      <Access.RestrictRoute
        key="userVip-list"
        onAccess={[permissions.userVip.viewAny, permissions.userVip.editAny]}
        parentPath={match.path}
        path={`${match.path}`}
        exact
        onAllow={UserVipListPage}
      />
      <Access.RestrictRoute
        key="userVip-create"
        onAccess={[permissions.userVip.viewAny, permissions.userVip.editAny]}
        parentPath={match.path}
        path={`${match.path}/create`}
        onAllow={UserVipFormPage}
      />
      <Access.RestrictRoute
        key="userVip-edit"
        onAccess={[permissions.userVip.editAny, permissions.userVip.edit]}
        parentPath={match.path}
        path={`${match.path}/edit/:id`}
        onAllow={UserVipFormPage}
      />
    </Switch>
  );
};

export default withRouter(UserVipPage);
