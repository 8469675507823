import React, { Fragment, useState } from "react";
import { withRouter } from "react-router-dom";

import { List } from "./../../../wrappers/ListWrappers.react";

import { useFilter } from "@cthulhi/comp-list"

import Access from "@cthulhi/comp-accs";
import Context from "@context";

import Notification from "@cthulhi/comp-noty";
import NotificationComponent from "../../../utils/Notification.react";
import ModalConfirmRemoval from "./../../../wrappers/ModalConfirmRemoval.react";

import {
  events as categoryListEvents,
  values as categoryListValues,
  actions as categoryListActions,
  options as categoryListOptions,
  schema as categoryListSchema
} from "./components/CategoryList.config";

const CategoryListPage = ({ match, history, ...props }) => {

  const { state: accState, action: accAction } = Access.useState();
  const { action: ctxAction } = Context.useState();
  const { state: ntfState, action: ntfAction } = Notification.useState();
  const [ modal, setModal ] = useState({ show: false, Content: () => null });

  const token = `Bearer ${accState.access.session.context.token}`

  const onEdit = value => {
    history.push(`${match.path}/edit/${value._id}`);
  };

  const onRemoveAction = (value, refresh, loader) => {
    loader(true)
    ctxAction("category")
      .remove({ _id: value._id }, token)
      .then(res => {

        if (res.name != "CategoryDeleteResult") throw res;

        refresh();

        ntfAction("notification").add(
          res.code,
          "categoryListPage",
          "success",
          "Categoria removida com sucesso.",
          undefined,
          process.env.notificationTimeOut
        );

      })
      .catch(err => {
        loader(false)

        ntfAction("notification").add(
          err.code,
          "categoryListPage",
          "danger",
          "Houve um erro ao remover a Categoria.",
          undefined,
          process.env.notificationTimeOut
        );
      });
  };


  const onRemove = (value, refresh, loader) => {
    setModal({
      show: true,
      Content: props => {
        return (
          <ModalConfirmRemoval
            onConfirm={() => onRemoveAction(value, refresh, loader)}
            onClose={() => setModal({ show: false, Content: () => null })}
          />
        );
      }
    });
  }

  const { filter, list, page, limit, options } = useFilter({ 
    schema: categoryListSchema, 
    events: categoryListEvents(), 
    options: categoryListOptions({ onEdit, onRemove }), 
    values: categoryListValues({ ctxAction, token }), 
    actions: categoryListActions({ ctxAction, token })
  })

  return (
    <Fragment>
      <h2 className="title">Listar categorias</h2>
      <div className="uk-margin-top">
        <Notification.NotificationWrapper group="categoryListPage" component={NotificationComponent} />
        <List { ...{ filter, list, page, limit, options } } />
        <div className="container-modal">{modal.show ? <modal.Content /> : null}</div>
      </div>
    </Fragment>
  );
};

export default withRouter(CategoryListPage);
