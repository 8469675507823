import moment from "moment";
import slugger from "./../../../../utils/slugger.funct";

const optionLoading = { value: "", label: "Carregando...", disabled: true };
const optionSelectBusiness = { value: "", label: "Selecione um parceiro *" };

const events = ({ ctxAction, ...props }) => {
  return {
    shelfLifeEnd: {
      onChange: (value, rawValue, update) => {
        update("shelfLifeEnd", { value: value.endOf("day") }, rawValue ? moment(rawValue).toISOString() : rawValue);
      }
    },
    shelfLifeStart: {
      onChange: (value, rawValue, update) => {
        update("shelfLifeStart", { value: value.startOf("day") }, rawValue ? moment(rawValue).toISOString() : rawValue);
      }
    },
    title: {
      onChange: (value, rawValue, update, get, vals, nodeElement) => {
        update("title", { value }, rawValue);
        update("slug", { value: slugger(value) }, slugger(rawValue));
        if (value.length > 0) {
          const MAX_LENGTH = 35;
          nodeElement.previousSibling.innerText = `Título * (Limite sugerido ${value.length}/${MAX_LENGTH})`;
        } else {
          nodeElement.previousSibling.innerText = `Título *`;
        }
      }
    },
    resume: {
      onChange: (value, rawValue, update, get, vals, nodeElement) => {
        update("resume", { value }, rawValue);
        if (value.length > 0) {
          const MAX_LENGTH = 122;
          nodeElement.previousSibling.innerText = `Resumo * (Limite sugerido ${value.length}/${MAX_LENGTH})`;
        } else {
          nodeElement.previousSibling.innerText = `Resumo *`;
        }
      }
    }
  };
};

const values = ({ ctxAction, token, ...props }) => {
  let initValues = {
    business: {
      className: "uk-select",
      label: {
        text: "Nome fantasia",
        className: "uk-form-label"
      },
      placeholder: "Selecione o nome fantasia",
      value: [],
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      },
      search: (update) => (input) => {
        update("businessId", { value: "", disabled: true, options: [optionLoading] });
        return ctxAction("business")
        .find({}, { sort: { name: 1 } }, token)
        .then(res => {
          if (res.name != "BusinessFoundResult" || res.result.values.length == 0) {
            return [{
              _id: "", name: "Nenhum parceiro encontrado" 
            }]
          }
          return res.result.values;
        });
      },
      optionSchema: (business) => ({
        label: business.name,
        value: business._id,
        key: business._id
      })
    },
    type: {
      className: "uk-select",
      placeholder: "Tipo",
      label: {
        text: "Tipo",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      },
      default: "",
      options: [
        { value: "", label: "Selecione um tipo de benefício *" },
        { value: "perennial", label: "Perene" },
        { value: "event", label: "Evento" }
      ]
    },
    flagPromotion: {
      className: "uk-checkbox",
      placeholder: "Promocional",
      value: false,
      label: {
        text: "Promocional",
        className: "uk-form-label uk-margin-small-left",
        position: "below"
      },
      wrapper: {
        className: "field-checkbox uk-width-1-4@m"
      }
    },
    flagSpotlight: {
      className: "uk-checkbox",
      placeholder: "Destaque",
      value: false,
      label: {
        text: "Destaque",
        className: "uk-form-label uk-margin-small-left",
        position: "below"
      },
      wrapper: {
        className: "field-checkbox uk-width-1-4@m"
      }
    },
    flagHidden: {
      className: "uk-checkbox",
      placeholder: "Oculto",
      value: false,
      label: {
        text: "Oculto",
        className: "uk-form-label uk-margin-small-left",
        position: "below"
      },
      wrapper: {
        className: "field-checkbox uk-width-1-4@m"
      }
    },
    status: {
      className: "uk-checkbox",
      placeholder: "Ativo",
      value: true,
      label: {
        text: "Ativo",
        className: "uk-form-label uk-margin-small-left",
        position: "below"
      },
      wrapper: {
        className: "field-checkbox uk-width-1-4@m"
      }
    },
    title: {
      className: "uk-input",
      placeholder: "Título do benefício",
      label: {
        text: "Título",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      }
    },
    slug: {
      className: "uk-input",
      placeholder: "URL parcial, será preenchida automáticamente.",
      label: {
        text: "Url parcial, será preenchida automaticamente",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      }
    },
    description: {
      className: "uk-textarea",
      placeholder: "Descrição",
      label: {
        text: "Descrição",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-1"
      }
    },
    resume: {
      className: "uk-textarea",
      placeholder: "Resumo do benefício *",
      label: {
        text: "Resumo",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-1"
      }
    },
    videoUrl: {
      className: "uk-input",
      placeholder: "URL do vídeo",
      label: {
        text: "URL vídeo",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-2-5@m"
      }
    },
    bannerUrl: {
      input: {
        wrapper: {
          className: "uk-width-1-1@m"
        },
        field: {
          className: "uk-input",
          placeholder: "Imagem"
        }
      },
      crops: {
        wrapper: {
          className: "uk-margin-top uk-grid uk-margin-top uk-margin-small"
        },
        cropper: {
          className: "uk-width-1-2@m uk-first-column"
        },
        croppeds: {
          className: "uk-width-1-2@m uk-grid"
        },
        images: [
          { wrapper: { className: "uk-width-1-2@m" , key: 0}, image: { style: { width: "100%" } } },
          { wrapper: { className: "uk-width-1-2@m", key: 1 }, image: { style: { width: "50%" } } }
        ]
      },
      locked: false,
      keepSelection: false,
      unit: "%",
      height: 100,
      aspect: 16 / 9,
      label: {
        text: "Imagem",
        className: "uk-form-label"
      },
      description: {
        text: "* Para uma boa aparência, a imagem devem conter no mínimo 1280 pixels de largura."
      },
      wrapper: {
        className: "field-wrapper uk-width-3-5@m"
      }
    },
    discountType: {
      className: "uk-select",
      placeholder: "Tipo do desconto",
      label: {
        text: "Tipo do desconto",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-2-5@m"
      },
      options: [
        { value: "", label: "Selecione um tipo de desconto *" },
        { value: "percent", label: "% Percentual" },
        { value: "plain", label: "R$ Dinheiro" }
      ]
    },
    discount: {
      className: "uk-input",
      placeholder: "Valor do desconto",
      label: {
        text: "Desconto",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-5@m"
      }
    },
    eventDescription: {
      className: "uk-textarea",
      placeholder: "Confira o valor do lote em vigor no site de vendas",
      value: "Confira o valor do lote em vigor no site de vendas",
      label: {
        text: "Valores do evento",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-1"
      }
    },
    siteUrl: {
      className: "uk-input",
      placeholder: "URL completa do site",
      default: "",
      label: {
        text: "URL completa site",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      }
    },
    tinySiteUrl: {
      className: "uk-input",
      placeholder: "URL curta do site",
      label: {
        text: "URL curta site",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      }
    },
    footerNote: {
      className: "uk-textarea",
      placeholder: "Nota do rodapé",
      label: {
        text: "Nota rodapé",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-1"
      }
    },
    shelfLifeStart: {
      className: "datepicker uk-margin-small-right",
      inputProps: {
        className: "uk-input",
        placeholder: "Válido de *"
      },
      dateFormat: "DD / MM / YYYY",
      value: new Date(),
      rawValue: new Date(),
      label: {
        text: "Válido de",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      },
    },
    shelfLifeEnd: {
      className: "datepicker uk-margin-small-right",
      dateFormat: "DD / MM / YYYY",
      inputProps: {
        className: "uk-input",
        placeholder: "Válido até *"
      },
      value: "",
      label: {
        text: "Válido até",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      }
    },
    redemptionType: {
      className: "uk-select",
      label: {
        text: "Tipo de resgate",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-3@m"
      },
      options: [
        { value: "", label: "Selecione um tipo de resgate *" },
        { value: "promocode", label: "Promocode" },
        { value: "limitedvoucher", label: "Voucher limitado" },
        { value: "limitlessvoucher", label: "Voucher ilimitado" }
      ]
    },
    voucherCode: {
      className: "uk-input",
      placeholder: "Código do voucher",
      label: {
        text: "Código do voucher",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-3@m"
      }
    },
    voucherAmount: {
      className: "uk-input",
      placeholder: "Quantidade de vouchers",
      label: {
        text: "Quantidade de vouchers",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-3@m"
      }
    },
    limitVoucherByUser: {
      className: "uk-checkbox",
      placeholder: "Limitar por CPF",
      label: {
        text: "Limitar por CPF",
        className: "uk-form-label uk-margin-small-left",
        position: "below"
      },
      wrapper: {
        className: "field-checkbox uk-width-1-3@m"
      }
    },
    voucherUserLimit: {
      className: "uk-input",
      placeholder: "Quantidade de vouchers por sócio",
      label: {
        text: "Quantidade de vouchers por sócio",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-3@m"
      }
    },
    voucherPeriodLimit: {
      className: "uk-input",
      placeholder: "Período limite",
      label: {
        text: "Período limite",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-3@m"
      }
    },
    preGeneratedCode: {
      className: "uk-checkbox",
      placeholder: "Códigos pré-cadastrados",
      label: {
        text: "Códigos pré-cadastrados",
        className: "uk-form-label uk-margin-small-left",
        position: "below"
      },
      wrapper: {
        className: "field-checkbox uk-width-1-3@m"
      }
    },
    voucherShelfLife: {
      className: "uk-input",
      placeholder: "Validade do voucher em dias",
      value: "",
      label: {
        text: "Validade do voucher em dias",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-3@m"
      }
    },
    howToUseVoucher: {
      className: "uk-textarea",
      placeholder:
        "Baixe o voucher no App Clube NSC e apresente o QR Code ou código alfanumérico no estabelecimento para usufruir seu benefício.",
      label: {
        text: "Como usar o voucher",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-1"
      },
      value:
        "Baixe o voucher no App Clube NSC e apresente o QR Code ou código alfanumérico no estabelecimento para usufruir seu benefício."
    }
  };

  return initValues;
};

const actions = ({ token, ctxAction, ntfAction, updater, history, path, ...props }) => {
  return {
    entity: {
      name: "benefit",
      token: token,
      form: ctxAction("form")
    },
    actions: {
      entity: ctxAction,
      notification: ntfAction,
      updater: updater
    },
    find: {
      success: {
        name: "BenefitFoundResult",
        action: { notify: false, redirect: false }
      },
      error: {
        name: "BenefitErrorResult",
        message: "Houve um erro ao buscar o benefício",
        action: { notify: "benefitListPage", redirect: () => history.push(path) }
      }
    },
    create: {
      success: {
        name: "BenefitCreateResult",
        message: "Benefício adicionado com sucesso",
        action: { notify: "benefitListPage", redirect: () => history.push(path) }
      },
      error: {
        name: "BenefitErrorResult",
        message: "Houve um erro ao adicionar o benefício",
        action: { notify: "benefitForm", redirect: false }
      }
    },
    update: {
      success: {
        name: "BenefitUpdateResult",
        message: "Benefício atualizado com sucesso",
        action: { notify: "benefitListPage", redirect: () => history.push(path) }
      },
      error: {
        name: "BenefitErrorResult",
        message: "Houve um erro ao atualizar o benefício",
        action: { notify: "benefitForm", redirect: false }
      }
    }
  };
};

export { events, values, actions };
