import React, { Fragment, useEffect, useState } from "react";
import Access from "@cthulhi/comp-accs";

import NegociosGroup from "../../../intranet/pages/menu/groups/NegociosGroup.react";
import CadastroGroup from "../../../intranet/pages/menu/groups/CadastroGroup.react";
import SuporteGroup from "../../../intranet/pages/menu/groups/SuporteGroup.react";

import Context from "@context"

const IntranetHeaderPwa = ({ path, showBackButton, history }) => {
  const { state: accState, action: accAction } = Access.useState();
  const { state: ctxState, action: ctxAction } = Context.useState();

  const [ isAvatarLoading, setIsAvatarLoading ] = useState(false);
  const [avatar, setAvatar] = useState("");

  const [showAsideMenu, setShowAsideMenu] = useState(false);

  const toggleAsideMenu = () => setShowAsideMenu(!showAsideMenu);

  const logout = () => {
    accAction("access").removeSessionCookie();
    accAction("access").removeSession();
  };
  
  const uploadImage = (e) => {
    var input = document.createElement('input');
    input.type = 'file';

    input.onchange = e => { 

      if (e.target.files && e.target.files.length > 0) {
        ctxAction("loader").addLoading("updateAvatar")
        setIsAvatarLoading(true)
        
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          fetch(reader.result)
            .then(res => res.blob())
            .then(blob => {
              ctxAction("user").updateAvatar(accState.access.session.context.payload._id, blob, accState.access.session.context.token)
                .then(res => {
                  accAction("access").updatePayload({ 
                    ...accState.access.session.context.payload,
                    avatar: res
                  });
                  ctxAction("loader").removeLoading("updateAvatar")
                  setIsAvatarLoading(false)

                  input.remove()
                })
                .catch(err => { 
                  ctxAction("loader").removeLoading("updateAvatar")
                  setIsAvatarLoading(false)
                  alert("Houver um erro ao atualizar seu avatar")
                  input.remove()
                });
            })
            .catch(err => {
              ctxAction("loader").removeLoading("updateAvatar")
              setIsAvatarLoading(false)
              alert("Houver um erro ao atualizar seu avatar")

              input.remove()
            })
          });
          reader.readAsDataURL(e.target.files[0]);
      } 
    }

    input.click();
  }

  const avatarImage = () => {
    if(accState.access.session.context.payload && accState.access.session.context.payload.avatar) {
      return process.env.avatarUrl + accState.access.session.context.payload.avatar
    }
    return ""
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setAvatar(avatarImage())
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <Fragment>
      <header className="header uk-sticky uk-sticky-fixed">
        <div className="uk-container uk-container-large header-inner">
          <div className="uk-flex-middle uk-flex-between uk-grid">
            <div>
              <button className="toggle" onClick={toggleAsideMenu} type="button">
                <i className="icon icon-toggle" aria-hidden="true"></i>
              </button>
              <div
                id="aside-menu"
                className={`uk-offcanvas ${showAsideMenu ? "uk-offcanvas-overlay uk-open" : ""}`}
                style={{display: showAsideMenu ? 'block' : 'none'}}
              >
                <div className="uk-offcanvas-bar">
                  <button className="uk-offcanvas-close uk-icon uk-close" type="button" onClick={toggleAsideMenu}>
                  <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" data-svg="close-icon"><line fill="none" stroke="#000" stroke-width="1.1" x1="1" y1="1" x2="13" y2="13"></line><line fill="none" stroke="#000" stroke-width="1.1" x1="13" y1="1" x2="1" y2="13"></line></svg>
                  </button>
                  <div className="profile">
                    <div className="profile-pic">
                      <img src={avatar} alt="" />
                      <button onClick={uploadImage}>
                        {isAvatarLoading ? "Carregando..." : "Alterar Foto"}
                      </button>
                    </div>
                    <span className="profile-name">
                      Olá,{" "}
                      {
                        accState.access.session.context.payload &&
                        accState.access.session.context.payload.name
                          ? `${accState.access.session.context.payload.name}`
                          : "Parceiro" 
                      }
                    </span>
                  </div>
                  <ul className="menu">
                    <li>
                      <Access.RestrictNavLink
                        toggleAsideMenu={toggleAsideMenu}
                        onDeny={() => null}
                        to={`/parceiroapp`}
                        className="menu-link"
                      >
                        <i className="icon icon-home" aria-hidden="true"></i>
                        <span>Início</span>
                      </Access.RestrictNavLink>
                    </li>
                    <NegociosGroup path={path} toggleAsideMenu={toggleAsideMenu} />
                    <CadastroGroup path={path} toggleAsideMenu={toggleAsideMenu} />
                    <SuporteGroup path={path} pwa={true} toggleAsideMenu={toggleAsideMenu} />
                  </ul>
                </div>
              </div>
            </div>
            <div>
              <div className="uk-flex uk-flex-middle uk-grid">
                <div className="logo">
                  <Access.RestrictNavLink onDeny={() => null} to={`/parceiroapp`}>
                    <img
                      src={`${process.env.domain}assets/pwa/images/logo-color.svg`}
                      alt="NSC Parceiro App"
                    />
                  </Access.RestrictNavLink>
                </div>
              </div>
            </div>
            <div>
              <canvas
                className="canvas uk-hidden@m"
                width="28"
                height="0"
                aria-hidden="true"
              ></canvas>
              <a
                className="logout uk-flex uk-flex-middle uk-flex-last"
                onClick={logout}
              >
                <i className="icon icon-logout-gray" aria-hidden="true" />
                <span>Sair</span>
              </a>
            </div>
          </div>
        </div>
        {showBackButton ? (
          <div className="backbar">
            <div className="uk-container uk-container-large">
              <div className="uk-width-expand">
                <div>
                  <h2 className="backbartext" onClick={() => history.push('/parceiroapp/home')}>
                    <svg width="34" viewBox="2 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <polyline fill="none" stroke="#FFF" points="10 14 5 9.5 10 5" />
                      <line fill="none" stroke="#FFF" x1="16" y1="9.5" x2="5" y2="9.52" />
                    </svg>
                    &nbsp; VOLTAR
                  </h2>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </header>
    </Fragment>
  );
};

export default IntranetHeaderPwa;
