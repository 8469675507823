export default {
  ACT_SET_STATUS: "ACT_BSS_STATUS",

  STS_IDLE: "STS_BSS_IDLE",

  STS_FIND_REQUEST: "STS_BSS_FIND_REQUEST",
  STS_FIND_RESPONSE: "STS_BSS_FIND_RESPONSE",
  STS_FIND_SUCCESS: "STS_BSS_FIND_SUCCESS",
  STS_FIND_ERROR: "STS_BSS_FIND_ERROR",

  STS_CREATE_REQUEST: "STS_BSS_CREATE_REQUEST",
  STS_CREATE_RESPONSE: "STS_BSS_CREATE_RESPONSE",
  STS_CREATE_SUCCESS: "STS_BSS_CREATE_SUCCESS",
  STS_CREATE_ERROR: "STS_BSS_CREATE_ERROR",

  STS_CREATE_WU_REQUEST: "STS_BSS_CREATE_WU_REQUEST",
  STS_CREATE_WU_RESPONSE: "STS_BSS_CREATE_WU_RESPONSE",
  STS_CREATE_WU_SUCCESS: "STS_BSS_CREATE_WU_SUCCESS",
  STS_CREATE_WU_ERROR: "STS_BSS_CREATE_WU_ERROR",

  STS_CREATE_WUB_REQUEST: "STS_BSS_CREATE_WUB_REQUEST",
  STS_CREATE_WUB_RESPONSE: "STS_BSS_CREATE_WUB_RESPONSE",
  STS_CREATE_WUB_SUCCESS: "STS_BSS_CREATE_WUB_SUCCESS",
  STS_CREATE_WUB_ERROR: "STS_BSS_CREATE_WUB_ERROR",

  STS_UPDATE_REQUEST: "STS_BSS_UPDATE_REQUEST",
  STS_UPDATE_RESPONSE: "STS_BSS_UPDATE_RESPONSE",
  STS_UPDATE_SUCCESS: "STS_BSS_UPDATE_SUCCESS",
  STS_UPDATE_ERROR: "STS_BSS_UPDATE_ERROR",

  STS_DELETE_REQUEST: "STS_BSS_DELETE_REQUEST",
  STS_DELETE_RESPONSE: "STS_BSS_DELETE_RESPONSE",
  STS_DELETE_SUCCESS: "STS_BSS_DELETE_SUCCESS",
  STS_DELETE_ERROR: "STS_BSS_DELETE_ERROR",
}