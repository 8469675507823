import React, { Component, Fragment, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";

import Access from "@cthulhi/comp-accs";

import Menu from "./menu/Menu.react";

const IntranetHeader = ({ path }) => {
  const { state: accState, action: accAction } = Access.useState();

  const logout = () => {
    accAction("access").removeSessionCookie();
    accAction("access").removeSession();
  };

  return (
    <Fragment>
      <header className="header header-dash">
        <div className="uk-container">
          <div className="uk-flex-middle uk-flex-between uk-grid">
            <div className="uk-hidden@m">
              <canvas className="canvas-toggle uk-hidden@m" width="28" height="0"></canvas>
              <button className="toggle" data-uk-toggle="target: #aside-menu" type="button">
                <i className="icon icon-toggle" aria-hidden="true" />
              </button>
              <div id="aside-menu" uk-offcanvas="overlay: true;">
                <div className="uk-offcanvas-bar uk-padding-remove">
                  <button className="uk-offcanvas-close" type="button" data-uk-close></button>
                  <Menu path={path} options={{ 'data-uk-toggle': "target: #aside-menu" }} />
                </div>
              </div>
            </div>
            <div>
              <div className="uk-flex uk-flex-middle uk-grid">
                <div className="logo">
                  <img
                    src={`${process.env.domain}assets/intranet/images/logo-nsc.svg`}
                    alt="NSC Backoffice App"
                  />
                </div>
                <div className="label uk-visible@m">
                  <h1>{
                    accState.access.session.context.payload &&
                    accState.access.session.context.payload.business &&
                    accState.access.session.context.payload.business.length > 0 ? 
                      `Olá ${accState.access.session.context.payload.business[0].name}` : 
                        "Backoffice App" 
                  }</h1>
                </div>
              </div>
            </div>
            <div>
              <canvas className="canvas uk-hidden@m" width="28" height="0"></canvas>
              <a className="logout uk-flex uk-flex-middle uk-flex-last" onClick={logout}>
                <i className="icon icon-logout" aria-hidden="true" />
                <span>Sair</span>
              </a>
            </div>
          </div>
        </div>
      </header>
    </Fragment>
  );
};

export default IntranetHeader;
