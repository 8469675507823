const view = {
  group: {
    title: "Vouchers",
    slug: "voucher"
  },
  slug: "voucher-view",
  title: "Ver vouchers",
  description: "Ver os próprios vouchers",
  list: [
    "prn::1:voucher-view-roles:voucher-view:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

const viewAny = {
  group: {
    title: "Vouchers",
    slug: "voucher"
  },
  slug: "voucher-view-any",
  title: "Ver qualquer voucher",
  description: "Ver todos os vouchers",
  list: [
    "prn::1:voucher-view-any-roles:voucher-view-any:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

const edit = {
  group: {
    title: "Vouchers",
    slug: "voucher"
  },
  slug: "voucher-edit",
  title: "Editar vouchers",
  description: "Editar os próprios vouchers",
  list: [
    "prn::1:voucher-edit-roles:voucher-edit:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

const editAny = {
  group: {
    title: "Vouchers",
    slug: "voucher"
  },
  slug: "voucher-edit-any",
  title: "Editar qualquer voucher",
  description: "Editar todos os vouchers",
  list: [
    "prn::1:voucher-edit-any-roles:voucher-edit-any:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

const create = {
  group: {
    title: "Vouchers",
    slug: "voucher"
  },
  slug: "voucher-create",
  title: "Criar vouchers",
  description: "Criar os próprios vouchers"
}

const createAny = {
  group: {
    title: "Vouchers",
    slug: "voucher"
  },
  slug: "voucher-create-any",
  title: "Criar qualquer voucher",
  description: "Criar todos os vouchers"
}

const remove = {
  group: {
    title: "Vouchers",
    slug: "voucher"
  },
  slug: "voucher-remove",
  title: "Remover vouchers",
  description: "Remover os próprios vouchers"
}

const removeAny = {
  group: {
    title: "Vouchers",
    slug: "voucher"
  },
  slug: "voucher-remove-any",
  title: "Remover qualquer voucher",
  description: "Remover todos os vouchers"
}

const validate = {
  group: {
    title: "Voucher",
    slug: "voucher"
  },
  slug: "voucher-validate",
  title: "Validar qualquer voucher",
  description: "Validar todos os vouchers",
  list: [
    "prn::1:voucher-validate-roles:voucher-validate:allow:lcnt:schema.user.roles:custom.static.manager,pos"
  ]
}

module.exports = {
  view,
  viewAny,
  edit,
  editAny,
  create,
  createAny,
  remove,
  removeAny,
  validate
}