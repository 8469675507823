import React, { Fragment, useEffect, useState } from "react";
import { Link, Switch, Route, withRouter, Redirect } from "react-router-dom";

import Access from "@cthulhi/comp-accs";

import UserTypeFormPage from "./UserTypeFormPage.react";
import UserTypeListPage from "./UserTypeListPage.react";

import permissions from "@perm";

const UserTypePage = ({ match, ...props }) => {
  return (
    <Switch>
      <Access.RestrictRoute
        key="userType-list"
        onAccess={[permissions.userType.viewAny, permissions.userType.editAny]}
        parentPath={match.path}
        path={`${match.path}`}
        exact
        onAllow={UserTypeListPage}
      />
      <Access.RestrictRoute
        key="userType-create"
        onAccess={[permissions.userType.viewAny, permissions.userType.editAny]}
        parentPath={match.path}
        path={`${match.path}/create`}
        onAllow={UserTypeFormPage}
      />
      <Access.RestrictRoute
        key="userType-edit"
        onAccess={[permissions.userType.editAny, permissions.userType.edit]}
        parentPath={match.path}
        path={`${match.path}/edit/:id`}
        onAllow={UserTypeFormPage}
      />
    </Switch>
  );
};

export default withRouter(UserTypePage);
