import React, { Component, Fragment, useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";

import Context from "../../../context";
import Access from "@cthulhi/comp-accs";
import Notification from "@cthulhi/comp-noty";

import { schema as userRecoverUpdatePassword } from "@schm/access/recoverUpdatePassword.schema";

import NotificationComponent from "./../../utils/Notification.react";
import BackButton from "./BackButton.react";

const RecoverUpdate = ({ match, history }) => {
  const { state: ctxState, action: ctxAction, dispatch: ctxDispatch } = Context.useState();
  const { state: ntfState, action: ntfAction } = Notification.useState();
  const { state: accState, action: accAction, consts: accConts } = Access.useState();

  const isLoading =
    accState.access.recover.updatePassword.status == accConts.STS_REC_PWD_UPDATE_REQUEST ||
    accState.access.recover.updatePassword.status == accConts.STS_REC_PWD_UPDATE_RESPONSE;
  const isSuccessfull =
    accState.access.recover.updatePassword.status == accConts.STS_REC_PWD_UPDATE_SUCCESS;

  const [userLoginFields, setUserLoginFields] = useState({ password: "", check: "" });

  const onChangeField = (name, value) => {
    setUserLoginFields({
      ...userLoginFields,
      [name]: value
    });
  };

  const onSubmit = async e => {
    e.preventDefault();

    ntfAction("notification").removeGroup("update");

    const form = userLoginFields;
    const valid = await userRecoverUpdatePassword.validate({
      ...form,
      code: match.params.code,
      _id: match.params._id
    });

    if (valid.result && form.password == form.check) {
      accAction("access")
        .updateRecoverPassword({
          _id: match.params._id,
          code: match.params.code,
          password: form.password
        })
        .then(res => {
          switch (res.name) {
            case "UpdatePasswordSuccessResult":
              ntfAction("notification").add(
                res.code,
                "update",
                "success",
                "Sua senha foi alterada com sucesso!"
              );
              ntfAction("notification").add(
                res.code,
                "update",
                "info",
                "Acesse a página de login para logar com sua nova senha. "
              );
              break;

            case "RecoverVerifyInvalidError":
              ntfAction("notification").add(
                res.code,
                "update",
                "danger",
                <span>
                  O código é invalido ou expirou. Solicite um{" "}
                  <a href="/access/recover">novo código</a>
                </span>
              );
              break;

            default:
              ntfAction("notification").add(
                res.code,
                "update",
                "danger",
                "Houve um erro ao atualizar sua senha."
              );
              break;
          }
        });
    } else {
      if (valid.errors) ctxAction("login").updateRecoverUpdateFormValidations(valid.errors);
      ntfAction("notification").add("EO52", "update", "danger", "As senhas não conferem.");
    }
  };

  const setValidationClass = (validations, field) => {
    if (typeof validations != "undefined") {
      if (typeof validations[field] != "undefined") {
        return validations[field] ? " uk-form-success" : " uk-form-danger";
      }
    }

    return "";
  };

  useEffect(() => {
    ctxDispatch({
      type: "IS_HOME",
      data: false
    });
  }, []);

  const isSessionSuccessfull = accState.access.session.status == accConts.STS_SESSION_SUCCESS;

  useEffect(() => {
    accAction("access").verifySession();
  }, [isSessionSuccessfull]);

  if (isSessionSuccessfull) {
    return <Redirect to="/parceiro" />;
  }

  return (
    <Fragment>
      <div className="uk-width-2-5@m">
        <div className="auth-form">
          <div className="uk-margin-medium uk-text-center">
            <span className="label">Atualizar senha</span>
          </div>
          <div className="uk-margin">
            <Notification.NotificationWrapper group="update" component={NotificationComponent} />
          </div>
          {isSuccessfull ? (
            <div className="uk-margin-medium-top">
              <button
                className="uk-button uk-button-primary uk-button-large uk-width-1-1"
                onClick={() => history.push("/access")}
              >
                Acessar
              </button>
            </div>
          ) : (
            <form className="form" action="">
              <div className="uk-margin">
                <input
                  autocomplete="new-password"
                  className={
                    "uk-input uk-width-1-1" +
                    setValidationClass(ctxState.login.recoverUpdate.form.validations, "password")
                  }
                  id="password"
                  name="password"
                  placeholder="Digite a nova senha"
                  required
                  onChange={e => onChangeField("password", e.target.value)}
                  value={userLoginFields.password}
                  type="password"
                />
              </div>
              <div className="uk-margin">
                <input
                  autocomplete="new-password"
                  className={
                    "uk-input uk-width-1-1" +
                    setValidationClass(ctxState.login.recoverUpdate.form.validations, "check")
                  }
                  id="password-check"
                  name="check"
                  placeholder="Repita a senha"
                  onChange={e => onChangeField("check", e.target.value)}
                  value={userLoginFields.check}
                  required
                  type="password"
                />
              </div>
              <div className="uk-margin-medium-top">
                <button
                  className="uk-button uk-button-primary uk-button-large uk-width-1-1"
                  onClick={e => onSubmit(e)}
                >
                  {isLoading ? <span>Atualizando...</span> : <span>Atualizar</span>}
                </button>
              </div>
              <BackButton />
            </form>
          )}
        </div>
      </div>
    </Fragment>
  );
};
export default RecoverUpdate;
