import React, { Fragment } from "react";
import { schema as schm, types } from "@cthulhi/pack-schm";
import { types as fields } from "@cthulhi/comp-form";

const optionLoading = { value: "", label: "Carregando...", disabled: true };
const optionSelectCity = { value: "", label: "Selecione uma Cidade..." };
const optionSelectState = { value: "", label: "Selecione um Estado..." };
const optionSelectStateToCity = { value: "", label: "Aguardando seleção do Estado..." };

const currency = (amount, decimalCount = 2, decimal = ".", thousands = ",") => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    return amount;
  }
};

const schema = schm("filter", {
  title: {
    type: types.string.type,
    field: fields.textfield.type
  },
  type: {
    type: types.string.type,
    field: fields.select.type
  },
  discount: {
    type: types.string.type,
    field: fields.textfield.type
  },
  status: {
    type: types.boolean.type,
    field: fields.select.type
  },
  shelfLifeStart: {
    type: types.datetime.type,
    field: fields.datetime.type
  },
  shelfLifeEnd: {
    type: types.datetime.type,
    field: fields.datetime.type
  }
});

const events = ({ ctxAction, ...props }) => {
  return {};
};

const values = ({ ctxAction, ...props }) => {
  return {
    title: {
      filter: "text",
      className: "uk-input",
      placeholder: "Título",
      label: {
        text: "Nome",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      }
    },
    type: {
      className: "uk-select",
      value: "",
      defaultValue: "",
      label: {
        text: "Tipo",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      },
      options: update => {
        update("type", {
          options: [
            { value: "", label: "Selecione um tipo..." },
            { value: "perennial", label: "Perene" },
            { value: "event", label: "Evento" }
          ]
        });
      }
    },
    discount: {
      filter: "text",
      className: "uk-input",
      placeholder: "Desconto",
      label: {
        text: "Nome",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper"
      }
    },
    status: {
      className: "uk-select",
      label: {
        text: "Status",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper"
      },
      options: update => {
        update("status", {
          options: [
            { value: "", label: "Selecione um status..." },
            { value: "true", label: "Habilitado" },
            { value: "false", label: "Desabilitado" }
          ]
        });
      }
    },
    shelfLifeStart: {
      filter: "datetime",
      className: "uk-input",
      label: {
        text: "Ativos no período de...",
        className: "uk-form-label show"
      },
      wrapper: {
        className: "field-wrapper"
      },
      dateFormat: "L"
    },
    shelfLifeEnd: {
      filter: "datetime",
      className: "uk-input",
      label: {
        text: "até...",
        className: "uk-form-label show"
      },
      wrapper: {
        className: "field-wrapper"
      },
      dateFormat: "L"
    }
  };
};

const options = ({ onEdit, onRemove, onCopyAppLink, onCopyWebsiteLink, onPreGenerateCode, ...props }) => {
  return {
    filter: {
      fields: true,
      submit: true
    },
    pagination: {
      next: {
        value: "Próximo",
        className: "page-next"
      },
      prev: {
        value: "Anterior",
        className: "page-prev"
      },
      numbers: {
        className: "page-numbers"
      },
      total: true
    },
    limit: {
      value: 5,
      limits: [
        { value: 5, label: "5 Items" },
        { value: 10, label: "10 Items" },
        { value: 20, label: "20 Items" }
      ]
    },
    items: {
      title: {
        header: {
          value: "Título",
          className: ""
        },
        column: {
          className: "",
          value: "title"
        }
      },
      type: {
        header: "Tipo",
        column: "type",
        parse: value => {
          if (value == "perennial") return "Perene";
          return "Evento";
        }
      },
      status: {
        header: "Status",
        column: "status",
        parse: value => {
          if (value) return "Habilitado";
          return "Desabilitado";
        }
      },
      discount: {
        header: "Desconto",
        column: "discount",
        parse: (value, item) => {
          return `${item.discountType != "percent" ? "R$ " : ""}${currency(value)}${item.discountType == "percent" ? " %" : ""}`
        }
      },
      actions: {
        header: "Ações",
        column: (item, refresh, loader) => {
          return {
            value: (
              <Fragment>
                <div className="uk-grid-small uk-child-width-auto" data-uk-grid>
                  <div className="uk-text-nowrap">
                    <button
                      className="uk-icon-button uk-button-primary"
                      onClick={() => onEdit(item, refresh, loader)}
                    >
                      <i className="icon icon-edit" aria-hidden="true"></i>
                    </button>
                    <button
                      className="uk-icon-button uk-button-danger uk-margin-small-left"
                      onClick={() => onRemove(item, refresh, loader)}
                    >
                      <i className="icon icon-delete" aria-hidden="true"></i>
                    </button>
                    <button
                      className="uk-icon-button uk-button-secondary uk-margin-small-left"
                      onClick={() => onCopyAppLink(item)}
                    >
                      app
                    </button>
                    <button
                      className="uk-icon-button uk-button-secondary uk-margin-small-left"
                      onClick={() => onCopyWebsiteLink(item)}
                    >
                      web
                    </button>
                    {
                      item.preGeneratedCode && (
                        <button
                          className="uk-icon-button uk-button-secondary uk-margin-small-left"
                          onClick={() => onPreGenerateCode(item)}
                        >
                          csv
                        </button>
                      )
                    }
                  </div>
                </div>
              </Fragment>
            )
          };
        }
      }
    }
  };
};

const actions = ({ ctxAction, token, ...props }) => {
  return {
    find: async (filters, options) => {
      if (filters.filters && filters.filters.shelfLifeStart) {
        filters.filters.shelfLifeStart = {
          $gt: new Date(filters.filters.shelfLifeStart)
        };
      }

      if (filters.filters && filters.filters.shelfLifeEnd) {
        filters.filters.shelfLifeEnd = {
          $lt: new Date(filters.filters.shelfLifeEnd)
        };
      }

      return ctxAction("benefit").find({ ...filters }, options, token);
    },
    downloadCSV: async (filters, options) => {
      if (filters.filters && filters.filters.shelfLifeStart) {
        filters.filters.shelfLifeStart = {
          $gt: new Date(filters.filters.shelfLifeStart)
        };
      }

      if (filters.filters && filters.filters.shelfLifeEnd) {
        filters.filters.shelfLifeEnd = {
          $lt: new Date(filters.filters.shelfLifeEnd)
        };
      }

      return ctxAction("benefit").downloadCSV(filters, options, token);
    }
  };
};

export { events, values, actions, options, schema };
