const view = {
  group: {
    title: "Users Vips",
    slug: "userVip"
  },
  slug: "userVip-view",
  title: "Ver usuários vips",
  description: "Ver os próprios usuários vips",
  list: ["prn::1:userVip-view-roles:userVip-view:allow:lcnt:schema.user.roles:custom.static.admin"]
};

const viewAny = {
  group: {
    title: "Users Vips",
    slug: "userVip"
  },
  slug: "userVip-view-any",
  title: "Ver qualquer usuário vip",
  description: "Ver todos os usuários vips",
  list: [
    "prn::1:userVip-view-any-roles:userVip-view-any:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
};

const edit = {
  group: {
    title: "Users Vips",
    slug: "userVip"
  },
  slug: "userVip-edit",
  title: "Editar usuários vips",
  description: "Editar os próprios usuários vips",
  list: ["prn::1:userVip-edit-roles:userVip-edit:allow:lcnt:schema.user.roles:custom.static.admin"]
};

const editAny = {
  group: {
    title: "Users Vips",
    slug: "userVip"
  },
  slug: "userVip-edit-any",
  title: "Editar qualquer usuário vip",
  description: "Editar todos os usuários vips",
  list: [
    "prn::1:userVip-edit-any-roles:userVip-edit-any:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
};

const remove = {
  group: {
    title: "Users Vips",
    slug: "userVip"
  },
  slug: "userVip-remove",
  title: "Remover usuários vips",
  description: "Remover os próprios usuários vips"
};

const removeAny = {
  group: {
    title: "Users Vips",
    slug: "userVip"
  },
  slug: "userVip-remove-any",
  title: "Remover qualquer usuário vip",
  description: "Remover todos os usuários vips"
};

const create = {
  group: {
    title: "Users Vips",
    slug: "userVip"
  },
  slug: "userVip-create",
  title: "Criar usuários vips",
  description: "Criar os próprios usuários vips"
};

const createAny = {
  group: {
    title: "Users Vips",
    slug: "userVip"
  },
  slug: "userVip-create-any",
  title: "Criar qualquer usuário vip",
  description: "Criar todos os usuários vips"
};

module.exports = {
  view,
  viewAny,
  edit,
  editAny,
  remove,
  removeAny,
  create,
  createAny
};
