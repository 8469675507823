import React, { Component, Fragment, useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";

import NotificationComponent from "@html/views/utils/Notification.react";

import Context from "@html-context";
import Access from "@cthulhi/comp-accs";
import Notification from "@cthulhi/comp-noty";

import { schema as userLoginSchema } from "@schm/access/login.schema";

const LoginForm = ({ match, history }) => {
  const { state: ctxState, action: ctxAction, dispatch: ctxDispatch } = Context.useState();
  const { state: ntfState, action: ntfAction } = Notification.useState();
  const { state: accState, action: accAction, consts: accConts } = Access.useState();

  const [userLoginFields, setUserLoginFields] = useState({ email: "", password: "" });

  const isLoading =
    accState.access.session.status == accConts.STS_SESSION_REQUEST ||
    accState.access.session.status == accConts.STS_SESSION_RESPONSE;
  const isSuccessfull = accState.access.session.status == accConts.STS_SESSION_SUCCESS;

  const onChangeField = (name, value) => {
    setUserLoginFields({
      ...userLoginFields,
      [name]: value
    });
  };

  const onSubmit = async e => {
    e.preventDefault();

    ntfAction("notification").removeGroup("login");

    const form = userLoginFields;
    const valid = await userLoginSchema.validate(form);

    if (valid.result) {
      accAction("access")
        .getSession({
          email: form.email,
          password: form.password,
          publicKey: process.env.public_key
        })
        .then(res => {
          if (res.code != "AL20") {
            ntfAction("notification").add(
              res.code,
              "login",
              "danger",
              "Email ou senha incorretos."
            );
          } else {
            history.push(`/parceiroapp`);
          }
        });
    } else {
      ctxAction("login").updateLoginFormValidations(valid.errors);
      ntfAction("notification").add("EO50", "login", "danger", "Email ou senha incorretos.");
    }
  };

  const setValidationClass = (validations, field) => {
    if (typeof validations != "undefined") {
      if (typeof validations[field] != "undefined") {
        return validations[field] ? " uk-form-success" : " uk-form-danger";
      }
    }

    return "";
  };

  useEffect(() => {
    ctxDispatch({
      type: "IS_HOME",
      data: true
    });
  }, []);

  useEffect(() => {
    accAction("access").verifySession();
  }, [isSuccessfull]);

  if (isSuccessfull) {
    return <Redirect to="/parceiroapp/home" />;
  }

  return (
    <Fragment>
      <div className="uk-flex uk-flex-column uk-padding uk-padding-remove-bottom">
        <img
          src={`${process.env.domain}assets/pwa/images/logo-color.svg`}
          alt="NSC"
          className="auth-logo"
          aria-hidden="true"
        />
        <h1 className="auth-title">Parceiro App</h1>
      </div>
      <div className="uk-flex uk-flex-middle uk-padding uk-padding-remove-top uk-padding-remove-bottom">
        <div className="uk-width-1-1">
          <Notification.NotificationWrapper group="login" component={NotificationComponent} />

          <form className="form" id="" action="">
            <div>
              <input
                type="email"
                name="email"
                id="email"
                className={
                  "uk-input uk-width-1-1" +
                  setValidationClass(ctxState.login.login.form.validations, "email")
                }
                onChange={e => onChangeField("email", e.target.value)}
                value={userLoginFields.email}
                placeholder="E-mail"
              />
            </div>
            <div>
              <input
                type="password"
                name="password"
                id="password"
                className={
                  "uk-input uk-width-1-1" +
                  setValidationClass(ctxState.login.login.form.validations, "password")
                }
                onChange={e => onChangeField("password", e.target.value)}
                value={userLoginFields.password}
                placeholder="Senha"
                required
              />
            </div>
            <div className="uk-margin-small uk-text-right">
              <Link className="uk-text-small" to="/parceiroapp/access/recover">
                Recuperar senha
              </Link>
            </div>
            <div className="uk-margin-top">
              <button
                type="submit"
                className="uk-button uk-button-primary uk-button-large uk-width-1-1"
                onClick={e => onSubmit(e)}
              >
                {isLoading ? <span>Verificando...</span> : <span>Entrar</span>}
              </button>
            </div>
          </form>
          <div className="uk-margin-top uk-text-center">
            <a href="/duvidas">Ajuda?</a>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default LoginForm;
