import React from "react"
import ReactQrCode from "react-qr-code"

const QrCode = ({ value }) => {

  return (
    <ReactQrCode value={value} />
  )
}

export default QrCode