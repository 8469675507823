import React, { Fragment, useEffect, useState } from 'react'
import { Link, Switch, Route, withRouter, Redirect } from "react-router-dom"

import Access from "@cthulhi/comp-accs"

import DiscountFormPage from "./DiscountFormPage.react";
import DiscountListPage from "./DiscountListPage.react";

import permissions from "@perm"

const DiscountPage = ({ match, ...props }) => {
    return (
    <Switch>
        {/* EVENTOS */}
        <Access.RestrictRoute
            key="discount-list-event"
            onAccess={[
                permissions.discount.viewAny,
                permissions.discount.editAny
            ]}
            parentPath={match.path}
            path={`${match.path}`} exact
            onAllow={DiscountListPage}
        />
        <Access.RestrictRoute
            key="discount-create-event"
            onAccess={[
                permissions.discount.create,
                permissions.discount.createAny
            ]}
            parentPath={match.path}
            path={`${match.path}/create`}
            onAllow={DiscountFormPage}
        />
        <Access.RestrictRoute
            key="discount-edit-event"
            onAccess={[
                permissions.discount.editAny,
                permissions.discount.edit
            ]}
            parentPath={match.path}
            path={`${match.path}/edit/:id`}
            onAllow={DiscountFormPage}
        />
    </Switch>
  )

}

export default withRouter(DiscountPage)