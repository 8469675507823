const view = {
  group: {
    title: "Assinantes",
    slug: "subscription"
  },
  slug: "subscription-view",
  title: "Ver assinantes",
  description: "Ver os próprios assinantes",
  list: [
    "prn::1:subscription-view-roles:subscription-view:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

const viewAny = {
  group: {
    title: "Assinantes",
    slug: "subscription"
  },
  slug: "subscription-view-any",
  title: "Ver qualquer assinante",
  description: "Ver todos os assinantes",
  list: [
    "prn::1:subscription-view-any-roles:subscription-view-any:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

const edit = {
  group: {
    title: "Assinantes",
    slug: "subscription"
  },
  slug: "subscription-edit",
  title: "Editar assinantes",
  description: "Editar os próprios assinantes",
  list: [
    "prn::1:subscription-edit-roles:subscription-edit:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

const editAny = {
  group: {
    title: "Assinantes",
    slug: "subscription"
  },
  slug: "subscription-edit-any",
  title: "Editar qualquer assinante",
  description: "Editar todos os assinantes",
  list: [
    "prn::1:subscription-edit-any-roles:subscription-edit-any:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

const create = {
  group: {
    title: "Assinantes",
    slug: "subscription"
  },
  slug: "subscription-create",
  title: "Criar assinantes",
  description: "Criar os próprios assinantes"
}

const createAny = {
  group: {
    title: "Assinantes",
    slug: "subscription"
  },
  slug: "subscription-create-any",
  title: "Criar qualquer assinante",
  description: "Criar todos os assinantes"
}

const remove = {
  group: {
    title: "Assinantes",
    slug: "subscription"
  },
  slug: "subscription-remove",
  title: "Remover assinantes",
  description: "Remover os próprios assinantes"
}

const removeAny = {
  group: {
    title: "Assinantes",
    slug: "subscription"
  },
  slug: "subscription-remove-any",
  title: "Remover qualquer assinante",
  description: "Remover todos os assinantes"
}

module.exports = {
  view,
  viewAny,
  edit,
  editAny,
  create,
  createAny,
  remove,
  removeAny
}