import React, { useState, useEffect, useCallback, Fragment } from "react"

import { useFilter } from "./filter"
import { FieldLabelWrapper } from "./../comp-form"

const Step = ({ pages, show, value, onClick, ...props }) => {

  return(
    <button { ...props } disabled={!pages || !show} onClick={onClick} >{value}</button>
  )
}

const Numbers = ({ curr, count, onClick }) => {
  const numbers = []
  
  var start = Math.max(0, curr - 5)
  var end = Math.min(start + 5, count - 1)

  for (var i = start; i <= end; i++) {
    numbers.push(i)
  }

  return (
    <Fragment>
      {numbers.map(number => (
        <button key={"number-" + number} onClick={() => onClick(number)} disabled={curr == number}>{number}</button>
      ))}
    </Fragment>
  )
}

const Pagination = ({ page, options, ...props }) => {

  return (
    <Fragment>
      {options.pagination.prev ? (
        <Step 
          pages={page.pages} 
          show={page.canPrev} 
          value={options.pagination.prev.value}
          onClick={page.prev}
          { ...options.pagination.prev } />
      ) : null}
      {options.pagination.numbers ? (
        <Numbers count={page.pages} curr={page.curr} onClick={page.page} />
      ) : null}
      {options.pagination.next ? (
        <Step 
        pages={page.pages} 
        show={page.canNext} 
        value={options.pagination.next.value}
        onClick={page.next}
        { ...options.pagination.next } />
      ) : null}
    </Fragment>
  )
}

const Limit = ({ options, ...props }) => {

  return (
    <select {...props}>
      {options.limit.limits.map(({ value, label, ...opts }) => (
        <option { ...opts } value={value}>{label}</option>
      ))}
    </select>
  )
}

const Header = ({ columns, refresh, options }) => {

  const [ values, setValues ] = useState([])

  useEffect(() => {

    const values = []

    for (let index = 0; index < columns.length; index++) {
      const key = columns[index];

      const type = typeof options.items[key].header

      switch (type) {
        case "string":
          values[key] = {
            value: options.items[key].header
          }
          break;

        case "function":
          values[key] = options.items[key].header(item, refresh)
          break;

        default:
          values[key] = {
            ...options.items[key].header
          }
          break;
      }

    }

    setValues(values)

  }, [JSON.stringify(columns)])

  return (
    <Fragment>
      {Object.entries(values).map(([key, { value, ...props }]) => (
        <div key={`header-${key}`} { ...props }>{value}</div>
      ))}
    </Fragment>
  )

}

const Row = ({ item, columns, refresh, options }) => {

  const [ values, setValues ] = useState([])

  useEffect(() => {

    const values = []

    for (let index = 0; index < columns.length; index++) {
      const key = columns[index];
      const type = typeof options.items[key].column

      switch (type) {
        case "string":
          values[key] = {
            value: item[options.items[key].column]
          }
          break;

        case "function":
          values[key] = options.items[key].column(item, refresh)
          break;

        default:
          values[key] = {
            ...options.items[key].column,
            value: item[options.items[key].column.value]
          }
          break;
      }

    }

    setValues(values)

  }, [JSON.stringify(item)])

  return (
    <Fragment>
      {Object.values(values).map(({ value, ...props }) => (
        <div { ...props }>{value}</div>
      ))}
    </Fragment>
  )
}

const List = ({ filter, list, page, limit, options, ...props }) => {
  
  const [ items, setItems ] = useState([])

  useEffect(() => {
    setItems(Object.keys(options.items))
  }, [options.items])

  return (
    <Fragment>
      <div className="list">
        {options && options.filter && options.filter.fields ? (
          Object.keys(filter.fields).map(name => (
            <FieldLabelWrapper name={name} fields={filter.fields} values={filter.values} />
          ))
        ) : null}
        {options && options.items ? (
          <Header columns={items} options={options} refresh={page.refresh} />
        ) : null }
        {list.items.map(item => (
          <Row item={item} columns={items} options={options} refresh={page.refresh} />
        ))}
        {options && options.limit && options.limit.limits ? (
          <Limit options={options} onChange={(e) => limit.updater(Number(e.target.value))} />
        ) : null }
        {options && options.pagination ? (
          <Pagination options={options} page={page} />
        ) : null }
      </div>
    </Fragment>
  )
}

export {
  List,
  useFilter
}