import permissions from "@perm"

const events = ({ ctxAction, ...props }) => {
  return {};
};

const values = ({ ctxAction, token, ...props }) => {
  let initValues = {
    name: {
      className: "uk-input",
      placeholder: "Nome",
      value: "",
      label: {
        text: "Nome",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      }
    },
    password: {
      className: "uk-input",
      placeholder: "Senha",
      value: "",
      label: {
        text: "Senha",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      }
    },
    email: {
      className: "uk-input",
      placeholder: "E-mail",
      value: "",
      label: {
        text: "E-mail",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-1"
      }
    },
    roles: {
      className: "uk-select",
      placeholder: "Perfil",
      label: {
        text: "Perfil",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      },
      value: "manager",
      options: [
        { label: "Parceiro", value: "manager" },
        { label: "Ponto de venda", value: "pos" },
      ]
    },
    status: {
      className: "uk-checkbox",
      placeholder: "Ativo",
      value: true,
      label: {
        text: "Ativo",
        className: "uk-form-label uk-margin-small-left",
        position: "below"
      },
      wrapper: {
        className: "field-checkbox uk-width-1-3@m"
      }
    }
  };

  return initValues;
};

const actions = ({ token, ctxAction, ntfAction, updater, history, path, ...props }) => {
  return {
    entity: {
      name: "user",
      token: token,
      form: ctxAction("form")
    },
    actions: {
      entity: ctxAction,
      notification: ntfAction,
      updater: updater
    },
    find: {
      success: {
        name: "UserFoundResult",
        action: { notify: false, redirect: false }
      },
      error: {
        name: "UserErrorResult",
        message: "Houve um erro ao buscar o usuário",
        action: { notify: "userListPage", redirect: () => history.push(path) }
      }
    },
    create: {
      success: {
        name: "UserCreateResult",
        message: "Usuáro adicionado com sucesso",
        action: { notify: "userListPage", redirect: () => history.push(path) }
      },
      error: {
        name: "UserErrorResult",
        message: "Houve um erro ao adicionar o usuário",
        action: { notify: "userForm", redirect: false }
      }
    },
    update: {
      success: {
        name: "UserUpdateResult",
        message: "Usuário atualizado com sucesso",
        action: { notify: "userListPage", redirect: () => history.push(path) }
      },
      error: {
        name: "UserErrorResult",
        message: "Houve um erro ao atualizar o usuário",
        action: { notify: "userForm", redirect: false }
      }
    }
  };
};

export { events, values, actions };
