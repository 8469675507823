import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";

const SplashViewPwa = ({ match, history }) => {
  const Cookie = require("universal-cookie").default;
  const splashCookie = new Cookie();

  useEffect(() => {
    if (splashCookie.get("splashFirstLoginCookie")) {
      return history.push(`${match.path}/access`);
    }
  }, []);

  const setFirstAccess = () => {
    splashCookie.set("splashFirstLoginCookie", true, {
      expires: new Date(Date.now() + 2592000),
      secure: true,
      path: "/"
    });
  }

  return (
    <div className="splash">
      <div className="uk-flex uk-flex-middle uk-flex-center uk-padding-small uk-height-1-1">
        <div>
          <img
            src={`${process.env.domain}assets/pwa/images/logo-nsc.svg`}
            alt="NSC"
            className="splash-logo"
            aria-hidden="true"
          />
          <h1 className="splash-title">Parceiro APP</h1>
          <div className="splash-container">
            <div className="splash-container-item">
              <img
                src={`${process.env.domain}assets/pwa/images/icon_cadastre.svg`}
                className="splash-icon"
                aria-hidden="true"
              />
              <h2 className="splash-subtitle">
                Cadastre seu negócio e defina um valor de desconto
              </h2>
            </div>
            <div className="splash-container-item-second">
              <img
                src={`${process.env.domain}assets/pwa/images/icon_voucher.svg`}
                className="splash-icon"
                aria-hidden="true"
              />
              <h2 className="splash-subtitle-second">
                Valide Vouchres criados pelos Assinantes do Clube NSC
              </h2>
            </div>
            <div className="splash-container-item-third">
              <img
                src={`${process.env.domain}assets/pwa/images/icon_defina.svg`}
                className="splash-icon"
                aria-hidden="true"
              />
              <h2 className="splash-subtitle">
                Defina uma estratégia de divulgação pelo Perfil do Consumidor
              </h2>
            </div>
          </div>
          <div className="uk-flex uk-float-right">
            <Link onClick={setFirstAccess} to={`${match.path}/access`} className="splash-link">
              Avançar
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SplashViewPwa;
