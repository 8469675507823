import lc from '../constants/loader.constant'

const state = {
  list: {
    window: [ "app" ] 
  } 
};

const reducer = (state, action) => {
  switch (action.type) {

    case lc.ACT_ADD_LOADING:
      
      const saal = { ...state }

      if(typeof saal.list[action.data.place] == "undefined") saal.list[action.data.place] = []

      saal.list[action.data.place].push(action.data.event)

      return saal

    case lc.ACT_DEL_LOADING:
      
      const sadl = { ...state }

      sadl.list[action.data.place] = sadl.list[action.data.place].filter((value, index) => value != action.data.event)

      return sadl
      
    default:
        return state;
  }
};

export default { state, reducer }