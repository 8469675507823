import React from "react"
import PropTypes from "prop-types";
import styled, { css, keyframes } from "styled-components";

const fadeOut = keyframes`
  0% {
    opacity: 1;
    visibility: visible;
    z-index: 9999;
  }

  100% {
    opacity: 0;
    visibility: hidden;
    z-index: -1;
  }
`;

const animationFadeOut = css`
  opacity: 0;
  z-index: -1;
  animation: ${fadeOut} 0.5s linear;
`;

const OverlayBackground = ({ backgroundColor, backgroundOpacity, className, onClick, ...props }) => {
  return (
    <div className={className} onClick={onClick} ></div>
  )
}

OverlayBackground.propTypes = {
  backgroundColor: PropTypes.string,
  backgroundOpacity: PropTypes.string
}

OverlayBackground.defaultTypes = {
  backgroundColor: "#000000",
  backgroundOpacity: "0.9"
}

const OverlayBackgroundStyled = styled(OverlayBackground)`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: ${props => props.backgroundColor}
  opacity: ${props => props.backgroundOpacity}
  z-index: -1
`

const OverlayContainer = ({ className, children, ...props }) => {
  return (
    <div className={className} >
      {children}
      <OverlayBackgroundStyled { ...props } />
    </div>
  )
}

const OverlayContainerStyled = styled(OverlayContainer)`
  display: flex;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow-x: auto;
  overflow-y: scroll;
  align-items: center;
  justify-content: center;
  z-index: 999;
  ${props => (props.isFadeOut ? animationFadeOut : null)}
`

const Overlay = ({ children, ...props }) => {
  return(
    <OverlayContainerStyled { ...props } >
      {children}
    </OverlayContainerStyled>
  )
}

export default Overlay