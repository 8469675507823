import React, { Fragment } from 'react'

const PageNotFound = ({ ...props }) => {
  return (
    <Fragment>
      <h1>404</h1>
      <p>Página não encontrada</p>
    </Fragment>
  )
}

export default PageNotFound