import React, { Fragment, useEffect, useState } from "react";
import { Link, Switch, Route, withRouter, Redirect } from "react-router-dom";

import Access from "@cthulhi/comp-accs";

import PartnerFormPage from "./PartnerFormPage.react";
import PartnerListPage from "./PartnerListPage.react";

import permissions from "@perm";

const PartnerPage = ({ match, ...props }) => {
  return (
    <Switch>
      <Access.RestrictRoute
        key="partner-list"        
        onAccess={[permissions.partner.viewAny, permissions.partner.editAny]}
        parentPath={match.path}
        path={`${match.path}`}
        exact
        onAllow={PartnerListPage}
      />
      <Access.RestrictRoute
        key="partner-create"
        onAccess={[permissions.partner.viewAny, permissions.partner.editAny]}
        parentPath={match.path}
        path={`${match.path}/create`}
        onAllow={PartnerFormPage}
      />
      <Access.RestrictRoute
        key="partner-edit"
        onAccess={[permissions.partner.editAny, permissions.partner.edit]}
        parentPath={match.path}
        path={`${match.path}/edit/:id`}
        onAllow={PartnerFormPage}
      />
    </Switch>
  );
};

export default withRouter(PartnerPage);
