import React, { Fragment } from "react"
import { Redirect } from "react-router-dom"

import Access from "@cthulhi/comp-accs"

const ForbbidenComponent = ({ permission, session, ...props }) => {

  const { state, action, consts } = Access.useState();

  const isNotLoggedIn = state.access.session.status == consts.STS_SESSION_ERROR || state.access.session.status == consts.STS_SESSION_DELETED

  return (
    <Fragment>
      {isNotLoggedIn ? (
        <Redirect to={'/access'} />
      ) : (
        <div>
          <h2>403</h2>
          <h4>Acesso Negado</h4>
          <p>...</p>
        </div>
      )}
    </Fragment>
  )
}

export default ForbbidenComponent