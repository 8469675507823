import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import Access from "@cthulhi/comp-accs";
import Context from "@context";
import { useFields, useForms } from "@cthulhi/comp-form";

import { schema as eventFormSchema } from "@schm/event/create.form.schema";
import { schema as branchPlaceFormSchema } from "@schm/branch/create.place.form.schema";
import { schema as branchVirtualFormSchema } from "@schm/branch/create.virtual.form.schema";

import {
  events as eventFormEvents,
  values as eventFormValues,
  actions as eventFormActions
} from "./components/EventForm.config";

import {
  events as branchPlaceFormEvents,
  values as branchPlaceFormValues,
  actions as branchPlaceFormActions
} from "./components/EventBranchPlaceForm.config";

import {
  events as branchVirtualFormEvents,
  values as branchVirtualFormValues,
  actions as branchVirtualFormActions
} from "./components/EventBranchVirtualForm.config";

import { FieldLabelWrapper } from "../../../wrappers/FormWrappers.react";

import Notification from "@cthulhi/comp-noty";
import NotificationComponent from "../../../utils/Notification.react";

import LoaderComponent from "../../../utils/LoaderCustomComponent.react";

const EventFormPage = ({ match, parentPath, history, ...props }) => {
  const { state: accState, action: accAction } = Access.useState();
  const { action: ctxAction, state: ctxtState } = Context.useState();
  const { state: ntfState, action: ntfAction } = Notification.useState();
  const [ eventSchema, setEventSchema] = useState(eventFormSchema);
  const [validation, setValidation] = useState({})
  const [branchForms, setBranchForms] = useState([]);
  const [ branchPlaceSchema, setBranchPlaceSchema] = useState(branchPlaceFormSchema);
  const [ branchVirtualSchema, setBranchVirtualSchema] = useState(branchVirtualFormSchema);

  const { 
    form: branchVirtualForm, 
    fields: branchVirtualFields, 
    values: branchVirtualValues, 
    updater: branchVirtualUpdater, 
    cleaner: branchVirtualCleaner,
    addFields: branchVirtualAddFields,
    removeFields: branchVirtualRemoveFields,
  } = useFields({
    schema: branchVirtualSchema,
    events: branchVirtualFormEvents({ ctxAction }),
    values: branchVirtualFormValues({
      ctxAction,
      token: `Bearer ${accState.access.session.context.token}`
    })
  });

  const { 
    form: branchPlaceForm, 
    fields: branchPlaceFields, 
    values: branchPlaceValues, 
    updater: branchPlaceUpdater, 
    cleaner: branchPlaceCleaner,
    addFields: branchPlaceAddFields,
    removeFields: branchPlaceRemoveFields,
  } = useFields({
    schema: branchPlaceSchema,
    events: branchPlaceFormEvents({ ctxAction }),
    values: branchPlaceFormValues({
      ctxAction,
      token: `Bearer ${accState.access.session.context.token}`
    })
  });

  const { form, fields, values, updater, cleaner } = useFields({
    schema: eventSchema,
    events: eventFormEvents({ ctxAction }),
    values: eventFormValues({
      ctxAction,
      token: `Bearer ${accState.access.session.context.token}`
    })
  });

  const { find, update, create } = useForms(
    eventFormActions({
      ctxAction,
      ntfAction,
      updater,
      history,
      path: parentPath,
      token: `Bearer ${accState.access.session.context.token}`
    })
  );

  const normalizeBranch = form => {
    const vals = form.type == 'virtual' ? branchVirtualValues : branchPlaceValues;
    const vs = {};
    const prefix = `${form.key}.`;

    Object.entries(vals).filter(([key]) => key.includes(form.key)).map(([key, value]) => {
      const hasValue = typeof value.value != "undefined" && value.value !== ""
      const hasRawValue = typeof value.rawValue != "undefined" && value.rawValue !== ""
      
      vs[key.replace(prefix, '')] = hasRawValue ? value.rawValue : (hasValue ? value.value : value.defaultValue)
    });

    if (form.child) {
      vs.branches = form.child.map(normalizeBranch)
    }

    vs.removed = form.removed;
    vs.type = form.type == "virtual" ? form.type : "fisico";
    vs._id = form._id;
    
    delete vs.children;
    delete vs.parent;
    delete vs.key;

    return vs;
  }

  const validateBranchForm = form => {
    const required = [];
    if (!form || form.removed) return required;

    const { key, type } = form;
    const vals = type == 'virtual' ? branchVirtualValues : branchPlaceValues;

    if (!vals[`${key}.name`].value)  
      required.push({ field: `${key}.name` });
    
    if (!vals[`${key}.contact`].value)  
      required.push({ field: `${key}.contact` });
    
    if (type == 'virtual') {
      if (!vals[`${key}.site`].value) required.push({ field: `${key}.site` });
    } else {
      if (!vals[`${key}.address`].value) required.push({ field: `${key}.address` });
    }

    return required;
  }

  const eventFormSubmit = async () => {
    let vs = {};

    Object.entries(values).forEach(([key, value]) => {
      vs[key] = value.value || value.defaultValue
    });

    vs.branches = branchForms.map(normalizeBranch);

    const validate = action => {
      action.then(() => {}).catch(setValidation)
    }

    let required = [];
    
    if (!vs.name) required.push({ field: 'name', friendly: 'Nome' });
    if (!vs.about) required.push({ field: 'about', friendly: 'Sobre' });
    if (!vs.site) required.push({ field: 'site', friendly: 'Site' });
    if (!vs.categories) required.push({ field: 'categories', friendly: 'Categorias' });
    if (!vs.startDate) required.push({ field: 'startDate', friendly: 'Início' });
    if (!vs.endDate) required.push({ field: 'endDate', friendly: 'Fim' });
    
    if (required.length) {
      setValidation(required.reduce((obj, { field }) => ({ ...obj, [field]: false }), {}))
      return;
    }  else {
      branchForms.forEach(form => {
        required = [
          ...required, 
          ...validateBranchForm(form)
        ];

        (form.child||[]).forEach(child => {
          required = [...required, ...validateBranchForm(child)];
        })
      })
      if (required.length) {
        setValidation(required.reduce((obj, { field }) => ({ ...obj, [field]: false }), {}));
        return;
      }
    }

    match.params.id ? validate(update(vs)) : validate(create(vs));
  };

  useEffect(() => {
    if (match.params.id) {
      find({ filters: { _id: match.params.id } }).then(res => {
        const { branch } = res.result.values[0];
        if (branch) {
          const type = branch.type == "virtual" ? branch.type : "place";
          const parent = addLocal(undefined, type, true, branch);
          
          const { children } = branch;
          if (children) {
            children.forEach(child => {
              const childType = child.type == "virtual" ? child.type : "place";
              addLocal(parent, childType, true, child);
            })
          }
        } else {
          addLocal();
        }
      });
    } else {
      addLocal();
    }

    return () => {
      cleaner();
    };
  }, [match.params.id]);

  const addLocal = (parent, type = 'virtual', createNew = true, values = {}) => {
    const key = new Date().getTime();
    if (type == 'virtual') {
      branchVirtualAddFields(key, values);
    } else {
      branchPlaceAddFields(key, values);
    }

    setBranchForms(v => {
      if (parent) {
        return v.map(form => {
          if (form.key == parent) {
            if (createNew) {
              form.child = [
                ...(form.child||[]),
                {
                  _id: values._id,
                  key,
                  parent,
                  type
                }
              ]
            } else {
              form.child = [{
                _id: values._id,
                key,
                parent,
                type
              }]
            }
          }

          return form
        })
      }

      return [...v, { key, type, _id: values._id }]
    });

    return key;
  }

  const renderPayment = ({ parentKey, type }) => {
    return <button className="button-add-form" onClick={() => addLocal(parentKey, "virtual", true)}>Adicionar novo local de pagamento</button>
  }

  const removeForm = key => {
    setBranchForms(v => v.map(form => {
      if ((form.child||[]).some(child => child.key == key)) {
        form.child = form.child.map(child => ({
          ...child,
          removed: child.key == key || child.removed
        }))
      }

      return form;
    }));
    branchVirtualRemoveFields(key);
    branchPlaceRemoveFields(key);
  }

  const removeChild = key => {
    setBranchForms(v => v.map(form => {
      if (form.key == key) {
        form.child = (form.child||[]).map(child => ({
          ...child,
          removed: true
        }))
      }

      return form;
    }));
  }

  const toggleBranchType = (key, { target }) => {
    const { value } = target;
    const newKey = new Date().getTime();

    setBranchForms(v => v.map(form => {
      if ((form.child||[]).some(child => child.key == key)) {
        form.child = form.child.map(child => {
          if (child.key == key) {
            child.type = value;
            child.key = newKey;
          } 

          return child;
        })
      }

      if (form.key == key) {
        form = { ...form, type: value, key: newKey };
      }

      return form;
    }));

    if (value == "place") {
      branchPlaceAddFields(newKey);
      branchVirtualRemoveFields(key);
    }

    if (value == "virtual") {
      branchVirtualAddFields(newKey);
      branchPlaceRemoveFields(key);
    }

    removeChild(key);
    removeChild(newKey);
  }
  
  const renderVirtualForm = ({ key, child, parent, type, classCard }) => {
    return (
      <div key={key} className="uk-accordion-content">
        <div className={classCard}>
          <form className="uk-grid-small" data-uk-grid>
            <Fragment>
                <FieldLabelWrapper onChange={e => toggleBranchType(key, e)} name={`${key}.type`} fields={branchVirtualFields} values={branchVirtualValues} />
                <FieldLabelWrapper validation={validation[`${key}.name`]} name={`${key}.name`} fields={branchVirtualFields} values={branchVirtualValues} />
                <FieldLabelWrapper validation={validation[`${key}.site`]} name={`${key}.site`} fields={branchVirtualFields} values={branchVirtualValues} />
                <FieldLabelWrapper validation={validation[`${key}.contact`]} name={`${key}.contact`} fields={branchVirtualFields} values={branchVirtualValues} />
              </Fragment> 
          </form>
        </div>
      </div>
    );
  }
  
  const renderPlaceForm = ({ key, child, parent, type, classCard }) => {
    return (
      <div key={key} className="uk-accordion-content">
        <div className={classCard}>
          <form className="uk-grid-small" data-uk-grid>
            <Fragment>
              <FieldLabelWrapper onChange={e => toggleBranchType(key, e)} name={`${key}.type`} fields={branchPlaceFields} values={branchPlaceValues} />
              <FieldLabelWrapper validation={validation[`${key}.name`]} name={`${key}.name`} fields={branchPlaceFields} values={branchPlaceValues} />
              <FieldLabelWrapper validation={validation[`${key}.address`]} name={`${key}.address`} fields={branchPlaceFields} values={branchPlaceValues} />
              <FieldLabelWrapper validation={validation[`${key}.contact`]} name={`${key}.contact`} fields={branchPlaceFields} values={branchPlaceValues} />
            </Fragment> 
          </form>
          {child && (
            <ul data-uk-accordion="multiple: true">
              {child.filter(c => !c.removed).map(c => (
                <li className="uk-open">
                  <a className="title title-lead-small title-accordion uk-accordion-title">
                    Local de pagamento
                  </a>
                  {renderForm(c)}
                  <button className="button-add-form" onClick={() => removeForm(c.key)}>Remover</button>
                </li>
              ))}
            </ul>
          )}
          {!parent && renderPayment({ parentKey: key, type })}
        </div>
      </div>
    );
  }

  const renderForm = ({ key, child, parent, type }) => {
    let classCard = null;

    if (!parent) {
      classCard = "uk-card uk-card-default uk-card-body uk-padding-small";
    }

    switch (type) {
      case 'virtual':
        return renderVirtualForm({ key, child, parent, type, classCard });
      case 'place':
        return renderPlaceForm({ key, child, parent, type, classCard });
    }
  }

  return (
    <Fragment>
      <h2 className="title">{match.params.id ? "Editar evento" : "Novo evento"}</h2>
      <div className="uk-margin-top">
        <Notification.NotificationWrapper
          group="eventForm"
          component={NotificationComponent}
        />
        <LoaderComponent from="eventForm" />

        <ul data-uk-accordion="multiple: true">
          <li className="uk-open">
          <a className="title title-lead-small title-accordion uk-accordion-title">Configurações gerais</a>
            <div className="uk-accordion-content">
              <div className="uk-card uk-card-default uk-card-body uk-padding-small">
                <form className="uk-grid-small" data-uk-grid>
                  <FieldLabelWrapper name="name" fields={fields} values={values} validation={validation.name} />
                  <FieldLabelWrapper name="site" fields={fields} values={values} validation={validation.site} />
                  <FieldLabelWrapper name="about" fields={fields} values={values} validation={validation.about} />
                  <FieldLabelWrapper name="categories" fields={fields} values={values} validation={validation.categories} />
                  <FieldLabelWrapper name="startDate" fields={fields} values={values} validation={validation.startDate}/>
                  <FieldLabelWrapper name="endDate" fields={fields} values={values} validation={validation.endDate}/>
                  <FieldLabelWrapper name="available" fields={fields} values={values} validation={validation.available}/>
                </form>
              </div>
            </div>
          </li>
          {branchForms.map(form => (
            <li className="uk-open">
              <a className="title title-lead-small title-accordion uk-accordion-title">
                Dados de resgate
              </a>
              {renderForm(form)}
            </li>
          ))}
        </ul>
        <div className="uk-margin-top">
          <button
            onClick={eventFormSubmit}
            className="uk-button uk-button-large uk-button-secondary"
            style={{ float: "right" }}
            disabled={typeof ctxtState.loader.list["eventForm"] != "undefined" && ctxtState.loader.list["eventForm"].length > 0}
          >
            {match.params.id ? "Atualizar" : "Salvar"}
          </button>
        </div>
      </div>
    </Fragment>
  );
};

// @ts-ignore
export default withRouter(EventFormPage);
