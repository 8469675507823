export default {
  ACT_SET_STATUS: "ACT_RDP_STATUS",

  STS_IDLE: "STS_RDP_IDLE",

  STS_FIND_REQUEST: "STS_RDP_FIND_REQUEST",
  STS_FIND_RESPONSE: "STS_RDP_FIND_RESPONSE",
  STS_FIND_SUCCESS: "STS_RDP_FIND_SUCCESS",
  STS_FIND_ERROR: "STS_RDP_FIND_ERROR",

  STS_CREATE_REQUEST: "STS_RDP_CREATE_REQUEST",
  STS_CREATE_RESPONSE: "STS_RDP_CREATE_RESPONSE",
  STS_CREATE_SUCCESS: "STS_RDP_CREATE_SUCCESS",
  STS_CREATE_ERROR: "STS_RDP_CREATE_ERROR",

  STS_UPDATE_REQUEST: "STS_RDP_UPDATE_REQUEST",
  STS_UPDATE_RESPONSE: "STS_RDP_UPDATE_RESPONSE",
  STS_UPDATE_SUCCESS: "STS_RDP_UPDATE_SUCCESS",
  STS_UPDATE_ERROR: "STS_RDP_UPDATE_ERROR",

  STS_DELETE_REQUEST: "STS_RDP_DELETE_REQUEST",
  STS_DELETE_RESPONSE: "STS_RDP_DELETE_RESPONSE",
  STS_DELETE_SUCCESS: "STS_RDP_DELETE_SUCCESS",
  STS_DELETE_ERROR: "STS_RDP_DELETE_ERROR",

  STS_AGE_REPORT_REQUEST: "STS_RDP_AGE_REPORT_REQUEST",
  STS_AGE_REPORT_RESPONSE: "STS_RDP_AGE_REPORT_RESPONSE",
  STS_AGE_REPORT_SUCCESS: "STS_RDP_AGE_REPORT_SUCCESS",
  STS_AGE_REPORT_ERROR: "STS_RDP_AGE_REPORT_ERROR",

  STS_CITY_REPORT_REQUEST: "STS_RDP_CITY_REPORT_REQUEST",
  STS_CITY_REPORT_RESPONSE: "STS_RDP_CITY_REPORT_RESPONSE",
  STS_CITY_REPORT_SUCCESS: "STS_RDP_CITY_REPORT_SUCCESS",
  STS_CITY_REPORT_ERROR: "STS_RDP_CITY_REPORT_ERROR",

  STS_DISTRICT_REPORT_REQUEST: "STS_RDP_DISTRICT_REPORT_REQUEST",
  STS_DISTRICT_REPORT_RESPONSE: "STS_RDP_DISTRICT_REPORT_RESPONSE",
  STS_DISTRICT_REPORT_SUCCESS: "STS_RDP_DISTRICT_REPORT_SUCCESS",
  STS_DISTRICT_REPORT_ERROR: "STS_RDP_DISTRICT_REPORT_ERROR",

  STS_PERIOD_REPORT_REQUEST: "STS_RDP_PERIOD_REPORT_REQUEST",
  STS_PERIOD_REPORT_RESPONSE: "STS_RDP_PERIOD_REPORT_RESPONSE",
  STS_PERIOD_REPORT_SUCCESS: "STS_RDP_PERIOD_REPORT_SUCCESS",
  STS_PERIOD_REPORT_ERROR: "STS_RDP_PERIOD_REPORT_ERROR",

  STS_RECURRENT_REPORT_REQUEST: "STS_RDP_RECURRENT_REPORT_REQUEST",
  STS_RECURRENT_REPORT_RESPONSE: "STS_RDP_RECURRENT_REPORT_RESPONSE",
  STS_RECURRENT_REPORT_SUCCESS: "STS_RDP_RECURRENT_REPORT_SUCCESS",
  STS_RECURRENT_REPORT_ERROR: "STS_RDP_RECURRENT_REPORT_ERROR",

  STS_WEEKDAY_REPORT_REQUEST: "STS_RDP_WEEKDAY_REPORT_REQUEST",
  STS_WEEKDAY_REPORT_RESPONSE: "STS_RDP_WEEKDAY_REPORT_RESPONSE",
  STS_WEEKDAY_REPORT_SUCCESS: "STS_RDP_WEEKDAY_REPORT_SUCCESS",
  STS_WEEKDAY_REPORT_ERROR: "STS_RDP_WEEKDAY_REPORT_ERROR",
}