import React, { Fragment, useEffect, useState } from "react"

import Context from "@context"
import Access from "@cthulhi/comp-accs"
import Notification from "@cthulhi/comp-noty"
import Forbidden from "./views/utils/ForbiddenComponent.react"

const Bootstrap = ({ children, ...props }) => {

  const { state: ctxState, action: ctxAction } = Context.useState()

  const addLoading = ctxAction("loader").addLoading
  const removeLoading = ctxAction("loader").removeLoading

  const accessProps = {
    accessServiceUrlLogin: process.env.domainAccessLogin,
    accessServiceUrlVerify: process.env.domainAccessVerify,
    accessServiceUrlRecoverPasswordVerify: process.env.domainAccessRecoverPasswordVerify,
    accessServiceUrlRecoverCodeVerify: process.env.domainAccessRecoverCodeVerify,
    accessServiceUrlRecoverPasswordUpdate: process.env.domainAccessRecoverPasswordUpdate,
    accessComponentDenyDefault: Forbidden,
    accessComponentLoadingDefault: props => <div>Default Loading Component</div>,
    onProcessBegin: (event) => {
      addLoading(event.action)  
    },
    onProcessEnd: (event) => {
      removeLoading(event.action)  
    }
  };

  useEffect(() => {
    removeLoading("app")
  }, [])
  
  return (
    <Notification.Provider>
      <Access.Provider {...accessProps}>
        {children}
      </Access.Provider>
    </Notification.Provider>
  )
}

export default Bootstrap