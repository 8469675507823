import websiteConstant from "@html/constants/website.constant";
import cc from "../constants/discount.constant";

export default (dispatch, state) => {
  const dispatchSetStatus = (data) => {
    dispatch({
      type: cc.ACT_SET_STATUS,
      data,
    });
  };

  const remove = (values, authorization) => {
    const args = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization,
      },
      body: JSON.stringify(values),
      credentials: "same-origin",
    };

    dispatchSetStatus(cc.STS_DELETE_REQUEST);

    return fetch(process.env.domainDiscountDelete, args)
      .then((res) => res.json())
      .then((res) => {
        dispatchSetStatus(cc.STS_DELETE_RESPONSE);
        dispatchSetStatus(cc.STS_DELETE_SUCCESS);

        setTimeout(() => {
          dispatchSetStatus(cc.STS_IDLE);
        }, 1000);

        return res;
      })
      .catch((error) => {
        dispatchSetStatus(cc.STS_DELETE_ERROR);

        setTimeout(() => {
          dispatchSetStatus(cc.STS_IDLE);
        }, 1000);

        return error;
      });
  };

  const update = async (values, authorization) => {
    const updateFetch = (args) => fetch(process.env.domainDiscountUpdate, args)
      .then((res) => res.json())
      .then((res) => {
        
        console.log('=====> VALUES ERROR: ', values);
        dispatchSetStatus(cc.STS_UPDATE_RESPONSE);
        dispatchSetStatus(cc.STS_UPDATE_SUCCESS);

        setTimeout(() => {
          dispatchSetStatus(cc.STS_IDLE);
        }, 1000);

        return res;
      })
      .catch((error) => {
        console.log('=====> VALUES ERROR: ', values);
        dispatchSetStatus(cc.STS_UPDATE_ERROR);

        setTimeout(() => {
          dispatchSetStatus(cc.STS_IDLE);
        }, 1000);

        return error;
      });

    dispatchSetStatus(cc.STS_UPDATE_REQUEST);

    values.hot = false;
    values.howToUse = values.howToUse || "-";
    values.refoundType = values.refoundType || "-";

    if (values.branches && !Array.isArray(values.branches)) {
      values.branches = [...values.branches.split(",")];
    }

    if (values.userTypes && !Array.isArray(values.userTypes)) {
      values.userTypes = [...values.userTypes.split(",")];
    }

    if (values.discountImageBlob) {
      const formData = new FormData();
      formData.append("file", values.discountImageBlob);

      const args = {
        method: "POST",
        body: formData,
      };

      return fetch(process.env.domainDocumentUploadImage, args)
        .then((res) => res.json())
        .then((res) => {
          values.discountImage = res.result.image;
          delete values.discountImageBlob;
          console.log('=====> VALUES: ', values);

          return updateFetch({
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: authorization,
            },
            body: JSON.stringify(values),
            credentials: "same-origin",
          });
        })
        .catch((error) => error);
    }

    console.log('=====> VALUES: ', values);
    return updateFetch({
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization,
      },
      body: JSON.stringify(values),
      credentials: "same-origin",
    });
  };

  const create = async (values, authorization) => {
    const createFetch = (args) => fetch(process.env.domainDiscountCreate, args)
      .then((res) => res.json())
      .then((res) => {
        dispatchSetStatus(cc.STS_CREATE_RESPONSE);
        dispatchSetStatus(cc.STS_CREATE_SUCCESS);

        setTimeout(() => {
          dispatchSetStatus(cc.STS_IDLE);
        }, 1000);
        return res;
      })
      .catch((error) => {
        dispatchSetStatus(cc.STS_CREATE_ERROR);

        setTimeout(() => {
          dispatchSetStatus(cc.STS_IDLE);
        }, 1000);

        return error;
      });

    dispatchSetStatus(cc.STS_CREATE_REQUEST);

    values.hot = false;
    values.howToUse = values.howToUse || "-";
    values.refoundType = values.refoundType || "-";

    if (values.branches && !Array.isArray(values.branches)) {
      values.branches = [...values.branches.split(",")];
    }

    if (values.userTypes && !Array.isArray(values.userTypes)) {
      values.userTypes = [...values.userTypes.split(",")];
    }

    if (values.discountImageBlob) {
      const formData = new FormData();
      formData.append("file", values.discountImageBlob);

      const args = {
        method: "POST",
        body: formData,
      };

      return fetch(process.env.domainDocumentUploadImage, args)
        .then((res) => res.json())
        .then((res) => {
          values.discountImage = res.result.image;
          delete values.discountImageBlob;

          return createFetch({
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: authorization,
            },
            body: JSON.stringify(values),
            credentials: "same-origin",
          });
        })
        .catch((error) => error);
    }

    return createFetch({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization,
      },
      body: JSON.stringify(values),
      credentials: "same-origin",
    });
  };

  const find = (filters, options, authorization, loader) => {
    const args = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization,
      },
      body: JSON.stringify({ ...filters, options: { sort: { _id: -1 }, ...options } }),
      credentials: "same-origin",
    };

    if (loader) loader.start();

    dispatchSetStatus(cc.STS_FIND_REQUEST);

    return fetch(process.env.domainDiscountFind, args)
      .then((res) => res.json())
      .then((res) => {
        dispatchSetStatus(cc.STS_FIND_RESPONSE);
        dispatchSetStatus(cc.STS_FIND_SUCCESS);

        if (loader) loader.finish();

        setTimeout(() => {
          dispatchSetStatus(cc.STS_IDLE);
        }, 1000);

        return res;
      })
      .catch((error) => {
        dispatchSetStatus(cc.STS_FIND_ERROR);

        if (loader) loader.finish();

        setTimeout(() => {
          dispatchSetStatus(cc.STS_IDLE);
        }, 1000);

        return error;
      });
  };

  const findCostumerDashboardData = (filters, options, authorization, loader) => {
    
    const args = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization,
      },
      body: JSON.stringify({ ...filters, options: { sort: { _id: -1 }, ...options } }),
      credentials: "same-origin",
    };

    if (loader) loader.start();

    dispatchSetStatus(cc.STS_FIND_REQUEST);

    return fetch(process.env.domainDiscountCostumerDashboard, args)
      .then((res) => res.json())
      .then((res) => {
        dispatchSetStatus(cc.STS_FIND_RESPONSE);
        dispatchSetStatus(cc.STS_FIND_SUCCESS);

        if (loader) loader.finish();

        setTimeout(() => {
          dispatchSetStatus(cc.STS_IDLE);
        }, 1000);

        return res;
      })
      .catch((error) => {
        dispatchSetStatus(cc.STS_FIND_ERROR);

        if (loader) loader.finish();

        setTimeout(() => {
          dispatchSetStatus(cc.STS_IDLE);
        }, 1000);

        return error;
      });
  };

  const aggregatePartner = (filters, options, authorization, loader) => {

    const body = JSON.stringify(
      { 
        filters: {...filters}, 
        aggregates: [
          {
            lookup: {
              from: 'partner', 
              field: 'partnerId', 
              match: '$_id', 
              result: 'partner'
            }
          }
        ],
        options: { sort: { _id: -1 }, ...options } }
    );

    const args = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization,
      },
      body: body,
      credentials: "same-origin",
    };

    if (loader) loader.start();

    dispatchSetStatus(cc.STS_FIND_REQUEST);

    return fetch(process.env.domainDiscountFind, args)
      .then((res) => res.json())
      .then((res) => {
        dispatchSetStatus(cc.STS_FIND_RESPONSE);
        dispatchSetStatus(cc.STS_FIND_SUCCESS);

        if (loader) loader.finish();

        setTimeout(() => {
          dispatchSetStatus(cc.STS_IDLE);
        }, 1000);

        return res;
      })
      .catch((error) => {
        dispatchSetStatus(cc.STS_FIND_ERROR);

        if (loader) loader.finish();

        setTimeout(() => {
          dispatchSetStatus(cc.STS_IDLE);
        }, 1000);

        return error;
      });
  };

  const aggregateEvent = (filters, options, authorization, loader) => {

    const body = JSON.stringify(
      { 
        filters: {...filters}, 
        aggregates: [
          {
            lookup: {
              from: 'event', 
              field: 'eventId', 
              match: '$_id', 
              result: 'event'
            }
          }
        ],
        options: { sort: { _id: -1 }, ...options } }
    );

    const args = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization,
      },
      body: body,
      credentials: "same-origin",
    };

    if (loader) loader.start();

    dispatchSetStatus(cc.STS_FIND_REQUEST);

    return fetch(process.env.domainDiscountFind, args)
      .then((res) => res.json())
      .then((res) => {
        dispatchSetStatus(cc.STS_FIND_RESPONSE);
        dispatchSetStatus(cc.STS_FIND_SUCCESS);

        if (loader) loader.finish();

        setTimeout(() => {
          dispatchSetStatus(cc.STS_IDLE);
        }, 1000);

        return res;
      })
      .catch((error) => {
        dispatchSetStatus(cc.STS_FIND_ERROR);

        if (loader) loader.finish();

        setTimeout(() => {
          dispatchSetStatus(cc.STS_IDLE);
        }, 1000);

        return error;
      });
  };

  return {
    find,
    create,
    update,
    remove,
    aggregatePartner,
    aggregateEvent,
    findCostumerDashboardData
  };
};
