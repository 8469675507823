import React, { Fragment, useEffect, useState } from "react";
import { Link, Switch, Route, withRouter, Redirect } from "react-router-dom";

import Access from "@cthulhi/comp-accs";

import ContentFormPage from "./ContentFormPage.react";
import ContentListPage from "./ContentListPage.react";

import permissions from "@perm";

const ContentPage = ({ match, ...props }) => {
  return (
    <Switch>
      <Access.RestrictRoute
        key="content-list"
        onAccess={[permissions.content.viewAny, permissions.content.editAny]}
        parentPath={match.path}
        path={`${match.path}`}
        exact
        onAllow={ContentListPage}
      />
      <Access.RestrictRoute
        key="content-create"
        onAccess={[permissions.content.viewAny, permissions.content.editAny]}
        parentPath={match.path}
        path={`${match.path}/create`}
        onAllow={ContentFormPage}
      />
      <Access.RestrictRoute
        key="content-edit"
        onAccess={[permissions.content.editAny, permissions.content.edit]}
        parentPath={match.path}
        path={`${match.path}/edit/:id`}
        onAllow={ContentFormPage}
      />
    </Switch>
  );
};

export default withRouter(ContentPage);
