import React, { useEffect } from "react"

const Select = ({ name, value, defaultValue, rawValue, options, masker, ...props }) => {

  const v = props.multiple ?  
    (value && Array.isArray(value) ? value : [value ? value : defaultValue ? defaultValue : undefined]) : 
    (value && Array.isArray(value) ? value[0] : value ? value : defaultValue ? defaultValue : undefined)

  return(
    <select name={name} id={name} {...props} value={v} >
      {typeof options !== "undefined" && typeof options !== "function" ? options.map(({ label, ...props }) => (
        <option key={"options-" + props.value} {...props}> {label} </option>
      )) : null}
    </select>
  )
}

export default Select