import { schema, types } from "@cthulhi/pack-schm";

const schm = schema("partner", {
  status: {
    type: types.boolean.type
  },
  createdAt: {
    type: types.datetime.type
  },
  updatedAt: {
    type: types.datetime.type
  }
});

export { schm as schema };
