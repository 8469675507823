const view = {
  group: {
    title: "Content",
    slug: "content"
  },
  slug: "content-view",
  title: "Ver conteúdo",
  description: "Ver os próprios conteúdo",
  list: ["prn::1:content-view-roles:content-view:allow:lcnt:schema.user.roles:custom.static.admin"]
};

const viewAny = {
  group: {
    title: "Content",
    slug: "content"
  },
  slug: "content-view-any",
  title: "Ver qualquer usuário vip",
  description: "Ver todos os conteúdo",
  list: [
    "prn::1:content-view-any-roles:content-view-any:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
};

const edit = {
  group: {
    title: "Content",
    slug: "content"
  },
  slug: "content-edit",
  title: "Editar conteúdo",
  description: "Editar os próprios conteúdo",
  list: ["prn::1:content-edit-roles:content-edit:allow:lcnt:schema.user.roles:custom.static.admin"]
};

const editAny = {
  group: {
    title: "Content",
    slug: "content"
  },
  slug: "content-edit-any",
  title: "Editar qualquer usuário vip",
  description: "Editar todos os conteúdo",
  list: [
    "prn::1:content-edit-any-roles:content-edit-any:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
};

const remove = {
  group: {
    title: "Content",
    slug: "content"
  },
  slug: "content-remove",
  title: "Remover conteúdo",
  description: "Remover os próprios conteúdo"
};

const removeAny = {
  group: {
    title: "Content",
    slug: "content"
  },
  slug: "content-remove-any",
  title: "Remover qualquer usuário vip",
  description: "Remover todos os conteúdo"
};

const create = {
  group: {
    title: "Content",
    slug: "content"
  },
  slug: "content-create",
  title: "Criar conteúdo",
  description: "Criar os próprios conteúdo"
};

const createAny = {
  group: {
    title: "Content",
    slug: "content"
  },
  slug: "content-create-any",
  title: "Criar qualquer usuário vip",
  description: "Criar todos os conteúdo"
};

module.exports = {
  view,
  viewAny,
  edit,
  editAny,
  remove,
  removeAny,
  create,
  createAny
};
