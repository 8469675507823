import htconst from '../constants/http.constant'
import opconst from '../constants/opportunity.constant'

const formFields = {
  name: "",
  email: "",
  job: "",
  phone: "",
  linkedin: "",
  portfolio: "",
  message: ""
}

const state = {
  form: {
    status: htconst.IDLE,
    options: {
      jobs: [
        { key: "", label: "Vaga de interesse *", disabled: true },
        { key: "UI/UX", label: "Designer UI/UX"},
        { key: "Full Stack Dev", label: "Android Developer"},
        { key: "Full Stack Dev", label: "iOS Developer"},
        { key: "Full Stack Dev", label: "Full Stack Developer"},
        { key: "SQA", label: "Software Quality Assurance"}
      ],
    },
    fields: { ...formFields },
    validations: undefined
  }
};

const reducer = (state, action) => {
  switch (action.type) {
       
    case opconst.SET_FORM_VALIDATIONS:
      
      return {
        ...state,
        form: { ...state.form, validations: action.data }
      }
    
    case opconst.CLEAR_FORM_VALIDATION:

      const sv = { ...state }
      sv.form.validations ? delete sv.form.validations[action.data.field] : null
      return sv

    case opconst.SET_FORM_FIELD:

      const sf = { ...state }
      sf.form.fields[action.data.field] = action.data.value
      return sf
  
    case opconst.CLEAR_FORM_FIELDS:

      return {
        ...state,
        form: {
          ...state.form,
          fields: { ...formFields } 
        }
      }

    case opconst.SET_FORM_STATUS:
      
      const ss = { ...state }
      ss.form.status = action.data
      return ss
      
    default:
      return state;
  }
};

export default { state, reducer }