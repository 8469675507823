import React, { Fragment } from "react";
import { schema as schm, types } from "@cthulhi/pack-schm";
import { types as fields } from "@cthulhi/comp-form";

const schema = schm("filter", {
  name: {
    type: types.string.type,
    field: fields.textfield.type
  },
  status: {
    type: types.string.type,
    field: fields.select.type
  }
});

const events = ({ ...props }) => {
  return {};
};

const values = ({ ...props }) => {
  return {
    name: {
      filter: "text",
      className: "uk-input",
      placeholder: "Nome",
      label: {
        text: "Nome",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-3@m"
      }
    },
    status: {
      className: "uk-select",
      value: "",
      defaultValue: "",
      label: {
        text: "Status",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-3@m"
      },
      options: update => {
        update("status", {
          options: [
            { value: "", label: "Selecione..." },
            { value: true, label: "Habilitado" },
            { value: false, label: "Desabilitado" }
          ]
        });
      }
    }
  };
};

const options = ({ onEdit, onRemove, ...props }) => {
  return {
    filter: {
      fields: true,
      submit: true
    },
    pagination: {
      next: {
        value: "Próximo",
        className: "page-next"
      },
      prev: {
        value: "Anterior",
        className: "page-prev"
      },
      numbers: {
        className: "page-numbers"
      },
      total: true
    },
    limit: {
      value: 5,
      limits: [
        { value: 5, label: "5 Items" },
        { value: 10, label: "10 Items" },
        { value: 20, label: "20 Items" }
      ]
    },
    items: {
      category: {
        header: "Categoria Pai",
        column: "parentItem.0.name"
      },
      name: {
        header: {
          value: "Categoria",
          className: ""
        },
        column: {
          className: "",
          value: "name"
        }
      },
      status: {
        header: "Status",
        column: "status",
        parse: (value) => value ? "Habilitado" : "Desabilitado"
      },
      weight: {
        header: "Ordem",
        column: "weight"
      },
      actions: {
        header: "Ações",
        column: (item, refresh, loader) => {
          return {
            value: (
              <Fragment>
                <div className="uk-grid-small uk-child-width-auto" data-uk-grid>
                  <div className="uk-text-nowrap">
                    <button
                      className="uk-icon-button uk-button-primary"
                      onClick={() => onEdit(item, refresh, loader)}
                    >
                      <i className="icon icon-edit" aria-hidden="true"></i>
                    </button>
                    <button
                      className="uk-icon-button uk-button-danger uk-margin-small-left"
                      onClick={() => onRemove(item, refresh, loader)}
                    >
                      <i className="icon icon-delete" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </Fragment>
            )
          };
        }
      }
    }
  };
};

const actions = ({ ctxAction, token, ...props }) => {
  return {
    find: (filters, options) => {
      return ctxAction("category").find({ ...filters, aggregates: [
        {
          lookup: {
            from: "category",
            field: "parentId",
            match: "_id",
            result: "parentItem",
            op: "$eq"
          }
        }
      ]}, options, token);
    }
  };
};

export { events, values, actions, options, schema };
