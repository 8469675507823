import React, { Fragment, useState } from "react";
import { withRouter } from "react-router-dom";

import { List } from "../../../wrappers/ListWrappers.react";

import { useFilter } from "@cthulhi/comp-list";

import Access from "@cthulhi/comp-accs";
import Context from "@context";

import Notification from "@cthulhi/comp-noty";
import NotificationComponent from "../../../utils/Notification.react";
import ModalConfirmRemoval from "../../../wrappers/ModalConfirmRemoval.react";

import {
  events as userVipListEvents,
  values as userVipListValues,
  actions as userVipListActions,
  options as userVipListOptions,
  schema as userVipListSchema
} from "./components/UserVipList.config";

const UserVipListPage = ({ match, history, ...props }) => {
  const { state: accState, action: accAction } = Access.useState();
  const { action: ctxAction } = Context.useState();
  const { state: ntfState, action: ntfAction } = Notification.useState();
  const [modal, setModal] = useState({ show: false, Content: () => null });
  const [downloadStatus, setDownloadStatus] = React.useState("waiting");
  const [downloadLinkAttributes, setDownloadLinkAttributes] = React.useState({
    download: "",
    href: ""
  });
  const token = `Bearer ${accState.access.session.context.token}`;


  const createFilter = () => {
    if (filter.values) {
      const searchFields = filter.values;
      const searchTerms = Object.keys(searchFields).reduce((filter, key) => {
        if (searchFields[key].value) {
          filter[key] = {
            $regex: searchFields[key].value,
            $options: "i"
          };
          return filter;
        }
        return filter;
      }, {});
      return searchTerms;
    }
    return {};
  };


  const downloadFile = e => {
    e.preventDefault();

    setDownloadStatus("downloading");

    ntfAction("notification").add(
      1,
      "userVipListPage",
      "success",
      "Preparando arquivo...",
      undefined,
      process.env.notificationTimeOut
    );

    console.log(userVipListActions({ ctxAction, token }))

    userVipListActions({ ctxAction, token })
      .downloadCSV({ filters: createFilter() }, {})
      .then(blob => {
        const href = window.URL.createObjectURL(blob);
        setDownloadLinkAttributes({
          href: href,
          download: "user-vips-export.csv"
        });
        setDownloadStatus("finished");
      })
      .catch(e => {
        console.log(e);
        setDownloadStatus("error");

        ntfAction("notification").add(
          500,
          "userVipListPage",
          "error",
          "Não foi possível gerar o arquivo.",
          undefined,
          process.env.notificationTimeOut
        );
      });
  };

  const onEdit = value => {
    history.push(`${match.path}/edit/${value._id}`);
  };

  const onRemoveAction = (value, refresh, loader) => {
    loader(true);
    ctxAction("userVip")
      .remove({ _id: value._id }, token)
      .then(res => {
        if (res.name != "UserVipDeleteResult") throw res;

        refresh();

        ntfAction("notification").add(
          res.code,
          "userVipListPage",
          "success",
          "Beneficiado removido com sucesso.",
          undefined,
          process.env.notificationTimeOut
        );
      })
      .catch(err => {
        loader(false);

        ntfAction("notification").add(
          err.code,
          "userVipListPage",
          "danger",
          "Houve um erro ao remover o beneficiado.",
          undefined,
          process.env.notificationTimeOut
        );
      });
  };

  const onRemove = (value, refresh, loader) => {
    setModal({
      show: true,
      Content: props => {
        return (
          <ModalConfirmRemoval
            onConfirm={() => onRemoveAction(value, refresh, loader)}
            onClose={() => setModal({ show: false, Content: () => null })}
          />
        );
      }
    });
  };

  const { filter, list, page, limit, options } = useFilter({
    schema: userVipListSchema,
    events: userVipListEvents({ ctxAction }),
    options: userVipListOptions({ onEdit, onRemove }),
    values: userVipListValues({ ctxAction, token }),
    actions: userVipListActions({ ctxAction, token })
  });

  return (
    <Fragment>
      <h2 className="title">Listar beneficiados</h2>
      <div className="uk-margin-top">
        <div className="uk-margin-top uk-margin-bottom">
          <div className="uk-text-nowrap">
            <button className="uk-button-primary uk-margin-right" onClick={downloadFile}>
              Baixar conteúdo em formato CSV
            </button>
            {downloadStatus == "finished" && (
              <a {...downloadLinkAttributes}>Click aqui para baixar o arquivo</a>
            )}
          </div>
        </div>
        <Notification.NotificationWrapper
          group="userVipListPage"
          component={NotificationComponent}
        />
        <List {...{ filter, list, page, limit, options }} />
        <div className="container-modal">{modal.show ? <modal.Content /> : null}</div>
      </div>
    </Fragment>
  );
};

export default withRouter(UserVipListPage);
