import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const BackButton = props => {
  return (
    <Fragment>
      <div className="uk-margin-top uk-text-center">
        <Link to={`${props.isPwa ? "/parceiroapp" : ""}/parceiro/access`}>Início</Link>
      </div>
    </Fragment>
  );
};
export default BackButton;
