import permissions from "@perm"

const events = ({ ctxAction, ...props }) => {
  return {};
};

const values = ({ ctxAction, token, ...props }) => {
  let initValues = {
    newPassword: {
      className: "uk-input",
      placeholder: "Digite sua nova senha",
      label: {
        text: "Nova senha",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-1"
      }
    },
    confirmPassword: {
      className: "uk-input",
      placeholder: "Digite/Confirme sua nova senha",
      label: {
        text: "Confirmação da senha",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-1"
      }
    },
  };

  return initValues;
};

const actions = ({ token, ctxAction, ntfAction, updater, history, path, ...props }) => {
  return {
    entity: {
      name: "user",
      token: token,
      form: ctxAction("form")
    },
    actions: {
      entity: ctxAction,
      notification: ntfAction,
      updater: updater
    },
    create: {},
    update: {},
    find: {},
    updatePassword: {
      action: "submit",
      success: {
        name: "UserUpdateResult",
        message: "Sua senha foi atualizada com sucesso",
        action: { notify: "passwordForm", redirect: false }
      },
      error: {
        name: "UserUpdateError",
        message: "Houve um erro ao atualizar a senha do usuário",
        action: { notify: "passwordForm", redirect: false }
      }
    }
  };
};

export { events, values, actions };
