import React, { Fragment, useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";

import Context from "@html-context";
import Access from "@cthulhi/comp-accs";

import LoaderComponent from "../../../../utils/LoaderCustomComponent.react";

const BranchRelationList = ({ onRemove, onFind, onAdd, onInit, values = [], ...props }) => {
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState(values);
  const [selectedItem, setSelectedItem] = useState({ value: "-" });

  useEffect(() => {
    const find = async () => {
      setItems(await onFind());
    };

    find();
  }, []);

  useEffect(() => {
    onInit(values);
    setSelectedItems(values);
  }, [JSON.stringify(values)])

  const onChangeUpdate = e => {
    setSelectedItem(items.filter(i => i.value == e.target.value)[0]);
  };

  const onClickAdd = () => {
    if (selectedItem.value != "-") {
      onAdd(selectedItem);
      setSelectedItems([...selectedItems, selectedItem]);
      setSelectedItem({ value: "-" });
    }
  };

  const onClickRemove = item => {
    onRemove(item);
    setSelectedItems([...selectedItems.filter(i => i.value != item.value)]);
  };

  const isItemsEmpty =
    items.length == 0 ||
    items.filter(
      i => selectedItems.length == 0 || selectedItems.filter(j => j.value != i.value).length > 0
    ).length == 0;

  return (
    <Fragment>
      <div className="uk-card uk-card-default uk-card-body uk-padding-small">
        <LoaderComponent from="branchRelationList" />
        <div className="uk-grid-small" data-uk-grid>
          <div className="uk-width-1-2@m">
            <select className="uk-select" value={selectedItem.value} onChange={onChangeUpdate}>
              {isItemsEmpty ? (
                <option value="-">Nenhuma unidade disponível...</option>
              ) : (
                <option value="-" disabled>
                  Selecione uma unidade...
                </option>
              )}
              {items
                .filter(
                  i =>
                    selectedItems.length == 0 ||
                    selectedItems.filter(j => j.value != i.value).length > 0
                )
                .map((item, index) => (
                  <option key={"brl-item-" + index} value={item.value}>
                    {item.label}
                  </option>
                ))}
            </select>
          </div>
          <div className="uk-width-1-2@m">
            <button
              className="uk-button uk-button-secondary"
              onClick={onClickAdd}
              disabled={isItemsEmpty}
            >
              Adicionar
            </button>
          </div>
          <div className="uk-width-1-1">
            <div className="uk-overflow-auto">
              <table className="uk-table uk-table-small uk-table-striped uk-margin-remove">
                <thead>
                  <tr>
                    <td>Unidades</td>
                    <td width="20%">Ação</td>
                  </tr>
                </thead>
                <tbody>
                  {selectedItems.length > 0
                    ? selectedItems.map((item, index) => (
                        <tr key={"brl-item-" + index}>
                          <td>{item.label}</td>
                          <td>
                            <button
                              className="uk-icon-button uk-button-danger"
                              onClick={e => onClickRemove(item)}
                            >
                              <i className="icon icon-delete" aria-hidden="true"></i>
                            </button>
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default BranchRelationList;
