import { schema, types } from "@cthulhi/pack-schm";
import { types as fields } from "@cthulhi/comp-form";

const schm = schema("partner", {
    company_name: {
        type: types.string.type,
        field: fields.textfield.type,
        required: { contains: true }
    },
    name: {
        type: types.string.type,
        field: fields.textfield.type,
        required: { contains: true }
    },
    email: {
        type: types.string.type,
        field: fields.textfield.type,
        required: { contains: true }
    },
    phone: {
        type: types.string.type,
        field: fields.textfield.type,
        required: { contains: true }
    }
});

export { schm as schema };
