import React, { Fragment, useEffect, useState } from "react";
import { Link, Switch, Route, withRouter } from "react-router-dom";

import Access from "@cthulhi/comp-accs";
import Context from "@context";
import { useFields, useForms } from "@cthulhi/comp-form";

import { schema as benefitFormSchema } from "@schm/benefit/create.form.schema";

import moment from "moment";

import {
  events as benefitFormEvents,
  values as benefitFormValues,
  actions as benefitFormActions
} from "./components/BenefitProfileForm.config";

import { FieldLabelWrapper } from "../../../wrappers/FormWrappers.react";

import Notification from "@cthulhi/comp-noty";
import NotificationComponent from "../../../utils/Notification.react";

import LoaderComponent from "../../../utils/LoaderCustomComponent.react";

const BenefitProfileFormPage = ({ match, parentPath, history, ...props }) => {
  const { state: accState, action: accAction } = Access.useState();
  const { action: ctxAction } = Context.useState();
  const { state: ntfState, action: ntfAction } = Notification.useState();

  const { form, fields, values, updater, cleaner } = useFields({
    schema: benefitFormSchema,
    events: benefitFormEvents({ ctxAction }),
    values: benefitFormValues({ ctxAction, token: `Bearer ${accState.access.session.context.token}` })
  })

  const { find, update, create } = useForms(benefitFormActions({ 
    ctxAction, 
    ntfAction, 
    updater, 
    history, 
    path: parentPath, 
    token: `Bearer ${accState.access.session.context.token}`, 
  }))

  useEffect(() => {
    if (match.params.id) {
      find({ filters:{ _id: match.params.id }, aggregates: [
        {
          lookup: {
            from: "branch",
            field: "branches.event",
            match: "_id",
            result: "branchesList"
          }
        },
        {
          lookup: {
            from: "category",
            field: "categories",
            match: "_id",
            result: "categoriesList"
          }
        }
      ] });
    }

    return () => {
      cleaner();
    };
  }, [match.params.id]);

  // TO DO: merge fix params and avoid duplication
  const branchTypes = {
    salespoint: "Ponto de venda",
    usepoint: "Ponto de uso" 
  }

  // TO DO: load from db agregate
  const branches =
    // @ts-ignore
    values && values.branches
      ? // @ts-ignore
        // @ts-ignore
        values.branches.rawValue.map((branch, index) => {
          const b = values.branchesList && values.branchesList.rawValue.find(item => item._id == branch.event)
          
          return {
            ...branch,
            ...b,
            action: branchTypes[branch.type]
          }
        })
      : [];

  // TO DO: load from db agregate
  const events =
    // @ts-ignore
    values && values.events
      ? // @ts-ignore
        // @ts-ignore
        values.events.rawValue
      : [];

  const categories =
  // @ts-ignore
    values && values.categoriesList
      ? // @ts-ignore
        // @ts-ignore
        values.categoriesList.rawValue
      : [];

  return (
    <Fragment>
      <h2 className="title">Ver benefício</h2>
      <div className="uk-margin-top">
        <ul data-uk-accordion="multiple: true">
          <li className="uk-open">
            <a className="title title-lead-small title-accordion uk-accordion-title">
              Dados do benefício
            </a>
            <div className="uk-accordion-content">
              <Notification.NotificationWrapper
                group="benefitForm"
                component={NotificationComponent}
              />
              <div className="uk-card uk-card-default uk-card-body uk-padding-small">
                <LoaderComponent from="benefitForm" />
                <div className="uk-grid-small uk-grid">
                  <FieldLabelWrapper name="businessId" fields={fields} values={values} />
                  <FieldLabelWrapper name="type" fields={fields} values={values} />
                  <FieldLabelWrapper name="flagPromotion" fields={fields} values={values} />
                  <FieldLabelWrapper name="flagSpotlight" fields={fields} values={values} />
                  <FieldLabelWrapper name="flagHidden" fields={fields} values={values} />

                  <FieldLabelWrapper name="title" fields={fields} values={values} />
                  <FieldLabelWrapper name="description" fields={fields} values={values} />
                  <FieldLabelWrapper name="resume" fields={fields} values={values} />
                  <FieldLabelWrapper name="discountType" fields={fields} values={values} />
                  <FieldLabelWrapper name="discount" fields={fields} values={values} />

                  {values.type.value == "event" ? (
                    <div className="uk-margin-top uk-width-1-1">
                      <ul data-uk-accordion="multiple: true">
                        <li>
                          <a className="title title-lead-small title-accordion uk-accordion-title">
                            Dados do evento
                          </a>
                          <div className="uk-accordion-content">
                            <div className="uk-card uk-card-default uk-card-body uk-padding-small">
                              <div>
                                {events.length > 0 ? (
                                  <table className="uk-table uk-table-small uk-table-striped uk-margin-remove">
                                    <thead>
                                      <tr>
                                        <td>Data</td>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {events.map((item, index) => (
                                        <tr key={"brl-item-" + index}>
                                          <td>{moment(item).format("DD / MM / YYYY HH:mm")}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                ) : null}
                                <FieldLabelWrapper
                                  name="eventDescription"
                                  fields={fields}
                                  values={values}
                                />
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  ) : null}

                  <FieldLabelWrapper name="siteUrl" fields={fields} values={values} />
                  <FieldLabelWrapper name="tinySiteUrl" fields={fields} values={values} />
                  <FieldLabelWrapper name="footerNote" fields={fields} values={values} />
                  <FieldLabelWrapper name="shelfLifeStart" fields={fields} values={values} />
                  <FieldLabelWrapper name="shelfLifeEnd" fields={fields} values={values} />
                  <FieldLabelWrapper name="redemptionType" fields={fields} values={values} />
                  
                  {["promocode", "limitedvoucher"].includes(values.redemptionType.value) ? (
                    <FieldLabelWrapper name="voucherCode" fields={fields} values={values} />
                  ) : null}

                  {["limitedvoucher"].includes(values.redemptionType.value) ? (
                    <FieldLabelWrapper name="voucherAmount" fields={fields} values={values} />
                  ) : null}

                  <FieldLabelWrapper name="voucherShelfLife" fields={fields} values={values} />
                  <FieldLabelWrapper name="limitVoucherByUser" fields={fields} values={values} />

                  {values.limitVoucherByUser.value ? (
                    <Fragment>
                      <FieldLabelWrapper name="voucherUserLimit" fields={fields} values={values} />
                      <FieldLabelWrapper name="voucherPeriodLimit" fields={fields} values={values} />
                    </Fragment>
                  ) : null}


                  <FieldLabelWrapper name="howToUseVoucher" fields={fields} values={values} />
                </div>
              </div>
            </div>
          </li>
          <li>
            <a className="title title-lead-small title-accordion uk-accordion-title">
              Banner & Vídeo
            </a>
            <div className="uk-accordion-content">
              <div className="uk-card uk-card-default uk-card-body uk-padding-small">
                <LoaderComponent from="benefitForm" />
                <div className="uk-grid-small" data-uk-grid>
                  <FieldLabelWrapper name="bannerUrl" fields={fields} values={values} />
                  <FieldLabelWrapper name="videoUrl" fields={fields} values={values} />
                </div>
              </div>
            </div>
          </li>
          <li>
            <a className="title title-lead-small title-accordion uk-accordion-title">
              Unidades
            </a>
            <div className="uk-accordion-content">
              <LoaderComponent from="benefitForm" />
              {branches.length > 0 ? (
                <table className="uk-table uk-table-small uk-table-striped uk-margin-remove">
                  <thead>
                    <tr>
                      <td>Nome</td>
                      <td>Tipo</td>
                    </tr>
                  </thead>
                  <tbody>
                    {branches.map((item, index) => (
                      <tr key={"brl-item-" + index}>
                        <td>{item.name}</td>
                        <td>{item.action}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : null}
            </div>
          </li>
          <li>
            <a className="title title-lead-small title-accordion uk-accordion-title">
              Categorias
            </a>
            <div className="uk-accordion-content">
              <LoaderComponent from="benefitForm" />
              {categories.length > 0 ? (
                <table className="uk-table uk-table-small uk-table-striped uk-margin-remove">
                  <thead>
                    <tr>
                      <td>Nome</td>
                    </tr>
                  </thead>
                  <tbody>
                    {categories.map((item, index) => (
                      <tr key={"brl-item-" + index}>
                        <td>{item.name}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : null}
            </div>
          </li>
        </ul>
      </div>
    </Fragment>
  );
};

export default withRouter(BenefitProfileFormPage);
