import React, { Fragment, useState, useRef } from "react";
import Context from "./../../../context";

const Accordion = ({ _id, title, description, paragraph2, open }) => {
  const { state, dispatch, action } = Context.useState();

  const accordionContent = useRef();

  return (
    <Fragment>
      <div
        className={`faq-list-item${open ? " open" : ""}`}
        onClick={() => action("website").toggleAccordion(_id)}
      >
        <button className="title">
          <h3>{title}</h3>
          <i className="icn icn-caret-down" aria-hidden="true"></i>
        </button>
        <div
          className="content"
          ref={accordionContent}
          style={{ maxHeight: `${open ? `${accordionContent && accordionContent.current ? accordionContent.current.scrollHeight : '0'}px` : "0px"}` }}
        >
          <p style={{ fontSize: "13px" }}>{description}</p>
          <p style={{ fontSize: "13px" }}>{paragraph2}</p>
        </div>
      </div>
    </Fragment>
  );
};

export default Accordion;
