import React, { Fragment } from "react"
import moment from "moment";
import Datetime from "react-datetime";

const DateTimePicker = ({ name, value, rawValue, options, masker, onChange, ...props }) => {

  const [selectedDate, setSelectedDate] = React.useState(null);
  const [selectedTime, setSelectedTime] = React.useState(null);

  const isValidDate = date => typeof date.includes != "function" ? true : date.includes("Invalid date") ? true : false;

  const updateDate = (date) => {
    setSelectedDate(moment(date, "DD/MM/YYYY"));
    updateDateTimeValue(moment(date, "DD/MM/YYYY"), selectedTime)
  }

  const updateTime = (time) => {
    setSelectedTime(moment(time, "HH:mm"));
    updateDateTimeValue(selectedDate, moment(time, "HH:mm"))
  }

  const onBlurDate = date => {
    if (!date) {
      setSelectedDate("")
      updateDateTimeValue("", selectedTime)
    } else if (date && moment(date, "DD/MM/YYYY").isValid()) {
      updateDate(date)
    }
  };

  const onBlurTime = time => {
    if (!time) {
      setSelectedTime("");
      updateDateTimeValue(selectedDate, "")
    } else if (time && moment(time, "HH:mm").isValid()) {
      updateTime(time)
    }
  };

  const onChangeDate = date => {
    return isValidDate(date) ? updateDate(date) : setSelectedDate("");
  };

  const onChangeTime = time => {
    return isValidDate(time) ? updateTime(time) : setSelectedTime("");
  }

  const updateDateTimeValue = (date, time) => {
    const newValue = moment(
      moment(date).format("DD/MM/YYYY") + " " + moment(time).format("HH:mm"),
      "DD/MM/YYYY HH:mm"
    )
    onChange(newValue)
  }

  React.useEffect(() => {
    if (value !== selectedDate && value !== selectedTime) {
      setSelectedDate(value)
      setSelectedTime(value)
    }
  }, [value])

  return (
    <div key={name} className="uk-flex">
      <Datetime key={`${name}-date`} value={selectedDate} locale="pt-br" onBlur={onBlurDate} {...props} onChange={onChangeDate} timeFormat={false} />
      {
        props.timeFormat ? 
          <Datetime key={`${name}-time`} value={selectedTime} locale="pt-br" onBlur={onBlurTime} {...props} onChange={onChangeTime} dateFormat={false} />
          : null
      }
    </div>
  )
}

export default DateTimePicker
