import React, { useCallback } from "react";
import ReactLoader from "./loader/Loader.react";
import Styled, { css, keyframes } from "styled-components";

import Context from "@context";

const fadeOut = keyframes`
  0% {
    opacity: 1;
    visibility: visible;
    z-index: 9999;
  }

  100% {
    opacity: 0;
    visibility: hidden;
    z-index: -1;
  }
`;

const animationFadeOut = css`
  opacity: 0;
  z-index: -1;
  animation: ${fadeOut} 0.5s linear;
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
    visibility: hidden;
    z-index: -1;
  }

  100% {
    opacity: 1;
    visibility: visible;
    z-index: 9999;
  }
`;

const animationFadeIn = css`
  opacity: 1;
  z-index: 9999;
  animation: ${fadeIn} 0.5s linear;
`;

const LoaderContainer = Styled.div`
  z-index: 9999;
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: center;
  ${props => (props.isFadeOut ? animationFadeOut : null)}
`;

const FadeInDiv = Styled.div`
  ${animationFadeIn}
`;

const Loader = ({ isFadeOut, ...props }) => {
  return (
    <LoaderContainer isFadeOut={isFadeOut} className="uk-overlay uk-position-cover">
      <FadeInDiv>
        <ReactLoader {...props} className="uk-position-fixed uk-position-center" />
      </FadeInDiv>
    </LoaderContainer>
  );
};

const LoaderWindowComponent = () => {
  const { state, action } = Context.useState();

  return (
    <Loader
      type="doubleRing"
      height="200"
      width="200"
      isFadeOut={state.loader.list.window.length == 0}
      color1="#1a65bb"
      color2="#03aeff"
    />
  );
};

export default LoaderWindowComponent;
