import React, { Fragment } from "react";

const Notification = ({ group, state, action, ...props }) => {
  if (!state.notification.list[group] || state.notification.list[group].length == 0) return null;

  return (
    <Fragment>
      {state.notification.list[group].map((item, iIndex) => (
        <div key={"notification-item-" + iIndex} className={`ntf ntf-${item.type} `}>
          <h3 className="ntf-title">{item.title}</h3>
          {item.message ? <p className="ntf-content">{item.message}</p> : null}
          <button
            onClick={e => action("notification").remove(group, iIndex)}
            data-uk-close
          ><i className="icn icn-close" aria-hidden="true"></i></button>
        </div>
      ))}
    </Fragment>
  );
};

export default Notification;
