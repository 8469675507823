import React, { Fragment } from "react";

import Access from "@cthulhi/comp-accs";

import permissions from "@perm";

const ParceirosGroup = ({ path, options = {} }) => {
  return (
    <Access.RestrictComponent
      onDeny={() => null}
      onLoading={() => null}
      onAccess={[
        permissions.branch.viewAny,
        permissions.branch.createAny,
        permissions.business.viewAny,
        permissions.business.createAny,
        permissions.category.viewAny,
        permissions.category.editAny,
        permissions.discount.viewAny,
        permissions.discount.editAny
      ]}
    >
      <li>
        <span className="menu-title uk-margin-top">Parceiros</span>
      </li>
      {/* <li>
        <Access.RestrictNavLink { ...options }
          onAccess={[permissions.business.viewAny]}
          onDeny={() => null}
          to={`${path}/business`}
          className="menu-link"
          activeClassName="active"
        >
          <i className="icon icon-bullet" aria-hidden="true"></i>
          <span>Listar parceiros</span>
        </Access.RestrictNavLink>
        <Access.RestrictNavLink { ...options }
          onAccess={[permissions.business.createAny]}
          onDeny={() => null}
          to={`${path}/business/create`}
          className="menu-link"
          activeClassName="active"
          data-uk-tooltip="title: Adicionar Parceiro; pos: right"
        >
          <i className="icon icon-plus-circle" aria-hidden="true"></i>
        </Access.RestrictNavLink>
      </li> */}
      {/* <li>
        <Access.RestrictNavLink { ...options }
          onAccess={[permissions.branch.viewAny]}
          onDeny={() => null}
          to={`${path}/branch`}
          className="menu-link"
          activeClassName="active"
        >
          <i className="icon icon-bullet" aria-hidden="true"></i>
          <span>Listar unidades</span>
        </Access.RestrictNavLink>
        <Access.RestrictNavLink { ...options }
          onAccess={[permissions.branch.createAny]}
          onDeny={() => null}
          to={`${path}/branch/create`}
          className="menu-link"
          activeClassName="active"
          data-uk-tooltip="title: Adicionar Unidade; pos: right"
        >
          <i className="icon icon-plus-circle" aria-hidden="true"></i>
        </Access.RestrictNavLink>
      </li> */}
      
      <li>
        <Access.RestrictNavLink { ...options }
          onAccess={[
            permissions.category.viewAny,
            permissions.category.editAny
          ]}
          onDeny={() => null}
          to={`${path}/category`}
          className="menu-link"
          activeClassName="active"
        >
          <i className="icon icon-bullet" aria-hidden="true"></i>
          <span>Listar categorias</span>
        </Access.RestrictNavLink>
        <Access.RestrictNavLink { ...options }
          onAccess={[permissions.category.editAny]}
          onDeny={() => null}
          to={`${path}/category/create`}
          className="menu-link"
          activeClassName="active"
          data-uk-tooltip="title: Adicionar Categoria; pos: right"
        >
          <i className="icon icon-plus-circle" aria-hidden="true"></i>
        </Access.RestrictNavLink>

      </li>

      <li>
        <Access.RestrictNavLink { ...options }
          onAccess={[
            permissions.discount.viewAny,
            permissions.discount.editAny
          ]}
          onDeny={() => null}
          to={`${path}/discount/partner`}
          className="menu-link"
          activeClassName="active"
          >
            <i className="icon icon-bullet" aria-hidden="true"></i>
            <span>Descontos em Parceiros</span>
          </Access.RestrictNavLink>
          <Access.RestrictNavLink { ...options }
            onAccess={[permissions.discount.editAny]}
            onDeny={() => null}
            to={`${path}/discount/partner/create`}
            className="menu-link"
            activeClassName="active"
            data-uk-tooltip="title: Adicionar Desconto; pos: right">

            <i className="icon icon-plus-circle" aria-hidden="true"></i>
        </Access.RestrictNavLink>
      </li>

      <li>
          <Access.RestrictNavLink { ...options }
            onAccess={[
              permissions.discount.viewAny,
              permissions.discount.editAny
            ]}
            onDeny={() => null}
            to={`${path}/discount/event`}
            className="menu-link"
            activeClassName="active"
            >
              <i className="icon icon-bullet" aria-hidden="true"></i>
              <span>Descontos em Eventos</span>
            </Access.RestrictNavLink>
            <Access.RestrictNavLink { ...options }
              onAccess={[permissions.discount.editAny]}
              onDeny={() => null}
              to={`${path}/discount/event/create`}
              className="menu-link"
              activeClassName="active"
              data-uk-tooltip="title: Adicionar Desconto; pos: right">

              <i className="icon icon-plus-circle" aria-hidden="true"></i>
          </Access.RestrictNavLink>
        </li>
        <li>
          <Access.RestrictNavLink { ...options }
            onAccess={[
              permissions.event.viewAny,
              permissions.event.editAny
            ]}
            onDeny={() => null}
            to={`${path}/partner`}
            className="menu-link"
            activeClassName="active"
            >
              <i className="icon icon-bullet" aria-hidden="true"></i>
              <span>Parceiros</span>
            </Access.RestrictNavLink>
            <Access.RestrictNavLink { ...options }
              onAccess={[permissions.event.editAny]}
              onDeny={() => null}
              to={`${path}/partner/create`}
              className="menu-link"
              activeClassName="active"
              data-uk-tooltip="title: Adicionar Desconto; pos: right">

              <i className="icon icon-plus-circle" aria-hidden="true"></i>
          </Access.RestrictNavLink>
        </li>
        <li>
          <Access.RestrictNavLink { ...options }
            onAccess={[
              permissions.event.viewAny,
              permissions.event.editAny
            ]}
            onDeny={() => null}
            to={`${path}/event`}
            className="menu-link"
            activeClassName="active"
            >
              <i className="icon icon-bullet" aria-hidden="true"></i>
              <span>Eventos</span>
            </Access.RestrictNavLink>
            <Access.RestrictNavLink { ...options }
              onAccess={[permissions.event.editAny]}
              onDeny={() => null}
              to={`${path}/event/create`}
              className="menu-link"
              activeClassName="active"
              data-uk-tooltip="title: Adicionar Desconto; pos: right">

              <i className="icon icon-plus-circle" aria-hidden="true"></i>
          </Access.RestrictNavLink>
        </li>
    </Access.RestrictComponent>
  );
};

export default ParceirosGroup;
