export default {
  ACT_SET_STATUS: "ACT_USR_VIP_STATUS",

  STS_IDLE: "STS_USR_VIP_IDLE",

  STS_FIND_REQUEST: "STS_USR_VIP_FIND_REQUEST",
  STS_FIND_RESPONSE: "STS_USR_VIP_FIND_RESPONSE",
  STS_FIND_SUCCESS: "STS_USR_VIP_FIND_SUCCESS",
  STS_FIND_ERROR: "STS_USR_VIP_FIND_ERROR",

  STS_CREATE_REQUEST: "STS_USR_VIP_CREATE_REQUEST",
  STS_CREATE_RESPONSE: "STS_USR_VIP_CREATE_RESPONSE",
  STS_CREATE_SUCCESS: "STS_USR_VIP_CREATE_SUCCESS",
  STS_CREATE_ERROR: "STS_USR_VIP_CREATE_ERROR",

  STS_UPDATE_REQUEST: "STS_USR_VIP_UPDATE_REQUEST",
  STS_UPDATE_RESPONSE: "STS_USR_VIP_UPDATE_RESPONSE",
  STS_UPDATE_SUCCESS: "STS_USR_VIP_UPDATE_SUCCESS",
  STS_UPDATE_ERROR: "STS_USR_VIP_UPDATE_ERROR",

  STS_DELETE_REQUEST: "STS_USR_VIP_DELETE_REQUEST",
  STS_DELETE_RESPONSE: "STS_USR_VIP_DELETE_RESPONSE",
  STS_DELETE_SUCCESS: "STS_USR_VIP_DELETE_SUCCESS",
  STS_DELETE_ERROR: "STS_USR_VIP_DELETE_ERROR"
};
