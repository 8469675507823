import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import Access from "@cthulhi/comp-accs";
import Context from "@context";
import { useFields, useForms } from "@cthulhi/comp-form";

import { schema as userVipFormSchema } from "@schm/userVip/create.form.schema.js";

import {
  events as userVipFormEvents,
  values as userVipFormValues,
  actions as userVipFormActions
} from "./components/UserVipForm.config";

import { FieldLabelWrapper } from "../../../wrappers/FormWrappers.react";

import Notification from "@cthulhi/comp-noty";
import NotificationComponent from "../../../utils/Notification.react";

import LoaderComponent from "../../../utils/LoaderCustomComponent.react";

const UserVipPage = ({ match, parentPath, history, ...props }) => {
  const { state: accState, action: accAction } = Access.useState();
  const { action: ctxAction, state: ctxtState } = Context.useState();
  const { state: ntfState, action: ntfAction } = Notification.useState();
  const [userVipSchema, setUserVipSchema] = useState(userVipFormSchema);
  const [validation, setValidation] = useState({})

  const { form, fields, values, updater, cleaner } = useFields({
    schema: userVipSchema,
    events: userVipFormEvents({ ctxAction }),
    values: userVipFormValues({
      ctxAction,
      token: `Bearer ${accState.access.session.context.token}`
    })
  });

  const { find, update, create } = useForms(
    userVipFormActions({
      ctxAction,
      ntfAction,
      updater,
      history,
      path: parentPath,
      token: `Bearer ${accState.access.session.context.token}`
    })
  );

  const userVipFormSubmit = () => {
    let vs = {};

    Object.entries(values).map(([key, value]) => {
      const hasValue = typeof value.value != "undefined" && value.value !== "";
      const hasRawValue = typeof value.rawValue != "undefined" && value.rawValue !== "";

      vs[key] = hasRawValue ? value.rawValue : hasValue ? value.value : value.defaultValue;
    });

    const validate = action => {
      action.then(() => {}).catch(setValidation)
    }

    match.params.id ? validate(update(vs)) : validate(create(vs));
  };

  useEffect(() => {
    if (match.params.id) {
      find({ filters: { _id: match.params.id } });
    }

    return () => {
      cleaner();
    };
  }, [match.params.id]);

  return (
    <Fragment>
      <h2 className="title">{match.params.id ? "Editar beneficiado" : "Novo beneficiado"}</h2>
      <div className="uk-margin-top">
        <Notification.NotificationWrapper group="userVipForm" component={NotificationComponent} />
        <div className="uk-card uk-card-default uk-card-body uk-padding-small">
          <LoaderComponent from="userVipForm" />
          <form className="uk-grid-small" data-uk-grid>
            <FieldLabelWrapper name="name" fields={fields} values={values} validation={validation.name}/>
            <FieldLabelWrapper name="email" fields={fields} values={values} validation={validation.email}/>
            <FieldLabelWrapper name="cpf" fields={fields} values={values} validation={validation.cpf}/>
            <FieldLabelWrapper name="expiracy" fields={fields} values={values} validation={validation.expiracy}/>
            <FieldLabelWrapper name="perfil" fields={fields} values={values} validation={validation.perfil}/>
            <FieldLabelWrapper name="status" fields={fields} values={values} validation={validation.status}/>
          </form>
        </div>

        <div className="uk-margin-top">
          <button
            onClick={userVipFormSubmit}
            className="uk-button uk-button-large uk-button-secondary"
            style={{ float: "right" }}
            disabled={
              typeof ctxtState.loader.list["userVipForm"] != "undefined" &&
              ctxtState.loader.list["userVipForm"].length > 0
            }
          >
            {match.params.id ? "Atualizar" : "Salvar"}
          </button>
        </div>
      </div>
    </Fragment>
  );
};

// @ts-ignore
export default withRouter(UserVipPage);
