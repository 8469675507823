const view = {
  group: {
    title: "Categorias",
    slug: "category"
  },
  slug: "category-view",
  title: "Ver categorias",
  description: "Ver as próprias categorias",
  list: [
    "prn::1:category-view-roles:category-view:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

const viewAny = {
  group: {
    title: "Categorias",
    slug: "category"
  },
  slug: "category-view-any",
  title: "Ver qualquer categoria",
  description: "Ver todas as categorias",
  list: [
    "prn::1:category-view-any-roles:category-view-any:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

const edit = {
  group: {
    title: "Categorias",
    slug: "category"
  },
  slug: "category-edit",
  title: "Editar categorias",
  description: "Editar as próprias categorias",
  list: [
    "prn::1:category-edit-roles:category-edit:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

const editAny = {
  group: {
    title: "Categorias",
    slug: "category"
  },
  slug: "category-edit-any",
  title: "Editar qualquer categoria",
  description: "Editar todas as categorias",
  list: [
    "prn::1:category-edit-any-roles:category-edit-any:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

const create = {
  group: {
    title: "Categorias",
    slug: "category"
  },
  slug: "category-create",
  title: "Criar categorias",
  description: "Criar as próprias categorias"
}

const createAny = {
  group: {
    title: "Categorias",
    slug: "category"
  },
  slug: "category-create-any",
  title: "Criar qualquer categoria",
  description: "Criar todas as categorias"
}

const remove = {
  group: {
    title: "Categorias",
    slug: "category"
  },
  slug: "category-remove",
  title: "Remover categorias",
  description: "Remover as próprias categorias"
}

const removeAny = {
  group: {
    title: "Categorias",
    slug: "category"
  },
  slug: "category-remove-any",
  title: "Remover qualquer categoria",
  description: "Remover todas as categorias"
}

module.exports = {
  view,
  viewAny,
  edit,
  editAny,
  create,
  createAny,
  remove,
  removeAny
}