const view = {
  group: {
    title: "Benefícios",
    slug: "benefit"
  },
  slug: "benefit-view",
  title: "Ver benefícios",
  description: "Ver os próprios benefícios",
  list: [
    "prn::1:benefit-view-roles:benefit-view:allow:lcnt:schema.user.roles:custom.static.manager"
  ]
}

const viewAny = {
  group: {
    title: "Benefícios",
    slug: "benefit"
  },
  slug: "benefit-view-any",
  title: "Ver qualquer benefício",
  description: "Ver todos os benefícios",
  list: [
    "prn::1:benefit-view-any-roles:benefit-view-any:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

const edit = {
  group: {
    title: "Benefícios",
    slug: "benefit"
  },
  slug: "benefit-edit",
  title: "Editar benefícios",
  description: "Editar os próprios benefícios",
  list: [
    "prn::1:benefit-edit-roles:benefit-edit:allow:lcnt:schema.user.roles:custom.static.manager"
  ]
}

const editAny = {
  group: {
    title: "Benefícios",
    slug: "benefit"
  },
  slug: "benefit-edit-any",
  title: "Editar qualquer benefício",
  description: "Editar todos os benefícios",
  list: [
    "prn::1:benefit-edit-any-roles:benefit-edit-any:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

const remove = {
  group: {
    title: "Benefícios",
    slug: "benefit"
  },
  slug: "benefit-remove",
  title: "Remover benefícios",
  description: "Remover os próprios benefícios"
}

const removeAny = {
  group: {
    title: "Benefícios",
    slug: "benefit"
  },
  slug: "benefit-remove-any",
  title: "Remover qualquer benefício",
  description: "Remover todos os benefícios"
}

const create = {
  group: {
    title: "Benefícios",
    slug: "benefit"
  },
  slug: "benefit-create",
  title: "Criar benefícios",
  description: "Criar os próprios benefícios"
}

const createAny = {
  group: {
    title: "Benefícios",
    slug: "benefit"
  },
  slug: "benefit-create-any",
  title: "Criar qualquer benefício",
  description: "Criar todos os benefícios"
}

module.exports = {
  view,
  viewAny,
  edit,
  editAny,
  remove,
  removeAny,
  create,
  createAny
}