const view = {
  group: {
    title: "Faq",
    slug: "faq"
  },
  slug: "faq-view",
  title: "Ver faqs",
  description: "Ver os próprios faqs",
  list: [
    "prn::1:faq-view-roles:faq-view:allow:lcnt:schema.user.roles:custom.static.manager,pos"
  ]
}

const viewAny = { 
  group: {
    title: "Faq",
    slug: "faq"
  },
  slug: "faq-view-any",
  title: "Ver qualquer faq",
  description: "Ver todos os faqs",
  list: [
    "prn::1:faq-view-any-roles:faq-view-any:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

const edit = {
  group: {
    title: "Faq",
    slug: "faq"
  },
  slug: "faq-edit",
  title: "Editar faqs",
  description: "Editar os próprios faqs"
}

const editAny = {
  group: {
    title: "Faq",
    slug: "faq"
  },
  slug: "faq-edit-any",
  title: "Editar qualquer faq",
  description: "Editar todos os faqs",
  list: [
    "prn::1:faq-edit-any-roles:faq-edit-any:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

const create = {
  group: {
    title: "Faq",
    slug: "faq"
  },
  slug: "faq-create",
  title: "Criar faqs",
  description: "Criar os próprios faqs"
}

const createAny = {
  group: {
    title: "Faq",
    slug: "faq"
  },
  slug: "faq-create-any",
  title: "Criar qualquer faq",
  description: "Criar todos os faqs"
}

const remove = {
  group: {
    title: "Faq",
    slug: "faq"
  },
  slug: "faq-remove",
  title: "Remover faqs",
  description: "Remover os próprios faqs"
}

const removeAny = {
  group: {
    title: "Faq",
    slug: "faq"
  },
  slug: "faq-remove-any",
  title: "Remover qualquer faq",
  description: "Remover todos os faqs"
}

module.exports = {
  view,
  viewAny,
  edit,
  editAny,
  create,
  createAny,
  remove,
  removeAny
}