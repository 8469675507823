import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import Access from "@cthulhi/comp-accs";
import Context from "@context";
import moment from "moment";
import LoaderComponent from "../../../utils/LoaderCustomComponent.react";

const RedemptionViewPage = ({ match, parentPath, history, ...props }) => {
  const { state: accState, action: accAction } = Access.useState();
  const { action: ctxAction, state: ctxtState } = Context.useState();

  const [voucher, setVoucher] = React.useState({
    qrcode: "",
    shopValue: "",
    status: "",
    createdAt: "",
    consumer: {},
    benefit: {},
    branch: {},
    redemption: {}
  });

  useEffect(() => {
    if (match.params.id) {
      ctxAction("voucher")
        .getList(
          { filters: {_id: match.params.id} },
          { limit: 1, skip: 0 },
          `Bearer ${accState.access.session.context.token}`
        ).then(res => {
          setVoucher(res.result.values[0]);
        })
        .catch(e => console.log(e));
    }
  }, [match.params.id]);

  const CreateField = ({ label, name, value, divClass = "uk-width-1-2@m" }) => {
    return (
      <div className={"field-wrapper uk-grid-margin uk-first-column " + divClass}>
        <label className="uk-form-label show">{label}</label>
        <input
          name={name}
          id={name}
          className="uk-input"
          placeholder={label}
          type="textfield"
          value={value}
          disabled={true}
        />
      </div>
    );
  };

  const statusToText = value => {
    if (value == "pending") return "Não usado";
    if (value == "tovalidate") return "Usado, não avaliado";
    if (value == "used") return "Usado e avaliado";
    if (value == "removedByUser") return "Removido pelo usuário";
    return "";
  };

  return (
    <Fragment>
      <h2 className="title">Visualizar voucher</h2>
      <LoaderComponent from="voucherView" />
      <div className="uk-margin-top uk-margin-bottom">
        <ul data-uk-accordion="multiple: true">
          <li className="uk-open">
            <a className="title title-lead-small title-accordion uk-accordion-title">
              Dados do usuário
            </a>
            <div className="uk-accordion-content">
              <div className="uk-card uk-card-default uk-card-body uk-padding-small">
                <div className="uk-grid-small" data-uk-grid>
                  <CreateField
                    label={"Usuário"}
                    name={"consumer"}
                    value={
                      voucher && voucher.consumer && voucher.consumer.given_name
                        ? `${voucher.consumer.given_name} ${voucher.consumer.last_name}`
                        : ""
                    }
                  />
                  <CreateField
                    label={"CPF usuário"}
                    name={"consumer-cpf"}
                    value={voucher && voucher.consumer ? voucher.consumer.document : ""}
                  />
                </div>
              </div>
            </div>
          </li>
          <li className="uk-open">
            <a className="title title-lead-small title-accordion uk-accordion-title">
              Dados do voucher
            </a>
            <div className="uk-accordion-content">
              <div className="uk-card uk-card-default uk-card-body uk-padding-small">
                <div className="uk-grid-small" data-uk-grid>
                  <CreateField
                    label={"QR Code"}
                    name={"qrcode"}
                    value={voucher ? voucher.qrcode : ""}
                    divClass="uk-width-1-3@m"
                  />
                  <CreateField
                    label={"Valor da compra"}
                    name={"shopValue"}
                    value={voucher ? voucher.shopValue : ""}
                    divClass="uk-width-1-3@m"
                  />
                  <CreateField
                    label={"Status"}
                    name={"status"}
                    value={statusToText(voucher.status)}
                    divClass="uk-width-1-3@m"
                  />

                  <CreateField
                    label={"Data Geração"}
                    name={"createdAt"}
                    value={moment(voucher.createdAt).format("DD / MM / YYYY HH:mm")}
                  />

                  <CreateField
                    label={"Data de Utilização"}
                    name={"usedAt"}
                    value={
                      voucher.redemption && voucher.redemption[0]
                        ? moment(voucher.redemption[0].createdAt).format("DD / MM / YYYY HH:mm")
                        : ""
                    }
                  />
                </div>
              </div>
            </div>
          </li>

          <li className="uk-open">
            <a className="title title-lead-small title-accordion uk-accordion-title">
              Dados do benefício
            </a>

            <div className="uk-accordion-content">
              <div className="uk-card uk-card-default uk-card-body uk-padding-small">
                <div className="uk-grid-small" data-uk-grid>
                  <CreateField
                    label={"Benefício"}
                    name={"benefit-title"}
                    value={
                      voucher && voucher.benefit && voucher.benefit[0]
                        ? voucher.benefit[0].title
                        : ""
                    }
                  />

                  <CreateField
                    label={"Desconto do benefício"}
                    name={"benefit-discount"}
                    value={
                      voucher && voucher.benefit && voucher.benefit[0]
                        ? voucher.benefit[0].discount
                        : ""
                    }
                  />
                  <CreateField
                    label={"Data início do benefício"}
                    name={"benefit-shelfLifeStart"}
                    value={
                      voucher && voucher.benefit && voucher.benefit[0]
                        ? moment(voucher.benefit[0].shelfLifeStart).format("DD / MM / YYYY HH:mm")
                        : ""
                    }
                  />
                  <CreateField
                    label={"Data fim do benefício"}
                    name={"benefit-shelfLifeEnd"}
                    value={
                      voucher && voucher.benefit && voucher.benefit[0]
                        ? moment(voucher.benefit[0].shelfLifeEnd).format("DD / MM / YYYY HH:mm")
                        : ""
                    }
                  />
                </div>
              </div>
            </div>
          </li>

          <li className="uk-open">
            <a className="title title-lead-small title-accordion uk-accordion-title">
              Dados do parceiro
            </a>

            <div className="uk-accordion-content">
              <div className="uk-card uk-card-default uk-card-body uk-padding-small">
                <div className="uk-grid-small" data-uk-grid>
                  <CreateField
                    label={"Parceiro"}
                    name={"business-title"}
                    value={
                      voucher &&
                      voucher.benefit &&
                      voucher.benefit[0] &&
                      voucher.benefit[0].business[0]
                        ? voucher.benefit[0].business[0].name
                        : ""
                    }
                  />
                  <CreateField
                    label={"Parceiro CPNJ"}
                    name={"business-cnpj"}
                    value={
                      voucher &&
                      voucher.benefit &&
                      voucher.benefit[0] &&
                      voucher.benefit[0].business[0]
                        ? voucher.benefit[0].business[0].cnpj
                        : ""
                    }
                  />
                  <CreateField
                    label={"Unidade"}
                    name={"branch-title"}
                    value={
                      voucher && voucher.branch && voucher.branch[0] ? voucher.branch[0].name : ""
                    }
                  />
                  <CreateField
                    label={"Endereço da Unidade"}
                    name={"branch-address"}
                    value={
                      voucher && voucher.branch && voucher.branch[0] && voucher.branch[0].address
                        ? voucher.branch[0].address
                        : ""
                    }
                  />
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </Fragment>
  );
};

// @ts-ignore
export default withRouter(RedemptionViewPage);
