const optionLoading = { value: "", label: "Carregando...", disabled: true };
const optionSelectCity = { value: "", label: "Selecione uma cidade..." };
const optionSelectState = { value: "", label: "Selecione um estado..." };
const optionSelectStateToCity = { value: "", label: "Aguardando seleção do estado..." };
const optionSelectType = { value: "", label: "Selecione um tipo..." };
const optionSelectStage = { value: "", label: "Selecione um status...", disabled: true };

const events = ({ ctxAction, ...props }) => {

  const updateLatLong = (vals, update) => {

    if(vals) {
      const state = vals.state.value && vals.state.options ? vals.state.options.filter(s => s.key == vals.state.value) : []
      const city = vals.city.value && vals.city.options ? vals.city.options.filter(s => s.key == vals.city.value) : []
      const list = [
        vals.address.value,
        vals.number.value,
        vals.complement.value ? vals.complement.value : " ",
        vals.district.value,
        state.length > 0 ? state[0].label : "",
        city.length > 0 ? city[0].label : "",
        vals.zipcode.value
      ]
  
      if(list.filter(v => !!v).length > 0) {
  
        ctxAction("address").findLatLngByAddress(list.join(", "), vals.googlemaps.value)
          .then(coords => {
            update("latitude", { value: coords.latitude }, coords.latitude)
            update("longitude", { value: coords.longitude }, coords.longitude)
            update("googlemaps", { value: coords }, coords)
          })
      }
    }

    return null
  }

  return {
    zipcode: {
      onChange: (value, rawValue, update) => {
        if (value.length == 10) {
          ctxAction("address")
            .findAddressByZipCode(value.replace(/\./g, "").replace(/\-/g, ""))
            .then(resAddress => {
              update("district", { value: resAddress.district }, resAddress.district);
              update("address", { value: resAddress.address }, resAddress.address);

              update("state", { disabled: true, options: [optionLoading] });
              ctxAction("address")
                .findState(resAddress.uf)
                .then(resState => {

                  if(resState) {
                    const cityId = resState.filter(u => {
                      return u.selected;
                    })[0].value;
  
                    update(
                      "state",
                      { disabled: false, value: cityId, options: [optionSelectState, ...resState] },
                      cityId
                    );
  
                    update("city", { disabled: true, options: [optionLoading] });
                    ctxAction("address")
                      .findCity(cityId)
                      .then(resCity => {
                        update(
                          "city",
                          {
                            disabled: false,
                            value: resAddress.city,
                            options: [optionSelectCity, ...resCity]
                          },
                          resAddress.city
                        );
                      });
                  }

                });
            });
        }

        return update("zipcode", { value }, rawValue);
      }
    },
    city: {
      onChangeFromState: (field, rawValue, update, get) => {
        const stateValue = field.value ? field.value : get("state");
        update("city", { value: "", options: [{ value: "", label: "Carregando..." }] });
        ctxAction("address")
          .findCity(stateValue)
          .then(res => {
            update("city", { value: get("city") ? get("city") : "", disabled: false, options: [optionSelectCity, ...res] })
          });
      }
    },
    googlemaps: {
      onChangeFromAddress: (field, rawValue, update, get, vals) => {
        updateLatLong(vals, update)
      },
      onChangeFromNumber: (field, rawValue, update, get, vals) => {
        updateLatLong(vals, update)
      },
      onChangeFromDistrict: (field, rawValue, update, get, vals) => {
        updateLatLong(vals, update)
      },
      onChangeFromState: (field, rawValue, update, get, vals) => {
        updateLatLong(vals, update)
      },
      onChangeFromCity: (field, rawValue, update, get, vals) => {
        updateLatLong(vals, update)
      },
      onChangeFromZipcode: (field, rawValue, update, get, vals) => {
        updateLatLong(vals, update)
      },
      onChange: (value, rawValue, update) => {
        update("latitude", { value: value.latitude }, value.latitude)
        update("longitude", { value: value.longitude }, value.longitude)
        update("googlemaps", { value }, value)
      }
    }
  };
};

const values = ({ ctxAction, ...props }) => {
  let initValues = {
    name: {
      className: "uk-input",
      placeholder: "Nome",
      value: "",
      label: {
        text: "Nome",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      }
    },
    stage: {
      className: "uk-select",
      label: {
        text: "Status",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      },
      value: "toapproval",
      disabled: true,
      options: [
        optionSelectStage,
        { value: "toapproval", label: "À aprovar" },
        { value: "onapproval", label: "Em aprovação" },
        { value: "approved", label: "Aprovado" },
        { value: "denied", label: "Negado" }
      ]
    },
    type: {
      className: "uk-select",
      label: {
        text: "Tipo",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-3@m"
      },
      value: "",
      options: [
        optionSelectType,
        { value: "fisico", label: "Físico" },
        { value: "virtual", label: "Virtual" }
      ]
    },
    phone: {
      className: "uk-input",
      placeholder: "Telefone",
      masks: ["(99) 9999-9999", "(99) 99999-9999", "+99 (99) 9999-9999", "+99 (99) 99999-9999"],
      value: "",
      label: {
        text: "Telefone",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-3@m"
      },
      options: [
        optionSelectType,
        { value: "virtual", label: "Virtual" },
        { value: "fisico", label: "Físico" }
      ]
    },
    status: {
      className: "uk-checkbox",
      value: true,
      label: {
        position: "below",
        text: "Ativo",
        className: "uk-form-label uk-margin-small-left"
      },
      wrapper: {
        className: "field-checkbox uk-width-1-3@m"
      }
    },
    site: {
      className: "uk-input",
      placeholder: "Site",
      value: "",
      label: {
        text: "Site",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-1"
      }
    },
    zipcode: {
      className: "uk-input",
      placeholder: "CEP",
      masks: ["99.999-999"],
      value: "",
      label: {
        text: "CEP",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-3@m"
      }
    },
    address: {
      className: "uk-input",
      placeholder: "Endereço",
      value: "",
      label: {
        text: "Endereço",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-2-3@m"
      }
    },
    district: {
      className: "uk-input",
      placeholder: "Bairro",
      value: "",
      label: {
        text: "Bairro",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-2-5@m"
      }
    },
    number: {
      className: "uk-input",
      placeholder: "Número",
      value: "",
      label: {
        text: "Número",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-5@m"
      }
    },
    complement: {
      className: "uk-input",
      placeholder: "Complemento",
      value: "",
      label: {
        text: "Complemento",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-2-5@m"
      }
    },
    state: {
      className: "uk-select",
      label: {
        text: "Estado",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      },
      value: "",
      options: update => {
        update("state", { value: "", disabled: true, options: [optionLoading] });
        ctxAction("address")
          .findState()
          .then(res => update("state", { disabled: false, options: [optionSelectState, ...res] }));
      }
    },
    city: {
      className: "uk-select",
      label: {
        text: "Cidade",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      },
      value: "",
      disabled: true,
      options: [optionSelectStateToCity]
    },
    latitude: {
      className: "uk-input",
      placeholder: "Latitude",
      disabled: true,
      value: "",
      label: {
        text: "Latitude",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      }
    },
    longitude: {
      className: "uk-input",
      placeholder: "Longitude",
      disabled: true,
      value: "",
      label: {
        text: "Longitude",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      }
    },
    googlemaps: {
      className: "uk-input",
      label: {
        text: "Mapa",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-1"
      },
      value: {
        latitude: -27.59292496408682, 
        longitude: -48.542876969970735
      }
    }
  };

  return initValues;
};

const actions = ({ token, ctxAction, ntfAction, updater, history, path, ...props }) => {
  return {
    entity: {
      name: "branch",
      token: token,
      form: ctxAction("form")
    },
    actions: {
      entity: ctxAction,
      notification: ntfAction,
      updater: updater
    },
    find: {
      success: {
        name: "BranchFoundResult",
        action: { notify: false, redirect: false }
      },
      error: {
        name: "BranchErrorResult",
        message: "Houve um erro ao buscar a unidade",
        action: { notify: "branchListPage", redirect: () => history.push(path) }
      }
    },
    create: {
      success: {
        name: "BranchCreateResult",
        message: "Unidade adicionada com sucesso",
        action: { notify: "branchListPage", redirect: () => history.push(path) }
      },
      error: {
        name: "BranchErrorResult",
        message: "Houve um erro ao adicionar a unidade",
        action: { notify: "branchForm", redirect: false }
      }
    },
    update: {
      success: {
        name: "BranchUpdateResult",
        message: "Unidade atualizada com sucesso",
        action: { notify: "branchListPage", redirect: () => history.push(path) }
      },
      error: {
        name: "BranchErrorResult",
        message: "Houve um erro ao atualizar a unidade",
        action: { notify: "branchForm", redirect: false }
      }
    }
  };
};

export { events, values, actions };
