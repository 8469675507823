import React, { Fragment } from "react";

import Access from "@cthulhi/comp-accs";

import permissions from "@perm";

const ComunicacaoGroup = ({ path, options = {} }) => {
  return (
    <Access.RestrictComponent
      onDeny={() => null}
      onLoading={() => null}
      onAccess={[
        permissions.benefit.viewAny,
        permissions.benefit.editAny,
        permissions.banner.view,
        permissions.banner.viewAny,
        permissions.banner.edit,
        permissions.banner.editAny,
        permissions.faq.viewAny,
        permissions.faq.editAny,
        permissions.content.viewAny,
        permissions.content.editAny
      ]}
    >
      <li>
        <span className="menu-title uk-margin-top">Comunicação</span>
      </li>
      <li>
        {/* <Access.RestrictNavLink
          {...options}
          onAccess={[permissions.benefit.viewAny]}
          onDeny={() => null}
          to={`${path}/benefit`}
          className="menu-link"
          activeClassName="active"
        >
          <i className="icon icon-bullet" aria-hidden="true"></i>
          <span>Listar benefícios</span>
        </Access.RestrictNavLink>
        <Access.RestrictNavLink
          {...options}
          onAccess={[permissions.benefit.create, permissions.benefit.createAny]}
          onDeny={() => null}
          to={`${path}/benefit/create`}
          className="menu-link"
          activeClassName="active"
          data-uk-tooltip="title: Adicionar Benefício; pos: right"
        >
          <i className="icon icon-plus-circle" aria-hidden="true"></i>
        </Access.RestrictNavLink> */}
      </li>
      <li>
        <Access.RestrictNavLink
          {...options}
          onAccess={[permissions.banner.viewAny]}
          onDeny={() => null}
          to={`${path}/banner`}
          className="menu-link"
          activeClassName="active"
        >
          <i className="icon icon-bullet" aria-hidden="true"></i>
          <span>Listar banners</span>
        </Access.RestrictNavLink>
        <Access.RestrictNavLink
          {...options}
          onAccess={[permissions.banner.create, permissions.banner.createAny]}
          onDeny={() => null}
          to={`${path}/banner/create`}
          className="menu-link"
          activeClassName="active"
          data-uk-tooltip="title: Adicionar Banner; pos: right"
        >
          <i className="icon icon-plus-circle" aria-hidden="true"></i>
        </Access.RestrictNavLink>
      </li>
      <li>
        <Access.RestrictNavLink
          {...options}
          onAccess={[permissions.content.viewAny, permissions.content.editAny]}
          onDeny={() => null}
          to={`${path}/content`}
          className="menu-link"
          activeClassName="active"
        >
          <i className="icon icon-bullet" aria-hidden="true"></i>
          <span>Listar conteúdos</span>
        </Access.RestrictNavLink>
      </li>
      <li>
        <Access.RestrictNavLink
          {...options}
          onAccess={[permissions.faq.viewAny, permissions.faq.editAny]}
          onDeny={() => null}
          to={`${path}/faq`}
          className="menu-link"
          activeClassName="active"
        >
          <i className="icon icon-bullet" aria-hidden="true"></i>
          <span>Listar FAQ</span>
        </Access.RestrictNavLink>
        <Access.RestrictNavLink
          {...options}
          onAccess={[permissions.faq.create, permissions.faq.createAny]}
          onDeny={() => null}
          to={`${path}/faq/create`}
          className="menu-link"
          activeClassName="active"
          data-uk-tooltip="title: Adicionar FAQ; pos: right"
        >
          <i className="icon icon-plus-circle" aria-hidden="true"></i>
        </Access.RestrictNavLink>
      </li>
    </Access.RestrictComponent>
  );
};

export default ComunicacaoGroup;
