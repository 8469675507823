import { schema, types } from '@cthulhi/pack-schm' 

const schm = schema('user', {
  createdAt: {
    type: types.datetime.type
  },
  updatedAt: {
    type: types.datetime.type
  },
  createdBy: {
    type: types.string.type
  },
  status: {
    type: types.boolean.type
  }
})

export {
  schm as schema
}