import React, { Fragment, useEffect, useState } from 'react'
import { Link, Switch, Route, withRouter, Redirect } from "react-router-dom"

import Access from "@cthulhi/comp-accs"

import CategoryFormPage from "./CategoryFormPage.react";
import CategoryListPage from "./CategoryListPage.react";

import permissions from "@perm"

const CategoryPage = ({ match, ...props }) => {
  return (
    <Switch>
      <Access.RestrictRoute
        key="category-list"
        onAccess={[
          permissions.category.viewAny,
          permissions.category.editAny
        ]} 
        parentPath={match.path}
        path={`${match.path}`} exact
        onAllow={CategoryListPage} 
      />
      <Access.RestrictRoute
        key="category-create"
        onAccess={[
          permissions.category.viewAny,
          permissions.category.editAny
        ]} 
        parentPath={match.path}
        path={`${match.path}/create`} 
        onAllow={CategoryFormPage} 
      />
      <Access.RestrictRoute
        key="category-edit"
        onAccess={[
          permissions.category.editAny,
          permissions.category.edit
        ]} 
        parentPath={match.path}
        path={`${match.path}/edit/:id`} 
        onAllow={CategoryFormPage} 
      />
    </Switch>
  )

} 

export default withRouter(CategoryPage)