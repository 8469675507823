import { schema, types } from '@cthulhi/pack-schm' 
import { schema as recoverVerifySchema } from './recoverVerify.schema'

const schm = schema('user', {
  ...recoverVerifySchema.schema.props,
  password: { 
    type: types.string.type,
    required: { contains: true }
  }
})

export {
  schm as schema
}