import { schema, types } from "@cthulhi/pack-schm";
import { schema as commonSchema } from "./common.schema";
import { types as fields } from "@cthulhi/comp-form";

const schm = schema("branch", {
  ...commonSchema.schema.props,
  name: {
    ...commonSchema.schema.props.name,
    field: fields.textfield.type,
  },
  type: {
    ...commonSchema.schema.props.type,
    field: fields.radio.type,
  },
  contact: {
    ...commonSchema.schema.props.contact,
    field: fields.textfield.type,
  },
  site: {
    type: types.string.type,
    field: fields.textfield.type,
    required: { contains: true }
  }
});

export { schm as schema };