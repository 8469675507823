import React, { useState, useEffect, useCallback, Fragment } from "react"

import moment from "moment"

import { useFields } from "./field"
import { types } from "./types"

import Input from "./fields/input.react"
import Select from "./fields/select.react"
import TextArea from "./fields/textarea.react"
import Checkbox from "./fields/checkbox.react"
import DateTime from "./fields/datetime.react"
import FileImageCrop from "./fields/fileimagecrop.react"
import FileImage from "./fields/fileimage.react"
import { GoogleMaps, GoogleMapsApiWrapper } from "./fields/googlemaps.react"
import InputSearch from "./fields/inputSearch.react"

const FieldLabelWrapperGroup = ({ filter = f => true, fields, values, ...props }) => {

  const [ fs, setFields ] = useState(Object.keys(fields))

  useEffect(() => {
    setFields(Object.keys(fields).filter(filter))
  }, [JSON.stringify(Object.keys(fields).filter(filter))])

  return (
    <Fragment>
      {fs.map(name => (
        <Wrapper name={name} f={fields[name]} { ...props } >
          <Label name={name} f={fields[name]} { ...props } >
            <Field name={name} f={fields[name]} v={values[name]} {...props} />
          </Label>
        </Wrapper>
      ))}
    </Fragment>
  )
}

const Wrapper = ({ name, fields = {}, values = {}, children, ...props }) => {

  if(!fields || !fields[name] && !props.f) return null

  const wrapper = props.v && props.v.wrapper ? 
    props.v.wrapper : 
    values[name] && values[name].wrapper ? 
    values[name].wrapper : { className: "field-wrapper" }

  let { ...prop } = wrapper

  return (
    <div { ...(prop ? prop : {}) } >
      {children}
    </div>
  )
}

const FieldLabelWrapper = ({ name, fields, values, ...props }) => {
  
  if(!fields || !fields[name] && !props.f) return null

  return (
    <Wrapper name={name} f={props.f ? props.f : fields[name]} v={props.v ? props.v : values[name]} { ...props }>
      <Label name={name} f={props.f ? props.f : fields[name]} v={props.v ? props.v : values[name]} { ...props } >
        <Field name={name} f={props.f ? props.f : fields[name]} v={props.v ? props.v : values[name]} {...props} />
      </Label>
    </Wrapper>
  )
}

const Label = ({ name, fields = {}, values={}, children, ...props}) => {
  
  if(!fields || !fields[name] && !props.f) return null

  const label = props.v && props.v.label ? 
    props.v.label : 
    values[name] && values[name].label ? 
    values[name].label : { className: "field-label", position: "above", text: name }

  let { position, text, ...prop } = label

  switch (position) {
    case "inner-above":
      return (
        <label {...(prop ? prop : {})} >
          {text}
          {children}
        </label>
      )
    case "inner-below":
      return (
        <label {...(prop ? prop : {})} >
          {text}
          {children}
        </label>
      )
    case "below":
      return(
        <Fragment>
          {children}
          <label {...(prop ? prop : {})} >{text}</label>
        </Fragment>
      )
  
    default:
      return (
        <Fragment>
          <label {...(prop ? prop : {})} >{text}</label>
          {children}
        </Fragment>
      )
  }
}

const FieldLabel = ({ name, fields, values, className, ...props }) => {
  
  if(!fields || !fields[name] && !props.f) return null

  return (
    <Label name={name} className={ className } f={props.f ? props.f : fields[name]}  { ...props } >
      <Field name={name} f={props.f ? props.f : fields[name]} v={props.v ? props.v : values[name]} {...props} />
    </Label>
  )
}

const Field = ({ name, fields = {}, values={}, f = undefined, v = undefined,  ...props }) => {

  if(!fields || !fields[name] && !f) return null

  const { value: val, options, label, wrapper, ...vals } = v ? v : values[name] ? values[name] : { defaultValue: "", options: [] }
  const { attrs: { type, field, ...attrs }, events, functs } = f ? f : fields[name]

  useEffect(() => {
    if(options && typeof options === "function") {
      options()
    }
  }, [options])

  switch (field) {
    case types.textfield.type:
    case types.number.type:
    case types.password.type:
      return <Input name={name} type={field} value={val} { ...vals } { ...attrs } { ...events } { ...functs } { ...props } />
    case types.datetime.type:
      return <DateTime name={name} type={field} value={val ? moment(val) : val } { ...vals } { ...attrs } { ...events } { ...functs } { ...props } />
    case types.checkbox.type:
      return <Checkbox name={name} type={field} value={val} { ...vals } { ...attrs } { ...events } { ...functs } { ...props } />
    case types.textarea.type:
      return <TextArea name={name} value={val} { ...vals } { ...attrs } { ...events } { ...functs } { ...props }  />
    case types.select.type:
      return <Select name={name} value={val} options={options} { ...vals } { ...attrs } { ...events } { ...functs } { ...props } />
    case types.fileimagecrop.type:
      return <FileImageCrop name={name} { ...vals } { ...attrs } { ...events } { ...functs } { ...props } />
    case types.fileimage.type:
      return <FileImage name={name} { ...vals } { ...attrs } { ...events } { ...functs } { ...props } />
    case types.googlemaps.type:
      return <GoogleMaps name={name} { ...vals } { ...attrs } { ...events } { ...functs } { ...props } />
    case types.inputSearch.type:
      return <InputSearch name={name} value={val} options={options} { ...vals } { ...attrs } { ...events } { ...functs } { ...props } />
  }

  return null
}

const useForms = ({ entity, actions, find, create, update, ...props }) => {

  const acts = { 
    find: (values, options) => {
      return entity.form.find({
        name: entity.name,
        action: "find",
        token: entity.token,
        actions,
        values,
        options,
        ...find
      })
    },
    create: (values, options) => {
      return entity.form.submit({
        name: entity.name,
        action: "create",
        token: entity.token,
        actions,
        values,
        options,
        ...create
      })
    },
    update: (values, options) => {
      return entity.form.submit({
        name: entity.name,
        action: "update",
        token: entity.token,
        actions,
        values,
        options,
        ...update
      })
    }
  }

  Object.entries(props).map(([key, action]) => {
    acts[key] = (values, options) => {
      return entity.form[action.action]({
        ...action,
        name: entity.name,
        action: key,
        token: entity.token,
        actions,
        values,
        options
      })
    } 
  })

  return acts
}

export {
  types,
  useFields,
  useForms,
  Input,
  Select,
  TextArea,
  Checkbox,
  DateTime,
  FileImageCrop,
  FileImage,
  Field,
  Label,
  Wrapper,
  FieldLabel,
  FieldLabelWrapper,
  FieldLabelWrapperGroup,
  GoogleMaps,
  GoogleMapsApiWrapper,
  InputSearch
}