import React, { Fragment, useState } from "react";
import { withRouter } from "react-router-dom";

import { List } from "./../../../wrappers/ListWrappers.react";

import { useFilter } from "@cthulhi/comp-list"

import Access from "@cthulhi/comp-accs";
import Context from "@context";

import Notification from "@cthulhi/comp-noty";
import NotificationComponent from "../../../utils/Notification.react";
import ModalConfirmRemoval from "./../../../wrappers/ModalConfirmRemoval.react";

import {
  events as businessListEvents,
  values as businessListValues,
  actions as businessListActions,
  options as businessListOptions,
  schema as businessListSchema
} from "./components/BusinessList.config";

const BusinessListPage = ({ match, history, ...props }) => {

  const { state: accState, action: accAction } = Access.useState();
  const { action: ctxAction } = Context.useState();
  const { state: ntfState, action: ntfAction } = Notification.useState();
  const [ modal, setModal ] = useState({ show: false, Content: () => null });

  const token = `Bearer ${accState.access.session.context.token}`

  const onEdit = value => {
    history.push(`${match.path}/edit/${value._id}`);
  };

  const onRemoveAction = (value, refresh, loader) => {
    loader(true)
    ctxAction("business")
      .remove({ _id: value._id }, token)
      .then(res => {
        if (res.name != "BusinessDeleteResult") throw res;

        refresh();

        ntfAction("notification").add(
          res.code,
          "businessListPage",
          "success",
          "O parceiro foi removido com sucesso.",
          undefined,
          process.env.notificationTimeOut
        );
      })
      .catch(err => {
        loader(false)
        if (err.name == "BusinessNotEmptyError") {
          ntfAction("notification").add(
            err.code,
            "businessListPage",
            "danger",
            `O Parceiro ${value.alias} possui vários usuários ou unidades. Remova-os antes de excluí-lo.`,
            undefined,
            process.env.notificationTimeOut
          );
        } else {
          ntfAction("notification").add(
            err.code,
            "businessListPage",
            "danger",
            "Houve um erro ao remover o parceiro.",
            undefined,
            process.env.notificationTimeOut
          );
        }
      });
  };

  const onRemove = (value, refresh, loader) => {
    setModal({
      show: true,
      Content: props => {
        return (
          <ModalConfirmRemoval
            onConfirm={() => onRemoveAction(value, refresh, loader)}
            onClose={() => setModal({ show: false, Content: () => null })}
          />
        );
      }
    });
  }

  const { filter, list, page, limit, options } = useFilter({ 
    schema: businessListSchema, 
    events: businessListEvents(), 
    options: businessListOptions({ onEdit, onRemove }), 
    values: businessListValues({ ctxAction, token }), 
    actions: businessListActions({ ctxAction, token })
  })

  return (
    <Fragment>
      <h2 className="title">Listar parceiros</h2>
      <div className="uk-margin-top">
        <Notification.NotificationWrapper group="businessListPage" component={NotificationComponent} />
        <List { ...{ filter, list, page, limit, options } } />
        <div className="container-modal">{modal.show ? <modal.Content /> : null}</div>
      </div>
    </Fragment>
  );
};

export default withRouter(BusinessListPage);
