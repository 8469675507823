import { createContext } from "@cthulhi/comp-ctxt";

const context = createContext();
export default context;

const getContextActions = domain => {
  const { action } = context.useState();
  return action(domain);
};

const getContextState = domain => {
  const { state } = context.useState();
  return state[domain];
};

export { getContextActions, getContextState };
