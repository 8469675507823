import React, { Fragment, useEffect, useState } from "react";

import CategoryMenu from "../../components/CategoryMenu.react";
import DisplayBenefitList from "../../components/DisplayBenefitList.react";
import Loader from "@html/views/utils/LoaderSiteComponent.react";
import Context from "@context";
import formatBenefitLocation from "../../utils/formatBenefitLocation";
import { Redirect, useHistory } from "react-router-dom";

const CategoryPage = ({ match }) => {
  const { state: ctxState, action: ctxAction } = Context.useState();
  const { push } = useHistory();

  const [benefitByCategoryList, setBenefitByCategoryList] = useState();
  const [isLoadingCategory, setIsLoadingCategory] = useState(false);

  const getCategoryObject = (categoryList, categoryName) => {
    let ret = (categoryList || []).filter(category => category.link === categoryName);

    if (ret.length > 0) {
      return ret[0];
    }

    return {};
  };

  const decodeLink = link => {
    return link ? link.replace("|", "/") : null;
  };

  const getFilterObj = subCategoryId => {
    return {
      categories: {
        $in: [subCategoryId]
      }
    };
  };

  const getDefaultOption = {
    orderby: { shelfLifeEnd: 1, title: 1 }
  };

  const loadBenefitObject = subCategoryId => {
    return ctxAction("website")
      .getBenefitList(
        {
          categories: {
            $in: [subCategoryId]
          }
        },
        {
          skip: 0,
          limit: 8,
          orderby: { shelfLifeEnd: 1, title: 1 }
        }
      )
      .then(benefitObject => {
        return formatBenefitLocation(benefitObject, ctxAction);
      })
      .then(benefitObject => {
        return benefitObject.values;
      });
  };

  useEffect(() => {
    if (ctxState.website.categorias.length > 0) {
      let subCategories = [];
      let benefitByCategory = [];

      // setBenefitByCategoryList(false);
      setIsLoadingCategory(true);

      const _category = getCategoryObject(
        ctxState.website.categorias,
        decodeLink(match.params.categoryName)
      );

      if (_category && match.params.subCategoryName) {
        if (!_category.subCategorias) {
          return push("/404");
        }

        subCategories = _category.subCategorias.filter(
          category => category.link === decodeLink(match.params.subCategoryName.toLowerCase())
        );
      } else {
        subCategories = _category.subCategorias;
      }

      if (!subCategories) {
        return push("/404");
      }

      let myPromiseList = subCategories.map(subCategory => {
        return loadBenefitObject(subCategory._id).then(res => {
          if (res.length > 0) {
            benefitByCategory.push({
              categoryId: subCategory._id,
              categoryName: subCategory.titulo,
              benefitList: res
            });
          }
        });
      });

      Promise.all(myPromiseList).then(res => {
        benefitByCategory.sort((a, b) => (a.categoryName > b.categoryName ? 1 : -1));
        setIsLoadingCategory(false);
        setBenefitByCategoryList(benefitByCategory);
      });
    }
  }, [match.params]);

  return (
    <Fragment>
      <main id="other">
        <CategoryMenu />
        <div className="pageContent" style={{ minHeight: "400px" }}>
          <Loader show={!benefitByCategoryList || isLoadingCategory} height="120" width="120" />
          <div className="cnt">
            {benefitByCategoryList ? (
              benefitByCategoryList.length > 0 ? (
                benefitByCategoryList.map(item =>
                  item.benefitList && item.benefitList.length > 0 ? (
                    <DisplayBenefitList
                      key={item.categoryName}
                      title={item.categoryName}
                      benefitList={item.benefitList}
                      filter={getFilterObj(item.categoryId)}
                      options={getDefaultOption}
                    />
                  ) : null
                )
              ) : (
                <section className="dsp">
                  <div className="dsp-title">
                    <h2>Nenhum benefício encontrado para a categoria</h2>
                  </div>
                </section>
              )
            ) : null}
          </div>
        </div>
      </main>
    </Fragment>
  );
};

export default CategoryPage;
