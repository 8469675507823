import React from "react";
import PropTypes from "prop-types";
import Oval from "./svg/oval";
import DoubleRing from "./svg/doubleRing";

const Loader = props => {
  switch (props.type) {
    case "oval":
      return <Oval {...props} />;

    case "doubleRing":
      return <DoubleRing {...props} />;

    default:
      return <span>Loading content, please wait.</span>;
  }
};

Loader.propTypes = {
  color: PropTypes.string,
  color1: PropTypes.string,
  color2: PropTypes.string,
  type: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
  backgroundColor: PropTypes.string
};

Loader.defaultProps = {
  color: "#712389",
  color1: "#712389",
  color2: "#712389",
  type: "oval",
  height: 80,
  width: 80,
  label: "Loading content, please wait.",
  backgroundColor: "white"
};

export default Loader;
