import React, { Component, Fragment, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";

import Notification from "@cthulhi/comp-noty";
import Access from "@cthulhi/comp-accs";
import Context from "@context";

import BackButton from "../../../access/components/BackButton.react";
import NotificationComponent from "@html/views/utils/Notification.react";

import { schema as userRecoverPassword } from "@schm/access/recoverPassword.schema";

const Recover = () => {
  const { state: ctxState, action: ctxAction, dispatch: ctxDispatch } = Context.useState();
  const { state: ntfState, action: ntfAction } = Notification.useState();
  const { state: accState, action: accAction, consts: accConts } = Access.useState();

  const isLoading =
    accState.access.recover.verifyPassword.status == accConts.STS_REC_PWD_VERIFY_REQUEST ||
    accState.access.recover.verifyPassword.status == accConts.STS_REC_PWD_VERIFY_RESPONSE;
  const isSuccessfull =
    accState.access.recover.verifyPassword.status == accConts.STS_REC_PWD_VERIFY_SUCCESS;

  const [userLoginFields, setUserLoginFields] = useState({ email: "" });

  const onChangeField = (name, value) => {
    setUserLoginFields({
      ...userLoginFields,
      [name]: value
    });
  };

  const onSubmit = async e => {
    e.preventDefault();

    ntfAction("notification").removeGroup("recover");

    const form = userLoginFields;
    const valid = await userRecoverPassword.validate(form);

    if (valid.result) {
      accAction("access")
        .verifyRecoverPassword({
          email: form.email
        })
        .then(res => {
          if (res && res.code == "AA21") {
            ntfAction("notification").add(
              res.code,
              "recover",
              "success",
              "Se o email tiver sido cadastrado você receberá em breve um email contendo mais informações de como recuperar sua conta."
            );
          } else {
            ntfAction("notification").add(
              "EO51",
              "recover",
              "danger",
              "Desculpe, houve um erro, tente novamente mais tarde"
            );
          }
        });
    } else {
      ctxAction("login").updateRecoverEmailFormValidations(valid.errors);
      ntfAction("notification").add("EO51", "recover", "danger", "Email incorreto.");
    }
  };

  const setValidationClass = (validations, field) => {
    if (typeof validations != "undefined") {
      if (typeof validations[field] != "undefined") {
        return validations[field] ? " uk-form-success" : " uk-form-danger";
      }
    }

    return "";
  };

  const isSessionSuccessfull = accState.access.session.status == accConts.STS_SESSION_SUCCESS;

  useEffect(() => {
    accAction("access").verifySession();
  }, [isSessionSuccessfull]);

  if (isSessionSuccessfull) {
    return <Redirect to="/parceiroapp/home" />;
  }

  return (
    <Fragment>
      <div className="uk-flex uk-flex-column uk-padding-large">
        <img
          src={`${process.env.domain}assets/pwa/images/logo-color.svg`}
          alt="NSC"
          className="auth-logo"
          aria-hidden="true"
        />
        <h1 className="auth-title">Recuperar Senha</h1>
      </div>
      <div
        className="uk-flex uk-flex-middle uk-padding uk-padding-remove-top"
      >
        <div className="uk-width-1-1">
          <Notification.NotificationWrapper group="recover" component={NotificationComponent} />

          <form className="form" id="" action="">
            <div className="uk-margin">
              <input
                type="email"
                name="email"
                id="email"
                className={
                  "uk-input uk-width-1-1" +
                  setValidationClass(ctxState.login.recoverEmail.form.validations, "email")
                }
                onChange={e => onChangeField("email", e.target.value)}
                value={userLoginFields.email}
                placeholder="Digite seu e-mail"
                required
              />
            </div>
            <div className="uk-margin uk-text-center">
              <span className="uk-text-small">
                Insira seu e-mail para receber as instruções de renovação de senha.
              </span>
            </div>
            <div className="uk-margin-medium-top">
              <button
                className="uk-button uk-button-primary uk-button-large uk-width-1-1"
                onClick={e => onSubmit(e)}
              >
                {isLoading ? <span>Enviando Email...</span> : <span>Enviar e-mail</span>}
              </button>
            </div>
          </form>
          <BackButton isPwa={true} />
        </div>
      </div>
    </Fragment>
  );
};

export default Recover;
