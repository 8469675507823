import React from 'react'

const SelectComponent = ({value, options, ...props}) => {
  return (
    <select value={value ? value : '-'} { ...props }>
      <option value="-" disabled>Select...</option>
      {options.map(item => (
        <option {...item}>{item.label}</option>
      ))}
    </select>
  )
}

export default SelectComponent
