import React, { Fragment } from "react";
import { schema as schm, types } from "@cthulhi/pack-schm";
import { types as fields } from "@cthulhi/comp-form";

const schema = schm("filter", {
  name: {
    type: types.string.type,
    field: fields.textfield.type
  },
  email: {
    type: types.string.type,
    field: fields.textfield.type
  },
  roles: {
    type: types.string.type,
    field: fields.select.type
  },
  status: {
    type: types.string.type,
    field: fields.select.type
  }
});

const events = ({ ctxAction, ...props }) => {
  return {};
};

const values = ({ ctxAction, ...props }) => {
  return {
    name: {
      filter: "text",
      className: "uk-input",
      placeholder: "Usuário",
      value: "",
      label: {
        text: "Usuário",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      }
    },
    email: {
      filter: "text",
      className: "uk-input",
      placeholder: "E-mail",
      value: "",
      label: {
        text: "E-mail",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      }
    },
    roles: {
      className: "uk-select",
      value: "",
      label: {
        text: "Papel",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      },
      options: [
        { value: "", label: "Selecione um papel..." },
        { value: "manager", label: "Parceiro", },
        { value: "pos", label: "Ponto de venda" },
      ]
    },
    status: {
      className: "uk-select",
      value: "",
      defaultValue: "",
      label: {
        text: "Status",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      },
      options: update => {
        update("status", {
          options: [
            { value: "", label: "Selecione um status..." },
            { value: true, label: "Habilitado" },
            { value: false, label: "Desabilitado" }
          ]
        });
      }
    }
  };
};

const options = ({ onEdit, onRemove, ...props }) => {
  return {
    filter: {
      fields: true,
      submit: true
    },
    pagination: {
      next: {
        value: "Próximo",
        className: "page-next"
      },
      prev: {
        value: "Anterior",
        className: "page-prev"
      },
      numbers: {
        className: "page-numbers"
      },
      total: true
    },
    limit: {
      value: 5,
      limits: [
        { value: 5, label: "5 Items" },
        { value: 10, label: "10 Items" },
        { value: 20, label: "20 Items" }
      ]
    },
    items: {
      business: {
        header: {
          value: "Parceiro",
          className: ""
        },
        column: {
          className: "",
          value: "business.0.name",
          parse: (value, item) => {
            if(value) return value;
            return "-";
          }
        }
      },
      name: {
        header: "Usuário",
        column: "name"
      },
      email: {
        header: "E-mail",
        column: "email"
      },
      roles: {
        header: "Papel",
        column: "roles.0",
        parse: (value, item) => {
          switch (value) {
            case "manager": 
              return "Parceiro";
            case "pos": 
              return "Ponto de venda"; 
            case "admin": 
              return "Administrador";
            default:
              if(item.super) {
                return "Super";
              }
              return "-";
          }
        }
      },
      status: {
        header: "Status",
        column: "status",
        parse: (value) => value ? "Habilitado" : "Desabilitado"
      },
      actions: {
        header: "Ações",
        column: (item, refresh, loader) => {
          return {
            value: (
              <Fragment>
                <div className="uk-grid-small uk-child-width-auto" data-uk-grid>
                  <div className="uk-text-nowrap">
                    <button
                      className="uk-icon-button uk-button-primary"
                      onClick={() => onEdit(item, refresh, loader)}
                    >
                      <i className="icon icon-edit" aria-hidden="true"></i>
                    </button>
                    <button
                      className="uk-icon-button uk-button-danger uk-margin-small-left"
                      onClick={() => onRemove(item, refresh, loader)}
                    >
                      <i className="icon icon-delete" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </Fragment>
            )
          };
        }
      }
    }
  };
};

const actions = ({ ctxAction, token, ...props }) => {
  return {
    find: async (filters, options) => {
      return ctxAction("user").find({ ...filters, aggregates: [
        {
          lookup: {
            from: "business",
            field: "businessId",
            match: "_id",
            result: "business",
            op: "$eq"
          }
        }
      ] }, options, token);
    }
  };
};

export { events, values, actions, options, schema };
