import React, { Fragment, useEffect, useRef } from "react";
import Context from "./../../../context";
import DrawerItem from "./DrawerItem.react";
import { Link } from "react-router-dom";

const Drawer = () => {
  const { state, dispatch, action } = Context.useState();
  const drw = useRef();

  const toggleDrawer = () => {
    document.getElementsByTagName("html")[0].classList.remove("overflow");
    action("website").toggleDrawerMenu();
  };

  const closeDrawerMenu = () => {
    document.getElementsByTagName("html")[0].classList.remove("overflow");
    action("website").closeDrawerMenu();
  };

  const handleEscapeClick = event => {
    if (event.keyCode === 27) {
      document.getElementsByTagName("html")[0].classList.remove("overflow");
      action("website").closeDrawerMenu();
    }
  };

  useEffect(() => {
    addEventListener("keydown", handleEscapeClick);
    return () => {
      removeEventListener("mousedown", handleEscapeClick);
    };
  }, []);

  return (
    <Fragment>
      <section id="drw" ref={drw} className={`${state.website.drawerOpen ? "open" : ""}`}>
        <div id="drw-lens" onClick={() => closeDrawerMenu()} aria-hidden="true"></div>
        <aside id="drw-aside">
          <button id="drw-aside-close" onClick={() => toggleDrawer()}>
            <i className="icn icn-close" aria-hidden="true"></i>
            <span className="lbl">Fechar</span>
          </button>
          <ul id="drw-aside-nav">
            <li>
              <div className="nav-item">
                <Link to="/agenda" onClick={() => closeDrawerMenu()}>
                  <i className="icn icn-schedule" aria-hidden="true"></i>
                  <span className="lbl">Agenda</span>
                </Link>
              </div>
            </li>
            <li>
              <div className="nav-item open">
                <Link to="/destaques" onClick={() => closeDrawerMenu()}>
                  <i className="icn icn-featured" aria-hidden="true"></i>
                  <span className="lbl">Destaques</span>
                </Link>
              </div>
            </li>
            {/* <DrawerItem {...categorias} /> */}

            {state.website.categorias
              .filter(category => category.showMenu === true && category.isPseudoCategory === false)
              .map(categoria => (
                <DrawerItem key={categoria._id} {...categoria} />
              ))}
          </ul>
          <span id="drw-aside-title">Outras opções</span>
          <ul id="drw-aside-links">
            <li>
              <Link to="/sobre" onClick={() => closeDrawerMenu()}>
                Sobre o Clube
              </Link>
            </li>
            <li>
              <Link to="/duvidas" onClick={() => closeDrawerMenu()}>
                Dúvidas Frequentes
              </Link>
            </li>
            <li>
              <Link to="/parceiros" onClick={() => closeDrawerMenu()}>
                Seja um Parceiro
              </Link>
            </li>
            <li>
              <Link to="/regulamento" onClick={() => closeDrawerMenu()}>
                Regulamento
              </Link>
            </li>
            <li>
              <a
                href="https://negociossc.com.br/politica-de-privacidade/"
                onClick={() => closeDrawerMenu()}
              >
                Política de Privacidade
              </a>
            </li>
          </ul>
        </aside>
      </section>
    </Fragment>
  );
};

export default Drawer;
