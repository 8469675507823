const formatBenefitLocation = (_benefitObject, ctxAction) => {
  let benefitObject = { ..._benefitObject };
  let citiesIdsByCard = "";
  let state = [];

  if (benefitObject && benefitObject.values && benefitObject.values.length > 0) {
    state = benefitObject.values.map(benefit => {
      const realUpList = benefit.usepointList.filter(usepoint => usepoint.type == "fisico");
      const virtualUpList = benefit.usepointList.filter(usepoint => usepoint.type == "virtual");
      const citiesIds = [];
      realUpList.map(realUp => {
        if (!citiesIds.includes(realUp.city)) {
          citiesIds.push(realUp.city);
        }
      });
      if (citiesIds.length > 1) {
        benefit.locationInfo = `DISPONÍVEL EM ${citiesIds.length} CIDADES`;
      } else if (citiesIds.length == 0 && virtualUpList.length > 0) {
        benefit.locationInfo = "UNIDADE VIRTUAL";
      } else if (citiesIds.length == 1) {
        if (citiesIdsByCard && citiesIds[0]) {
          citiesIdsByCard = `${citiesIdsByCard}|${citiesIds[0]}`;
        } else {
          if(citiesIds[0]) {
            citiesIdsByCard = citiesIds[0];
          }
        }
      }
      return benefit;
    });
  }

  if (citiesIdsByCard != "" && citiesIdsByCard != " ") {
    return ctxAction("address")
      .findCityByListId(citiesIdsByCard)
      .then(retornoApi => {
        if (Array.isArray(retornoApi)) {
          retornoApi.map(resItem => {
            state.map(benefit => {
              if (
                !benefit.locationInfo &&
                benefit.usepointList.find(
                  usepoint => usepoint.type == "fisico" && usepoint.city == resItem.id
                )
              ) {
                benefit.locationInfo = `${resItem.nome}/${resItem.microrregiao.mesorregiao.UF.sigla}`;
              }
              return benefit;
            });
            return state;
          });
        } else {
          state.map(benefit => {
            if (
              !benefit.locationInfo &&
              benefit.usepointList.find(
                usepoint => usepoint.type == "fisico" && usepoint.city == retornoApi.id
              )
            ) {
              benefit.locationInfo = `${retornoApi.nome}/${retornoApi.microrregiao.mesorregiao.UF.sigla}`;
            }
            return benefit;
          });
        }

        benefitObject = {
          values: state,
          count: benefitObject.count
        };

        return benefitObject;
      })
      .catch(err => {
        benefitObject = {
          values: state,
          count: benefitObject.count
        };
      });
  }

  return new Promise((resolve, reject) => {
    resolve(benefitObject);
  });
};

export default formatBenefitLocation;
