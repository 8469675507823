import bc from "../constants/banner.constant";

export default (dispatch, state) => {
  const dispatchSetStatus = data => {
    dispatch({
      type: bc.ACT_SET_STATUS,
      data: data
    });
  };

  const remove = (values, authorization) => {
    var args = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization
      },
      body: JSON.stringify(values),
      credentials: "same-origin"
    };

    dispatchSetStatus(bc.STS_DELETE_REQUEST);

    return fetch(process.env.domainBannerDelete, args)
      .then(res => res.json())
      .then(res => {
        dispatchSetStatus(bc.STS_DELETE_RESPONSE);
        dispatchSetStatus(bc.STS_DELETE_SUCCESS);

        setTimeout(function() {
          dispatchSetStatus(bc.STS_IDLE);
        }, 1000);

        return res;
      })
      .catch(error => {
        dispatchSetStatus(bc.STS_DELETE_ERROR);

        setTimeout(function() {
          dispatchSetStatus(bc.STS_IDLE);
        }, 1000);

        return error;
      });
  };

  const update = (values, authorization) => {
    const updateFetch = args => {
      return fetch(process.env.domainBannerUpdate, args)
        .then(res => res.json())
        .then(res => {
          dispatchSetStatus(bc.STS_UPDATE_RESPONSE);
          dispatchSetStatus(bc.STS_UPDATE_SUCCESS);

          setTimeout(function() {
            dispatchSetStatus(bc.STS_IDLE);
          }, 1000);

          return res;
        })
        .catch(error => {
          dispatchSetStatus(bc.STS_UPDATE_ERROR);

          setTimeout(function() {
            dispatchSetStatus(bc.STS_IDLE);
          }, 1000);

          return error;
        });
    };

    dispatchSetStatus(bc.STS_UPDATE_REQUEST);

    if (values.imageUrlBlob) {
      const formData = new FormData();
      formData.append("file", values.imageUrlBlob);

      const args = {
        method: "POST",
        body: formData,
      };

      return fetch(process.env.domainDocumentUploadImage, args)
        .then(res => res.json())
        .then(res => {
          values.imageUrl = res.result.image;
          delete values.imageUrlBlob;

          return updateFetch({
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: authorization
            },
            body: JSON.stringify(values),
            credentials: "same-origin"
          });
        })
        .catch(error => {
          return error;
        });
    }

    if (values.imageUrl && values.imageUrl.includes("https:")) {
      values.imageUrl = values.imageUrl.replace(process.env.bannerUrl, "");
    }

    return updateFetch({
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization
      },
      body: JSON.stringify(values),
      credentials: "same-origin"
    });
  };

  const create = (values, authorization) => {

    const updateFetch = (args) => {
      return fetch(process.env.domainBannerCreate, args)
      .then(res => res.json())
      .then(res => {
        dispatchSetStatus(bc.STS_CREATE_RESPONSE);
        dispatchSetStatus(bc.STS_CREATE_SUCCESS);
        
        setTimeout(function() {
          dispatchSetStatus(bc.STS_IDLE);
        }, 1000);
        
        return res
      })
      .catch(error => {
        dispatchSetStatus(bc.STS_CREATE_ERROR);
        
        setTimeout(function() {
          dispatchSetStatus(bc.STS_IDLE);
        }, 1000);
        
        return error;
      });
    }

    dispatchSetStatus(bc.STS_CREATE_REQUEST);

    if(values.imageUrlBlob) {

      const formData = new FormData();
      formData.append("file", values.imageUrlBlob);
    
      const args = {
        method: "POST",
        body: formData,
      };

      return fetch(process.env.domainDocumentUploadImage, args)
        .then(res => res.json())
        .then(res => {
          values.imageUrl = res.result.image
          delete values.imageUrlBlob;

          return updateFetch( {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: authorization
            },
            body: JSON.stringify(values),
            credentials: "same-origin"
          })
        })
        .catch(error => {
          return error;
        });
    }

    return updateFetch({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization
      },
      body: JSON.stringify(values),
      credentials: "same-origin"
    });
  };

  const find = (filters, options, authorization, loader) => {
    
    var args = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization
      },
      body: JSON.stringify({ ...filters, options: { sort: { _id: -1 }, ...options } }),
      credentials: "same-origin"
    };

    dispatchSetStatus(bc.STS_FIND_REQUEST);

    if (loader) loader.start();

    return fetch(process.env.domainBannerFind, args)
      .then(res => res.json())
      .then(res => {
        dispatchSetStatus(bc.STS_FIND_RESPONSE);
        dispatchSetStatus(bc.STS_FIND_SUCCESS);

        if (loader) loader.finish();

        setTimeout(function() {
          dispatchSetStatus(bc.STS_IDLE);
        }, 1000);

        if (res.result.values) {
          res.result.values.map(b => {
            if (b.imageUrl) {
              b.imageUrl = process.env.bannerUrl + b.imageUrl;
            }
            return b;
          });
        }

        return res;
      })
      .catch(error => {
        dispatchSetStatus(bc.STS_FIND_ERROR);

        if (loader) loader.finish();

        setTimeout(function() {
          dispatchSetStatus(bc.STS_IDLE);
        }, 1000);

        return error;
      });
  };

  return {
    find,
    create,
    update,
    remove
  };
};
