import React, { Fragment, useEffect, useState } from "react";
import BenefitList from "../utils/BenefitList.react";
import Context from "@context";
import Loader from "@html/views/utils/LoaderSiteComponent.react";
import formatBenefitLocation from "@html/views/website/utils/formatBenefitLocation";

const DisplaySpotlightBenefitList = props => {
  const { action: ctxAction, state: ctxState } = Context.useState();

  const [seeMoreIsLoading, setSeeMoreIsLoading] = useState();
  const [benefitObject, setBenefitObject] = useState({
    values: undefined,
    isDone: false
  });

  const [lineLength, setLineLenght] = useState(props.seeAll ? 24 : 16);

  const loadBenefitObject = () => {
    setSeeMoreIsLoading(true);

    ctxAction("website")
      .getBenefitList(
        {
          flagSpotlight: true
        },
        {
          skip: (benefitObject.values || []).length,
          limit: lineLength,
          orderby: { updatedAt: -1 }
        }
      )
      .then(eventResult => {
        formatBenefitLocation(eventResult, ctxAction).then(newEventValues => {
          setSeeMoreIsLoading(false);
          setBenefitObject({
            values: (benefitObject.values || []).concat(newEventValues.values),
            isDone: newEventValues.values.length < lineLength
          });
        });
      })
      .catch(err => {
        setBenefitObject({
          values: [],
          isDone: true
        });
      });
  };

  useEffect(() => {
    loadBenefitObject();
  }, []);

  return (
    <Fragment>
      <section className="dsp">
        <div className="dsp-title">
          <h2>{props.title}</h2>
        </div>
        <span className="dsp-lead">{props.description}</span>

        <Loader show={!benefitObject.values} height="120" width="120" />
        <div className="dsp-grid">
          <BenefitList benefitList={benefitObject.values} />
        </div>

        {!benefitObject.isDone && benefitObject.values !== undefined && (
          <div className={"loader-container"}>
            <Loader show={seeMoreIsLoading} height="60" width="60" />
            <button className="dsp-more" onClick={() => loadBenefitObject()}>
              <span>Mostrar mais</span>
              <i className="icn icn-caret-down" aria-hidden="true"></i>
            </button>
          </div>
        )}
      </section>
    </Fragment>
  );
};

export default DisplaySpotlightBenefitList;
