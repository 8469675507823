import React, { Fragment } from "react";

const Sobre = ({ contentCollection }) => {
  return (
    <Fragment>
      <main id="about">
        <div id="bnr">
          <div id="bnr-content">
            <h1>{contentCollection.bannerTitle}</h1>
            <p>{contentCollection.bannerParagraph1}</p>
            <p>{contentCollection.bannerParagraph2}</p>
          </div>
        </div>

        <div id="adv">
          <div className="cnt">
            <h2>{contentCollection.firstSubtitle}</h2>

            <div id="adv-grid">
              <div className="adv-grid-item">
                <i className="icn icn-voucher" aria-hidden="true"></i>
                <div className="content">
                  <h3>{contentCollection.firstCardTitle}</h3>
                  <p>{contentCollection.firstCardParagraph1}</p>
                  <p>{contentCollection.firstCardParagraph2}</p>
                </div>
              </div>
              <div className="adv-grid-item">
                <i className="icn icn-user" aria-hidden="true"></i>
                <div className="content">
                  <h3>{contentCollection.secondCardTitle}</h3>
                  <p>{contentCollection.secondCardParagraph}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="joi">
          <div className="cnt">
            <h2>{contentCollection.secondSubtitle}</h2>

            <div id="joi-grid">
              <div className="joi-grid-item">
                <div className="img">
                  <img
                    src={`${process.env.domain}assets/website/images/app-small.png`}
                    alt="App Clube NSC"
                  />
                </div>
                <div className="content">
                  <p>{contentCollection.thirdCardParagraph1}</p>
                  <p>{contentCollection.thirdCardParagraph2}</p>
                  <ul className="list">
                    <li>
                      <a
                        href="https://play.google.com/store/apps/details?id=br.com.nsccomunicacao.clubensc"
                        target="_blank"
                      >
                        <img
                          src={`${process.env.domain}assets/website/images/google-play-badge.png`}
                          alt="Clube NSC na Google Play"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://itunes.apple.com/br/app/clube-nsc/id1451592115"
                        target="_blank"
                      >
                        <img
                          src={`${process.env.domain}assets/website/images/app-store-badge.png`}
                          alt="Clube NSC na App Store"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="joi-grid-item">
                <i className="icn icn-users" aria-hidden="true"></i>
                <div className="content">
                  <h3>{contentCollection.fourthCardTitle}</h3>
                  <p>{contentCollection.fourthCardParagraph}</p>
                </div>
              </div>

              <div className="joi-grid-item">
                <i className="icn icn-new-user teal" aria-hidden="true"></i>
                <div className="content">
                  <h3>{contentCollection.fifthCardTitle}</h3>
                  <p>{contentCollection.fifthCardParagraph1}</p>
                  <p className="obs">{contentCollection.fifthCardParagraph2}</p>
                </div>
              </div>

              <div className="joi-grid-item">
                <i className="icn icn-new-users teal" aria-hidden="true"></i>
                <div className="content">
                  <h3>{contentCollection.sixthCardTitle}</h3>
                  <p>{contentCollection.sixthCardParagraph1}</p>
                  <p className="obs">{contentCollection.sixthCardParagraph2}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="cnt"></div>
      </main>
    </Fragment>
  );
};

export default Sobre;
