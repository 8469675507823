import address from "./address.action";
import login from "./login.action";
import opportunity from "./opportunity.action";
import permission from "./permission.action";
import business from "./business.action";
import benefit from "./benefit.action";
import branch from "./branch.action";
import banner from "./banner.action";
import loader from "./loader.action";
import user from "./user.action";
import category from "./category.action";
import form from "./form.action";
import website from "./website.action";
import cognito from "./cognito.action";
import redemption from "./redemption.action";
import voucher from "./voucher.action";
import partner from "./partner.action";
import userVip from "./userVip.action";
import faq from "./faq.action";
import content from "./content.action";
import voucherCode from "./voucherCode.action";
import coupon from "./coupon.action";
import discount from "./discount.action";
import event from "./event.action";
import userType from "./userType.action";

export default {
  voucher,
  userType,
  redemption,
  login,
  form,
  loader,
  opportunity,
  permission,
  business,
  benefit,
  branch,
  banner,
  category,
  user,
  address,
  website,
  cognito,
  partner,
  userVip,
  faq,
  content,
  voucherCode,
  coupon,
  discount,
  event,
};
