import React, { Fragment } from "react";
import { schema as schm, types } from "@cthulhi/pack-schm";
import { types as fields } from "@cthulhi/comp-form";

const schema = schm("filter", {
  name: {
    type: types.string.type,
    field: fields.textfield.type
  }
});

const events = ({ ctxAction, ...props }) => {
  return {};
};

const values = ({ ctxAction, ...props }) => {
  return {
    name: {
      filter: "text",
      className: "uk-input",
      placeholder: "Nome",
      label: {
        text: "Nome",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      }
    }
  };
};

const options = ({ onEdit, onRemove, onShowDiscounts, ...props }) => {
  return {
    filter: {
      fields: true,
      submit: true
    },
    pagination: {
      next: {
        value: "Próximo",
        className: "page-next"
      },
      prev: {
        value: "Anterior",
        className: "page-prev"
      },
      numbers: {
        className: "page-numbers"
      },
      total: true
    },
    limit: {
      value: 5,
      limits: [
        { value: 5, label: "5 Items" },
        { value: 10, label: "10 Items" },
        { value: 20, label: "20 Items" }
      ]
    },
    items: {
      name: {
        header: {
          value: "Nome",
          className: ""
        },
        column: "name"
      },
      about: {
        header: "Sobre",
        column: "about"
      },
      actions: {
        header: "Ações",
        column: (item, refresh, loader) => {
          return {
            value: (
              <Fragment>
                <div className="uk-grid-small uk-child-width-auto" data-uk-grid>
                  <div className="uk-text-nowrap">
                    
                    <button
                      className="uk-icon-button uk-button-primary"
                      onClick={() => onEdit(item, refresh, loader)}
                    >
                      <i className="icon icon-edit" aria-hidden="true"></i>
                    </button> 
                    
                    <button
                      className="uk-icon-button uk-button-danger uk-margin-small-left"
                      onClick={() => onRemove(item, refresh, loader)}
                    >
                      <i className="icon icon-delete" aria-hidden="true"></i>
                    </button>

                    <button
                      className="uk-icon-button uk-button-danger uk-margin-small-left"
                      onClick={() => onShowDiscounts(item, refresh, loader)} >
                      %
                    </button>
                    
                  </div>
                </div>
              </Fragment>
            )
          };
        }
      }
    }
  };
};

const actions = ({ ctxAction, token, ...props }) => {
  return {
    find: async (filters, options) => {
      return ctxAction("partner").find({ ...filters }, options, token)
    }
  };
};

export { events, values, actions, options, schema };
