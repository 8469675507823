import opconst from './../constants/opportunity.constant'
import htconst from './../constants/http.constant'

export default (dispatch, state) => {

  const setFormValidations = (value) => {
    return {
      type: opconst.SET_FORM_VALIDATIONS,
      data: value
    }
  }

  const setFormStatus = (value) => {
    return {
      type: opconst.SET_FORM_STATUS,
      data: value
    }
  }

  const setFormField = (field, value) => {
    return {
      type: opconst.SET_FORM_FIELD,
      data: { field, value }
    }
  }

  const clearFormFields = () => {
    return {
      type: opconst.CLEAR_FORM_FIELDS
    }
  }

  const clearFormValidation = (field) => {
    return {
      type: opconst.CLEAR_FORM_VALIDATION,
      data: { field }
    }
  }

  const sendForm = (options) => {

    var args = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(options),
      credentials: 'same-origin'
    };

    dispatch(setFormStatus(htconst.REQUEST))

    return fetch(process.env.domainRender + 'v1/api/opportunity/apply', args)
      .then(res => res.json())
      .then(res => {

        dispatch(setFormStatus(htconst.RESPONSE))
        dispatch(setFormStatus(htconst.SUCCESS))

        res.code == "OA20" ? dispatch(clearFormFields()) : null

        setTimeout(function () {
          dispatch(setFormStatus(htconst.IDLE))
        }, 1000);

        return res

      })
      .catch(error => {

        dispatch(setFormStatus(htconst.ERROR));

        setTimeout(function () {
          dispatch(setFormStatus(htconst.IDLE));
        }, 1000);

        return error

      })
  }

  const updateFormField = (field, value) => {
    dispatch(clearFormValidation(field)) 
    dispatch(setFormField(field, value))
  }

  const updateFormValidations = (validations) => {
    
    dispatch(setFormValidations(
      validations.map(validation => {
        return { [validation.field.split(":")[1]]: false }
      }).reduce((a, b) => {
        return a && b ? {...a, ...b} : a ? { ...a } : b ? { ...b } : {}
      })
    ))
  }

  return { 
    sendForm,
    updateFormField,
    updateFormValidations
  }
}