export default {
    ACT_SET_STATUS: "ACT_PRT_STATUS",

    STS_IDLE: "STS_PRT_IDLE",

    STS_FIND_REQUEST: "STS_PRT_FIND_REQUEST",
    STS_FIND_RESPONSE: "STS_PRT_FIND_RESPONSE",
    STS_FIND_SUCCESS: "STS_PRT_FIND_SUCCESS",
    STS_FIND_ERROR: "STS_PRT_FIND_ERROR",

    STS_CREATE_REQUEST: "STS_PRT_CREATE_REQUEST",
    STS_CREATE_RESPONSE: "STS_PRT_CREATE_RESPONSE",
    STS_CREATE_SUCCESS: "STS_PRT_CREATE_SUCCESS",
    STS_CREATE_ERROR: "STS_PRT_CREATE_ERROR",

    STS_UPDATE_REQUEST: "STS_PRT_UPDATE_REQUEST",
    STS_UPDATE_RESPONSE: "STS_PRT_UPDATE_RESPONSE",
    STS_UPDATE_SUCCESS: "STS_PRT_UPDATE_SUCCESS",
    STS_UPDATE_ERROR: "STS_PRT_UPDATE_ERROR",

    STS_DELETE_REQUEST: "STS_PRT_DELETE_REQUEST",
    STS_DELETE_RESPONSE: "STS_PRT_DELETE_RESPONSE",
    STS_DELETE_SUCCESS: "STS_PRT_DELETE_SUCCESS",
    STS_DELETE_ERROR: "STS_PRT_DELETE_ERROR",
}
