import React, { Fragment } from "react";
import { schema as schm, types } from "@cthulhi/pack-schm";
import { types as fields } from "@cthulhi/comp-form";

const optionLoading = { value: "", label: "Carregando...", disabled: true };
const optionSelectCity = { value: "", label: "Selecione uma cidade..." };
const optionSelectState = { value: "", label: "Selecione um estado..." };
const optionSelectStateToCity = { value: "", label: "Aguardando seleção do estado..." };

const schema = schm("filter", {
  name: {
    type: types.string.type,
    field: fields.textfield.type
  },
  type: {
    type: types.string.type,
    field: fields.select.type
  },
  stage: {
    type: types.string.type,
    field: fields.select.type
  },
  state: {
    type: types.integer.type,
    field: fields.select.type
  },
  city: {
    type: types.string.type,
    field: fields.select.type
  }
});

const events = ({ ctxAction, ...props }) => {
  return {
    zipcode: {
      onChange: (value, rawValue, update) => {
        if (value.length == 10) {
          ctxAction("address")
            .findAddressByZipCode(value.replace(/\./g, "").replace(/\-/g, ""))
            .then(resAddress => {
              update("district", { value: resAddress.district }, resAddress.district);
              update("address", { value: resAddress.address }, resAddress.address);

              update("state", { disabled: true, options: [optionLoading] });
              ctxAction("address")
                .findState(resAddress.uf)
                .then(resState => {
                  const cityId = resState.filter(u => {
                    return u.selected;
                  })[0].value;

                  update(
                    "state",
                    { disabled: false, value: cityId, options: [optionSelectState, ...resState] },
                    cityId
                  );

                  update("city", { disabled: true, options: [optionLoading] });
                  ctxAction("address")
                    .findCity(cityId)
                    .then(resCity => {
                      update(
                        "city",
                        {
                          disabled: false,
                          value: resAddress.city,
                          options: [optionSelectCity, ...resCity]
                        },
                        resAddress.city
                      );
                    });
                });
            });
        }

        return update("zipcode", { value }, rawValue);
      }
    },
    city: {
      onChangeFromState: (field, rawValue, update) => {
        update("city", { value: "", options: [{ value: "", label: "Carregando..." }] });
        ctxAction("address")
          .findCity(field.value)
          .then(res => update("city", { disabled: false, options: [optionSelectCity, ...res] }));
      }
    }
  };
};

const values = ({ ctxAction, ...props }) => {
  return {
    name: {
      filter: "text",
      className: "uk-input",
      placeholder: "Nome",
      label: {
        text: "Nome",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-3@m"
      }
    },
    type: {
      className: "uk-select",
      value: "",
      defaultValue: "",
      label: {
        text: "Tipo",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-3@m"
      },
      options: update => {
        update("type", {
          options: [
            { value: "", label: "Selecione um tipo..." },
            { value: "fisico", label: "Físico" },
            { value: "virtual", label: "Virtual" }
          ]
        });
      }
    },
    stage: {
      className: "uk-select",
      label: {
        text: "Status",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-3@m"
      },
      options: update => {
        update("stage", {
          options: [
            { value: "", label: "Selecione um status..." },
            { value: "toapproval", label: "À aprovar" },
            { value: "onapproval", label: "Em aprovação" },
            { value: "approved", label: "Aprovado" },
            { value: "denied", label: "Negado" }
          ]
        });
      }
    },
    state: {
      className: "uk-select",
      label: {
        text: "Estado",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-3@m"
      },
      defaultValue: "",
      options: update => {
        update("state", { value: "", disabled: true, options: [optionLoading] });
        ctxAction("address")
          .findState()
          .then(res => update("state", { disabled: false, options: [optionSelectState, ...res] }));
      }
    },
    city: {
      className: "uk-select",
      label: {
        text: "Cidade",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-3@m"
      },
      disabled: true,
      options: [optionSelectStateToCity]
    }
  };
};

const options = ({ onEdit, onRemove, ...props }) => {
  return {
    filter: {
      fields: true,
      submit: true
    },
    pagination: {
      next: {
        value: "Próximo",
        className: "page-next"
      },
      prev: {
        value: "Anterior",
        className: "page-prev"
      },
      numbers: {
        className: "page-numbers"
      },
      total: true
    },
    limit: {
      value: 5,
      limits: [
        { value: 5, label: "5 Items" },
        { value: 10, label: "10 Items" },
        { value: 20, label: "20 Items" }
      ]
    },
    items: {
      name: {
        header: {
          value: "Nome",
          className: ""
        },
        column: {
          className: "",
          value: "name"
        }
      },
      type: {
        header: "Tipo",
        column: "type",
        parse: value => {
          if (value == "fisico") return "Físico";
          return "Virtual";
        }
      },
      city: {
        header: "Cidade",
        column: "city",
        parse: value => {
          if (value) return value;
          return "-";
        }
      },
      state: {
        header: "Estado",
        column: "state",
        parse: value => {
          if (value) return value;
          return "-";
        }
      },
      stage: {
        header: "Status",
        column: "stage",
        parse: value => {
          switch (value) {
            case "toapproval":
              return "À aprovar";
            case "onapproval":
              return "Em aprovação";
            case "approved":
              return "Aprovado";
            case "denied":
              return "Negado";
            default:
              return "-";
          }
        }
      },
      actions: {
        header: "Ações",
        column: (item, refresh, loader) => {
          return {
            value: (
              <Fragment>
                <div className="uk-grid-small uk-child-width-auto" data-uk-grid>
                  <div className="uk-text-nowrap">
                    <button
                      className="uk-icon-button uk-button-primary"
                      onClick={() => onEdit(item, refresh, loader)}
                    >
                      <i className="icon icon-edit" aria-hidden="true"></i>
                    </button>
                    <button
                      className="uk-icon-button uk-button-danger uk-margin-small-left"
                      onClick={() => onRemove(item, refresh, loader)}
                    >
                      <i className="icon icon-delete" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </Fragment>
            )
          };
        }
      }
    }
  };
};

const actions = ({ ctxAction, token, ...props }) => {
  return {
    find: async (filters, options) => {
      let branchList = await ctxAction("branch").find({ ...filters }, options, token);

      if (branchList.result.values) {
        await ctxAction("address")
          .findStateByListId(
            branchList.result.values
              .filter(b => b.state)
              .map(b => b.state)
              .join("|")
          )
          .then(resState => {
            if (!Array.isArray(resState)) {
              resState = [resState];
            }

            branchList.result.values.map(b => {
              if (b.state) {
                const state = resState.find(s => s.id == b.state);
                if (state) {
                  b.state = state.nome;
                }
              }
            });
          });

        await ctxAction("address")
          .findCityByListId(
            branchList.result.values
              .filter(b => b.city)
              .map(b => b.city)
              .join("|")
          )
          .then(resState => {
            if (!Array.isArray(resState)) {
              resState = [resState];
            }

            branchList.result.values.map(b => {
              if (b.city) {
                const city = resState.find(s => s.id == b.city);
                if (city) {
                  b.city = city.nome;
                }
              }
            });
          });
      }

      return branchList;
    }
  };
};

export { events, values, actions, options, schema };
