import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Access from "@cthulhi/comp-accs";

const WelcomePwa = ({ match }) => {
  const { state: accState, action: accAction } = Access.useState();

  const [deferredPrompt, setDeferredPrompt] = useState(null);

  const askInstallPWA = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then(function(choiceResult) {
        if (choiceResult.outcome === "accepted") {
          console.log("Your PWA has been installed");
        } else {
          console.log("User chose to not install your PWA");
        }
        setDeferredPrompt(null);
      });
    }
  };

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
      setDeferredPrompt(e);
    });
  }, []);

  const isAdmin =
    accState.access.session.context.payload && accState.access.session.context.payload.roles
      ? accState.access.session.context.payload.roles.includes("admin")
      : false;

  return (
    <Fragment>
      <div className="uk-text-center">
        <span className="label">Olá,</span>
        <h2 className="title">
          {
            accState.access.session.context.payload &&
              accState.access.session.context.payload.name
              ? `${accState.access.session.context.payload.name}`
              : isAdmin
                ? "Admin"
                : "Parceiro"
          }
        </h2>
        <h2 className="title" style={{ fontSize: "32px", marginTop: "12px", color: "gray"}}>
          {accState.access.session.context.payload &&
            accState.access.session.context.payload.business &&
            accState.access.session.context.payload.business.length > 0
            ? `${accState.access.session.context.payload.business[0].name}`
            : isAdmin
              ? "Admin"
              : "Parceiro"}
        </h2>
        {isAdmin ? (
          <Fragment>
            <span>Sua conta não possui acesso ao PWA ):</span>
          </Fragment>
        )
          : null
        }
      </div>
      {!isAdmin ? (
        <Fragment>
          <div className="grid-menu uk-margin-large-top">
            <Link to={`${match.path}/voucher/validate`}>
              <img src={`${process.env.domain}assets/pwa/images/grid-menu-qrcode.svg`} alt="" />
              <h2>Validar</h2>
              <h2>Vouchers</h2>
            </Link>
          </div>
          {deferredPrompt !== null ? (
            <div className="uk-flex uk-align-center" style={{ maxWidth: "300px" }}>
              <div className="uk-alert uk-flex uk-flex-column">
                <span className="uk-margin-bottom">Você gostaria de instalar nosso site?</span>
                <div className="uk-flex">
                  <button
                    className="uk-button uk-button-default uk-margin-left"
                    type="button"
                    onClick={() => setDeferredPrompt(null)}
                  >
                    Não
                  </button>
                  <button
                    className="uk-button uk-button-primary uk-margin-left"
                    type="button"
                    onClick={askInstallPWA}
                  >
                    SIM!
                  </button>
                </div>
              </div>
            </div>
          ) : null}
          <div className="uk-text-center uk-margin-medium-top uk-flex uk-flex-column">
            <Link to={`${match.path}/help`} className="uk-link">
              Precisa de ajuda?
            </Link>
          </div>
        </Fragment>
      ) : null}
    </Fragment>
  );
};

export default WelcomePwa;
