import React, { Fragment, useState, useEffect } from "react";
import Papa from "papaparse";
import Context from "@context";
import Access from "@cthulhi/comp-accs";

import Overlay from "../../../../utils/overlay/Overlay.react";
import ModalConfirmRemoval from "../../../../wrappers/ModalConfirmRemoval.react";
import Notification from "@cthulhi/comp-noty";
import NotificationComponent from "../../../../utils/Notification.react";
import LoaderComponent from "../../../../utils/LoaderCustomComponent.react";

const PreGeneratedModal = ({ visible, onClose, discountId }) => {
  const { state: accState, action: accAction } = Access.useState();
  const { action: ctxAction, state: ctxtState } = Context.useState();
  const { state: ntfState, action: ntfAction } = Notification.useState();
  const [modal, setModal] = useState({ show: false, Content: () => null });
  const [codeList, setCodeList] = useState([]);
  const [listLimit, setListLimit] = useState(0);
  const listLimitValue = 1000;

  const token = `Bearer ${accState.access.session.context.token}`

  const onLoadMore = () =>{
    setListLimit(listLimit + listLimitValue)
  }

  const renderLoadMoreButton = () => {
    if(codeList.length < listLimitValue) return null;

    return (
      <button className="uk-button uk-button-default" type="button" onClick={onLoadMore}>Carregar Mais</button> 
    )
  }

  const onChangeHandler = e => {
    Papa.parse(e.currentTarget.files[0], {
      complete: content => {
        const reducedData = content.data
          .filter(list => list[0])
          .map(code => ({
            code: code[0],
            discountId: null,
          }));
        setCodeList([...codeList, ...reducedData]);
        onLoadMore();
      }
    })
  }

  const saveNewCodeList = (codes) => {
    return ctxAction("coupon").createBulk(
      { 
        codeList: codes
      },
      token,
      {
        start: () => ctxAction("loader").addLoading("find", "preGeneratedModal"),
        finish: () => ctxAction("loader").removeLoading("find", "preGeneratedModal")
      }
    )
  }

  

  const invalidateOldCodeList = () => {
    return ctxAction("coupon").invalidateBulk(
      { discountId: discountId },
      token,
      {
        start: () => ctxAction("loader").addLoading("find", "preGeneratedModal"),
        finish: () => ctxAction("loader").removeLoading("find", "preGeneratedModal")
      }
    )
  }

  const onSave = (e) => {

    const newCodeList = codeList
      .filter(code => (!code._id && code.code != '\n' && code.code.length > 0))
      .map(code => {
        return {
          ...code,
          discountId: discountId
        }
      });


    if (newCodeList.length == 0) return onClose();
    saveNewCodeList(newCodeList).then((res) => {
      
      if (res.code == 11000) {
      console.log('=======> ERRO 106');
      return ntfAction("notification").add(
          1,
          "preGeneratedModal",
          "danger",
          "Não foi possível cadastrar a lista de códigos. Existem códigos duplicados ou que já foram salvos anteriormente.",
          undefined,
          process.env.notificationTimeOut
        );
      }

      return onClose();

    }).catch(() => {

      return ntfAction("notification").add(
        1,
        "preGeneratedModal",
        "danger",
        "Não foi possível cadastrar a lista de códigos.",
        undefined,
        process.env.notificationTimeOut
      );
    });

  }

  const onDeleteAll = (e) => {

    if (codeList.length == 0) return onClose();
    
    ctxAction("loader").addLoading("find", "preGeneratedModal");
    invalidateOldCodeList().then((res) => {

      console.log('=======> ERRO 90 ', res);
      
      if (res.code == 11000) {
        return ntfAction("notification").add(
          1,
          "preGeneratedModal",
          "danger",
          "Não foi possível remover a lista de códigos.",
          undefined,
          process.env.notificationTimeOut
        );
      }

      ctxAction("loader").removeLoading("find", "preGeneratedModal");
      return onClose();

    }).catch(() => {

      ctxAction("loader").removeLoading("find", "preGeneratedModal");
      return ntfAction("notification").add(
        1,
        "preGeneratedModal",
        "danger",
        "Não foi possível remover a lista de códigos.",
        undefined,
        process.env.notificationTimeOut
      );
    });

  }

  const onDelete = (code) => {
    setModal({
      show: true,
      Content: props => {
        return (
          <ModalConfirmRemoval
            onConfirm={() => {
              if (code.discountId) {
                return ntfAction("notification").add(
                  1,
                  "preGeneratedModal",
                  "danger",
                  "Esse código não pode ser removido pois já foi usado na criação de um coupon.",
                  undefined,
                  process.env.notificationTimeOut
                );
              }
              if (code._id) {
                ctxAction("coupon").remove({ _id: code._id }, token)
                  .then(() => getCouponList(discountId))
              } else {
                setCodeList([...codeList.filter(c => c.code !== code.code)])
              }
            }}
            onClose={() => setModal({ show: false, Content: () => null })}
          />
        );
      }
    })

  }

  const getPreGeneratedCodes = (discountId) => {
    return ctxAction("coupon").find(
      { filters: { discountId:discountId } },
      { sort: { code: 1 } },
      token,
      {
        start: () => ctxAction("loader").addLoading("find", "preGeneratedModal"),
        finish: () => ctxAction("loader").removeLoading("find", "preGeneratedModal")
      }
    ).
      then(res => {
        if (res.result && res.result.values) {
          return res.result.values
        }
        return []
      })
  };

  const getCouponList = (discountId) => {
    if (discountId) {
      getPreGeneratedCodes(discountId)
        .then((res) => {
          if(res){
            setCodeList(res)
            setListLimit(res.length < listLimitValue ? res.length : listLimitValue); 
          }
        })
        .catch(() => setCodeList([]))
    }
  }

  React.useEffect(() => {
    getCouponList(discountId)
  }, [discountId]);

  if (!visible) return null;

  return (
    <Overlay backgroundColor="#000000" backgroundOpacity="0.5" onClick={onClose}>
      <div className="uk-modal-dialog" style={{ opacity: 1, transform: "translateY(0)", width: "700px" }}>
        <div className="uk-modal-header">
          <h2 className="uk-modal-title">Pré-Cadastro de códigos</h2>
        </div>
        <LoaderComponent from="preGeneratedModal" />
        <div className="uk-modal-body">
          <Notification.NotificationWrapper group="preGeneratedModal" component={NotificationComponent} />
          <div className="field-wrapper uk-width-1-1" style={{ overflowY: "scroll", maxHeight: "500px" }}>
            {codeList && codeList.length ? (
              <>
                <button className="uk-button uk-button-primary" type="button" onClick={onDeleteAll}>Excluir todos</button>
                <table className="uk-table uk-table-small uk-table-striped">
                  <thead>
                    <th>Código</th>
                    <th>ID Cupom</th>
                    <th>Usuário</th>
                    <th>Ações</th>
                  </thead>
                  <tbody>

                    {codeList.slice(0,listLimit).map((code) => (
                      <tr key={code._id ? code._id : code.code}>
                        <th>{code.code}</th>
                        <th>{code.discountId || "Cupom não salvo"}</th>
                        <th>{code.userId || "Código não usado"}</th>
                        <th>
                          <button
                            className="uk-icon-button uk-button-danger uk-margin-small-left"
                            onClick={() => onDelete(code)}
                          >
                            <i className="icon icon-delete" aria-hidden="true"></i>
                          </button>
                        </th>
                      </tr>
                    ))}

                  </tbody>
                </table>
              </>
            ) : <div>Nenhum código cadastrado</div>}
          </div>
          <div className="uk-flex uk-flex-between uk-margin-small">
            <span>{listLimit} de {codeList.length}</span>
            {renderLoadMoreButton()}
            <span>Usados: {codeList.filter(code => code.userId).length}</span>
          </div>
          <div className="uk-width-1-1 uk-margin-top">
            <input type="file" id="csvfile" name="csvfile" accept=".csv" onChange={onChangeHandler} />
          </div>
        </div>
        <div className="uk-modal-footer uk-text-right">
          <button className="uk-button uk-button-default uk-margin-left" type="button" onClick={onClose}>Fechar</button>
          <button className="uk-button uk-button-primary" type="button" onClick={onSave}>Salvar</button>
        </div>
        <div className="container-modal">{modal.show ? <modal.Content /> : null}</div>
      </div>
    </Overlay>
  );
};

export default PreGeneratedModal;
