import React, { Fragment, useState } from "react";
import { withRouter } from "react-router-dom";

import { List } from "./../../../wrappers/ListWrappers.react";

import { useFilter } from "@cthulhi/comp-list";

import Access from "@cthulhi/comp-accs";
import Context from "@context";

import Notification from "@cthulhi/comp-noty";
import NotificationComponent from "../../../utils/Notification.react";
import ModalConfirmRemoval from "./../../../wrappers/ModalConfirmRemoval.react";
import PreGeneratedModal from "./components/PreGeneratedModal.react";

import {
  events as benefitListEvents,
  values as benefitListValues,
  actions as benefitListActions,
  options as benefitListOptions,
  schema as benefitListSchema
} from "./components/BenefitList.config";

const BenefitListPage = ({ match, history, ...props }) => {
  const { state: accState, action: accAction } = Access.useState();
  const { action: ctxAction } = Context.useState();
  const { state: ntfState, action: ntfAction } = Notification.useState();
  const [modal, setModal] = useState({ show: false, Content: () => null });
  const [downloadStatus, setDownloadStatus] = React.useState("waiting");
  const [downloadLinkAttributes, setDownloadLinkAttributes] = React.useState({
    download: "",
    href: ""
  });
  const [preGeneratedShow, setPreGeneratedShow] = React.useState(false);
  const [preGeneratedBenefitId, setPreGeneratedBenefitId] = React.useState("");
  const token = `Bearer ${accState.access.session.context.token}`;

  const createFilter = () => {
    if (filter.values) {
      const searchFields = filter.values;
      const searchTerms = Object.keys(searchFields).reduce((newFilter, key) => {
        // datetime rawValue is a moment object
        if (key === "shelfLifeStart" || key === "shelfLifeEnd") {
          newFilter[key] = searchFields[key].rawValue;
        } else if (searchFields[key].value) {
          newFilter[key] = {
            $regex: searchFields[key].value,
            $options: "i"
          };
          return newFilter;
        }
        return newFilter;
      }, {});
      return searchTerms;
    }
    return {};
  };

  const downloadFile = e => {
    e.preventDefault();

    setDownloadStatus("downloading");

    ntfAction("notification").add(
      1,
      "benefitListPage",
      "success",
      "Preparando arquivo...",
      undefined,
      process.env.notificationTimeOut
    );

    benefitListActions({ ctxAction, token })
      .downloadCSV({ filters: createFilter() }, {})
      .then(blob => {
        const href = window.URL.createObjectURL(blob);
        setDownloadLinkAttributes({
          href: href,
          download: "benefit-export.csv"
        });
        setDownloadStatus("finished");
      })
      .catch(e => {
        console.log(e);
        setDownloadStatus("error");

        ntfAction("notification").add(
          500,
          "benefitListPage",
          "error",
          "Não foi possível gerar o arquivo.",
          undefined,
          process.env.notificationTimeOut
        );
      });
  };
  const onEdit = value => {
    history.push(`${match.path}/edit/${value._id}`);
  };

  const onRemoveAction = (value, refresh, loader) => {
    loader(true);
    ctxAction("benefit")
      .remove({ _id: value._id }, token)
      .then(res => {
        if (res.name != "BenefitDeleteResult") throw res;

        refresh();

        ntfAction("notification").add(
          res.code,
          "benefitListPage",
          "success",
          "O benefício foi removido com sucesso.",
          undefined,
          process.env.notificationTimeOut
        );
      })
      .catch(err => {
        loader(false);

        ntfAction("notification").add(
          err.code,
          "benefitListPage",
          "danger",
          "Houve um erro ao remover o benefício.",
          undefined,
          process.env.notificationTimeOut
        );
      });
  };

  const onRemove = (value, refresh, loader) => {
    setModal({
      show: true,
      Content: props => {
        return (
          <ModalConfirmRemoval
            onConfirm={() => onRemoveAction(value, refresh, loader)}
            onClose={() => setModal({ show: false, Content: () => null })}
          />
        );
      }
    });
  };

  function textToClipboard (text) {
    var dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  }

  const onCopyAppLink = (item) => {
    const baseUrl = "br.com.nsccomunicacao.clubensc://br.com.nsccomunicacao.clubensc/desconto/"
    textToClipboard(`${baseUrl}${item._id}`)
  }


  const onCopyWebsiteLink = (item) => {
    const baseUrl = process.env.domainApp
    textToClipboard(`${baseUrl}beneficio/${item.slug}`)
  }

  const onPreGenerateCode = (item) => {
    setPreGeneratedBenefitId(item._id);
    setPreGeneratedShow(true)
  }

  const { filter, list, page, limit, options } = useFilter({
    schema: benefitListSchema,
    events: benefitListEvents({ ctxAction }),
    options: benefitListOptions({ onEdit, onRemove, onCopyAppLink, onCopyWebsiteLink, onPreGenerateCode }),
    values: benefitListValues({ ctxAction, token }),
    actions: benefitListActions({ ctxAction, token })
  });

  return (
    <Fragment>
    {
      preGeneratedShow && preGeneratedBenefitId && (
        <PreGeneratedModal
          benefitId={preGeneratedBenefitId}
          visible={preGeneratedShow}
          onClose={() => {
            setPreGeneratedShow(false);
            setPreGeneratedBenefitId("")
          }}
        />)
    }
      <h2 className="title">Listar benefícios</h2>
      <div className="uk-margin-top">
        <div className="uk-margin-top uk-margin-bottom">
          <div className="uk-text-nowrap">
            <button className="uk-button-primary uk-margin-right" onClick={downloadFile}>
              Baixar conteúdo em formato CSV
            </button>
            {downloadStatus == "finished" && (
              <a {...downloadLinkAttributes}>Click aqui para baixar o arquivo</a>
            )}
          </div>
        </div>
        <Notification.NotificationWrapper
          group="benefitListPage"
          component={NotificationComponent}
        />
        <List {...{ filter, list, page, limit, options }} />
        <div className="container-modal">{modal.show ? <modal.Content /> : null}</div>
      </div>
    </Fragment>
  );
};

export default withRouter(BenefitListPage);
