import React from "react";
import Overlay from "./../utils/overlay/Overlay.react"

const ConfirmRemovalModal = ({ onConfirm, onClose }) => {
  return (
    <Overlay backgroundColor="#000000" backgroundOpacity="0.5" onClick={onClose}>
      <div className="uk-modal-dialog" style={{opacity: 1}}>
        <div className="uk-modal-header">
          <h2 className="uk-modal-title">Atenção!</h2>
        </div>
        <div className="uk-modal-body">
          <p>Tem certeza que deseja excluir este item?</p>
        </div>
        <div className="uk-modal-footer uk-text-right">
          <button className="uk-button uk-button-default" type="button" onClick={onClose}>Cancelar</button>
          <button className="uk-button uk-button-primary" type="button" onClick={() => { onClose(); onConfirm(); }}>Remover</button>
        </div>
      </div>
    </Overlay>
  );
};

export default ConfirmRemovalModal;
