import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import Access from "@cthulhi/comp-accs";
import Context from "@context";
import { useFields, useForms } from "@cthulhi/comp-form";

import { schema as passwordFormSchema } from "@schm/user/password.form.schema";

import {
  events as passwordFormEvents,
  values as passwordFormValues,
  actions as passwordFormActions
} from "./components/PasswordForm.config";

import { FieldLabelWrapper } from "../../../wrappers/FormWrappers.react";

import Notification from "@cthulhi/comp-noty";
import NotificationComponent from "../../../utils/Notification.react";

import LoaderComponent from "../../../utils/LoaderCustomComponent.react";

import permissions from "@perm";

const PasswordPage = ({ match, parentPath, history, ...props }) => {
  const { action: ctxAction, state: ctxtState } = Context.useState();
  const { state: accState, action: accAction, consts: accConts } = Access.useState();
  const { state: ntfState, action: ntfAction } = Notification.useState();

  const { form, fields, values, updater, cleaner } = useFields({
    schema: passwordFormSchema,
    events: passwordFormEvents({ ctxAction }),
    values: passwordFormValues({
      ctxAction,
      accAction,
      token: `Bearer ${accState.access.session.context.token}`
    })
  });

  const { updatePassword } = useForms(
    passwordFormActions({
      ctxAction,
      ntfAction,
      updater,
      history,
      path: parentPath,
      token: `Bearer ${accState.access.session.context.token}`
    })
  );

  const passwordFormSubmit = () => {
    let vs = {};

    if(values.newPassword.rawValue == values.confirmPassword.rawValue) {

      vs["_id"] = accState.access.session.context.payload._id
      vs["password"] = values["newPassword"].rawValue ? values["newPassword"].rawValue : values["newPassword"].defaultValue;
  
      updatePassword(vs);

    } else {
      
      ntfAction("notification")
        .add(
          "UP50",
          "passwordForm",
          "danger",
          "As senhas não são iguais.",
          undefined,
          process.env.notificationTimeOut
        );
    }

  };

  return (
    <Fragment>
      <h2 className="title">Alterar senha</h2>
      <div className="uk-margin-top">
        <ul data-uk-accordion="multiple: true">
          <li className="uk-open">
            <a className="title title-lead-small title-accordion uk-accordion-title">
              Sua nova Senha
            </a>
            <div className="uk-accordion-content">
              <Notification.NotificationWrapper
                group="passwordForm"
                component={NotificationComponent}
              />
              <div className="uk-card uk-card-default uk-card-body uk-padding-small">
                <LoaderComponent from="userForm" />
                <form className="uk-grid-small" data-uk-grid>
                  <FieldLabelWrapper name="newPassword" fields={fields} values={values} />
                  <FieldLabelWrapper name="confirmPassword" fields={fields} values={values} />
                </form>
              </div>
            </div>
          </li>
        </ul>
        <div className="uk-margin-top">
          <button
            onClick={passwordFormSubmit}
            className="uk-button uk-button-large uk-button-secondary"
            style={{ float: "right" }}
            disabled={typeof ctxtState.loader.list["userForm"] != "undefined" && ctxtState.loader.list["userForm"].length > 0}
          >
            Salvar
          </button>
        </div>
      </div>
    </Fragment>
  );
};

// @ts-ignore
export default withRouter(PasswordPage);
