import moment from "moment";

const optionLoading = { value: "", label: "Carregando...", disabled: true };

const events = ({ ctxAction, ...props }) => {
  return {    
    shelfLifeEnd: {
      onChange: (value, rawValue, update) => {
        update("shelfLifeEnd", { value: value.endOf("day") }, rawValue ? moment(rawValue).toISOString() : rawValue);
      }
    },
    shelfLifeStart: {
      onChange: (value, rawValue, update) => {
        update("shelfLifeStart", { value: value.startOf("day") }, rawValue ? moment(rawValue).toISOString() : rawValue);
      }
    },
  };
};

const values = ({ ctxAction, token, ...props }) => {
  let initValues = {
    businessId: {
      className: "uk-select",
      disabled: true,
      label: {
        text: "Nome fantasia",
        className: "uk-form-label"
      },
      defaultValue: "",
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      },
      options: update => {
        update("businessId", { value: "", disabled: true, options: [optionLoading] });
        ctxAction("business")
          .find({ filters: {} }, { sort: { name: 1 } }, token)
          .then(res => {
            if (res.name != "BusinessFoundResult") {
              update(
                "businessId",
                {
                  disabled: true,
                  options: [{ value: "", label: "Nenhum parceiro encontrado" }]
                },
                ""
              );
            }
            const businessList = res.result.values.map(business => {
              return {
                key: business._id,
                value: business._id,
                label: business.name,
                disabled: true
              };
            });
            update(
              "businessId",
              {
                disabled: true,
                options: [...businessList]
              },
              ""
            );
          });
      }
    },
    type: {
      className: "uk-select",
      placeholder: "Tipo",
      disabled: true,
      label: {
        text: "Tipo",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      },
      default: "",
      options: [
        { value: "", label: "Selecione um tipo de benefício *" },
        { value: "perennial", label: "Perene" },
        { value: "event", label: "Evento" }
      ]
    },
    flagPromotion: {
      className: "uk-checkbox",
      placeholder: "Promocional",
      disabled: true,
      value: false,
      label: {
        text: "Promocional",
        className: "uk-form-label uk-margin-small-left",
        position: "below"
      },
      wrapper: {
        className: "field-checkbox uk-width-1-3@m"
      }
    },
    flagSpotlight: {
      className: "uk-checkbox",
      placeholder: "Destaque",
      disabled: true,
      value: false,
      label: {
        text: "Destaque",
        className: "uk-form-label uk-margin-small-left",
        position: "below"
      },
      wrapper: {
        className: "field-checkbox uk-width-1-3@m"
      }
    },
    flagHidden: {
      className: "uk-checkbox",
      placeholder: "Oculto",
      disabled: true,
      value: false,
      label: {
        text: "Oculto",
        className: "uk-form-label uk-margin-small-left",
        position: "below"
      },
      wrapper: {
        className: "field-checkbox uk-width-1-3@m"
      }
    },
    title: {
      className: "uk-input",
      placeholder: "Título do benefício",
      disabled: true,
      label: {
        text: "Título",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-1"
      }
    },
    description: {
      className: "uk-textarea",
      placeholder: "Descrição do benefício *",
      disabled: true,
      label: {
        text: "Descrição",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-1"
      }
    },
    resume: {
      className: "uk-textarea",
      placeholder: "Resumo do benefício *",
      disabled: true,
      label: {
        text: "Resumo",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-1"
      }
    },
    videoUrl: {
      className: "uk-input",
      placeholder: "URL do vídeo",
      disabled: true,
      label: {
        text: "URL vídeo",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-2-5@m"
      }
    },
    bannerUrl: {
      input: {
        wrapper: {
          className: "uk-width-1-1@m"
        },
        field: {
          className: "uk-input",
          placeholder: "Imagem",
          disabled: true,
        }
      },
      crops: {
        wrapper: {
          className: "uk-margin-top uk-grid uk-margin-top uk-margin-small"
        },
        cropper: {
          className: "uk-width-1-2@m uk-first-column"
        },
        croppeds: {
          className: "uk-width-1-2@m uk-grid"
        },
        images: [
          { wrapper: { className: "uk-width-1-2@m" }, image: { style: { width: "100%" } } },
          { wrapper: { className: "uk-width-1-2@m" }, image: { style: { width: "50%" } } },
        ]
      },
      locked: false,
      keepSelection: false,
      unit: "%",
      height: 100,
      aspect: 16 / 9,
      label: {
        text: "Imagem",
        className: "uk-form-label"
      },
      description: {
        text: "* Para uma boa aparência, a imagem devem conter no mínimo 1280 pixels de largura."
      },
      wrapper: {
        className: "field-wrapper uk-width-3-5@m"
      }
    },
    discountType: {
      className: "uk-select",
      placeholder: "Tipo do desconto",
      disabled: true,
      label: {
        text: "Tipo do desconto",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-2-5@m"
      },
      options: [
        { value: "", label: "Selecione um tipo de desconto *" },
        { value: "percent", label: "% Percentual" },
        { value: "plain", label: "R$ Dinheiro" }
      ]
    },
    discount: {
      className: "uk-input",
      placeholder: "Valor do desconto",
      disabled: true,
      label: {
        text: "Desconto",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-5@m"
      }
    },
    eventDescription: {
      className: "uk-textarea",
      placeholder: "Confira o valor do lote em vigor no site de vendas",
      value: "Confira o valor do lote em vigor no site de vendas",
      disabled: true,
      label: {
        text: "Valores do evento",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-1"
      }
    },
    siteUrl: {
      className: "uk-input",
      placeholder: "URL completa do site",
      disabled: true,
      label: {
        text: "URL completa site",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      }
    },
    tinySiteUrl: {
      className: "uk-input",
      placeholder: "URL curta do site",
      disabled: true,
      label: {
        text: "URL curta site",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      }
    },
    footerNote: {
      className: "uk-textarea",
      placeholder: "Nota do rodapé",
      disabled: true,
      label: {
        text: "Nota rodapé",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-1"
      }
    },
    shelfLifeStart: {
      className: "datepicker",
      inputProps: {
        className: "uk-input",
        placeholder: "Válido de *",
        disabled: true,
      },
      dateFormat: "DD / MM / YYYY  ",
      value: new Date(),
      rawValue: new Date(),
      label: {
        text: "Válido de",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      }
    },
    shelfLifeEnd: {
      className: "datepicker",
      dateFormat: "DD / MM / YYYY  ",
      inputProps: {
        className: "uk-input",
        placeholder: "Válido até *",
        disabled: true,
      },
      value: "",
      label: {
        text: "Válido até",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      }
    },
    redemptionType: {
      className: "uk-select",
      disabled: true,
      label: {
        text: "Tipo de resgate",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-3@m"
      },
      options: [
        { value: "", label: "Selecione um tipo de resgate *" },
        { value: "promocode", label: "Promocode" },
        { value: "limitedvoucher", label: "Voucher Limitado" },
        { value: "limitlessvoucher", label: "Voucher Ilimitado" }
      ]
    },
    voucherCode: {
      className: "uk-input",
      placeholder: "Código do voucher",
      disabled: true,
      label: {
        text: "Código do voucher",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-3@m"
      }
    },
    voucherAmount: {
      className: "uk-input",
      placeholder: "Quantidade de vouchers",
      disabled: true,
      label: {
        text: "Quantidade de vouchers",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-3@m"
      }
    },
    limitVoucherByUser: {
      className: "uk-checkbox",
      placeholder: "Limitar por CPF",
      disabled: true,
      label: {
        text: "Limitar por CPF",
        className: "uk-form-label uk-margin-small-left",
        position: "below"
      },
      wrapper: {
        className: "field-checkbox uk-width-1-3@m"
      }
    },
    voucherUserLimit: {
      className: "uk-input",
      placeholder: "Quantidade de vouchers por sócio",
      disabled: true,
      label: {
        text: "Quantidade de vouchers por sócio",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-3@m"
      }
    },
    voucherPeriodLimit: {
      className: "uk-input",
      placeholder: "Período limite",
      disabled: true,
      label: {
        text: "Período limite",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-3@m"
      }
    },
    voucherShelfLife: {
      className: "uk-input",
      placeholder: "Validade do voucher em dias",
      disabled: true,
      value: "",
      label: {
        text: "Validade do voucher em dias",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-3@m"
      }
    },
    howToUseVoucher: {
      className: "uk-textarea",
      placeholder: "Como usar o voucher",
      disabled: true,
      label: {
        text: "Como usar o voucher",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-1"
      }
    }
  };

  return initValues;
};

const actions = ({ token, ctxAction, ntfAction, updater, history, path, ...props }) => {
  return {
    entity: {
      name: "benefit",
      token: token,
      form: ctxAction("form")
    },
    actions: {
      entity: ctxAction,
      notification: ntfAction,
      updater: updater
    },
    find: {
      success: {
        name: "BenefitFoundResult",
        action: { notify: false, redirect: false }
      },
      error: {
        name: "BenefitErrorResult",
        message: "Houve um erro ao buscar o benefício",
        action: { notify: "benefitListPage", redirect: () => history.push(path) }
      }
    },
    create: {},
    update: {}
  };
};

export { events, values, actions };
