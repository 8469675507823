import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import Access from "@cthulhi/comp-accs";
import Context from "@context";
import { useFields, useForms } from "@cthulhi/comp-form";

import { schema as userTypeFormSchema } from "@schm/userType/create.form.schema.js";

import {
  events as userTypeFormEvents,
  values as userTypeFormValues,
  actions as userTypeFormActions
} from "./components/UserTypeForm.config";

import { FieldLabelWrapper } from "../../../wrappers/FormWrappers.react";

import Notification from "@cthulhi/comp-noty";
import NotificationComponent from "../../../utils/Notification.react";
import isBefore from 'date-fns/isBefore';
import parseJSON from 'date-fns/parseJSON';
import LoaderComponent from "../../../utils/LoaderCustomComponent.react";
import { ExceptionMap } from "antd/lib/result";

const UserTypePage = ({ match, parentPath, history, ...props }) => {

  const { state: accState, action: accAction } = Access.useState();
  const { action: ctxAction, state: ctxtState } = Context.useState();
  const { state: ntfState, action: ntfAction } = Notification.useState();

  const [userTypeSchema, setUserTypeSchema] = useState(userTypeFormSchema);
  const [validation, setValidation] = useState({})

  const { form, fields, values, updater, cleaner } = useFields({
    schema: userTypeSchema,
    events: userTypeFormEvents({ ctxAction }),
    values: userTypeFormValues({
      ctxAction,
      token: `Bearer ${accState.access.session.context.token}`
    })
  });

  const { find, update, create } = useForms(
    userTypeFormActions({
      ctxAction,
      ntfAction,
      updater,
      history,
      path: parentPath,
      token: `Bearer ${accState.access.session.context.token}`
    })
  );

  const userTypeFormSubmit = () => {
    let vs = {};

    Object.entries(values).map(([key, value]) => {
      
      const hasValue = typeof value.value != "undefined" && value.value !== "";
      const hasRawValue = typeof value.rawValue != "undefined" && value.rawValue !== "";

      vs[key] = hasRawValue ? value.rawValue : hasValue ? value.value : value.defaultValue;
    });
    
    const validate = action => {
      action.then(() => {

        if (isBefore(parseJSON(vs.endDate), new Date())) {
          console.log('=======> DATA INVÀLIDA');
          throw new Error('Data inválida');
        }

      }).catch(setValidation)
    }

    match.params.id ? validate(update(vs)) : validate(create(vs));
  };

  useEffect(() => {
    if (match.params.id) {
      find({ filters: { _id: match.params.id } });
    }

    return () => {
      cleaner();
    };
  }, [match.params.id]);

  return (
    <Fragment>
      <h2 className="title">{match.params.id ? "Editar tipo de usuário" : "Novo tipo de usuário"}</h2>
      <div className="uk-margin-top">
        <Notification.NotificationWrapper group="userTypeForm" component={NotificationComponent} />
        <div className="uk-card uk-card-default uk-card-body uk-padding-small">
          <LoaderComponent from="userTypeForm" />
          <form className="uk-grid-small" data-uk-grid>
            <FieldLabelWrapper name="name" fields={fields} values={values} validation={validation.name}/>
            <FieldLabelWrapper name="description" fields={fields} values={values} validation={validation.description}/>
            <FieldLabelWrapper name="endDate" fields={fields} values={values} validation={validation.endDate} />
          </form>
        </div>

        <div className="uk-margin-top">
          <button
            onClick={userTypeFormSubmit}
            className="uk-button uk-button-large uk-button-secondary"
            style={{ float: "right" }}
            disabled={
              typeof ctxtState.loader.list["userTypeForm"] != "undefined" &&
              ctxtState.loader.list["userTypeForm"].length > 0
            }
          >
            {match.params.id ? "Atualizar" : "Salvar"}
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default withRouter(UserTypePage);
