import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import Access from "@cthulhi/comp-accs";
import Context from "@context";
import { useFields, useForms } from "@cthulhi/comp-form";

import { schema as faqFormSchema } from "@schm/faq/create.form.schema";

import {
  events as faqFormEvents,
  values as faqFormValues,
  actions as faqFormActions
} from "./components/FaqForm.config";

import { FieldLabelWrapper } from "../../../wrappers/FormWrappers.react";

import Notification from "@cthulhi/comp-noty";
import NotificationComponent from "../../../utils/Notification.react";

import LoaderComponent from "../../../utils/LoaderCustomComponent.react";

const FaqFormPage = ({ match, parentPath, history, ...props }) => {
  const { state: accState, action: accAction } = Access.useState();
  const { action: ctxAction, state: ctxtState } = Context.useState();
  const { state: ntfState, action: ntfAction } = Notification.useState();
  const [validation, setValidation] = useState({})

  const { form, fields, values, updater, cleaner } = useFields({
    schema: faqFormSchema,
    events: faqFormEvents({ ctxAction }),
    values: faqFormValues({
      ctxAction,
      token: `Bearer ${accState.access.session.context.token}`
    })
  });

  const { find, update, create } = useForms(
    faqFormActions({
      ctxAction,
      ntfAction,
      updater,
      history,
      path: parentPath,
      token: `Bearer ${accState.access.session.context.token}`
    })
  );

  const faqFormSubmit = () => {
    let vs = {};

    Object.entries(values).map(([key, value]) => {
      const hasValue = typeof value.value != "undefined" && value.value !== "";
      const hasRawValue = typeof value.rawValue != "undefined" && value.rawValue !== "";

      vs[key] = hasRawValue ? value.rawValue : hasValue ? value.value : value.defaultValue;
    });

    const validate = action => {
      action.then(() => {}).catch(setValidation)
    }

    match.params.id ? validate(update(vs)) : validate(create(vs));
  };

  useEffect(() => {
    if (match.params.id) {
      find({ filters: { _id: match.params.id } });
    }

    return () => {
      cleaner();
    };
  }, [match.params.id]);

  return (
    <Fragment>
      <h2 className="title">{match.params.id ? "Editar FAQ" : "Novo FAQ"}</h2>
      <div className="uk-margin-top">
        <Notification.NotificationWrapper group="faqForm" component={NotificationComponent} />
        <div className="uk-card uk-card-default uk-card-body uk-padding-small">
          <LoaderComponent from="faqForm" />
          <form className="uk-grid-small" data-uk-grid>
            <FieldLabelWrapper name="title" fields={fields} values={values} validation={validation.title}/>
            <FieldLabelWrapper name="description" fields={fields} values={values} validation={validation.description}/>
            <FieldLabelWrapper name="weight" fields={fields} values={values} validation={validation.weight}/>
            <FieldLabelWrapper name="status" fields={fields} values={values} validation={validation.status}/>
          </form>
        </div>
        <div className="uk-margin-top">
          <button
            onClick={faqFormSubmit}
            className="uk-button uk-button-large uk-button-secondary"
            style={{ float: "right" }}
            disabled={
              typeof ctxtState.loader.list["faqForm"] != "undefined" &&
              ctxtState.loader.list["faqForm"].length > 0
            }
          >
            {match.params.id ? "Atualizar" : "Salvar"}
          </button>
        </div>
      </div>
    </Fragment>
  );
};

// @ts-ignore
export default withRouter(FaqFormPage);
