const view = {
  group: {
    title: "Banners",
    slug: "banner"
  },
  slug: "banner-view",
  title: "Ver banners",
  description: "Ver os próprios banners",
  list: [
    "prn::1:banner-view-roles:banner-view:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

const viewAny = {
  group: {
    title: "Banners",
    slug: "banner"
  },
  slug: "banner-view-any",
  title: "Ver qualquer banner",
  description: "Ver todos os banners",
  list: [
    "prn::1:banner-view-any-roles:banner-view-any:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

const edit = {
  group: {
    title: "Banners",
    slug: "banner"
  },
  slug: "banner-edit",
  title: "Editar banners",
  description: "Editar os próprios banners",
  list: [
    "prn::1:banner-edit-roles:banner-edit:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

const editAny = {
  group: {
    title: "Banners",
    slug: "banner"
  },
  slug: "banner-edit-any",
  title: "Editar qualquer banner",
  description: "Editar todos os banners",
  list: [
    "prn::1:banner-edit-any-roles:banner-edit-any:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

const remove = {
  group: {
    title: "Banners",
    slug: "banner"
  },
  slug: "banner-remove",
  title: "Remover banners",
  description: "Remover os próprios banners"
}

const removeAny = {
  group: {
    title: "Banners",
    slug: "banner"
  },
  slug: "banner-remove-any",
  title: "Remover qualquer banner",
  description: "Remover todos os banners"
}

const create = {
  group: {
    title: "Banners",
    slug: "banner"
  },
  slug: "banner-create",
  title: "Criar banners",
  description: "Criar os próprios banners"
}

const createAny = {
  group: {
    title: "Banners",
    slug: "banner"
  },
  slug: "banner-create-any",
  title: "Criar qualquer banner",
  description: "Criar todos os banners"
}

module.exports = {
  view,
  viewAny,
  edit,
  editAny,
  remove,
  removeAny,
  create,
  createAny
}