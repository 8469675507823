import React, { Fragment, useState, useRef, useEffect } from "react";
import Accordion from "../utils/Accordion.react";
import Context from "./../../../context";
import { Link } from "react-router-dom";

const Duvidas = () => {
  const { state, dispatch, action } = Context.useState();

  useEffect(() => {
    action("website").filterFaqList(
      {
        filters: {
          status: true
        }
      },
      {
        sort: {
          weight: 1
        }
      }
    );
  }, []);

  const handleChange = e => {
    e.preventDefault();

    let newList = [];
    let currentList = [];

    if (e.target.value !== "") {
      currentList = state.website.accordionDrop;

      newList = currentList.filter(item => {
        const title = item.title.toLowerCase();
        const paragraph = item.paragraph.toLowerCase();

        const accordionConcat = title.concat(paragraph);

        const filter = e.target.value.toLowerCase();

        return accordionConcat.includes(filter);
      });
    } else {
      newList = state.website.accordionDrop;
    }

    action("website").filterFaqList(newList);
  };

  return (
    <Fragment>
      <main id="faq">
        <div className="cnt cnt-small">
          <h1>Dúvidas frequentes</h1>

          <form id="faq-search" onSubmit={e => e.preventDefault()}>
            <i className="icn icn-search" aria-hidden="true"></i>
            <input
              id="faq-search-input"
              onChange={e => handleChange(e)}
              type="search"
              placeholder="Tem alguma dúvida?"
            />
          </form>

          <div id="faq-list">
            {state.website.newList.map(accordion => (
              <Accordion {...accordion} />
            ))}
            <p className="contact">
              Não encontrou o que buscava? <Link to="/contato">Fale conosco</Link>.
            </p>
          </div>
        </div>
      </main>
    </Fragment>
  );
};

export default Duvidas;
