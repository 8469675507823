const events = ({ ctxAction, ...props }) => {
  return {};
};

const values = ({ ctxAction, token, ...props }) => {
  let initValues = {
    title: {
      className: "uk-input",
      placeholder: "Título",
      value: "",
      label: {
        text: "Título",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-1"
      }
    },
    description: {
      className: "uk-textarea",
      placeholder: "Descrição",
      value: "",
      label: {
        text: "Descrição",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-1"
      }
    } /* 
    linkTo: {
      className: "uk-input",
      placeholder: "Redirecionar para",
      value: "",
      label: {
        text: "Redirecionar para",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-1"
      }
    }, */,
    weight: {
      className: "uk-input",
      value: "",
      placeholder: "Ordem",
      label: {
        text: "Ordem",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-5@m"
      }
    },
    status: {
      className: "uk-checkbox",
      value: true,
      label: {
        position: "below",
        text: "Ativo",
        className: "uk-form-label uk-margin-small-left"
      },
      wrapper: {
        className: "field-checkbox uk-width-1-5@m"
      }
    }
  };

  return initValues;
};

const actions = ({ token, ctxAction, ntfAction, updater, history, path, ...props }) => {
  return {
    entity: {
      name: "faq",
      token: token,
      form: ctxAction("form")
    },
    actions: {
      entity: ctxAction,
      notification: ntfAction,
      updater: updater
    },
    find: {
      success: {
        name: "FaqFoundResult",
        action: { notify: false, redirect: false }
      },
      error: {
        name: "FaqErrorResult",
        message: "Houve um erro ao buscar o Faq",
        action: { notify: "faqListPage", redirect: () => history.push(path) }
      }
    },
    create: {
      success: {
        name: "FaqCreateResult",
        message: "Faq adicionado com sucesso",
        action: { notify: "faqListPage", redirect: () => history.push(path) }
      },
      error: {
        name: "FaqErrorResult",
        message: "Houve um erro ao adicionar o Faq",
        action: { notify: "faqForm", redirect: false }
      }
    },
    update: {
      success: {
        name: "FaqUpdateResult",
        message: "Faq atualizado com sucesso",
        action: { notify: "faqListPage", redirect: () => history.push(path) }
      },
      error: {
        name: "FaqErrorResult",
        message: "Houve um erro ao atualizar o Faq",
        action: { notify: "faqForm", redirect: false }
      }
    }
  };
};

export { events, values, actions };
