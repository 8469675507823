import React, { Fragment, useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import Context from "./../../../context";

const Header = props => {
  const { state, dispatch, action } = Context.useState();
  const [searchState, setSearchState] = useState("");
  const [searchFocus, setSearchFocus] = useState(false);
  const [searchPath, setSearchPath] = useState("");

  const toggleDrawer = () => {
    document.getElementsByTagName("html")[0].classList.add("overflow");
    action("website").toggleDrawerMenu();
  };

  const handleChange = event => {
    setSearchState(event.target.value);
    setSearchPath(event.target.value.replace(/%/g, "").trim());
  };

  const handleKeyDown = e => {
    if (e.key === "Enter" && searchPath !== "") {
      return props.history.push(`/search/${searchPath}`);
    }
  };

  useEffect(() => {
    searchState === "" ? setSearchFocus(false) : setSearchFocus(true);
  }, [searchState]);

  useEffect(() => {
    if(props.history.location.pathname.indexOf("search")< 0) {
      setSearchState("");
    }
  },[props.history.location.pathname])
  return (
    <Fragment>
      <header id="hdr">
        <div id="hdr-actions">
          <button id="hdr-actions-menu" onClick={() => toggleDrawer()}>
            <i className="icn icn-menu" aria-hidden="true"></i>
            <span className="lbl">Menu</span>
          </button>
          <Link to="/" id="hdr-actions-logo">
            <img
              className="img"
              src={`${process.env.domain}assets/website/images/nsc-clube.png`}
              alt="NSC Clube de Assinantes"
            />
          </Link>
        </div>
        <div id="hdr-search">
          <i className="icn icn-search" aria-hidden="true"></i>
          <input
            id="hdr-search-input"
            type="search"
            placeholder="Busque por shows, restaurantes e mais"
            value={searchState}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
          <Link
            to={`/search/${searchPath}`}
            className={`hdr-search-button${!searchFocus ? " display-none" : ""}`}
          >
            <i className="icn icn-search" aria-hidden="true"></i>
            <span className="lbl">Buscar</span>
          </Link>
        </div>
        <div id="hdr-tools">
          {/*<Link to="" id="hdr-tools-signin">Entrar</Link>*/}
          <a href="https://www.assinensc.com.br/?url_state=https://clubensc.com.br/&_ga=2.76762773.1289537031.1570825309-1352988405.1564521988" id="hdr-tools-signup">
            Seja Sócio
          </a>
        </div>
      </header>
    </Fragment>
  );
};

export default withRouter(Header);
