import React, { Fragment, useEffect, useState, useRef } from "react";
import { Link, Switch, Route, withRouter } from "react-router-dom";

import Context from "@context";
import { schema as businessFormSchema } from "@schm/partner/create.contact.form.schema";

import Access from "@cthulhi/comp-accs";
import { useFields, useForms } from "@cthulhi/comp-form";

import Notification from "@cthulhi/comp-noty";
import NotificationComponent from "./../../utils/NotificationFrontForm.react";

import Loader from "@html/views/utils/LoaderSiteComponent.react";

import {
  events as businessFormEvents,
  values as businessFormValues,
  actions as businessFormActions
} from "../utils/ParceirosForm.config";

import { FieldLabelWrapper } from "../../wrappers/FormWrappers.react";


const Parceiros = ({ match, parentPath, history, ...props }) => {

  const { state: accState, action: accAction } = Access.useState();
  const { action: ctxAction, state: ctxtState } = Context.useState();
  const { state: ntfState, action: ntfAction } = Notification.useState();
  const [disableButton, setDisableButton] = useState(false);

  const { form, fields, values, updater, cleaner } = useFields({
    schema: businessFormSchema,
    events: businessFormEvents({ ctxAction }),
    values: businessFormValues({ ctxAction })
  });

  const { sendEmail } = useForms(
    businessFormActions({
      ctxAction,
      ntfAction,
      updater,
      history,
      cleaner,
      path: match.path,
      token: `Bearer ${accState.access.session.context.token}`
    })
  );

  const businessFormSubmit = (e) => {

    console.log('====> businessFormSubmit');
    let vs = {};
    let error = false;

    setDisableButton(true)

    window.scrollTo({
      top: 210,
      behavior: 'smooth'
    });

    Object.entries(values).map(([key, value]) => {
      const hasValue = typeof value.value != "undefined" && value.value !== ""
      const hasRawValue = typeof value.rawValue != "undefined" && value.rawValue !== ""

      vs[key] = hasRawValue ? value.rawValue
        : (hasValue ? value.value : value.defaultValue)
    });

    if (!vs.name) error = true;
    if (!vs.phone) error = true;
    if (!vs.email) error = true;
    if (!vs.company_name) error = true;
    
    if(error) {
      console.log('===================> ERROR', error);
      setDisableButton(false)
      return;
    }

    sendEmail(vs).then(res => {

      if (res) {
        updater("name", { value: "" }, "")
        updater("company_name", { value: "" }, "")
        updater("phone", { value: "" }, "")
        updater("email", { value: "" }, "")
      }

      setDisableButton(false)
    }).catch(err => {
      setDisableButton(false)
    });
  };

  const isLoading = (
    typeof ctxtState.loader.list["businessForm"] != "undefined" && ctxtState.loader.list["businessForm"].length > 0
  )

  return (
    <Fragment>

      <main id="partner">

        <Loader show={isLoading} height="120" width="120" />


        <div className="cnt cnt-small">

          <h1>Seja um parceiro</h1>
          <h3>
            Já imaginou participar de um Clube com vantagens também para os parceiros?
          </h3>
          <p>
            Com as vantagens da parceria do Clube NSC você pode alcançar clientes
            qualificados, fazer promoções exclusivas e divulgar a sua marca nos veículos da NSC Comunicação.
          </p>

          <Notification.NotificationWrapper group="businessForm" component={NotificationComponent} />

          <form className="form" onSubmit={e => e.preventDefault()}>
            <FieldLabelWrapper name="name" fields={fields} values={values} />
            <FieldLabelWrapper name="company_name" fields={fields} values={values} />
            <FieldLabelWrapper name="phone" fields={fields} values={values} />
            <FieldLabelWrapper name="email" fields={fields} values={values} />


            <div className="submit">
              <button
                onClick={businessFormSubmit}
                className="btn"
                disabled={disableButton}
              >
                {isLoading ? "Enviando solicitação" : "Enviar solicitação"}
              </button>
            </div>
          </form>

        </div>

      </main>

    </Fragment >
  );
}

// @ts-ignore
export default withRouter(Parceiros);