import React, { useState, Fragment, useEffect, useCallback } from "react";
import ReactImageCrop from "react-image-crop";

function debounce(f, interval) {
  let timer = null;

  return (...args) => {
    clearTimeout(timer);
    return new Promise((resolve) => {
      timer = setTimeout(
        () => resolve(f(...args)),
        interval,
      );
    });
  };
}

const getCroppedImg = (cropCopy, image) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const sizes = { x: 0, y: 0, width: 0, height: 0 };

  if (cropCopy.unit == "%") {
    sizes.x = (cropCopy.x / 100) * image.naturalWidth;
    sizes.y = (cropCopy.y / 100) * image.naturalHeight;
    sizes.width = (cropCopy.width / 100) * image.naturalWidth;
    sizes.height = (cropCopy.height / 100) * image.naturalHeight;

    canvas.width = sizes.width;
    canvas.height = sizes.height;
  } else {
    const scale = {
      x: image.naturalWidth / image.width,
      y: image.naturalHeight / image.height
    };

    sizes.x = cropCopy.x * scale.x;
    sizes.y = cropCopy.y * scale.y;
    sizes.width = cropCopy.width * scale.x;
    sizes.height = cropCopy.height * scale.y;

    canvas.width = cropCopy.width;
    canvas.height = cropCopy.height;
  }
  ctx.drawImage(
    image,
    sizes.x,
    sizes.y,
    sizes.width,
    sizes.height,
    0,
    0,
    sizes.width,
    sizes.height
  );

  return generateNewBlobUrl(canvas)
};


const generateNewBlobUrl = (canvasNode) => {
  return new Promise((resolve, reject) => {
    let mimeType = null;
    canvasNode.toBlob(
      blob => {
        blob.name = "newFile";
        mimeType = blob.type
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, 'image/jpeg', 1);
  });
}

const debouncedGetCroppedImg = debounce(getCroppedImg, 200)

const FileImageCrop = ({
  name,
  value,
  rawValue,
  options,
  aspect,
  locked,
  unit,
  width,
  height,
  keepSelection,
  masker,
  ...props
}) => {
  const [src, setSrc] = useState(null);
  const [imageElement, setImageElement] = useState(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [crop, setCrop] = useState({ unit, height, width, x: 0, y: 0, aspect });

  const onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setSrc(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onImageLoaded = image => {
    // upload
    if (image.src.startsWith('data')) {
      setImageElement(image)
    } else {
      // precisamos do crossOrigin = 'anonymous' para conseguir desenhar uma imagem externa
      // no canvas. No entanto Chrome já faz um request para a imagem com o elemento <img>
      // quando tentamos carregar a imagem novamente ele vai trazer o resultado cacheado
      // sem o header crossOrigin, o que resulta em erro. Para evitar isso adicionamos
      // um parametro random no fim da URL.
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.src = image.src + `?${Math.random()}`;
      img.onload = () => setImageElement(img)
    }
  };

  const onCropChange = (_, percentCrop) => {
    if (percentCrop.height) {
      setCrop({ ...percentCrop, aspect });
    } else {
      setCrop({
        aspect: aspect,
        height: 100,
        unit: "%",
        width: 100,
        x: 0,
        y: 0
      });
    }
  };

  const makeClientCrop = async crop => {
    if (imageElement && crop.width && crop.height) {
      const croppedImageUrl = await debouncedGetCroppedImg(crop, imageElement);
      setCroppedImageUrl(croppedImageUrl);
      props.onChange
      ? props.onChange({ target: { value: croppedImageUrl }, checkValidity: () => true })
      : null;
    }
  };

  useEffect(() => {
    makeClientCrop(crop);
  }, [crop, imageElement]);

  useEffect(() => {
    if (!src && rawValue) {
      setSrc(rawValue);
    }
  }, [rawValue]);

  return (
    <Fragment>
      <div {...props.input.wrapper}>
        <input
          type="file"
          name={name}
          onChange={onSelectFile}
          {...props.input.field}
          className={`${props.input.field.className ? props.input.field.className : ""} ${
            props.validation === false ? "invalid-input-field" : ""
          }`}
        />
      </div>
      <div {...props.crops.wrapper}>
        {src && (
          <div {...props.crops.cropper}>
            <ReactImageCrop
              style={{ backgroundColor: "white" }}
              src={src}
              crop={crop}
              locked={locked}
              keepSelection={keepSelection}
              onImageLoaded={onImageLoaded}
              onChange={onCropChange}
            />
          </div>
        )}
        {croppedImageUrl && (
          <div {...props.crops.croppeds}>
            {props.crops.images.map(c => (
              <div {...c.wrapper}>
                <img src={croppedImageUrl} crossOrigin="anonymous" {...c.image} />
              </div>
            ))}
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default FileImageCrop;
