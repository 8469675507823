import moment from "moment"

const optionLoading = { value: "", label: "Carregando...", disabled: true };
const optionSelectPartner = { value: "", label: "Selecione um parceiro..." };

const events = ({ ctxAction, ...props }) => {
  return {};
};

const values = ({ ctxAction, token, ...props }) => {
  let initValues = {
    categories: {
      className: "uk-select",
      placeholder: "Sub-Categorias",
      label: {
        text: "Sub-Categorias",
        className: "uk-form-label"
      },
      wrapper: {
        className: "uk-width-1-1@m"
      },
      default: "",
      find: () => {
        return ctxAction("category").find(
          { filters: { parentId: {$ne:null} }},
          { sort: { title: 1 } },
          token,
        )
        .then((res) => {
          if (Array.isArray(res.result.values)) {
            return res.result.values.map(({ _id, name }) => ({
              value: _id, 
              label: name
            }))
          }
          return [];
        });
      },
      options: [
        
      ]
    },
    name: {
      className: "uk-input",
      placeholder: "Nome",
      label: {
        text: "Nome",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      },
      default: "",
    },
    site: {
      className: "uk-input",
      placeholder: "Site",
      label: {
        text: "Site",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      },
      default: "",
    },
    about: {
      className: "uk-input",
      placeholder: "Sobre",
      label: {
        text: "Sobre",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      }
    },
    email: {
      className: "uk-input",
      placeholder: "E-mail",
      value: "",
      label: {
        text: "E-mail",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      }
    },
    document: {
      className: "uk-input",
      placeholder: "CNPJ",
      value: "",
      masks: ["99.999.999/9999-99"],
      label: {
        text: "CNPJ",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      }
    }
  };

  return initValues;
};

const actions = ({ token, ctxAction, ntfAction, updater, history, path, ...props }) => {
  return {
    entity: {
      name: "partner",
      token: token,
      form: ctxAction("form")
    },
    actions: {
      entity: ctxAction,
      notification: ntfAction,
      updater: updater
    },
    find: {
      success: {
        name: "PartnerFoundResult",
        action: { notify: false, redirect: false }
      },
      error: {
        name: "PartnerErrorResult",
        message: "Houve um erro ao buscar o parceiro",
        action: { notify: "partnerListPage", redirect: () => history.push(path) }
      }
    },
    create: {
      success: {
        name: "PartnerCreateResult",
        message: "Parceiro adicionado com sucesso",
        action: { notify: "partnerListPage", redirect: () => history.push(path) }
      },
      error: {
        name: "PartnerErrorResult",
        message: "Houve um erro ao adicionar o parceiro",
        action: { notify: "partnerForm", redirect: false }
      }
    },
    update: {
      success: {
        name: "PartnerUpdateResult",
        message: "Parceiro atualizado com sucesso",
        action: { notify: "partnerListPage", redirect: () => history.push(path) }
      },
      error: {
        name: "PartnerErrorResult",
        message: "Houve um erro ao atualizar o parceiro",
        action: { notify: "partnerForm", redirect: false }
      }
    }
  };
};

export { events, values, actions };
