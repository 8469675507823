import { schema, types } from "@cthulhi/pack-schm";
import { types as fields } from "@cthulhi/comp-form";
import { schema as commonSchema } from "./common.schema";

const schm = schema("partner", {
    ...commonSchema.schema.props,
    name: {
        type: types.string.type,
        field: fields.textfield.type,
        required: { contains: true }
    },
    about: {
        type: types.string.type,
        field: fields.textfield.type,
        required: { contains: true }
    },
    site: {
        type: types.string.type,
        field: fields.textfield.type,
        required: { contains: true }
    },
    document: {
        type: types.string.type,
        field: fields.textfield.type
    },
    email: {
        type: types.string.type,
        field: fields.textfield.type,
    },
    categories: {
        type: types.array.type,
        field: "ant-select",
        required: { contains: true },
        items: {
            type: types.string.type
        }
    },
    branches: {
        type: types.array.type,
        required: { contains: true },
        items: {
            type: types.string.type
        }
    }
});

export { schm as schema };
