import React, { Fragment } from "react";

const Notification = ({ group, state, action, ...props }) => {
  if (!state.notification.list[group] || state.notification.list[group].length == 0) return null;

  return (
    <Fragment>
      {state.notification.list[group].map((item, iIndex) => (
        <div key={"notification-item-" + iIndex} className={`uk-alert-${item.type} uk-alert`}>
          <a
            className="uk-alert-close"
            onClick={e => action("notification").remove(group, iIndex)}
            data-uk-close
          ></a>
          <p>{item.title}</p>
          {item.message ? <p>{item.message}</p> : null}
        </div>
      ))}
    </Fragment>
  );
};

export default Notification;
