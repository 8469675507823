import React, { Fragment, useEffect } from "react";

import AdmGroup from "./groups/AdmGroup.react";
import VoucherGroup from "./groups/VoucherGroup.react";
import ParceirosGroup from "./groups/ParceirosGroup.react";
import AssinantesGroup from "./groups/AssinantesGroup.react";
import ComunicacaoGroup from "./groups/ComunicacaoGroup.react";

import NegociosGroup from "./groups/NegociosGroup.react";
import CadastroGroup from "./groups/CadastroGroup.react";
import SuporteGroup from "./groups/SuporteGroup.react";

const Menu = ({ path, options = {} }) => {

  return (
    <Fragment>
      <ul className="menu uk-margin-remove-top">
        {/* ------ ADMINISTRADOR ------ */}
        <VoucherGroup path={path} options={options} />
        <ParceirosGroup path={path} options={options} />
        <AssinantesGroup path={path} options={options} />
        <ComunicacaoGroup path={path} options={options} />
        {/* ------ PARCEIRO ------ */}
        <AdmGroup path={path} options={options} />
        <SuporteGroup path={path} pwa={false} options={options} />
      </ul>
    </Fragment>
  );
};

export default Menu;
