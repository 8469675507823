const view = {
  group: {
    title: "Usuários",
    slug: "user"
  },
  slug: "user-view",
  title: "Ver usuários",
  description: "Ver os próprios usuários",
  list: [
    "prn::1:user-view-roles:user-view:allow:lcnt:schema.user.roles:custom.static.manager"
  ]
}

const viewAny = {
  group: {
    title: "Usuários",
    slug: "user"
  },
  slug: "user-view-any",
  title: "Ver qualquer usuário",
  description: "Ver todos os usuários",
  list: [
    "prn::1:user-view-any-roles:user-view-any:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

const edit = {
  group: {
    title: "Usuários",
    slug: "user"
  },
  slug: "user-edit",
  title: "Editar usuários",
  description: "Editar os próprios usuários",
  list: [
    "prn::1:user-edit-roles:user-edit:allow:lcnt:schema.user.roles:custom.static.manager"
  ]
}

const editAny = {
  group: {
    title: "Usuários",
    slug: "user"
  },
  slug: "user-edit-any",
  title: "Editar qualquer usuário",
  description: "Editar todos os usuários",
  list: [
    "prn::1:user-edit-any-roles:user-edit-any:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

const create = {
  group: {
    title: "Usuários",
    slug: "user"
  },
  slug: "user-create",
  title: "Criar usuários",
  description: "Criar os próprios usuários",
  list: [
    "prn::1:user-create-roles:user-create:allow:lcnt:schema.user.roles:custom.static.manager"
  ]
}

const createAny = {
  group: {
    title: "Usuários",
    slug: "user"
  },
  slug: "user-create-any",
  title: "Criar qualquer usuário",
  description: "Criar todos os usuários",
  list: [
    "prn::1:user-create-any-roles:user-create-any:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

const remove = {
  group: {
    title: "Usuários",
    slug: "user"
  },
  slug: "user-remove",
  title: "Remover usuários",
  description: "Remover os próprios usuários",
  list: [
    "prn::1:user-remove-roles:user-remove:allow:lcnt:schema.user.roles:custom.static.manager"
  ]
}

const removeAny = {
  group: {
    title: "Usuários",
    slug: "user"
  },
  slug: "user-remove-any",
  title: "Remover qualquer usuário",
  description: "Remover todos os usuários",
  list: [
    "prn::1:user-remove-any-roles:user-remove-any:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

const viewPassword = {
  group: {
    title: "Usuários",
    slug: "user"
  },
  slug: "user-pass-view",
  title: "Ver a própria senha de usuário",
  description: "Ver a própria senha de usuário",
  list: [
    "prn::1:user-pass-edit-roles:user-pass-view:allow:lcnt:schema.user.roles:custom.static.admin,manager"
  ]
}

const editPassword = {
  group: {
    title: "Usuários",
    slug: "user"
  },
  slug: "user-pass-edit",
  title: "Editar a própria senha de usuário",
  description: "Editar a própria senha de usuário",
  list: [
    "prn::1:user-pass-view-roles:user-pass-edit:allow:lcnt:schema.user.roles:custom.static.admin,manager"
  ]
}

module.exports = {
  view,
  viewAny,
  viewPassword,
  edit,
  editAny,
  editPassword,
  create,
  createAny,
  remove,
  removeAny
}