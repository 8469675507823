import React, { Fragment } from "react";

import Access from "@cthulhi/comp-accs";

import permissions from "@perm";

const SuporteGroup = ({ path, pwa, toggleAsideMenu }) => {
  const { state: accState, action: accAction } = Access.useState();

  const logout = () => {
    accAction("access").removeSessionCookie();
    accAction("access").removeSession();
  };

  return (
    <Access.RestrictComponent
      onDeny={() => null}
      onLoading={() => null}
      onAccess={[permissions.faq.view]}
    >
      <li>
        <span className="menu-title uk-margin-top">Suporte</span>
      </li>
      <li>
        <Access.RestrictNavLink
          onAccess={[permissions.faq.view]}
          to={`${path}/help`}
          className="menu-link"
          onClick={toggleAsideMenu}
        >
          <i className="icon icon-help" aria-hidden="true"></i>
          <span>Ajuda</span>
        </Access.RestrictNavLink>
      </li>
      <li>
        <a className="menu-link" onClick={logout}>
          <i className={`icon icon-logout${pwa ? "" : "-gray"}`} aria-hidden="true"></i>
          <span>Sair</span>
        </a>
      </li>
    </Access.RestrictComponent>
  );
};

export default SuporteGroup;
