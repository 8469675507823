import React, { Component, Fragment, useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";

import Access from "@cthulhi/comp-accs";
import Notification from "@cthulhi/comp-noty";
import Context from "@context";

import BackButton from "../../../access/components/BackButton.react";
import NotificationComponent from "@html/views/utils/Notification.react";

import { schema as userRecoverUpdatePassword } from "@schm/access/recoverUpdatePassword.schema";

const RecoverUpdate = ({ match, history }) => {
  const { state: ctxState, action: ctxAction, dispatch: ctxDispatch } = Context.useState();
  const { state: ntfState, action: ntfAction } = Notification.useState();
  const { state: accState, action: accAction, consts: accConts } = Access.useState();

  const isLoading =
    accState.access.recover.updatePassword.status == accConts.STS_REC_PWD_UPDATE_REQUEST ||
    accState.access.recover.updatePassword.status == accConts.STS_REC_PWD_UPDATE_RESPONSE;
  const isSuccessfull =
    accState.access.recover.updatePassword.status == accConts.STS_REC_PWD_UPDATE_SUCCESS;

  const [ userLoginFields, setUserLoginFields ] = useState({ password: "", check: "" })

  const onChangeField = (name, value) => {
    setUserLoginFields({
      ...userLoginFields,
      [name]: value
    })
  }

  const onSubmit = async e => {
    e.preventDefault();

    ntfAction("notification").removeGroup("update");

    const form = userLoginFields;
    const valid = await userRecoverUpdatePassword.validate({
      ...form,
      code: match.params.code,
      _id: match.params._id
    });

    if (valid.result && form.password == form.check) {
      accAction("access")
        .updateRecoverPassword({
          _id: match.params._id,
          code: match.params.code,
          password: form.password
        })
        .then(res => {
          if (res.code != "AA23") {
            ntfAction("notification").add(res.code, "update", "danger", "As senhas não conferem.");
          } else {
            ntfAction("notification").add(
              res.code,
              "update",
              "success",
              "Sua senha foi alterada com sucesso!"
            );
            ntfAction("notification").add(
              res.code,
              "update",
              "info",
              "Acesse a página de login para logar com sua nova senha. "
            );
          }
        });
    } else {
      ctxAction("login").updateRecoverUpdateFormValidations(valid.errors);
      ntfAction("notification").add("EO52", "update", "danger", "As senhas não conferem.");
    }
  };

  const setValidationClass = (validations, field) => {
    if (typeof validations != "undefined") {
      if (typeof validations[field] != "undefined") {
        return validations[field] ? " uk-form-success" : " uk-form-danger";
      }
    }

    return "";
  };

  const isSessionSuccessfull = accState.access.session.status == accConts.STS_SESSION_SUCCESS;

  useEffect(() => {
    accAction("access").verifySession();
  }, [isSessionSuccessfull]);

  if (isSessionSuccessfull) {
    return <Redirect to="/parceiroapp/home" />;
  }

  return (
    <Fragment>
      <div className="uk-flex uk-flex-column uk-padding-large">
        <img
          src={`${process.env.domain}assets/pwa/images/logo-color.svg`}
          alt="NSC"
          className="auth-logo"
          aria-hidden="true"
        />
        <h1 className="auth-title">Atualize sua Senha</h1>
      </div>
      <div
        className="uk-flex uk-flex-middle uk-padding uk-padding-remove-top"
      >
        <div className="uk-width-1-1">
          <Notification.NotificationWrapper group="update" component={NotificationComponent} />
          {isSuccessfull ? (
            <div className="uk-margin-medium-top">
              <button
                className="uk-button uk-button-primary uk-button-large uk-width-1-1"
                onClick={() => history.push("/parceiroapp/access")}
              >
                Acessar
              </button>
            </div>
          ) : (
            <form className="form" action="">
              <div className="uk-margin">
                <input
                  autocomplete="new-password"
                  className={
                    "uk-input uk-width-1-1" +
                    setValidationClass(ctxState.login.recoverUpdate.form.validations, "password")
                  }
                  id="password"
                  name="password"
                  placeholder="Digite a nova senha"
                  onChange={(e) => onChangeField("password", e.target.value)}
                  value={userLoginFields.password}
                  required
                  type="password"
                />
              </div>
              <div className="uk-margin">
                <input
                  autocomplete="new-password"
                  className={
                    "uk-input uk-width-1-1" +
                    setValidationClass(ctxState.login.recoverUpdate.form.validations, "check")
                  }
                  id="password-check"
                  name="check"
                  placeholder="Repita a senha"
                  onChange={(e) => onChangeField("check", e.target.value)}
                  value={userLoginFields.check}
                  required
                  type="password"
                />
              </div>
              <div className="uk-margin-medium-top">
                <button className="uk-button uk-button-primary uk-button-large uk-width-1-1" onClick={e => onSubmit(e)}>
                  {isLoading ? <span>Atualizando...</span> : <span>Atualizar</span>}
                </button>
              </div>
              <div className="uk-margin-top uk-text-center">
                <Link to="/parceiroapp/access">Início</Link>
              </div>
            </form>
          )}
        </div>
      </div>
    </Fragment>
  );
};
export default RecoverUpdate;
