import React, { Fragment } from "react";
import { Switch, withRouter } from "react-router-dom";

import Access from "@cthulhi/comp-accs";
import permissions from "@perm";

import UserListPage from "./UserListPage.react";
import UserProfileListPage from "./UserProfileListPage.react";
import UserFormPage from "./UserFormPage.react";
import UserProfileFormPage from "./UserProfileFormPage.react";
import UserPasswordFormPage from "./UserPasswordPage.react";

const UserPage = ({ match, ...props }) => {
  return (
    <Fragment>
      <Switch>
        <Access.RestrictRoute
          key="user-list"
          onAccess={[
            permissions.user.viewAny, 
            permissions.user.editAny]
          }
          parentPath={match.path}
          path={`${match.path}`}
          exact
          onAllow={UserListPage}
        />
        <Access.RestrictRoute
          key="user-list-profile"
          onAccess={[
            permissions.user.view, 
            permissions.user.edit
          ]}
          parentPath={`${match.path}/profile`}
          path={`${match.path}/profile`} exact
          onAllow={UserProfileListPage}
        />
        <Access.RestrictRoute
          key="user-create"
          onAccess={[
            permissions.user.viewAny, 
            permissions.user.editAny
          ]}
          parentPath={match.path}
          path={`${match.path}/create`}
          onAllow={UserFormPage}
        />
         <Access.RestrictRoute
          key="user-create-profile"
          onAccess={[
            permissions.user.view, 
            permissions.user.edit
          ]}
          parentPath={`${match.path}/profile`}
          path={`${match.path}/profile/create`}
          onAllow={UserProfileFormPage}
        />
        <Access.RestrictRoute
          key="user-password"
          onAccess={[
            permissions.user.view, 
            permissions.user.edit, 
            permissions.user.viewAny, 
            permissions.user.editAny
          ]}
          parentPath={match.path}
          path={`${match.path}/password`}
          onAllow={UserPasswordFormPage}
        />
        <Access.RestrictRoute
          key="user-edit"
          onAccess={[ 
            permissions.user.editAny, 
            permissions.user.edit
          ]}
          parentPath={match.path}
          path={`${match.path}/edit/:id`}
          onAllow={UserFormPage}
        />
        <Access.RestrictRoute
          key="user-edit-profile"
          onAccess={[
            permissions.user.view, 
            permissions.user.edit
          ]}
          parentPath={`${match.path}/profile`}
          path={`${match.path}/profile/edit/:id`}
          onAllow={UserProfileFormPage}
        />
      </Switch>
    </Fragment>
  );
};

export default withRouter(UserPage);
