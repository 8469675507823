import bc from "./../constants/branch.constant";

export default (dispatch, state) => {
  const dispatchSetStatus = data => {
    dispatch({
      type: bc.ACT_SET_STATUS,
      data: data
    });
  };

  const remove = (values, authorization) => {
    var args = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization
      },
      body: JSON.stringify(values),
      credentials: "same-origin"
    };

    dispatchSetStatus(bc.STS_DELETE_REQUEST);

    return fetch(process.env.domainBranchDelete, args)
      .then(res => res.json())
      .then(res => {
        dispatchSetStatus(bc.STS_DELETE_RESPONSE);
        dispatchSetStatus(bc.STS_DELETE_SUCCESS);

        setTimeout(function() {
          dispatchSetStatus(bc.STS_IDLE);
        }, 1000);

        return res;
      })
      .catch(error => {
        dispatchSetStatus(bc.STS_DELETE_ERROR);

        setTimeout(function() {
          dispatchSetStatus(bc.STS_IDLE);
        }, 1000);

        return error;
      });
  };

  const update = (values, authorization) => {
    var args = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization
      },
      body: JSON.stringify(values),
      credentials: "same-origin"
    };

    dispatchSetStatus(bc.STS_UPDATE_REQUEST);

    return fetch(process.env.domainBranchUpdate, args)
      .then(res => res.json())
      .then(res => {
        dispatchSetStatus(bc.STS_UPDATE_RESPONSE);
        dispatchSetStatus(bc.STS_UPDATE_SUCCESS);

        setTimeout(function() {
          dispatchSetStatus(bc.STS_IDLE);
        }, 1000);

        return res;
      })
      .catch(error => {
        dispatchSetStatus(bc.STS_UPDATE_ERROR);

        setTimeout(function() {
          dispatchSetStatus(bc.STS_IDLE);
        }, 1000);

        return error;
      });
  };

  const create = (values, authorization) => {
    var args = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization
      },
      body: JSON.stringify(values),
      credentials: "same-origin"
    };

    dispatchSetStatus(bc.STS_CREATE_REQUEST);

    return fetch(process.env.domainBranchCreate, args)
      .then(res => res.json())
      .then(res => {
        dispatchSetStatus(bc.STS_CREATE_RESPONSE);
        dispatchSetStatus(bc.STS_CREATE_SUCCESS);

        setTimeout(function() {
          dispatchSetStatus(bc.STS_IDLE);
        }, 1000);

        return res;
      })
      .catch(error => {
        dispatchSetStatus(bc.STS_CREATE_ERROR);

        setTimeout(function() {
          dispatchSetStatus(bc.STS_IDLE);
        }, 1000);

        return error;
      });
  };

  const find = (filters, options, authorization, loader) => {
    var args = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization
      },
      body: JSON.stringify({ ...filters, options: { sort: { _id: -1 }, ...options} }),
      credentials: "same-origin"
    };

    if (loader) loader.start();

    dispatchSetStatus(bc.STS_FIND_REQUEST);

    return fetch(process.env.domainBranchFind, args)
      .then(res => res.json())
      .then(res => {
        dispatchSetStatus(bc.STS_FIND_RESPONSE);
        dispatchSetStatus(bc.STS_FIND_SUCCESS);

        setTimeout(function() {
          dispatchSetStatus(bc.STS_IDLE);
        }, 1000);

        if (loader) loader.finish();

        res.result.values = res.result.values.map(r => {
          r.longitude = r.location ? r.location.coordinates[0] : "";
          r.latitude = r.location ? r.location.coordinates[1] : "";
          return r;
        });

        return res
      })
      .catch(error => {
        dispatchSetStatus(bc.STS_FIND_ERROR);

        if (loader) loader.finish();

        setTimeout(function() {
          dispatchSetStatus(bc.STS_IDLE);
        }, 1000);

        return error;
      });
  };

  return {
    find,
    create,
    update,
    remove
  };
};
