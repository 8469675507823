import login from "./login.reducer";
import opportunity from "./opportunity.reducer";
import permission from "./permission.reducer";
import background from "./background.reducer";
import business from "./business.reducer";
import benefit from "./benefit.reducer";
import banner from "./banner.reducer";
import loader from "./loader.reducer";
import user from "./user.reducer";
import branch from "./branch.reducer";
import category from "./category.reducer";
import website from "./website.reducer";
import cognito from "./cognito.reducer";

export default {
  login,
  loader,
  branch,
  opportunity,
  permission,
  background,
  business,
  benefit,
  banner,
  user,
  category,
  website,
  cognito
};
