import React, { Fragment, useState } from "react";
import { withRouter } from "react-router-dom";

import { List } from "../../../../wrappers/ListWrappers.react";

import { useFilter } from "@cthulhi/comp-list"

import Access from "@cthulhi/comp-accs";
import Context from "@context";

import Notification from "@cthulhi/comp-noty";
import NotificationComponent from "../../../../utils/Notification.react";
import ModalConfirmRemoval from "../../../../wrappers/ModalConfirmRemoval.react";

import {
  events as discountListEvents,
  values as discountListValues,
  actions as discountListActions,
  options as discountListOptions,
  schema as discountListSchema
} from "./components/DiscountList.config";
import PreGeneratedModal from "../components/PreGeneratedModal.react";

const DiscountListPage = ({ match, history, ...props }) => {

  const { state: accState, action: accAction } = Access.useState();
  const { action: ctxAction } = Context.useState();
  const { state: ntfState, action: ntfAction } = Notification.useState();
  const [ modal, setModal ] = useState({ show: false, Content: () => null });

  const token = `Bearer ${accState.access.session.context.token}`
  const { state } = props.location;

  const onEdit = value => {
    history.push(`${match.path}/edit/${value._id}`);
  };

  const onCsv = (value) => {
    setModal({
      show: true,
      Content: props => {
        return (
          <PreGeneratedModal
            discountId={value._id}
            visible={true}
            onClose={() => setModal({ show: false, Content: () => null })}
          />
        );
      }
    });
  }

  const onRemoveAction = (value, refresh, loader) => {
    loader(true)
    ctxAction("discount")
      .remove({ _id: value._id }, token)
      .then(res => {

        if (res.name != "DiscountDeleteResult") throw res;

        refresh();

        ntfAction("notification").add(
          res.code,
          "discountListPage",
          "success",
          "Desconto removida com sucesso.",
          undefined,
          process.env.notificationTimeOut
        );

      })
      .catch(err => {
        loader(false)

        ntfAction("notification").add(
          err.code,
          "discountListPage",
          "danger",
          "Houve um erro ao remover o desconto.",
          undefined,
          process.env.notificationTimeOut
        );
      });
  };


  const onRemove = (value, refresh, loader) => {
    setModal({
      show: true,
      Content: props => {
        return (
          <ModalConfirmRemoval
            onConfirm={() => onRemoveAction(value, refresh, loader)}
            onClose={() => setModal({ show: false, Content: () => null })}
          />
        );
      }
    });
  }

  const { filter, list, page, limit, options } = useFilter({
    schema: discountListSchema,
    events: discountListEvents(),
    options: discountListOptions({ onEdit, onRemove, onCsv }),
    values: discountListValues({ ctxAction, token }),
    actions: discountListActions({ ctxAction, token, state })
  })

  return (
    <Fragment>
      <h2 className="title">Listar descontos</h2>
      <div className="uk-margin-top">
        <Notification.NotificationWrapper group="discountListPage" component={NotificationComponent} />
        <List { ...{ filter, list, page, limit, options } } />
        <div className="container-modal">{modal.show ? <modal.Content /> : null}</div>
      </div>
    </Fragment>
  );
};

export default withRouter(DiscountListPage);