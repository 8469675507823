import React, { Fragment } from "react";

const DoubleRing = props => {
  return (
    <svg
      width={props.width}
      height={props.height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      style={{background: "none"}}
    >
      <circle
        cx="50"
        cy="50"
        ng-attr-r="{{config.radius}}"
        ng-attr-stroke-width="{{config.width}}"
        ng-attr-stroke="{{config.c1}}"
        ng-attr-stroke-dasharray="{{config.dasharray}}"
        fill="none"
        strokeLinecap="round"
        r="40"
        strokeWidth="4"
        stroke={props.color1}
        strokeDasharray="62.83185307179586 62.83185307179586"
        transform="rotate(110.09 50 50)"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          calcMode="linear"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
          dur="1s"
          begin="0s"
          repeatCount="indefinite"
        ></animateTransform>
      </circle>
      <circle
        cx="50"
        cy="50"
        ng-attr-r="{{config.radius2}}"
        ng-attr-stroke-width="{{config.width}}"
        ng-attr-stroke="{{config.c2}}"
        ng-attr-stroke-dasharray="{{config.dasharray2}}"
        ng-attr-stroke-dashoffset="{{config.dashoffset2}}"
        fill="none"
        strokeLinecap="round"
        r="35"
        strokeWidth="4"
        stroke={props.color2}
        strokeDasharray="54.97787143782138 54.97787143782138"
        strokeDashoffset="54.97787143782138"
        transform="rotate(-110.09 50 50)"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          calcMode="linear"
          values="0 50 50;-360 50 50"
          keyTimes="0;1"
          dur="1s"
          begin="0s"
          repeatCount="indefinite"
        ></animateTransform>
      </circle>
    </svg>
  );
};

export default DoubleRing;
