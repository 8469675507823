import uc from "../constants/userType.constant";

export default (dispatch, state) => {
  const dispatchSetStatus = data => {
    dispatch({
      type: uc.ACT_SET_STATUS,
      data: data
    });
  };

  const remove = (values, authorization) => {
    var args = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization
      },
      body: JSON.stringify(values),
      credentials: "same-origin"
    };

    dispatchSetStatus(uc.STS_DELETE_REQUEST);

    return fetch(process.env.domainUserTypeDelete, args)
      .then(res => res.json())
      .then(res => {
        dispatchSetStatus(uc.STS_DELETE_RESPONSE);
        dispatchSetStatus(uc.STS_DELETE_SUCCESS);

        setTimeout(function() {
          dispatchSetStatus(uc.STS_IDLE);
        }, 1000);

        return res;
      })
      .catch(error => {
        dispatchSetStatus(uc.STS_DELETE_ERROR);

        setTimeout(function() {
          dispatchSetStatus(uc.STS_IDLE);
        }, 1000);

        return error;
      });
  };

  const update = (values, authorization) => {
    var args = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization
      },
      body: JSON.stringify(values),
      credentials: "same-origin"
    };

    dispatchSetStatus(uc.STS_UPDATE_REQUEST);

    return fetch(process.env.domainUserTypeUpdate, args)
      .then(res => res.json())
      .then(res => {
        dispatchSetStatus(uc.STS_UPDATE_RESPONSE);
        dispatchSetStatus(uc.STS_UPDATE_SUCCESS);

        setTimeout(function() {
          dispatchSetStatus(uc.STS_IDLE);
        }, 1000);
        return res;
      })
      .catch(error => {
        dispatchSetStatus(uc.STS_UPDATE_ERROR);

        setTimeout(function() {
          dispatchSetStatus(uc.STS_IDLE);
        }, 1000);

        return error;
      });
  };

  const create = (values, authorization) => {
    var args = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization
      },
      body: JSON.stringify(values),
      credentials: "same-origin"
    };

    dispatchSetStatus(uc.STS_CREATE_REQUEST);

    return fetch(process.env.domainUserTypeCreate, args)
      .then(res => res.json())
      .then(res => {
        dispatchSetStatus(uc.STS_CREATE_RESPONSE);
        dispatchSetStatus(uc.STS_CREATE_SUCCESS);

        setTimeout(function() {
          dispatchSetStatus(uc.STS_IDLE);
        }, 1000);
        return res;
      })
      .catch(error => {
        dispatchSetStatus(uc.STS_CREATE_ERROR);

        setTimeout(function() {
          dispatchSetStatus(uc.STS_IDLE);
        }, 1000);

        return error;
      });
  };

  const find = (filters, options, authorization, loader) => {
    var args = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization
      },
      body: JSON.stringify({ ...filters, options: { sort: { _id: -1 }, ...options } }),
      credentials: "same-origin"
    };

    if (loader) loader.start();

    dispatchSetStatus(uc.STS_FIND_REQUEST);

    return fetch(process.env.domainUserTypeFind, args)
      .then(res => res.json())
      .then(res => {
        dispatchSetStatus(uc.STS_FIND_RESPONSE);
        dispatchSetStatus(uc.STS_FIND_SUCCESS);
        if (loader) loader.finish();

        setTimeout(function() {
          dispatchSetStatus(uc.STS_IDLE);
        }, 1000);

        return res;
      })
      .catch(error => {
        dispatchSetStatus(uc.STS_FIND_ERROR);

        if (loader) loader.finish();

        setTimeout(function() {
          dispatchSetStatus(uc.STS_IDLE);
        }, 1000);

        return error;
      });
  };


  return {
    find,
    create,
    update,
    remove
  };
};
