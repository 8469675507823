import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import Access from "@cthulhi/comp-accs";
import Context from "@context";
import { useFields, useForms } from "@cthulhi/comp-form";

import { schema as categoryFormSchema } from "@schm/category/create.form.schema";

import {
  events as categoryFormEvents,
  values as categoryFormValues,
  actions as categoryFormActions
} from "./components/CategoryForm.config";

import { FieldLabelWrapper } from "../../../wrappers/FormWrappers.react";

import Notification from "@cthulhi/comp-noty";
import NotificationComponent from "../../../utils/Notification.react";

import LoaderComponent from "../../../utils/LoaderCustomComponent.react";

const CategoryFormPage = ({ match, parentPath, history, ...props }) => {
  const { state: accState, action: accAction } = Access.useState();
  const { action: ctxAction, state: ctxtState } = Context.useState();
  const { state: ntfState, action: ntfAction } = Notification.useState();
  const [categorySchema, setCategorySchema] = useState(categoryFormSchema);
  const [validation, setValidation] = useState({})

  const { form, fields, values, updater, cleaner } = useFields({
    schema: categorySchema,
    events: categoryFormEvents({ ctxAction }),
    values: categoryFormValues({
      ctxAction,
      token: `Bearer ${accState.access.session.context.token}`
    })
  });

  const { find, update, create } = useForms(
    categoryFormActions({
      ctxAction,
      ntfAction,
      updater,
      history,
      path: parentPath,
      token: `Bearer ${accState.access.session.context.token}`
    })
  );

  const categoryFormSubmit = async () => {
    let vs = {};

    Object.entries(values).map(([key, value]) => {
      const hasValue = typeof value.value != "undefined" && value.value !== ""
      const hasRawValue = typeof value.rawValue != "undefined" && value.rawValue !== ""    
      
      vs[key] = hasRawValue ? value.rawValue
      : (hasValue ? value.value : value.defaultValue)
    });

    if (vs.iconWebsiteUrl && vs.iconWebsiteUrl.includes("data:")) {
      await fetch(values.iconWebsiteUrl.rawValue)
        .then(res => res.blob())
        .then(blob => {
          vs.iconWebsiteUrlBlob = blob;
        });
    }

    if (vs.backgroundAppUrl && vs.backgroundAppUrl.includes("blob:")) {
      await fetch(values.backgroundAppUrl.rawValue)
        .then(res => res.blob())
        .then(blob => {
          vs.backgroundAppUrlBlob = blob;
        });
    }

    if (vs.iconAppUrl && vs.iconAppUrl.includes("data:")) {
      await fetch(values.iconAppUrl.rawValue)
        .then(res => res.blob())
        .then(blob => {
          vs.iconAppUrlBlob = blob;
        });
    }

    const validate = action => {
      action.then(() => {}).catch(setValidation)
    }

    match.params.id ? validate(update(vs)) : validate(create(vs));
  };

  useEffect(() => {
    if (match.params.id) {
      find({ filters: { _id: match.params.id } }) ;
    }

    return () => {
      cleaner();
    };
  }, [match.params.id]);

  return (
    <Fragment>
      <h2 className="title">{match.params.id ? "Editar categoria" : "Nova categoria"}</h2>
      <div className="uk-margin-top">
        <Notification.NotificationWrapper
          group="categoryForm"
          component={NotificationComponent}
        />
        <LoaderComponent from="categoryForm" />

        <ul data-uk-accordion="multiple: true">
          <li className="uk-open">
          <a className="title title-lead-small title-accordion uk-accordion-title">Configurações gerais</a>
            <div className="uk-accordion-content">
              <div className="uk-card uk-card-default uk-card-body uk-padding-small">
                <form className="uk-grid-small" data-uk-grid>
                  <FieldLabelWrapper name="parentId" fields={fields} values={values} validation={validation.parentId}/>
                  <FieldLabelWrapper name="name" fields={fields} values={values} validation={validation.name}/>
                  <FieldLabelWrapper name="weight" fields={fields} values={values} validation={validation.weight}/>
                  <FieldLabelWrapper name="weightByDate" fields={fields} values={values} validation={validation.weightByDate}/>
                  <FieldLabelWrapper name="status" fields={fields} values={values} validation={validation.status}/>
                </form>
              </div>
            </div>
          </li>
          <li className="uk-open">
            <a className="title title-lead-small title-accordion uk-accordion-title">Configurações do website</a>
            <div className="uk-accordion-content">
              <div className="uk-card uk-card-default uk-card-body uk-padding-small">
                <FieldLabelWrapper name="showMenu" fields={fields} values={values} validation={validation.showMenu}/>
                <FieldLabelWrapper name="iconWebsiteUrl" fields={fields} values={values} validation={validation.iconWebsiteUrl}/>
              </div>
            </div>
          </li>
          <li className="uk-open">
            <a className="title title-lead-small title-accordion uk-accordion-title">Configurações do aplicativo</a>
            <div className="uk-accordion-content">
              <div className="uk-card uk-card-default uk-card-body uk-padding-small">
                <FieldLabelWrapper name="showApp" fields={fields} values={values} validation={validation.showApp}/>
                <FieldLabelWrapper name="iconAppUrl" fields={fields} values={values} validation={validation.iconAppUrl}/>
                <FieldLabelWrapper name="backgroundAppUrl" fields={fields} values={values} validation={validation.backgroundAppUrl}/>
              </div>
            </div>
          </li>
        </ul>
        <div className="uk-margin-top">
          <button
            onClick={categoryFormSubmit}
            className="uk-button uk-button-large uk-button-secondary"
            style={{ float: "right" }}
            disabled={typeof ctxtState.loader.list["categoryForm"] != "undefined" && ctxtState.loader.list["categoryForm"].length > 0}
          >
            {match.params.id ? "Atualizar" : "Salvar"}
          </button>
        </div>
      </div>
    </Fragment>
  );
};

// @ts-ignore
export default withRouter(CategoryFormPage);
