export default {
  ACT_SET_STATUS: "ACT_DCT_STATUS",
  UPDATE_STATE: "DISCOUNT_UPDATE_STATE",

  STS_IDLE: "STS_DCT_IDLE",

  STS_FIND_REQUEST: "STS_DCT_FIND_REQUEST",
  STS_FIND_RESPONSE: "STS_DCT_FIND_RESPONSE",
  STS_FIND_SUCCESS: "STS_DCT_FIND_SUCCESS",
  STS_FIND_ERROR: "STS_DCT_FIND_ERROR",

  STS_CREATE_REQUEST: "STS_DCT_CREATE_REQUEST",
  STS_CREATE_RESPONSE: "STS_DCT_CREATE_RESPONSE",
  STS_CREATE_SUCCESS: "STS_DCT_CREATE_SUCCESS",
  STS_CREATE_ERROR: "STS_DCT_CREATE_ERROR",

  STS_UPDATE_REQUEST: "STS_DCT_UPDATE_REQUEST",
  STS_UPDATE_RESPONSE: "STS_DCT_UPDATE_RESPONSE",
  STS_UPDATE_SUCCESS: "STS_DCT_UPDATE_SUCCESS",
  STS_UPDATE_ERROR: "STS_DCT_UPDATE_ERROR",

  STS_DELETE_REQUEST: "STS_DCT_DELETE_REQUEST",
  STS_DELETE_RESPONSE: "STS_DCT_DELETE_RESPONSE",
  STS_DELETE_SUCCESS: "STS_DCT_DELETE_SUCCESS",
  STS_DELETE_ERROR: "STS_DCT_DELETE_ERROR",
}
