import React, { Fragment } from "react";


const Events = (props) => {   
  return (
    <Fragment>
      <section className="dsp">

        <div className="dsp-title-big">
          <h3>Display Title</h3>
          <span>EVENTOS</span>
        </div>
        <span className="dsp-lead">
          Uma puta dessa, TEY! Quétinhu
        </span>


      </section>


    </Fragment >
  );
}

export default Events;