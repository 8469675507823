import React, { Fragment, useEffect, useState } from "react";
import { Link, Switch, Route, withRouter, Redirect } from "react-router-dom";

import Access from "@cthulhi/comp-accs";

import BannerFormPage from "./BannerFormPage.react";
import BannerListPage from "./BannerListPage.react";

import permissions from "@perm";

const BannerPage = ({ match, ...props }) => {
  return (
    <Switch>
      <Access.RestrictRoute
        key="banner-list"        
        onAccess={[permissions.banner.viewAny, permissions.banner.editAny]}
        parentPath={match.path}
        path={`${match.path}`}
        exact
        onAllow={BannerListPage}
      />
      <Access.RestrictRoute
        key="banner-create"
        onAccess={[permissions.banner.viewAny, permissions.banner.editAny]}
        parentPath={match.path}
        path={`${match.path}/create`}
        onAllow={BannerFormPage}
      />
      <Access.RestrictRoute
        key="banner-edit"
        onAccess={[permissions.banner.editAny, permissions.banner.edit]}
        parentPath={match.path}
        path={`${match.path}/edit/:id`}
        onAllow={BannerFormPage}
      />
    </Switch>
  );
};

export default withRouter(BannerPage);
