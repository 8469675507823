const view = {
  group: {
    title: "Resgate",
    slug: "redemption"
  },
  slug: "redemption-view",
  title: "Ver os próprios resgates",
  description: "Ver os próprios resgates",
  list: [
    "prn::1:redemption-view-roles:redemption-view:allow:lcnt:schema.user.roles:custom.static.manager"
  ]
}

const viewAny = {
  group: {
    title: "Resgate",
    slug: "redemption"
  },
  slug: "redemption-view-any",
  title: "Ver qualquer resgate",
  description: "Ver todos os resgates",
  list: [
    "prn::1:redempetion-view-any-roles:redemption-view-any:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

module.exports = {
  view,
  viewAny
}
