import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";

import LoginFormPwa from "./LoginForm.pwa.react";

import RecoverPwa from "./Recover.pwa.react";
import RecoverVerifyPwa from "./RecoverVerify.pwa.react";
import RecoverUpdatePwa from "./RecoverUpdate.pwa.react";
import RegisterPwa from "./Register.pwa.react";

const AccessViewPwa = ({ match }) => {
  useEffect(() => {
    if (document) {
      const html = document.getElementsByTagName("html")[0];
      const body = document.getElementsByTagName("body")[0];
      const aside = document.getElementById("aside-menu");
      html.removeAttribute("class");
      html.removeAttribute("style");

      body.removeAttribute("class");
      if (aside) {
        document.getElementById("aside-menu").remove();
      }
    }
  }, []);

  return (
    <section className="auth">
      <Switch>
        <Route key="pwa-access" path={`${match.path}`} exact component={LoginFormPwa} />
        <Route key="pwa-access-recover" path={`${match.path}/recover`} component={RecoverPwa} />
        <Route key="pwa-access-verify" path={`${match.path}/verify/:_id`} component={RecoverVerifyPwa} />
        <Route key="pwa-access-update" path={`${match.path}/update/:_id/:code`} component={RecoverUpdatePwa} />
      </Switch>
    </section>
  );
};

export default AccessViewPwa;
