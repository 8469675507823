import { schema, types } from '@cthulhi/pack-schm' 
import { schema as commonSchema } from './common.schema'

const schm = schema('user', {
  ...commonSchema.schema.props,
  email: { 
    type: types.string.type,
    required: { contains: true }
  },
  type: { 
    type: types.string.type,
  }
})

export {
  schm as schema
}