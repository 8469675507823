import React, { Fragment, useEffect, useState } from "react";
import BenefitList from "../utils/BenefitList.react";
import Context from "@context";
import Loader from "@html/views/utils/LoaderSiteComponent.react";
import formatBenefitLocation from "../utils/formatBenefitLocation";

const DisplayBenefitList = props => {
  const { action: ctxAction, state: ctxState } = Context.useState();

  const [isDone, setIsDone] = useState(false);
  const [seeMoreIsLoading, setSeeMoreIsLoading] = useState(false);
  const [benefitList, setBenefitList] = useState();

  const loadBenefitObject = () => {
    setSeeMoreIsLoading(true);
    ctxAction("website")
      .getBenefitList(props.filter, {
        skip: (benefitList || []).length,
        limit: 8,
        ...props.options
      })
      .then(_benefitObject => {
        formatBenefitLocation(_benefitObject, ctxAction).then(newBenefitObjectList => {
          setSeeMoreIsLoading(false);
          setBenefitList(benefitList.concat(newBenefitObjectList.values));
          setIsDone(newBenefitObjectList.values.length < 8);
        });
      });
  };

  useEffect(() => {
    props.benefitList ? setBenefitList(props.benefitList) : null;
    setIsDone(props.benefitList.length < 8);
  }, [props.benefitList]);

  return (
    <Fragment>
      <section className="dsp">
        <div className="dsp-title">
          <h2>{props.title}</h2>
        </div>
        <span className="dsp-lead">{props.description}</span>

        <Loader show={!benefitList} height="120" width="120" />
        <div className="dsp-grid">
          <BenefitList benefitList={benefitList} />
        </div>

        {!isDone && benefitList !== undefined && (
          <div className={"loader-container"}>
            <Loader show={seeMoreIsLoading} height="60" width="60" />
            <button className="dsp-more" onClick={() => loadBenefitObject()}>
              <span>Mostrar mais</span>
              <i className="icn icn-caret-down" aria-hidden="true"></i>
            </button>
          </div>
        )}
      </section>
    </Fragment>
  );
};

export default DisplayBenefitList;
