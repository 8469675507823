import cc from "../constants/category.constant";
import websiteConstant from "@html/constants/website.constant";

export default (dispatch, state) => {
  const dispatchSetStatus = data => {
    dispatch({
      type: cc.ACT_SET_STATUS,
      data: data
    });
  };

  const remove = (values, authorization) => {
    var args = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization
      },
      body: JSON.stringify(values),
      credentials: "same-origin"
    };

    dispatchSetStatus(cc.STS_DELETE_REQUEST);

    return fetch(process.env.domainCategoryDelete, args)
      .then(res => res.json())
      .then(res => {
        dispatchSetStatus(cc.STS_DELETE_RESPONSE);
        dispatchSetStatus(cc.STS_DELETE_SUCCESS);

        setTimeout(function() {
          dispatchSetStatus(cc.STS_IDLE);
        }, 1000);

        return res;
      })
      .catch(error => {
        dispatchSetStatus(cc.STS_DELETE_ERROR);

        setTimeout(function() {
          dispatchSetStatus(cc.STS_IDLE);
        }, 1000);

        return error;
      });
  };

  const update = async (values, authorization) => {

    const updateFetch = args => {
      return fetch(process.env.domainCategoryUpdate, args)
      .then(res => res.json())
      .then(res => {
        dispatchSetStatus(cc.STS_UPDATE_RESPONSE);
        dispatchSetStatus(cc.STS_UPDATE_SUCCESS);

        setTimeout(function() {
          dispatchSetStatus(cc.STS_IDLE);
        }, 1000);

        return res;
      })
      .catch(error => {
        dispatchSetStatus(cc.STS_UPDATE_ERROR);

        setTimeout(function() {
          dispatchSetStatus(cc.STS_IDLE);
        }, 1000);

        return error;
      });
    }

    dispatchSetStatus(cc.STS_UPDATE_REQUEST);

    const documentsToUpload = [
      { field: 'iconAppUrlBlob' }, 
      { field: 'backgroundAppUrlBlob' },
      { field: 'iconWebsiteUrlBlob' }, 
    ];
    
    const imagesUpload = documentsToUpload.map(({ field }) => {
      if (values[field]) {
        const formData = new FormData();
        formData.append("file", values[field]);
  
        return fetch(process.env.domainDocumentUploadImageCategory, {
          method: "POST",
          body: formData,
        })
          .then(res => res.json())
          .then(res => res.result.image)
      }
      return null;
    });

    const [iconAppUrl, backgroundAppUrl, iconWebsiteUrl] = await Promise.all(imagesUpload);
    
    if (iconAppUrl) {
      values.iconAppUrl = iconAppUrl
      delete values.iconAppUrlBlob;
    }
    if (backgroundAppUrl) {
      values.backgroundAppUrl = backgroundAppUrl
      delete values.backgroundAppUrlBlob;
    }
    if (iconWebsiteUrl) {
      values.iconWebsiteUrl = iconWebsiteUrl
      delete values.iconWebsiteUrlBlob;
    }

    if (values.iconAppUrl && values.iconAppUrl.includes("https:")) {
      values.iconAppUrl = values.iconAppUrl.replace(process.env.categoryUrl, "");
    }
    if (values.backgroundAppUrl && values.backgroundAppUrl.includes("https:")) {
      values.backgroundAppUrl = values.backgroundAppUrl.replace(process.env.categoryUrl, "");
    }
    if (values.iconWebsiteUrl && values.iconWebsiteUrl.includes("https:")) {
      values.iconWebsiteUrl = values.iconWebsiteUrl.replace(process.env.categoryUrl, "");
    }

    return updateFetch({
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization
      },
      body: JSON.stringify(values),
      credentials: "same-origin"
    })
  };

  const create = async (values, authorization) => {
    
    const createFetch = (args) => {
      return fetch(process.env.domainCategoryCreate, args)
      .then(res => res.json())
      .then(res => {
        dispatchSetStatus(cc.STS_CREATE_RESPONSE);
        dispatchSetStatus(cc.STS_CREATE_SUCCESS);

        setTimeout(function() {
          dispatchSetStatus(cc.STS_IDLE);
        }, 1000);
        return res;
      })
      .catch(error => {
        dispatchSetStatus(cc.STS_CREATE_ERROR);

        setTimeout(function() {
          dispatchSetStatus(cc.STS_IDLE);
        }, 1000);

        return error;
      });
    }

    dispatchSetStatus(cc.STS_CREATE_REQUEST);

    const documentsToUpload = [
      { field: 'iconAppUrlBlob' }, 
      { field: 'backgroundAppUrlBlob' },
      { field: 'iconWebsiteUrlBlob' }, 
    ];
    
    const imagesUpload = documentsToUpload.map(({ field }) => {
      if (values[field]) {
        const formData = new FormData();
        formData.append("file", values[field]);
  
        return fetch(process.env.domainDocumentUploadImageCategory, {
          method: "POST",
          body: formData,
        })
          .then(res => res.json())
          .then(res => res.result.image)
      }
      return null;
    });

    const [iconAppUrl, backgroundAppUrl, iconWebsiteUrl] = await Promise.all(imagesUpload);
    
    if (iconAppUrl) {
      values.iconAppUrl = iconAppUrl
      delete values.iconAppUrlBlob;
    }
    if (backgroundAppUrl) {
      values.backgroundAppUrl = backgroundAppUrl
      delete values.backgroundAppUrlBlob;
    }
    if (iconWebsiteUrl) {
      values.iconWebsiteUrl = iconWebsiteUrl
      delete values.iconWebsiteUrlBlob;
    }

    return createFetch({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization
      },
      body: JSON.stringify(values),
      credentials: "same-origin"
    });
  };

  const find = (filters, options, authorization, loader) => {
    var args = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization
      },
      body: JSON.stringify({ ...filters, options: { sort: { _id: -1 }, ...options} }),
      credentials: "same-origin"
    };

    if (loader) loader.start();

    dispatchSetStatus(cc.STS_FIND_REQUEST);

    return fetch(process.env.domainCategoryFind, args)
      .then(res => res.json())
      .then(res => {
        dispatchSetStatus(cc.STS_FIND_RESPONSE);
        dispatchSetStatus(cc.STS_FIND_SUCCESS);

        if (loader) loader.finish();

        setTimeout(function() {
          dispatchSetStatus(cc.STS_IDLE);
        }, 1000);

        if (res.result.values) {
          res.result.values.map(category => {
            if (category.iconAppUrl) {
              category.iconAppUrl = process.env.categoryUrl + category.iconAppUrl;
            }
            if (category.backgroundAppUrl) {
              category.backgroundAppUrl = process.env.categoryUrl + category.backgroundAppUrl;
            } 
            if (category.iconWebsiteUrl) {
              category.iconWebsiteUrl = process.env.categoryUrl + category.iconWebsiteUrl;
            }
            return category;
          });
        }

        return res;
      })
      .catch(error => {

        dispatchSetStatus(cc.STS_FIND_ERROR);

        if (loader) loader.finish();

        setTimeout(function() {
          dispatchSetStatus(cc.STS_IDLE);
        }, 1000);

        return error;
      });
  };

  return {
    find,
    create,
    update,
    remove
  };
};
