import React, { Fragment } from "react";
import { schema as schm, types } from "@cthulhi/pack-schm";
import { types as fields } from "@cthulhi/comp-form";

const schema = schm("filter", {
  name: {
    type: types.string.type,
    field: fields.textfield.type
  },
  email: {
    type: types.string.type,
    field: fields.textfield.type
  },
  perfil: {
    type: types.string.type,
    field: fields.select.type
  }
});
const events = ({ ctxAction, ...props }) => {
  return {};
};

const values = ({ ctxAction, ...props }) => {
  return {
    name: {
      filter: "text",
      value: "",
      className: "uk-input",
      placeholder: "Nome",
      label: {
        text: "Nome",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      }
    },
    email: {
      filter: "text",
      value: "",
      className: "uk-input",
      placeholder: "E-mail",
      label: {
        text: "E-mail",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      }
    },
    perfil: {
      value: "",
      className: "uk-select",
      label: {
        text: "Perfil",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      },
      options: update => {
        update("perfil", {
          options: [
            { value: "", label: "Selecione um perfil..." },
            { value: "ARBS", label: "Associado ANSC" },
            { value: "VITALICIO", label: "Sócio vitalício" },
            { value: "FUNCIONARIO", label: "Funcionário Clube NSC" }
          ]
        });
      }
    }
  };
};

const options = ({ onEdit, onRemove, ...props }) => {
  return {
    filter: {
      fields: true,
      submit: true
    },
    pagination: {
      next: {
        value: "Próximo",
        className: "page-next"
      },
      prev: {
        value: "Anterior",
        className: "page-prev"
      },
      numbers: {
        className: "page-numbers"
      },
      total: true
    },
    limit: {
      value: 5,
      limits: [
        { value: 5, label: "5 Items" },
        { value: 10, label: "10 Items" },
        { value: 20, label: "20 Items" }
      ]
    },
    items: {
      name: {
        header: {
          value: "Nome",
          className: ""
        },
        column: {
          className: "",
          value: "name"
        }
      },
      email: {
        header: {
          value: "E-mail",
          className: ""
        },
        column: {
          className: "",
          value: "email"
        }
      },
      status: {
        header: {
          value: "Status da Conta",
          className: ""
        },
        column: "status",
        parse: value => {
          if (value == true) return "Ativo";
          return "Inativo";
        }
      },
      actions: {
        header: "Ações",
        column: (item, refresh, loader) => {
          return {
            value: (
              <Fragment>
                <div className="uk-grid-small uk-child-width-auto" data-uk-grid>
                  <div className="uk-text-nowrap">
                    <button
                      className="uk-icon-button uk-button-primary"
                      onClick={() => onEdit(item, refresh, loader)}
                    >
                      <i className="icon icon-edit" aria-hidden="true"></i>
                    </button>
                    <button
                      className="uk-icon-button uk-button-danger uk-margin-small-left"
                      onClick={() => onRemove(item, refresh, loader)}
                    >
                      <i className="icon icon-delete" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </Fragment>
            )
          };
        }
      }
    }
  };
};

const actions = ({ ctxAction, token, ...props }) => {
  return {
    find: async (filters, options) => {
      return ctxAction("userVip").find(filters, options, token);
    },
    downloadCSV: async (filters, options) => {
      return ctxAction("userVip").downloadCSV(filters, options, token);
    }
  };
};

export { events, values, actions, options, schema };
