const optionLoading = { value: "", label: "Carregando...", disabled: true };
const optionSelectState = { value: "", label: "Selecione um estado..." };
const optionSelectCity = { value: "", label: "Selecione uma cidade..." };
const optionSelectType = { value: "", label: "Selecione um tipo..." };

const events = ({ ctxAction, ...props }) => {
  return {
    zipcode: {
      onChange: (value, rawValue, update) => {
        if (value.length == 10) {
          ctxAction("address")
            .findAddressByZipCode(value.replace(/\./g, "").replace(/\-/g, ""))
            .then(resAddress => {
              update("district", { value: resAddress.district }, resAddress.district);
              update("address", { value: resAddress.address }, resAddress.address);

              update("state", { disabled: true, options: [optionLoading] });
              ctxAction("address")
                .findState(resAddress.uf)
                .then(resState => {
                  if (resState) {
                    const filter = resState.filter(u => {
                      return u.selected;
                    });
                    const cityId = filter.length > 0 ? filter[0].value : ""


                    update(
                      "state",
                      { disabled: false, value: cityId, options: [optionSelectState, ...resState] },
                      cityId
                    );

                    update("city", { disabled: true, options: [optionLoading] });
                    ctxAction("address")
                      .findCity(cityId)
                      .then(resCity => {
                        update(
                          "city",
                          {
                            disabled: false,
                            value: resAddress.city,
                            options: [optionSelectCity, ...resCity]
                          },
                          resAddress.city
                        );
                      });
                  }
                });
            });
        }

        return update("zipcode", { value }, rawValue);
      }
    },
    city: {
      onChangeFromState: (field, rawValue, update) => {
        update("city", { value: "", options: [{ value: "", label: "Carregando..." }] });
        ctxAction("address")
          .findCity(field.value)
          .then(res => update("city", { disabled: false, options: [optionSelectCity, ...res] }));
      }
    }
  };
};

const values = ({ ctxAction, ...props }) => {
  let initValues = {
    name: {
      placeholder: "Nome",
      value: "",
      label: {
        text: "Nome",
        className: "input"
      },
      wrapper: {
        className: "input column-one"
      }
    },
    title: {
      placeholder: "Titulo",
      value: "",
      label: {
        text: "Titulo",
        className: "input"
      },
      wrapper: {
        className: "input"
      }
    },
    cpf: {
      className: "input",
      placeholder: "CPF",
      value: "",
      masks: ["999.999.999-99"],
      label: {
        text: "CPF",
        className: "input"
      },
      wrapper: {
        className: "input"
      }
    },
    email: {
      className: "input",
      placeholder: "Email",
      value: "",
      label: {
        text: "Email",
        className: "input"
      },
      wrapper: {
        className: "input"
      }
    },
    phone: {
      className: "input",
      placeholder: "Telefone",
      masks: ["(99) 9999-9999", "(99) 99999-9999", "+99 (99) 9999-9999", "+99 (99) 99999-9999"],
      value: "",
      label: {
        text: "Telefone",
        className: "input"
      },
      wrapper: {
        className: "input"
      },
      options: [
        optionSelectType,
        { value: "fisico", label: "Físico" },
        { value: "virtual", label: "Virtual" }
      ]
    },
    subject: {
      className: "input",
      label: {
        text: "Assunto",
        className: "input"
      },
      wrapper: {
        className: "input"
      },
      value: "select",
      options: [
        { value: "", label: "Selecione..." },
        { value: "duvidas", label: "Dúvidas" },
        { value: "reclamacoes", label: "Reclamações" },
        { value: "problemas", label: "Problemas no cadastro" },
        { value: "parceiros", label: "Parceiros" },
        { value: "promocoes", label: "Promoções" },
        { value: "outros", label: "Outros Assuntos" }
      ]
    },
    news: {
      className: "input",
      label: {
        text: "Jornal",
        className: "input"
      },
      wrapper: {
        className: "input"
      },
      value: "select",
      options: [
        { value: "", label: "Selecione..." },
        { value: "diarioc", label: "Diário Catarinense" },
        { value: "anoticia", label: "A Notícia" },
        { value: "jsc", label: "Jornal de Santa Catarina" },
        { value: "pioneiro", label: "Pioneiro" },
        { value: "zerohora", label: "Zero Hora" }
      ]
    },
    message: {
      Id: "subject",
      placeholder: "Mensagem",
      value: "",
      label: {
        text: "Mensagem",
        Id: "subject",
      },
      wrapper: {
        className: "input column-one"
      }
    }
  };

  return initValues;
};

const actions = ({ token, ctxAction, ntfAction, updater, history, path, ...props }) => {
  return {
    entity: {
      name: "website",
      token: token,
      form: ctxAction("form")
    },
    actions: {
      entity: ctxAction,
      notification: ntfAction,
      updater: updater
    },
    sendMessage: {
      action: "submit",
      success: {
        name: "EmailSendResult",
        message: "Sua mensagem foi enviada com sucesso",
        action: { notify: "websiteForm", redirect: false }
      },
      error: {
        name: "EmailNotSendedError",
        message: "Houve um erro ao enviar sua mensagem",
        action: { notify: "websiteForm", redirect: false }
      }
    },
    find: {
    },
    create: {
    },
    update: {
    },
  };
};

export { events, values, actions };
