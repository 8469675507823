import React, { Fragment, useEffect, useState } from "react";
import Context from "./../../../context";
import { Link } from "react-router-dom";
import Loader from "@html/views/utils/LoaderSiteComponent.react";
import { TYPE_VALUES } from "@schm/banner/values.const";

const Mosaic = props => {
  const { state: ctxState, action: ctxAction } = Context.useState();

  const [bannerList, setBannerList] = useState([]);
  const [hideBanner, setHideBanner] = useState(false);

  const transformData = banner => {
    return {
      title: getBannerTitle(banner),
      description: getBannerDescription(banner),
      redirectUrl: getRedirectURL(banner),
      isLinkExternal: getIsLinkExternal(banner),
      imageUrl: getBannerImageUrl(banner),
      discountType: getBannerDiscountType(banner),
      discount: getBannerDiscount(banner),
      key: banner.key
    };
  };

  const getBannerTitle = (bannerItem) => {
    if (bannerItem && bannerItem.title) return bannerItem.title;
    if (bannerItem && bannerItem.benefit && bannerItem.benefit.title) return bannerItem.benefit.title;
    return "";
  }

  const getBannerDescription = (bannerItem) => {
    if (bannerItem && bannerItem.description) return bannerItem.description;
    if (bannerItem && bannerItem.benefit && bannerItem.benefit.resume) return bannerItem.benefit.resume;
    return "";
  }

  const getRedirectURL = (bannerItem) => {
    if (bannerItem && bannerItem.redirectUrl) return bannerItem.redirectUrl;
    if (bannerItem && bannerItem.benefit && bannerItem.benefit.slug) return `/beneficio/${bannerItem.benefit.slug}`;
    return "";
  }

  const getIsLinkExternal = (bannerItem) => {
    if (bannerItem && bannerItem.redirectUrl) return true;
    return false;
  }

  const getBannerImageUrl = (bannerItem) => {
    if (bannerItem && bannerItem.imageUrl) {
      return `${process.env.bannerUrl + bannerItem.imageUrl.replace("-full", "-large")}`;
    }

    if (bannerItem && bannerItem.benefit && bannerItem.benefit.bannerUrl) {
      return `${process.env.bannerUrl + bannerItem.benefit.bannerUrl.replace("-full", "-large")}`;
    }

    return `${process.env.bannerUrl}large_beneficio20190220035556.png`;
  }

  const getBannerDiscountType = (bannerItem) => {
    if (bannerItem && bannerItem.benefit && bannerItem.benefit.discountType) return bannerItem.benefit.discountType;
    return ""
  }

  const getBannerDiscount = (bannerItem) => {
    if (bannerItem && bannerItem.benefit && bannerItem.benefit.discount) return bannerItem.benefit.discount;
    return "";
  }

  const getBanners = sliderLength => {
    return ctxAction("website")
      .getBannerList(
        {},
        {
          skip: 0,
          limit: sliderLength,
          orderby: { weight: 1 }
        }
      )
      .then(bannerList => {
        return bannerList
          .filter(
            bannerItem =>
              bannerItem.benefit.length > 0 || bannerItem.type === TYPE_VALUES.NO_BENEFIT
          )
          .map(banner => ({
            ...banner,
            benefit: banner.benefit.length ? banner.benefit[0] : null,
            key: `banner_${banner._id}`
          }))
          .filter(i => i !== undefined);
      });
  };

  const getEvents = eventsNeeded => {
    return ctxAction("website")
      .getEventList(
        {},
        {
          skip: 0,
          limit: eventsNeeded,
          orderby: { "events.0": 1 }
        }
      )
      .then(eventResult => {
        return [
          ...eventResult.values.map(event => ({
            benefit: event,
            key: `events_${event._id}`
          }))
        ];
      });
  };

  const repeatBanners = (bannersFound, numberOfRepeats) => {
    const newBannerList = [...bannersFound];
    for (let i = 0; i < numberOfRepeats; i++) {
      newBannerList.push({
        ...bannersFound[0],
        key: `duplicate_${bannersFound[0]._id}_${i}`
      });
    }
    return newBannerList;
  };

  useEffect(() => {
    const sliderLength = 3;

    getBanners(sliderLength)
      .then(bannersFound => {
        const eventsNeeded = sliderLength - bannersFound.length;
        if (eventsNeeded === 0) {
          return bannersFound;
        }
        return getEvents(eventsNeeded).then(eventsFound => [...bannersFound, ...eventsFound]);
      })
      .then(bannerList => {
        const banners = bannerList.map(transformData);
        if (banners.length < sliderLength && banners.length > 1) {
          const numberOfRepeats = sliderLength - banners.length;
          const newBannerList = repeatBanners(banners, numberOfRepeats);
          setBannerList(newBannerList);
        } else {
          setBannerList(banners);
        }
      })
      .catch(err => {
        console.log(err);
        setHideBanner(true);
      });
  }, []);

  const SmartLink = ({ isLinkExternal, link, className, children, key }) => {
    if (link === "") {
      return <div className={className} key={key}>{children}</div>
    }

    if (link.includes("https://") || link.includes("http://")) {
      return <a target="_blank" className={className} href={link} key={key}>{children}</a>
    }

    return <Link className={className} to={link} key={key}>{children}</Link>
  };

  if (hideBanner) {
    return <Fragment></Fragment>;
  }

  return (
    <Fragment>
      <Loader show={!bannerList} height="120" width="120" />
      <section id="ftd">
        {bannerList.map((banner, index) => (
          <SmartLink
            isLinkExternal={banner.isLinkExternal}
            className="ftd-item"
            key={`${banner.key}_${index}`}
            link={banner.redirectUrl}
          >
            <img className="ftd-item-image" src={banner.imageUrl} />
            <div className="ftd-item-lens" aria-hidden="true"></div>
            <div className="ftd-item-content">
              <div className="title">
                {index === 0 && banner.discount && (
                  banner.discountType && banner.discountType === "percent" && +banner.discount === 100 ?
                    <span className="badge badge-orange">
                      <strong>FREE</strong>
                    </span> :
                    <span className="badge badge-teal">
                      <div style={{ paddingLeft: 8 }}>
                        {banner.discountType === "plain" && (<small>R$</small>)}
                        {banner.discount}
                        {(banner.discountType === "percent" || !banner.discountType) && (<small>%</small>)}
                      </div>
                    </span>
                )}
                <h2>{banner.title}</h2>
              </div>
              {index === 0 && <span className="lead">{banner.description}</span>}
            </div>
          </SmartLink>
        ))}
      </section>
    </Fragment>
  );
};

export default Mosaic;
