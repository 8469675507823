import timeoutPromise from "../helpers/timeoutPromise";

export default (dispatch, state) => {

  const findAddressByZipCode = (zipcode) => {
    return timeoutPromise(
      process.env.defaultTimeout,
      fetch(`https://viacep.com.br/ws/${zipcode}/json/`)
    )
      .then((res) => res.json())
      .then((res) => ({
        zip: res.cep,
        uf: res.uf,
        city: res.ibge,
        district: res.bairro,
        address: res.logradouro
      }))
      .catch((error) => error);
  };


  const findCity = (state) => {
    return timeoutPromise(
      process.env.defaultTimeout,
      fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/municipios`)
    )
      .then((res) => res.json())
      .then((res) => {
        const options = res.map((result) => ({
          key: result.id,
          value: result.id,
          label: result.nome
        })).sort((a, b) => {
          if (a.label < b.label) return -1;
          if (a.label > b.label) return 1;
          return 0;
        });
        return options;
      })
      .catch((error) => error);
  };

  const findState = (uf) => {
    return timeoutPromise(
      process.env.defaultTimeout,
      fetch("https://servicodados.ibge.gov.br/api/v1/localidades/estados")
    )
      .then((res) => res.json())
      .then((results) => {
        let options = results.map((result) => {
          return {
            key: result.id,
            value: result.id,
            label: result.nome,
            ...(result.sigla == uf ? { selected: true } : {})
          };
        });
        options = options.sort((a, b) => {
          if (a.label < b.label) return -1;
          if (a.label > b.label) return 1;
          return 0;
        });

        return options;
      });
  };

  const findStateByListId = (list) => {

    return timeoutPromise(
      process.env.defaultTimeout,
      fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${list}`)
    )
      .then((res) => res.json())
      .then((res) => res);
  };

  const findCityByListId = (list) => {

    return timeoutPromise(
      process.env.defaultTimeout,
      fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/municipios/${list}`)
    )
      .then((res) => res.json())
      .then((res) => res)
      .catch((error) => error);
  };

  const findLatLngByAddress = (address, coords) => {

    return timeoutPromise(
      process.env.defaultTimeout,
      fetch(`https://maps.googleapis.com/maps/api/geocode/json?&address=${encodeURI(address)}&key=${process.env.googleMapsApiKey}`)
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.results.length > 0) {
          return {
            latitude: res.results[0].geometry.location.lat,
            longitude: res.results[0].geometry.location.lng
          };
        }
        return coords;
      });
  };

  return {
    findLatLngByAddress,
    findAddressByZipCode,
    findState,
    findStateByListId,
    findCityByListId,
    findCity
  };
};
