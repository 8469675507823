import React, { useState, useEffect, useCallback, Fragment, useRef } from "react";

import { useFields } from "./../comp-form";

const useFilter = ({ schema, events, values: initValues = {}, options, actions, ...props }) => {
  const [count, setCount] = useState();
  const [limit, setLimit] = useState(options.limit.value ? options.limit.value : 10);
  const [offset, setOffset] = useState(0);
  const [timer, setTimer] = useState();
  const [isLoading, setIsLoading] = useState();
  const [force, setForce] = useState(0);

  const [items, setItems] = useState([]);

  const { form, fields, values, updater, cleaner } = useFields({
    schema,
    events,
    values: initValues
  });

  const filter = () => {
    const fts = {};
    const arr = Object.keys(values);

    for (let index = 0; index < arr.length; index++) {
      const key = arr[index];

      if (values[key] && values[key].rawValue && values[key].rawValue != "-") {
        switch (values[key].filter) {
          case "regex":
            fts[key] = { $regex: values[key].rawValue };
            break;
          case "gt":
            fts[key] = { $gt: values[key].rawValue };
            break;
          case "gte":
            fts[key] = { $gte: values[key].rawValue };
            break;
          case "lt":
            fts[key] = { $lt: values[key].rawValue };
            break;
          case "lte":
            fts[key] = { $lte: values[key].rawValue };
            break;
          case "in":
            fts[key] = { $in: values[key].rawValue };
            break;
          case "ne":
            fts[key] = { $ne: values[key].rawValue };
            break;
          case "exists":
            fts[key] = { $exists: values[key].rawValue ? true : false };
            break;
          case "text":
            fts[key] = { $regex: values[key].rawValue, $options: "i" };
            // fts["$text"] = {
            //   "$search": values[key].rawValue
            // }
            break;

          default:
            if(form[key] && form[key].type == "boolean") {
              fts[key] = values[key].value == "true";
            } else {
              fts[key] = values[key].rawValue;
            }
            break;
        }
      }
    }

    return fts;
  };

  const find = () => {
    actions
      .find({ filters: filter() }, { limit, skip: offset })
      .then(res => {
        setCount(res.result.count);
        setItems(res.result.values);
        setIsLoading(false);
        return res;
      })
      .catch(err => {
        setIsLoading(false);
        return err;
      });
  };

  const canNext = () => {
    return curr() < pages() - 1 && !isLoading;
  };

  const canPrev = () => {
    return curr() > 0 && !isLoading;
  };

  const next = () => {
    if (canNext()) setOffset(offset ? offset + limit : limit);
    setIsLoading(true);
  };

  const prev = () => {
    if (canPrev()) setOffset(offset - limit);
    setIsLoading(true);
  };

  const page = page => {
    setOffset(limit * page);
    setIsLoading(true);
  };

  const curr = () => {
    return offset ? offset / limit : 0;
  };

  const pages = () => {
    return Math.ceil(count / limit);
  };

  const setLimitAction = value => {
    setLimit(value);
    setOffset(0);
    setIsLoading(true);
  };

  useEffect(() => {
    if (values) {
      clearTimeout(timer);
      setTimer(
        setTimeout(() => {
          setIsLoading(true);
          find();
        }, 1000)
      );
    }
  }, [offset, limit, force]);

  useEffect(() => {
    setOffset(0);
    setForce(Math.floor(Math.random() * 1000) + 1);
  }, [JSON.stringify(Object.values(values).map(item => item.value))]);

  return {
    filter: {
      form,
      fields,
      values,
      updater,
      cleaner
    },
    list: {
      items,
      count,
      find,
      refresh: find,
      loader: setIsLoading
    },
    page: {
      canNext: canNext(),
      canPrev: canPrev(),
      next,
      prev,
      page,
      pages: pages(),
      curr: curr(),
      isLoading
    },
    limit: {
      limit,
      limits: [],
      updater: setLimitAction
    },
    options
  };
};

export { useFilter };
