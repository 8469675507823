
const state = {
  isHome: true
};

const reducer = (state, action) => {

  switch (action.type) {
   
    case "IS_HOME":
      return { ...state, isHome: action.data }

    default:
      return state; 
  }

};

export default { state, reducer }