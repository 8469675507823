import React, { Fragment } from "react";
import { schema as schm, types } from "@cthulhi/pack-schm";
import { types as fields } from "@cthulhi/comp-form";
import moment from "moment";

const schema = schm("filter", {
  qrcode: {
    type: types.string.type,
    field: fields.textfield.type
  },
  business: {
    type: types.string.type,
    field: fields.textfield.type
  },
  status: {
    type: types.string.type,
    field: fields.select.type
  }
});
const events = ({ ctxAction, ...props }) => {
  return {};
};

const values = ({ ctxAction, ...props }) => {
  return {
    qrcode: {
      filter: "text",
      value: "",
      className: "uk-input",
      placeholder: "QR code",
      label: {
        text: "QR code",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      }
    },
    business: {
      filter: "text",
      value: "",
      className: "uk-input",
      placeholder: "Nome do parceiro",
      label: {
        text: "Parceiro",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      }
    },
    status: {
      value: "",
      className: "uk-select",
      label: {
        text: "Status",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      },
      options: update => {
        update("status", {
          options: [
            { value: "", label: "Selecione um status..." },
            { value: "pending", label: "Não usado" },
            { value: "tovalidate", label: "Usado, não avaliado" },
            { value: "used", label: "Usado e avaliado" },
            { value: "removedByUser", label: "Removido pelo usuário" },
          ]
        });
      }
    }
  };
};

const options = ({ onEdit, ...props }) => {
  return {
    filter: {
      fields: true,
      submit: true
    },
    pagination: {
      next: {
        value: "Próximo",
        className: "page-next"
      },
      prev: {
        value: "Anterior",
        className: "page-prev"
      },
      numbers: {
        className: "page-numbers"
      },
      total: true
    },
    limit: {
      value: 5,
      limits: [
        { value: 5, label: "5 Items" },
        { value: 10, label: "10 Items" },
        { value: 20, label: "20 Items" }
      ]
    },
    items: {
      qrcode: {
        header: {
          value: "QR code",
          className: ""
        },
        column: {
          className: "",
          value: "qrcode"
        }
      },
      consumer: {
        header: {
          value: "Usuário",
          className: ""
        },
        column: {
          className: "",
          value: "consumer"
        },
        parse: consumer => {
          return consumer ? `${consumer.given_name} ${consumer.last_name}` : "";
        }
      },
      business: {
        header: {
          value: "Parceiro",
          className: ""
        },
        column: {
          className: "",
          value: "benefit"
        },
        parse: benefit => {
          return benefit[0]
            ? benefit[0].business
              ? benefit[0].business[0]
                ? benefit[0].business[0].name
                : ""
              : ""
            : "";
        }
      },
      generatedAt: {
        header: {
          value: "Data Geração",
          className: ""
        },
        column: {
          className: "",
          value: "createdAt"
        },
        parse: createdAt => {
          return moment(createdAt).format("DD / MM / YYYY HH:mm");
        }
      },
      usedAt: {
        header: {
          value: "Data de Utilização",
          className: ""
        },
        column: {
          className: "",
          value: "redemption"
        },
        parse: redemption => {
          return redemption && redemption[0] && redemption[0].createdAt
            ? moment(redemption[0].createdAt).format("DD / MM / YYYY HH:mm")
            : "";
        }
      },
      status: {
        header: {
          value: "Status",
          className: ""
        },
        column: "status",
        parse: value => {
          if (value == "pending") return "Não usado";
          if (value == "tovalidate") return "Usado, não avaliado";
          if (value == "used") return "Usado e avaliado";
          if (value == "removedByUser") return "Removido pelo usuário";
          return "";
        }
      },
      actions: {
        header: "Ações",
        column: (item, refresh, loader) => {
          return {
            value: (
              <Fragment>
                <div className="uk-grid-small uk-child-width-auto" data-uk-grid>
                  <div className="uk-text-nowrap">
                    <button
                      className="uk-icon-button uk-button-primary"
                      onClick={() => onEdit(item, refresh, loader)}
                    >
                      <i className="icon icon-edit" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </Fragment>
            )
          };
        }
      }
    }
  };
};

const actions = ({ ctxAction, token, ...props }) => {
  return {
    find: async (filters, options) => {
      return ctxAction("voucher").getList(filters, options, token);
    },
    downloadCSV: async (filters, options) => {
      return ctxAction("voucher").downloadCSV(filters, options, token);
    }
  };
};

export { events, values, actions, options, schema };
