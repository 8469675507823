import React, { Fragment, useEffect, useState } from "react";
import { Link, Switch, Route, withRouter } from "react-router-dom";

import Access from "@cthulhi/comp-accs";
import Context from "@context";
import { useFields, useForms } from "@cthulhi/comp-form";

import { schema as businessFormSchema } from "@schm/business/create.form.schema";

import {
  events as businessFormEvents,
  values as businessFormValues,
  actions as businessFormActions
} from "./components/BusinessProfileForm.config";

import { FieldLabelWrapper } from "../../../wrappers/FormWrappers.react";

import Notification from "@cthulhi/comp-noty";
import NotificationComponent from "../../../utils/Notification.react";

import LoaderComponent from "../../../utils/LoaderCustomComponent.react";

const BusinessProfileFormPage = ({ match, parentPath, history, ...props }) => {
  const { state: accState, action: accAction } = Access.useState();
  const { action: ctxAction, state: ctxtState } = Context.useState();
  const { state: ntfState, action: ntfAction } = Notification.useState();

  const { form, fields, values, updater, cleaner } = useFields({
    schema: businessFormSchema,
    events: businessFormEvents({ ctxAction }),
    values: businessFormValues({ ctxAction })
  });

  const { find, update } = useForms(
    businessFormActions({
      ctxAction,
      ntfAction,
      updater,
      history,
      path: parentPath,
      token: `Bearer ${accState.access.session.context.token}`
    })
  );

  useEffect(() => {
    find({ filters: { } });

    return () => {
      cleaner();
    };
  }, []);

  const businessFormSubmit = () => {
    let vs = {};

    Object.entries(values).map(([key, value]) => {
      const hasValue = typeof value.value != "undefined" && value.value !== ""
      const hasRawValue = typeof value.rawValue != "undefined" && value.rawValue !== ""    
      
      vs[key] = hasRawValue ? value.rawValue
      : (hasValue ? value.value : value.defaultValue)
    });

    update(vs);
  };

  return (
    <Fragment>
      <h2 className="title">Meu cadastro</h2>
      <div className="uk-margin-top">
        <ul data-uk-accordion="multiple: true">
          <li className="uk-open">
            <a className="title title-lead-small title-accordion uk-accordion-title">
              Meus dados
            </a>
            <div className="uk-accordion-content">
              <Notification.NotificationWrapper
                group="businessForm"
                component={NotificationComponent}
              />
              <div className="uk-card uk-card-default uk-card-body uk-padding-small">
                <LoaderComponent from="businessForm" />
                <div className="uk-grid-small uk-grid">
                  <FieldLabelWrapper name="alias" fields={fields} values={values} />
                  <FieldLabelWrapper name="stage" fields={fields} values={values} />

                  <FieldLabelWrapper name="name" fields={fields} values={values} />
                  <FieldLabelWrapper name="cnpj" fields={fields} values={values} />
                  <FieldLabelWrapper name="zipcode" fields={fields} values={values} />
                  <FieldLabelWrapper name="address" fields={fields} values={values} />

                  <FieldLabelWrapper name="district" fields={fields} values={values} />
                  <FieldLabelWrapper name="number" fields={fields} values={values} />
                  <FieldLabelWrapper name="complement" fields={fields} values={values} />
                  <FieldLabelWrapper name="state" fields={fields} values={values} />
                  <FieldLabelWrapper name="city" fields={fields} values={values} />

                  <FieldLabelWrapper name="phone" fields={fields} values={values} />
                  <FieldLabelWrapper name="email" fields={fields} values={values} />
                  <FieldLabelWrapper name="site" fields={fields} values={values} />
                  <FieldLabelWrapper name="contact" fields={fields} values={values} />
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div className="uk-margin-top">
        <button
          onClick={businessFormSubmit}
          className="uk-button uk-button-large uk-button-secondary"
          style={{ float: "right" }}
          disabled={typeof ctxtState.loader.list["businessForm"] != "undefined" && ctxtState.loader.list["businessForm"].length > 0}
        >
         Atualizar
        </button>
      </div>
    </Fragment>
  );
};

// @ts-ignore
export default withRouter(BusinessProfileFormPage);
