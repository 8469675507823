import React  from "react"

const Checkbox = ({ value, rawValue, name, masker, required, ...props }) => {

  const checked = typeof rawValue != "undefined" ?  (rawValue ? true : false) : (value ? true : false)
  
  return(
    <input 
      id={name}
      {...props}
      { ...(checked ? { checked } : { checked: false }) }
      name={name}
      value={name ? name : ""} />
  )
}

export default Checkbox