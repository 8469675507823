import cc from '../constants/category.constant'

const state = {
  status: cc.STS_IDLE,
  categoryList: [],
};

const reducer = (state, action) => {
  switch (action.type) {

    case cc.ACT_SET_STATUS:
      const sals = { ...state }

      sals.status = action.data

      return sals

    case cc.UPDATE_STATE:
      return {
        ...state,
        ...action.data,
      }

    default:
      return state
  }
};

export default { state, reducer }
