const view = {
  group: {
    title: "Eventos",
    slug: "event"
  },
  slug: "event-view",
  title: "Ver eventos",
  description: "Ver os próprios eventos",
  list: [
    "prn::1:event-view-roles:event-view:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

const viewAny = {
  group: {
    title: "Eventos",
    slug: "event"
  },
  slug: "event-view-any",
  title: "Ver qualquer categoria",
  description: "Ver todos os eventos",
  list: [
    "prn::1:event-view-any-roles:event-view-any:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

const edit = {
  group: {
    title: "Eventos",
    slug: "event"
  },
  slug: "event-edit",
  title: "Editar eventos",
  description: "Editar os próprios eventos",
  list: [
    "prn::1:event-edit-roles:event-edit:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

const editAny = {
  group: {
    title: "Eventos",
    slug: "event"
  },
  slug: "event-edit-any",
  title: "Editar qualquer categoria",
  description: "Editar todos os eventos",
  list: [
    "prn::1:event-edit-any-roles:event-edit-any:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

const create = {
  group: {
    title: "Eventos",
    slug: "event"
  },
  slug: "event-create",
  title: "Criar eventos",
  description: "Criar os próprios eventos"
}

const createAny = {
  group: {
    title: "Eventos",
    slug: "event"
  },
  slug: "event-create-any",
  title: "Criar qualquer categoria",
  description: "Criar todos os eventos"
}

const remove = {
  group: {
    title: "Eventos",
    slug: "event"
  },
  slug: "event-remove",
  title: "Remover eventos",
  description: "Remover os próprios eventos"
}

const removeAny = {
  group: {
    title: "Eventos",
    slug: "event"
  },
  slug: "event-remove-any",
  title: "Remover qualquer categoria",
  description: "Remover todos os eventos"
}

module.exports = {
  view,
  viewAny,
  edit,
  editAny,
  create,
  createAny,
  remove,
  removeAny
}