import React, { Component, Fragment, useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";

import Context from "../../../context";
import Access from "@cthulhi/comp-accs";
import Notification from "@cthulhi/comp-noty";

import { schema as userRecoverVerify } from "@schm/access/recoverVerify.schema";

import NotificationComponent from "./../../utils/Notification.react";
import BackButton from "./BackButton.react";

const RecoverVerify = ({ match, history }) => {
  const { state: ctxState, action: ctxAction, dispatch: ctxDispatch } = Context.useState();
  const { state: ntfState, action: ntfAction } = Notification.useState();
  const { state: accState, action: accAction, consts: accConts } = Access.useState();

  const isLoading =
    accState.access.recover.verifyCode.status == accConts.STS_REC_COD_VERIFY_REQUEST ||
    accState.access.recover.verifyCode.status == accConts.STS_REC_COD_VERIFY_RESPONSE;
  let isSuccessfull =
    accState.access.recover.verifyCode.status == accConts.STS_REC_COD_VERIFY_SUCCESS;

  const [userLoginFields, setUserLoginFields] = useState({ code: "" });

  const onChangeField = (name, value) => {
    setUserLoginFields({
      ...userLoginFields,
      [name]: value
    });
  };

  const onSubmit = async e => {
    e.preventDefault();

    ntfAction("notification").removeGroup("verify");

    const form = userLoginFields;
    const valid = await userRecoverVerify.validate({ ...form, _id: match.params._id });

    if (valid.result) {
      accAction("access")
        .verifyRecoverCode({
          _id: match.params._id,
          code: form.code
        })
        .then(res => {
          if (!res || res.code != "AA22") {
            ntfAction("notification").add(
              res.code,
              "verify",
              "danger",
              <span>
                O código é invalido ou expirou. Solicite um{" "}
                <a href="/access/recover">novo código</a>
              </span>
            );
          } else {
            history.push(`/access/update/${match.params._id}/${form.code}`);
          }
        });
    } else {
      ctxAction("login").updateRecoverCodeFormValidations(valid.errors);
      ntfAction("notification").add("EO52", "verify", "danger", "Código incorreto.");
    }
  };

  const setValidationClass = (validations, field) => {
    if (typeof validations != "undefined") {
      if (typeof validations[field] != "undefined") {
        return validations[field] ? " uk-form-success" : " uk-form-danger";
      }
    }

    return "";
  };

  useEffect(() => {
    ctxDispatch({
      type: "IS_HOME",
      data: false
    });
  }, []);

  const isSessionSuccessfull = accState.access.session.status == accConts.STS_SESSION_SUCCESS;

  useEffect(() => {
    accAction("access").verifySession();
  }, [isSessionSuccessfull]);

  if (isSessionSuccessfull) {
    return <Redirect to="/parceiro" />;
  }

  return (
    <Fragment>
      <div className="uk-width-2-5@m">
        <div className="auth-form">
          <div className="uk-margin-medium uk-text-center">
            <span className="label">Insira o código</span>
          </div>
          <div className="uk-margin">
            <Notification.NotificationWrapper group="verify" component={NotificationComponent} />
          </div>
          <form action="" className="form">
            <div className="uk-margin">
              <input
                className={
                  "uk-input uk-width-1-1" +
                  setValidationClass(ctxState.login.recoverCode.form.validations, "code")
                }
                id="code"
                name="code"
                placeholder="Insira o código"
                onChange={e => onChangeField("code", e.target.value)}
                value={userLoginFields.code}
                type="text"
              />
            </div>
            <div className="uk-margin-medium uk-text-center">
              <span className="label">Insira o código que você recebeu por e-mail.</span>
            </div>
            <div className="uk-margin-top">
              <button
                className="uk-button uk-button-primary uk-button-large uk-width-1-1"
                onClick={e => onSubmit(e)}
              >
                {isLoading ? <span>Verificando...</span> : <span>Verificar</span>}
              </button>
            </div>
            <BackButton />
          </form>
        </div>
      </div>
    </Fragment>
  );
};
export default RecoverVerify;
