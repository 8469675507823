import React, { Fragment, useState, useRef } from "react";
import Context from "@context";
import { Link } from "react-router-dom";

const DrawerItem = ({ _id, titulo, link, icone, iconWebsiteUrl, subCategorias, open }) => {
  const { state: ctxState, dispatch: ctxDispatch, action: ctxAction } = Context.useState();

  const drawerList = useRef();

  const closeDrawerMenu = () => {
    document.getElementsByTagName("html")[0].classList.remove("overflow");
    ctxAction("website").closeDrawerMenu();
  };

  const encodeLink = (link) => {
    return link.replace("/", "|")
  }

  return (
    <Fragment>
      <li>
        <div className={`nav-item${open ? " open" : ""}`}>
          <Link to={`/${encodeLink(link)}`} onClick={() => closeDrawerMenu()}>
          <img src={`${process.env.categoryUrl}${iconWebsiteUrl}`} alt=""/>
            <span className="lbl">{titulo}</span>
          </Link>
          <button onClick={() => ctxAction("website").toggleItemDrawer(_id)}>
            <i className="icn icn-caret-down" aria-hidden="true"></i>
          </button>
        </div>
        <ul
          ref={drawerList}
          className="nav-list"
          style={{ maxHeight: `${open ? `${drawerList.current.scrollHeight}px` : "0px"}` }}
        >
          {subCategorias.map(categoria => (
            <DrawerSubCategory key={categoria._id} {...categoria} link={encodeLink(categoria.link)} parentCategoryLink={encodeLink(link)} />
          ))}
        </ul>
      </li>
    </Fragment>
  );
};

const DrawerSubCategory = ({ titulo, link, parentCategoryLink }) => {
  const { state: ctxState, dispatch: ctxDispatch, action: ctxAction } = Context.useState();
  const closeDrawerMenu = () => {
    document.getElementsByTagName("html")[0].classList.remove("overflow");
    ctxAction("website").closeDrawerMenu();
  };

  return (
      <li>
        <a href={`/${parentCategoryLink}/${link}`} onClick={() => closeDrawerMenu()} >
          <span>{titulo}</span>
          <i className="icn icn-caret-down" aria-hidden="true"></i>
        </a>
      </li>
  );

};
export default DrawerItem;
