const types = {
  textfield: {
    type: "textfield"
  },
  radio: {
    type: "radio"
  },
  checkbox: {
    type: "checkbox"
  },
  textarea: {
    type: "textarea"
  },
  select: {
    type: "select"
  },
  datetime: {
    type: "datetime"
  },
  number: {
    type: "number"
  },
  password: {
    type: "password"
  },
  fileimagecrop: {
    type: "fileimagecrop"
  },
  googlemaps: {
    type: "googlemaps"
  },
  inputSearch: {
    type: "inputSearch"
  },
  fileimage: {
    type: "fileimage" 
  },
  file: {
    type: "file" 
  }
}

export { types }