export const ROUTERS_REDIRECT = [
  { path: '/beneficio/9363/Semana Light', redirect: '/beneficio/semana-light' },
  { path: '/beneficio/41784/Bokitos - Doces e Salgados', redirect: '/beneficio/bokitos-doces-e-salgados' },
  { path: '/capa/758/Gastronomia/Online', redirect: '/gastronomia/online' },
  { path: '/capa/95/Lojas/Produtos Naturais', redirect: '/lojas/produtos-naturais' },
  { path: '/beneficio/42275/Ghanem Laboratório e Saúde', redirect: '/beneficio/ghanem-laboratorio-e-saude' },
  { path: '/beneficio/42271/Proll Med Laboratórios', redirect: '/beneficio/proll-med-laboratorios' },
  { path: '/beneficio/42279/Game Mania Shop', redirect: '/beneficio/game-mania-shop' },
  { path: '/?tipoModal=seja-socio', redirect: '/' },
  { path: '/beneficio/41874/Lavanderia 5 à Sec', redirect: '/beneficio/lavanderia-5-a-sec' },
  { path: '/beneficio/42325/Studio Kamarati - Massagem', redirect: '/beneficio/studio-kamarati-massagem' },
  { path: '/capa/28/Gastronomia/Restaurantes', redirect: '/gastronomia/restaurantes' },
  { path: '/capa/1258/Entretenimento/Drive-ins', redirect: '/entretenimento/drive-ins' },
  { path: '/beneficio/42277/Quevedo Joalheria e Ótica', redirect: '/beneficio/quevedo-joalheria-e-otica-0' },
  { path: '/beneficio/42472/Phone Smart Center', redirect: '/beneficio/phone-smart-center' },
  { path: '/capa/117/Agenda', redirect: '/agenda' },
  { path: '/beneficio/42754/Restaurante Fortaleza da Ilha', redirect: '/beneficio/restaurante-fortaleza-da-ilha' },
  { path: '/capa/137/Entretenimento/Shows', redirect: '/entretenimento/shows' },
  { path: '/capa/27/Gastronomia', redirect: '/gastronomia' },
  { path: '/beneficio/42249/UNINGÁ - Centro Universitário Ingá', redirect: '/beneficio/uninga-centro-universitario-inga' },
  { path: '/beneficio/42244/Uniasselvi - Pós Graduação', redirect: '/beneficio/uniasselvi-pos-graduacao-0' },
  { path: '/capa/44/Serviços', redirect: '/servi%C3%A7os' },
  { path: '/capa/2/Entretenimento', redirect: '/entretenimento' },
  { path: '/beneficio/36182/Pizzaria Bel Mangio', redirect: '/beneficio/pizzaria-bel-mangio' },
  { path: '/beneficio/42357/Marília Mendonça', redirect: '/beneficio/marilia-mendonca' },
  { path: '/beneficio/42142/Laboratório São Gerônimo', redirect: '/beneficio/laboratorio-sao-geronimo' },
  { path: '/beneficio/42267/Barbearia VIP', redirect: '/beneficio/barbearia-vip-0' },
  { path: '/capa/43/Saúde e Bem-estar/Academia', redirect: '/sa%C3%BAde%20e%20bem-estar/academia' },
  { path: '/capa/1258/Entretenimento/Drive-in', redirect: '/entretenimento/drive-ins' },
  { path: '/beneficio/42247/UniCesumar - Graduação e Pós-graduação', redirect: '/beneficio/unicesumar-graduacao-e-pos-graduacao' },
  { path: '/beneficio/41870/Kanaloa Vaa - Canoagem Havaiana', redirect: '/beneficio/kanaloa-vaa-canoagem-havaiana' },
];