import React, { Fragment } from "react";
import Context from "@context";
import { Link } from "react-router-dom";
import Loader from "@html/views/utils/LoaderSiteComponent.react";

const CategoryMenu = () => {
  const { state: ctxState, action: ctxAction } = Context.useState();

  React.useEffect(() => {
    ctxAction("website").fetchAllCategories({
      filters: {
        showMenu: true,
        status: true,
      }
    });
  }, []);

  const encodeLink = (link) => {
    return link.replace("/", "|")
  }

  return (
    <Fragment>
      <ul id="ctg">

        <Loader show={ctxState.website.categorias.length === 0} height="50" width="50" />

        {ctxState.website.categorias
          .filter(category => category.showMenu === true && category.isPseudoCategory === false)
          .map(category => {
            return (
              <li key={`${category._id}-li`}>
                <div className="ctg-item">
                  <Link
                    key={`${category._id}-link`}
                    to={`/${encodeLink(category.link)}`}
                    className="ctg-item-button"
                  >
                    <img src={`${process.env.categoryUrl}${category.iconWebsiteUrl}`} alt=""/>
                    <span>{category.titulo}</span>
                    <i className="icn icn-caret-down" aria-hidden="true" />
                  </Link>
                  <div className="ctg-item-content">
                    {category.subCategorias
                      .map(subCategory => {
                        return (
                          <Link
                            key={`${subCategory._id}-link`}
                            to={`/${category.link}/${encodeLink(subCategory.link)}`}
                          >
                            <span>{subCategory.titulo}</span>
                            <i className="icn icn-caret-down" aria-hidden="true" />
                          </Link>
                        );
                      })}
                  </div>
                </div>
              </li>
            );
          })}
      </ul>
    </Fragment>
  );
};

export default CategoryMenu;
