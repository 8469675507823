export default {
  ACT_SET_LIST: "ACT_SET_PEM_LIST",
  ACT_SET_LIST_STATUS: "ACT_SET_PEM_LIST_STATUS",
  
  ACT_SET_FIELD: "ACT_SET_PEM_FIELD",
  ACT_PUSH_ITEM: "ACT_PUSH_PEM_ITEM",
  ACT_DELETE_ITEM: "ACT_DELETE_PEM_ITEM",

  STS_IDLE: "STS_PEM_IDLE",

  STS_GET_REQUEST: "STS_PEM_GET_REQUEST",
  STS_GET_RESPONSE: "STS_PEM_GET_RESPONSE",
  STS_GET_SUCCESS: "STS_PEM_GET_SUCCESS",
  STS_GET_ERROR: "STS_PEM_GET_ERROR",

  STS_PATCH_REQUEST: "STS_PEM_PATCH_REQUEST",
  STS_PATCH_RESPONSE: "STS_PEM_PATCH_RESPONSE",
  STS_PATCH_SUCCESS: "STS_PEM_PATCH_SUCCESS",
  STS_PATCH_ERROR: "STS_PEM_PATCH_ERROR"
}