import React, { Fragment } from "react";
import { schema as schm, types } from "@cthulhi/pack-schm";
import { types as fields } from "@cthulhi/comp-form";
import moment from "moment";


const currency = (amount, decimalCount = 2, decimal = ".", thousands = ",") => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    return amount;
  }
};

const schema = schm("filter", {
  name: {
    type: types.string.type,
    field: fields.textfield.type
  },
  description: {
    type: types.string.type,
    field: fields.textfield.type
  },
  quantity: {
    type: types.string.type,
    field: fields.textfield.type
  },
  cashbackQuantity: {
    type: types.string.type,
    field: fields.textfield.type
  },
  shelfLifeEnd: {
    type: types.datetime.type,
    field: fields.datetime.type
  }
});

const events = ({ ...props }) => {
  return {};
};

const values = ({ ...props }) => {
  return {
    name: {
      filter: "text",
      className: "uk-input",
      placeholder: "Evento",
      label: {
        text: "Nome do evento",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-3@m"
      }
    },
    description: {
      filter: "text",
      className: "uk-input",
      placeholder: "Descrição",
      label: {
        text: "Descrição do desconto",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-3@m"
      }
    },
    shelfLifeEnd: {
      className: "datepicker uk-margin-small-bottom uk-input",
      dateFormat: "DD / MM / YYYY",
      inputProps: {
        className: "uk-input",
        placeholder: "Validade *"
      },
      value: "",
      label: {
        text: "",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-4@m"
      }
    },
    quantity: {
      filter: "text",
      className: "uk-input",
      placeholder: "Desconto",
      label: {
        text: "% ou R$ de desconto",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-4@m"
      }
    },
    cashbackQuantity: {
      filter: "text",
      className: "uk-input",
      placeholder: "Cashback",
      label: {
        text: "% ou R$ de cashback",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-4@m"
      }
    }
  };
};

const options = ({ onCsv, onEdit, onRemove, ...props }) => {
  return {
    filter: {
      fields: true,
      submit: true
    },
    pagination: {
      next: {
        value: "Próximo",
        className: "page-next"
      },
      prev: {
        value: "Anterior",
        className: "page-prev"
      },
      numbers: {
        className: "page-numbers"
      },
      total: true
    },
    limit: {
      value: 5,
      limits: [
        { value: 5, label: "5 Items" },
        { value: 10, label: "10 Items" },
        { value: 20, label: "20 Items" }
      ]
    },
    items: {
      event: {
        header: {
          value: "Evento",
          className: ""
        },
        column: {
          className: "",
          value: "event"
        },
        parse: event => {
          return event.name;
        }
      },
      description: {
        header: "Descrição",
        column: "description",
      },
      quantity: {
        header: "Desconto",
        column: "quantity",
        parse: (value, item) => {
          return `${(item.type == "absolute") ? "R$ " : ""}${currency(value)}${(item.type == "percentage" || item.type == "percent") ? " %" : ""}`
        }
      },
      shelfLifeEnd: {
        header: "Data de validade",
        column: "shelfLifeEnd",
        parse: (value, item) => {
          return moment(value).format("DD/MM/YYYY");
        }
      },
      cashBackQuantity: {
        header: "cashBack",
        column: "cashbackQuantity",
        parse: (value, item) => {
          if (item.hasCashback) {
            return `${item.cashbackType != "percent" ? "R$ " : ""}${currency(value)}${item.cashbackType == "percent" ? " %" : ""}`
          } else {
            return "Não tem";
          }
        }
      },
      actions: {
        header: "Ações",
        column: (item, refresh, loader) => {
          return {
            value: (
              <Fragment>
                <div className="uk-grid-small uk-child-width-auto" data-uk-grid>
                  <div className="uk-text-nowrap">
                    <button
                      className="uk-icon-button uk-button-primary"
                      onClick={() => onEdit(item, refresh, loader)}
                    >
                      <i className="icon icon-edit" aria-hidden="true"></i>
                    </button>
                    <button
                      className="uk-icon-button uk-button-danger uk-margin-small-left"
                      onClick={() => onRemove(item, refresh, loader)}
                    >
                      <i className="icon icon-delete" aria-hidden="true"></i>
                    </button>

                    {
                      (item.refoundType == 'import') && (
                        <button
                          className="uk-icon-button uk-button-primary uk-margin-small-left"
                          onClick={() => onCsv(item, refresh, loader)}
                        >
                          csv
                        </button>
                      )
                    }
                  </div>
                </div>
              </Fragment>
            )
          };
        }
      }
    }
  };
};

const actions = ({ ctxAction, token, state, ...props }) => {
  return {
    find: async (filters, options) => {
      
      filters.filters.eventId = { $exists: true };

      if (filters.filters && filters.filters.shelfLifeEnd && filters.filters.shelfLifeEnd.trim().length > 0) {

        let date = new Date(filters.filters.shelfLifeEnd);
        if (!isNaN(date.getTime())) {
          date.setDate(date.getDate() + 1);
          filters.filters.shelfLifeEnd = { $lt: date };
        } else {
          delete filters.filters.shelfLifeEnd;
        }
      }

      if (filters.filters && filters.filters.name) {
        filters.filters = { ...filters.filters,  "event.name": filters.filters.name };
      }

      if (filters.filters && filters.filters.quantity) {
        filters.filters.quantity = filters.filters.quantity.$regex;
      }

      if (filters.filters && filters.filters.cashbackQuantity) {
        filters.filters.cashbackQuantity = filters.filters.cashbackQuantity.$regex;
      }
      
      delete filters.filters.name;
      return ctxAction("discount").aggregateEvent({ ...filters.filters, ...(state ? {...state.filters} : {}) }, { sort: { _id: -1 }, ...options }, token);
    
    }
  };
};

export { events, values, actions, options, schema };