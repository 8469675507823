import React from "react";
import Overlay from "./../../../utils/overlay/Overlay.react"

const SuccessValidationModal = ({ result, onClose }) => {
  return (
    <Overlay backgroundColor={"#2c9f35"} backgroundOpacity={"0.9"} onClick={onClose}>
      <div id="voucher-ok" className="voucher voucher-ok">
        <div className="voucher-card">
          <div className="voucher-icon voucher-ok"></div>
          <h3 className="voucher-title uk-text-uppercase">
            Voucher
            <br />
            validado
          </h3>
        </div>
        <button
          className="uk-button uk-button-large uk-button-secondary uk-align-center"
          onClick={onClose}
        >
          Validar novo voucher
        </button>
      </div>
    </Overlay>
  );
};

export default SuccessValidationModal;
