// @ts-nocheck
import React, { Component, Fragment, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";

import Access from "@cthulhi/comp-accs";

import permissions from "@perm";

import SplashView from "./pages/splash/SlashView.pwa.react";
import AccessViewPwa from "./pages/access/AccesView.pwa.react";
import IntranetViewPwa from "./pages/intranet/IntranetView.pwa.react";

import LoaderWindow from "./../../views/utils/LoaderWindowComponent.react";

const PwaView = ({ match, location }) => {
  
  const preLoadedPermissions = Object.values(permissions).reduce((a, b) => {
    if (a && b) return [...Object.values(a), ...Object.values(b)];
    if (b) return [...Object.values(b)];
    return [];
  });

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register(`/sw.js`, { scope: "/parceiroapp" })
        .then((registration) => console.log("ServiceWorker registration successful with scope: ", registration.scope))
        .catch((err) => console.log("ServiceWorker registration failed: ", `${process.env.domainRender}sw.js`, err));
    }
  }, []);

  return (
    <Fragment>
      <LoaderWindow />
      <Helmet>
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="application-name" content="NSC Parceiro" />
        <meta name="apple-mobile-web-app-title" content="NSC Parceiro" />
        <meta name="theme-color" content="#FFFFFF" />
        <meta name="msapplication-navbutton-color" content="#FFFFFF" />
        <meta name="apple-mobile-web-app-status-bar-style" content="white-translucent" />
        <meta name="msapplication-starturl" content="/parceiroapp" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />

        <link href={`${process.env.domain}assets/pwa/css/uikit.min.css`} rel="stylesheet" />
        <link href={`${process.env.domain}assets/pwa/css/main.css`} rel="stylesheet" />
        <link href={`${process.env.domain}assets/pwa/images/favicon.ico`} rel="icon" />
        <link href={`${process.env.domain}assets/pwa/images/favicon-16x16.png`} rel="icon" type="image/png" sizes="16x16" />
        <link href={`${process.env.domain}assets/pwa/images/favicon-32x32.png`} rel="icon" type="image/png" sizes="32x32" />
        <link href={`${process.env.domain}assets/pwa/images/apple-touch-icon.png`} rel="apple-touch-icon" sizes="180x180" />
        <link href={`${process.env.domain}assets/pwa/images/android-chrome-192x192.png`} rel="icon" type="image/png" sizes="192x192" />
        <link href={`${process.env.domain}assets/pwa/images/android-chrome-256x256.png`} rel="icon" type="image/png" sizes="256x256" />
        <link href={`${process.env.domain}assets/pwa/images/safari-pinned-tab.svg`} color="#5bbad5" rel="mask-icon" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />

        <link rel="manifest" href={`/manifest.json`} />

        <script src={`${process.env.domain}assets/pwa/js/uikit.min.js`}></script>
        <script src={`${process.env.domain}assets/pwa/js/uikit-icons.min.js`}></script>

        <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-NS5NCW5');`}</script>
      </Helmet>
      <Switch>
        <Route path={`${match.path}`} exact component={SplashView} />
        <Route path={`${match.path}/access`} component={AccessViewPwa} />
        <Access.Session onDeny={() => <Redirect to={`${match.path}/access`} />}>
          <Access.Permission preLoaded={preLoadedPermissions}>
            <Access.RestrictRoute path={`${match.path}/home`} onAllow={IntranetViewPwa} />
          </Access.Permission>
        </Access.Session>
      </Switch>
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-NS5NCW5"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        ></iframe>
      </noscript>
    </Fragment>
  );
};

export default withRouter(PwaView);
