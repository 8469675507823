import lc from "./../constants/login.constant"

export default (dispatch, state) => {

  const setFormValidations = (type, data) => {
    return { type, data }
  }

  const updateFormValidations = (action, prop, validations) => {
    let formValidations = {}

    Object.keys(state[prop].form.fields).map(key => { 
      formValidations[key] = validations.filter(i => i.field.split(":")[1] == key).length ? false : true
    })

    dispatch(setFormValidations(action, formValidations))
  }

  const updateLoginFormValidations = (validations) => {
    return updateFormValidations(lc.SET_LOGIN_FORM_VALIDATIONS, "login", validations)
  }

  const updateRecoverEmailFormValidations = (validations) => {
    return updateFormValidations(lc.SET_RECOVER_EMAIL_FORM_VALIDATIONS, "recoverEmail", validations)
  }

  const updateRecoverCodeFormValidations = (validations) => {
    return updateFormValidations(lc.SET_RECOVER_CODE_FORM_VALIDATIONS, "recoverCode", validations)
  }

  const updateRecoverUpdateFormValidations = (validations) => {
    return updateFormValidations(lc.SET_RECOVER_UPDATE_FORM_VALIDATIONS, "recoverUpdate", validations)
  }

  return { 
    updateLoginFormValidations,
    updateRecoverEmailFormValidations,
    updateRecoverCodeFormValidations,
    updateRecoverUpdateFormValidations
  }
}