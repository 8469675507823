import bc from "../constants/redemption.constant";

export default (dispatch, state) => {
  const dispatchSetStatus = data => {
    dispatch({
      type: bc.ACT_SET_STATUS,
      data: data
    });
  };

  const remove = (values, authorization) => {
    var args = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization
      },
      body: JSON.stringify(values),
      credentials: "same-origin"
    };

    dispatchSetStatus(bc.STS_DELETE_REQUEST);

    return fetch(process.env.domainRedemptionDelete, args)
      .then(res => res.json())
      .then(res => {
        dispatchSetStatus(bc.STS_DELETE_RESPONSE);
        dispatchSetStatus(bc.STS_DELETE_SUCCESS);

        setTimeout(function() {
          dispatchSetStatus(bc.STS_IDLE);
        }, 1000);

        return res;
      })
      .catch(error => {
        dispatchSetStatus(bc.STS_DELETE_ERROR);

        setTimeout(function() {
          dispatchSetStatus(bc.STS_IDLE);
        }, 1000);

        return error;
      });
  };

  const update = (values, authorization) => {
    const updateFetch = args => {
      return fetch(process.env.domainRedemptionUpdate, args)
        .then(res => res.json())
        .then(res => {
          dispatchSetStatus(bc.STS_UPDATE_RESPONSE);
          dispatchSetStatus(bc.STS_UPDATE_SUCCESS);

          setTimeout(function() {
            dispatchSetStatus(bc.STS_IDLE);
          }, 1000);

          return res;
        })
        .catch(error => {
          dispatchSetStatus(bc.STS_UPDATE_ERROR);

          setTimeout(function() {
            dispatchSetStatus(bc.STS_IDLE);
          }, 1000);

          return error;
        });
    };

    dispatchSetStatus(bc.STS_UPDATE_REQUEST);

    if (values.bannerUrlBlob) {
      const formData = new FormData();
      formData.append("file", values.bannerUrlBlob);

      const args = {
        method: "POST",
        headers: {
          Authorization: authorization
        },
        body: formData,
        credentials: "same-origin"
      };

      return fetch(process.env.domainDocumentUploadImage, args)
        .then(res => res.json())
        .then(res => {
          values.bannerUrl = res.result.image;
          delete values.bannerUrlBlob;

          return updateFetch({
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: authorization
            },
            body: JSON.stringify(values),
            credentials: "same-origin"
          });
        })
        .catch(error => {
          return error;
        });
    }

    return updateFetch({
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization
      },
      body: JSON.stringify(values),
      credentials: "same-origin"
    });
  };

  const create = (values, authorization, loader) => {
    var args = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization
      },
      body: JSON.stringify(values),
      credentials: "same-origin"
    };
    if (loader) loader.start();

    return fetch(process.env.domainRedemptionCreate, args)
      .then(res => res.json())
      .then(res => {
        if (loader) loader.finish();
        return res;
      })
      .catch(err => {
        if (loader) loader.finish();
        return err;
      });
  };

  const find = (filters, options, authorization, loader) => {
    var args = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization
      },
      body: JSON.stringify({ ...filters, options }),
      credentials: "same-origin"
    };

    if (loader) loader.start();

    dispatchSetStatus(bc.STS_FIND_REQUEST);

    return fetch(process.env.domainRedemptionFind, args)
      .then(res => res.json())
      .then(res => {
        dispatchSetStatus(bc.STS_FIND_RESPONSE);
        dispatchSetStatus(bc.STS_FIND_SUCCESS);

        if (loader) loader.finish();

        setTimeout(function() {
          dispatchSetStatus(bc.STS_IDLE);
        }, 1000);

        return res;
      })
      .catch(error => {
        dispatchSetStatus(bc.STS_FIND_ERROR);

        if (loader) loader.finish();

        setTimeout(function() {
          dispatchSetStatus(bc.STS_IDLE);
        }, 1000);

        return error;
      });
  };

  const age = (ageRanges, filters, options, authorization, loader) => {
    var args = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization
      },
      body: JSON.stringify({ ...ageRanges, ...filters, ...options }),
      credentials: "same-origin"
    };

    if (loader) loader.start();

    dispatchSetStatus(bc.STS_AGE_REPORT_REQUEST);

    return fetch(process.env.domainRedemptionAgeReport, args)
      .then(res => res.json())
      .then(res => {
        dispatchSetStatus(bc.STS_RDP_AGE_REPORT_RESPONSE);
        dispatchSetStatus(bc.STS_RDP_AGE_REPORT_SUCCESS);

        if (loader) loader.finish();

        setTimeout(function() {
          dispatchSetStatus(bc.STS_IDLE);
        }, 1000);

        return res;
      })
      .catch(error => {
        dispatchSetStatus(bc.STS_RDP_AGE_REPORT_ERROR);

        if (loader) loader.finish();

        setTimeout(function() {
          dispatchSetStatus(bc.STS_IDLE);
        }, 1000);

        return error;
      });
  };

  const city = (filters, options, authorization, loader) => {
    var args = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization
      },
      body: JSON.stringify({ ...filters, ...options }),
      credentials: "same-origin"
    };

    if (loader) loader.start();

    dispatchSetStatus(bc.STS_CITY_REPORT_REQUEST);

    return fetch(process.env.domainRedemptionCityReport, args)
      .then(res => res.json())
      .then(res => {
        dispatchSetStatus(bc.STS_RDP_CITY_REPORT_RESPONSE);
        dispatchSetStatus(bc.STS_RDP_CITY_REPORT_SUCCESS);

        if (loader) loader.finish();

        setTimeout(function() {
          dispatchSetStatus(bc.STS_IDLE);
        }, 1000);

        return res;
      })
      .catch(error => {
        dispatchSetStatus(bc.STS_RDP_CITY_REPORT_ERROR);

        if (loader) loader.finish();

        setTimeout(function() {
          dispatchSetStatus(bc.STS_IDLE);
        }, 1000);

        return error;
      });
  };

  const district = (filters, options, authorization, loader) => {
    var args = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization
      },
      body: JSON.stringify({ ...filters, ...options }),
      credentials: "same-origin"
    };

    if (loader) loader.start();

    dispatchSetStatus(bc.STS_DISTRICT_REPORT_REQUEST);

    return fetch(process.env.domainRedemptionDistrictReport, args)
      .then(res => res.json())
      .then(res => {
        dispatchSetStatus(bc.STS_RDP_DISTRICT_REPORT_RESPONSE);
        dispatchSetStatus(bc.STS_RDP_DISTRICT_REPORT_SUCCESS);

        if (loader) loader.finish();

        setTimeout(function() {
          dispatchSetStatus(bc.STS_IDLE);
        }, 1000);

        return res;
      })
      .catch(error => {
        dispatchSetStatus(bc.STS_RDP_DISTRICT_REPORT_ERROR);

        if (loader) loader.finish();

        setTimeout(function() {
          dispatchSetStatus(bc.STS_IDLE);
        }, 1000);

        return error;
      });
  };

  const period = (filters, options, authorization, loader) => {
    var args = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization
      },
      body: JSON.stringify({ ...filters, ...options }),
      credentials: "same-origin"
    };

    if (loader) loader.start();

    dispatchSetStatus(bc.STS_PERIOD_REPORT_REQUEST);

    return fetch(process.env.domainRedemptionPeriodReport, args)
      .then(res => res.json())
      .then(res => {
        dispatchSetStatus(bc.STS_RDP_PERIOD_REPORT_RESPONSE);
        dispatchSetStatus(bc.STS_RDP_PERIOD_REPORT_SUCCESS);

        if (loader) loader.finish();

        setTimeout(function() {
          dispatchSetStatus(bc.STS_IDLE);
        }, 1000);

        return res;
      })
      .catch(error => {
        dispatchSetStatus(bc.STS_RDP_PERIOD_REPORT_ERROR);

        if (loader) loader.finish();

        setTimeout(function() {
          dispatchSetStatus(bc.STS_IDLE);
        }, 1000);

        return error;
      });
  };

  const recurrent = (filters, options, authorization, loader) => {
    var args = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization
      },
      body: JSON.stringify({ ...filters, ...options }),
      credentials: "same-origin"
    };

    if (loader) loader.start();

    dispatchSetStatus(bc.STS_RECURRENT_REPORT_REQUEST);

    return fetch(process.env.domainRedemptionRecurrentReport, args)
      .then(res => res.json())
      .then(res => {
        dispatchSetStatus(bc.STS_RDP_RECURRENT_REPORT_RESPONSE);
        dispatchSetStatus(bc.STS_RDP_RECURRENT_REPORT_SUCCESS);

        if (loader) loader.finish();

        setTimeout(function() {
          dispatchSetStatus(bc.STS_IDLE);
        }, 1000);

        return res;
      })
      .catch(error => {
        dispatchSetStatus(bc.STS_RDP_RECURRENT_REPORT_ERROR);

        if (loader) loader.finish();

        setTimeout(function() {
          dispatchSetStatus(bc.STS_IDLE);
        }, 1000);

        return error;
      });
  };

  const weekday = (filters, options, authorization, loader) => {
    var args = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization
      },
      body: JSON.stringify({ ...filters, ...options }),
      credentials: "same-origin"
    };

    if (loader) loader.start();

    dispatchSetStatus(bc.STS_WEEKDAY_REPORT_REQUEST);

    return fetch(process.env.domainRedemptionWeekdayReport, args)
      .then(res => res.json())
      .then(res => {
        dispatchSetStatus(bc.STS_RDP_WEEKDAY_REPORT_RESPONSE);
        dispatchSetStatus(bc.STS_RDP_WEEKDAY_REPORT_SUCCESS);

        if (loader) loader.finish();

        setTimeout(function() {
          dispatchSetStatus(bc.STS_IDLE);
        }, 1000);

        return res;
      })
      .catch(error => {
        dispatchSetStatus(bc.STS_RDP_WEEKDAY_REPORT_ERROR);

        if (loader) loader.finish();

        setTimeout(function() {
          dispatchSetStatus(bc.STS_IDLE);
        }, 1000);

        return error;
      });
  };

  return {
    find,
    create,
    update,
    remove,
    reports: {
      age,
      city,
      district,
      period,
      recurrent,
      weekday
    }
  };
};
