import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Access from "@cthulhi/comp-accs";
import Context from "@context";
import { useFields, useForms } from "@cthulhi/comp-form";
import { schema as discountFormSchema } from "@schm/discount/create.partner.form.schema";

import {
  events as discountFormEvents,
  values as discountFormValues,
  actions as discountFormActions
} from "./components/DiscountForm.config";

import { FieldLabelWrapper } from "../../../../wrappers/FormWrappers.react";

import Notification from "@cthulhi/comp-noty";
import NotificationComponent from "../../../../utils/Notification.react";

import LoaderComponent from "../../../../utils/LoaderCustomComponent.react";

const DiscountFormPage = ({ match, parentPath, history, ...props }) => {
  const { state: accState, action: accAction } = Access.useState();
  const { action: ctxAction, state: ctxtState } = Context.useState();
  const { state: ntfState, action: ntfAction } = Notification.useState();
  const [ discountSchema, setDiscountSchema] = useState(discountFormSchema);
  const [validation, setValidation] = useState({});
  const [selectedPartner, setSelectedPartner] = useState({});
  const [selectedBranches, setSelectedBranches] = useState([]);

  const { form, fields, values, updater, cleaner } = useFields({
    schema: discountSchema,
    events: discountFormEvents({ 
      ctxAction,
      token: `Bearer ${accState.access.session.context.token}`
    }),
    values: discountFormValues({
      ctxAction,
      token: `Bearer ${accState.access.session.context.token}`
    })
  });

  const hasCashback = values.hasCashback.value;
  const refoundType = values.refoundType.value;
  const cashbackType = values.cashbackType.value;
  const limitType = values.limitType.value;

  const { find, update, create } = useForms(
    discountFormActions({
      ctxAction,
      ntfAction,
      updater,
      history,
      path: parentPath,
      token: `Bearer ${accState.access.session.context.token}`
    })
  );

  const discountFormSubmit = async () => {
    
    let vs = {};
    Object.entries(values).map(([key, value]) => {
      const hasValue = typeof value.value != "undefined" && value.value !== ""
      const hasRawValue = typeof value.rawValue != "undefined" && value.rawValue !== ""

      vs[key] = hasRawValue ? value.rawValue : (hasValue ? value.value : value.defaultValue)
    });

    if (vs.discountImage && vs.discountImage.includes("blob:")) {
      await fetch(values.discountImage.rawValue)
        .then(res => res.blob())
        .then(blob => {
          vs.discountImageBlob = blob;
        });
    }

    const validate = action => {
      action.then(() => {}).catch(setValidation)
    }

    match.params.id ? validate(update(vs)) : validate(create(vs));
  };

  useEffect(() => {
    if (match.params.id) {
      find({ filters: { _id: match.params.id } }) ;
    }

    return () => {
      cleaner();
    };
  }, [match.params.id]);

  useEffect(() => {
    if (values.partnerId.value && !Array.isArray(values.partnerId.value)) {
      setSelectedPartner({ loading: true });
      ctxAction("partner").find(
        { filters: { _id: values.partnerId.value } },
        { sort: { title: 1 } },
        `Bearer ${accState.access.session.context.token}`,
      )
      .then((res) => {
        if (Array.isArray(res.result.values)) {
          const partner = res.result.values[0];
          if (partner)
            setSelectedPartner(partner);
          else 
            setSelectedPartner({ notfound: true });
        } else {
          setSelectedPartner({ notfound: true });
        }
        
        return [];
      });
    }
  }, [values.partnerId.value]);

  useEffect(() => {
    const selected = values.branches.value;
    if (!selected) return;
    const partnerBranches = selectedPartner.branches||[];

    setSelectedBranches(
      partnerBranches.filter(branch => selected.includes(branch._id))
    );
  }, [selectedPartner, values.branches.value])

  const renderBranch = (branch, child, index = 0) => {
    return (
      <ul data-uk-accordion="multiple: true">
        <li>
          <a className="title title-lead-small title-accordion uk-accordion-title">
            {child ? 
              branch ? 
                `Local de pagamento: ${branch.name}` : 'Local de pagamento' : 
              branch.name
            }
          </a>
          <div className="uk-accordion-content">
            <div className="uk-card uk-card-default uk-card-body uk-padding-small uk-width-1-1@m">
              <div>
                <div className="uk-grid-small" data-uk-grid>
                  {branch ? (
                    <>
                      {branch.name && <p><b>Nome:</b> {branch.name}</p>}
                      {branch.contact && <p><b>Contato:</b> {branch.contact}</p>}
                      {branch.type && <p><b>Tipo:</b> {branch.type}</p>}
                    </>
                  ) : (
                    <p>Mesmo local de uso</p>
                  )}
                </div>
              </div>

              
              {branch ? (branch.children && branch.children.length) ? 
                branch.children.map(child => renderBranch(child, true)) : 
                renderBranch(undefined, true)
              : null}
            </div>
          </div>
        </li>
      </ul>
    );
  }

  return (
    <Fragment>
      <h2 className="title">{match.params.id ? "Editar desconto" : "Novo desconto"}</h2>
      <div className="uk-margin-top">
        <Notification.NotificationWrapper
          group="discountForm"
          component={NotificationComponent}
        />
        <LoaderComponent from="discountForm" />

        <ul data-uk-accordion="multiple: true">
          <li className="uk-open">
            <a className="title title-lead-small title-accordion uk-accordion-title">
              Configurações gerais
            </a>
            <div className="uk-accordion-content">
              <div className="uk-card uk-card-default uk-card-body uk-padding-small">
                <form className="uk-grid-small" data-uk-grid>
                  <FieldLabelWrapper name="partnerId" fields={fields} values={values} validation={validation.partnerId} />
                  {selectedPartner.notfound && <p className="not-found-message uk-width-1-1 uk-grid-margin uk-first-column">O parceiro selecionado foi excluído.</p>}

                  <FieldLabelWrapper name="description" fields={fields} values={values} validation={validation.description} />
                  <FieldLabelWrapper name="type" fields={fields} values={values} validation={validation.type} />
                  <FieldLabelWrapper name="quantity" fields={fields} values={values} validation={validation.quantity} />
                  <FieldLabelWrapper name="shelfLifeStart" fields={fields} values={values} validation={validation.shelfLifeStart} />
                  <FieldLabelWrapper name="shelfLifeEnd" fields={fields} values={values} validation={validation.shelfLifeEnd} />

                  <FieldLabelWrapper name="hasCashback" fields={fields} values={values} validation={validation.hasCashback} />
                  {hasCashback && (<>
                    <FieldLabelWrapper name="cashbackQuantity" fields={fields} values={values} validation={validation.cashbackQuantity} />
                    <FieldLabelWrapper name="cashbackType" fields={fields} values={values} validation={validation.cashbackType} />
                    {cashbackType == "percent" && <FieldLabelWrapper name="cashbackLimit" fields={fields} values={values} validation={validation.cashbackLimit} />}
                  </>)}

                  <FieldLabelWrapper name="userTypes" fields={fields} values={values} validation={validation.userTypes} />
                  <FieldLabelWrapper name="available" fields={fields} values={values} validation={validation.available} />
                  <FieldLabelWrapper name="spotlight" fields={fields} values={values} validation={validation.spotlight} />
                  <FieldLabelWrapper name="siteBanner" fields={fields} values={values} validation={validation.siteBanner} />
                  <FieldLabelWrapper name="discountImage" fields={fields} values={values} validation={validation.discountImage} />
                  
                </form>
              </div>
            </div>
          </li>

          <li className="uk-open">
            <a className="title title-lead-small title-accordion uk-accordion-title">
              Unidades selecionadas({selectedBranches.length})
            </a>
            <div className="uk-accordion-content">
              <div className="uk-card uk-card-default uk-card-body uk-padding-small">
                <FieldLabelWrapper name="branches" fields={fields} values={values} validation={validation.branches} />
                {selectedPartner.loading && <LoaderComponent visible={true} />}
                {selectedBranches.map(branch => renderBranch(branch))}  
              </div>
            </div>
          </li>

          {selectedBranches.some(branch => branch.type == "virtual") && 
            <li className="uk-open">
              <a className="title title-lead-small title-accordion uk-accordion-title">
                Informações de resgate
              </a>
              <div className="uk-accordion-content">
                <div className="uk-card uk-card-default uk-card-body uk-padding-small">
                  <form className="uk-grid-small" data-uk-grid>
                    <FieldLabelWrapper name="refoundType" fields={fields} values={values} validation={validation.refoundType} />

                    {refoundType == "promocode" && <FieldLabelWrapper name="refoundCode" fields={fields} values={values} validation={validation.refoundCode} />}
                    {refoundType == "url" && <FieldLabelWrapper name="refoundUrl" fields={fields} values={values} validation={validation.refoundUrl} />}

                    <FieldLabelWrapper name="howToUse" fields={fields} values={values} validation={validation.howToUse} />
                  </form>
                </div>
              </div>
            </li>
          } 

          <li className="uk-open">
            <a className="title title-lead-small title-accordion uk-accordion-title">
              Limite
            </a>
            <div className="uk-accordion-content">
              <div className="uk-card uk-card-default uk-card-body uk-padding-small">
                <form className="uk-grid-small" data-uk-grid>
                  <FieldLabelWrapper name="limitType" fields={fields} values={values} validation={validation.limitType} />
                  {["1", "2", "3"].includes(limitType) && <FieldLabelWrapper name="limitQuantity" fields={fields} values={values} validation={validation.limitQuantity} />}
                  {["3"].includes(limitType) && <FieldLabelWrapper name="limitDaysQuantity" fields={fields} values={values} validation={validation.limitDaysQuantity} />}
                </form>
              </div>
            </div>
          </li>
        </ul>
      <div className="uk-margin-top">
          <button
            onClick={discountFormSubmit}
            className="uk-button uk-button-large uk-button-secondary"
            style={{ float: "right" }}
            disabled={typeof ctxtState.loader.list["discountForm"] != "undefined" && ctxtState.loader.list["discountForm"].length > 0}
          >
            {match.params.id ? "Atualizar" : "Salvar"}
          </button>
        </div>
      </div>
    </Fragment>
  );
};

// @ts-ignore
export default withRouter(DiscountFormPage);
