const optionLoading = { value: "", label: "Carregando..." };
const optionSelectParentCategory = {
  value: "",
  label: "Selecione uma categoria pai...",
  disabled: false
};

const events = ({ ctxAction, ...props }) => {
  return {};
};

const values = ({ ctxAction, token, ...props }) => {
  const initValues = {
    parentId: {
      className: "uk-select",
      value: "",
      label: {
        text: "Categoria pai",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      },
      options: update => {
        update("parentId", { value: "", disabled: true, options: [optionLoading] });
        ctxAction("category")
          .find(
            {
              filters: {
                $or: [
                  { parentId: { $exists: 0 } },
                  { parentId: null }
                ]
              }
            },
            { sort: { name: 1 } },
            token
          )
          .then(res => {
            if (res.name != "CategoryFoundResult") {
              update(
                "parentId",
                {
                  disabled: true,
                  options: [{ value: "", label: "Erro ao trazer categorias" }]
                },
                ""
              );
            }
            const categories = res.result.values.map(foundedCategory => {
              return {
                key: foundedCategory._id,
                value: foundedCategory._id,
                label: foundedCategory.name
              };
            });
            update(
              "parentId",
              {
                disabled: false,
                options: [optionSelectParentCategory, ...categories]
              },
              ""
            );
          });
      }
    },
    name: {
      className: "uk-input",
      placeholder: "Nome",
      label: {
        text: "Nome",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-1"
      }
    },
    weight: {
      className: "uk-input",
      value: "",
      placeholder: "Ordem",
      label: {
        text: "Ordem",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-5@m"
      }
    },
    weightByDate: {
      className: "uk-checkbox",
      value: false,
      label: {
        position: "below",
        text: "Ordenar por data do evento",
        className: "uk-form-label uk-margin-small-left"
      },
      wrapper: {
        className: "field-checkbox uk-width-1-2@m"
      }
    },
    status: {
      className: "uk-checkbox",
      value: true,
      label: {
        position: "below",
        text: "Ativo",
        className: "uk-form-label uk-margin-small-left"
      },
      wrapper: {
        className: "field-checkbox uk-width-1-5@m"
      }
    },
    showMenu: {
      className: "uk-checkbox",
      value: true,
      label: {
        position: "below",
        text: "Mostrar no menu do website. Para a categoria aparecer como uma das categorias principais ela não pode possuir uma categoria pai",
        className: "uk-form-label uk-margin-small-left"
      },
      wrapper: {
        className: "field-checkbox uk-width-1-1@m"
      }
    },
    iconWebsiteUrl: {
      input: {
        wrapper: {
          className: "uk-width-1-1@m"
        },
        field: {
          className: "uk-input",
          placeholder: "Ícone",
          accept: "image/svg+xml"
        },
      },
      label: {
        text: "Ícone do Website",
        className: "uk-form-label uk-margin-bottom"
      },
      description: {
        text: "Ícone do website utilizado nos menus. A imagem deve ser um arquivo SVG onde a largura e a altura são números inteiros."
      },
      wrapper: {
        className: "field-wrapper uk-width-1-1@m uk-margin-large-bottom"
      },
      image: {
        className: "uk-margin--bottom",
        style: { 
          maxWidth: "48px",
          maxWidth: "48px"
        }
      }
    },
    showApp: {
      className: "uk-checkbox uk-margin-small-bottom",
      value: true,
      label: {
        position: "below",
        text: "Mostrar no aplicativo. Determina se a categoria deve aparecer ou não na seleção da tela de descontos no aplicativo, categoria não deve ter categoria pai.",
        className: "uk-form-label uk-margin-small-left"
      },
      wrapper: {
        className: "field-checkbox uk-width-1-1@m uk-margin-large-bottom"
      }
    },
    iconAppUrl: {
      input: {
        wrapper: {
          className: "uk-width-1-1@m uk-margin-small-bottom"
        },
        field: {
          className: "uk-input",
          placeholder: "Ícone do aplicativo",
          accept: "image/svg+xml"
        },
      },
      label: {
        text: "Ícone do aplicativo",
        className: "uk-form-label"
      },
      description: {
        text: "Ícone usado para representar a categoria no aplicativo. A imagem deve ser um arquivo SVG onde a largura e a altura são números inteiros."
      },
      wrapper: {
        className: "field-wrapper uk-width-1-1@m uk-margin-large-bottom"
      },
      image: {
        className: "uk-margin--bottom",
        style: { 
          maxWidth: "48px",
          maxWidth: "48px"
        }
      }
    },
    backgroundAppUrl: {
      input: {
        wrapper: {
          className: "uk-width-1-1@m uk-margin-small-bottom"
        },
        field: {
          className: "uk-input",
          placeholder: "Imagem de fundo utilizada na seleção de categirua na tela de descontos do aplicativo. Tamanho recomendado mínimo: 500 pixels x 500 pixels. Formato: PNG."
        }
      },
      crops: {
        wrapper: {
          className: "uk-margin-top uk-grid uk-margin-top uk-margin-small"
        },
        cropper: {
          className: "uk-width-1-2@m uk-first-column"
        },
        croppeds: {
          className: "uk-width-1-2@m uk-grid"
        },
        images: [
          { wrapper: { className: "uk-width-1-2@m" }, image: { style: { width: "100%" } } },
          { wrapper: { className: "uk-width-1-2@m" }, image: { style: { width: "50%" } } }
        ]
      },
      locked: false,
      keepSelection: false,
      unit: "%",
      height: 100,
      aspect: 1,
      label: {
        text: "Imagem de fundo do aplicativo",
        className: "uk-form-label"
      },
      description: {
        text: `Imagem usada no fundo do botão de seleção de categoria no aplicativo.
        Tamanho mínimo recomendado: 500 pixels x 500 pixels.`
      },
      wrapper: {
        className: "field-wrapper uk-width-1-1@m uk-margin-large-bottom"
      },
    },
  };

  return initValues;
};

const actions = ({ token, ctxAction, ntfAction, updater, history, path, ...props }) => {
  return {
    entity: {
      name: "category",
      token,
      form: ctxAction("form")
    },
    actions: {
      entity: ctxAction,
      notification: ntfAction,
      updater
    },
    find: {
      success: {
        name: "CategoryFoundResult",
        action: { notify: false, redirect: false }
      },
      error: {
        name: "CategoryErrorResult",
        message: "Houve um erro ao buscar a categoria",
        action: { notify: "categoryListPage", redirect: false }
      }
    },
    create: {
      success: {
        name: "CategoryCreateResult",
        message: "Categoria adicionada com sucesso",
        action: { notify: "categoryListPage", redirect: () => history.push(path) }
      },
      error: {
        name: "CategoryErrorResult",
        message: "Houve um erro ao adicionar a categoria",
        action: { notify: "categoryForm", redirect: false }
      }
    },
    update: {
      success: {
        name: "CategoryUpdateResult",
        message: "Categoria atualizada com sucesso",
        action: { notify: "categoryListPage", redirect: () => history.push(path) }
      },
      error: {
        name: "CategoryErrorResult",
        message: "Houve um erro ao atualizar a categoria",
        action: { notify: "categoryForm", redirect: false }
      }
    }
  };
};

export { events, values, actions };
