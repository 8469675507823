import React, { Component, Fragment, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";

const IntranetFooter = () => {
  return (
    <Fragment>
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-NS5NCW5"
          height="0"
          width="0"
          style={{"display": "none", "visibility": "hidden"}}
        ></iframe>
      </noscript>
    </Fragment>
  );
};

export default IntranetFooter;
