import React, { useCallback } from "react";
import ReactLoader from "./loader/Loader.react";
import Styled, { css, keyframes } from "styled-components";

import Context from "@context";

const fadeOut = keyframes`
  0% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
`;

const animationFadeOut = css`
  opacity: 0;
  animation: ${fadeOut} 0.5s linear;
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
    visibility: hidden;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
`;

const animationFadeIn = css`
  opacity: 1;
  animation: ${fadeIn} 0.5s linear;
`;

const LoaderContainer = Styled.div`
  display: flex;
  background-color: #f8f8f888;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: 998;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  ${props => (props.isFadeOut ? animationFadeOut : null)}
`;

const FadeInDiv = Styled.div`
  ${animationFadeIn}
`;

const Loader = ({ isFadeOut, ...props }) => {
  return (
    <LoaderContainer isFadeOut={isFadeOut} className="uk-overlay-default uk-position-cover">
      <FadeInDiv>
        <ReactLoader {...props} className="uk-position-fixed uk-position-center" />
      </FadeInDiv>
    </LoaderContainer>
  );
};

const LoaderSiteComponent = ({ show, height = "75", width = "75" }) => {
  const { state, action } = Context.useState();

  return (
    <Loader
      type="doubleRing"
      height={height}
      width={width}
      isFadeOut={show == false}
      color1="#5c2d91"
      color2="#da1c5c"
    />
  );
};

export default LoaderSiteComponent;
