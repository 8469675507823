const view = {
  group: {
    title: "Bônus",
    slug: "bonus"
  },
  slug: "bonus-view",
  title: "Ver bônus",
  description: "Ver os próprios bônus",
  list: [
    "prn::1:bonus-view-roles:bonus-view:allow:lcnt:schema.user.roles:custom.static.manager"
  ]
}

const viewAny = {
  group: {
    title: "Bônus",
    slug: "bonus"
  },
  slug: "bonus-view-any",
  title: "Ver qualquer bônus",
  description: "Ver todos os bônus",
  list: [
    "prn::1:bonus-view-any-roles:bonus-view-any:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

module.exports = {
  view,
  viewAny
}