import React, { useState, useEffect, useCallback, Fragment } from "react";

import { FieldLabelWrapper } from "./FormWrappers.react";
import LoaderBooleanComponent from "../utils/LoaderBooleanComponent.react";

const getProp = (obj, prop) => {
  if (typeof obj !== "object") throw "getProp: obj is not an object";
  if (typeof prop !== "string") throw "getProp: prop is not a string";

  // Replace [] notation with dot notation
  prop = prop.replace(/\[["'`](.*)["'`]\]/g, ".$1");

  return prop.split(".").reduce(function(prev, curr) {
    return prev ? prev[curr] : undefined;
  }, obj || self);
};

const Total = ({ count }) => {
  const c = typeof count == "undefined" || isNaN(count) ? 0 : count;

  return (
    <div className="uk-align-right">
      {c} registro{c == 1 ? "" : "s"} encontrado{c == 1 ? "" : "s"}.
    </div>
  );
};

const Step = ({ name, pages, show, value, onClick, points, ...props }) => {
  return (
    <li className={!pages || !show ? "uk-disabled" : "uk-active"}>
      <a onClick={onClick}>
        <span
          {...{ [`uk-pagination-${name}`]: "" }}
          {...props}
          className={`uk-icon uk-pagination-${name}`}
        >
          <svg
            width="7"
            height="12"
            viewBox="0 0 7 12"
            xmlns="https://www.w3.org/2000/svg"
            data-svg={`pagination-${name}`}
          >
            <polyline fill="none" stroke="#000" strokeWidth="1.2" points={points}></polyline>
          </svg>
        </span>
      </a>
    </li>
  );
};

function paginate(totalItems, currentPage = 0, pageSize = 10, maxPages = 10) {
  // calculate total pages
  let totalPages = Math.ceil(totalItems / pageSize);

  // ensure current page isn't out of range
  if (currentPage < 1) {
    currentPage = 1;
  } else if (currentPage > totalPages) {
    currentPage = totalPages;
  }

  let startPage, endPage;
  if (totalPages <= maxPages) {
    // total pages less than max so show all pages
    startPage = 1;
    endPage = totalPages;
  } else {
    // total pages more than max so calculate start and end pages
    let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
    let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
    if (currentPage <= maxPagesBeforeCurrentPage) {
      // current page near the start
      startPage = 1;
      endPage = maxPages;
    } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
      // current page near the end
      startPage = totalPages - maxPages + 1;
      endPage = totalPages;
    } else {
      // current page somewhere in the middle
      startPage = currentPage - maxPagesBeforeCurrentPage;
      endPage = currentPage + maxPagesAfterCurrentPage;
    }
  }

  // calculate start and end item indexes
  let startIndex = (currentPage - 1) * pageSize;
  let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

  // create an array of pages to ng-repeat in the pager control
  let pages = Array.from(Array(endPage + 1 - startPage).keys()).map(i => startPage + i);

  // return object with all pager properties required by the view
  return {
    totalItems: totalItems,
    currentPage: currentPage,
    pageSize: pageSize,
    totalPages: totalPages,
    startPage: startPage,
    endPage: endPage,
    startIndex: startIndex,
    endIndex: endIndex,
    pages: pages
  };
}

const Numbers = ({ curr, count, limit, isLoading, onClick }) => {
  const numbersFormatted = paginate(count, curr, limit);

  const currentNumberStyle = {
    backgroundColor: "#e1e1e1",
    color: "#1e87f0",
    fontWeight: "bold",
    paddingLeft: "8px",
    paddingRight: "8px",
    paddingBottom: "4px",
    borderRadius: "4px",
  };

  return (
    <Fragment>
      {numbersFormatted.pages.map(number => (
        <li key={"number-" + number} className={isLoading ? "uk-disabled" : "uk-active"}>
          {curr == number - 1 || isLoading ? (
            <span
              key={"number-" + number}
              style={currentNumberStyle}
            >
              {number}
            </span>
          ) : (
            <a key={"number-" + number} onClick={() => onClick(number - 1)}>
              {number}
            </a>
          )}
        </li>
      ))}
    </Fragment>
  );
};

const Pagination = ({ page, options, totalItems, limit, ...props }) => {
  return (
    <Fragment>
      <ul className="uk-pagination">
        {options.pagination.prev ? (
          <Step
            name="previous"
            points="6 1 1 6 6 11"
            pages={page.pages}
            show={page.canPrev}
            value={options.pagination.prev.value}
            onClick={page.prev}
            {...options.pagination.prev}
          />
        ) : null}
        {options.pagination.numbers ? (
          <Numbers
            limit={limit}
            count={totalItems}
            curr={page.curr}
            onClick={page.page}
            isLoading={page.isLoading}
          />
        ) : null}
        {options.pagination.next ? (
          <Step
            name="next"
            points="1 1 6 6 1 11"
            pages={page.pages}
            show={page.canNext}
            value={options.pagination.next.value}
            onClick={page.next}
            {...options.pagination.next}
          />
        ) : null}
      </ul>
    </Fragment>
  );
};

const Limit = ({ page, options, ...props }) => {
  return (
    <select {...props} className="uk-select uk-margin-remove" disabled={page.isLoading}>
      {options.limit.limits.map(({ value, label, ...opts }, index) => (
        <option key={"limit" + index} {...opts} value={value}>
          {label}
        </option>
      ))}
    </select>
  );
};

const Header = ({ columns, refresh, loader, options }) => {
  const [values, setValues] = useState([]);

  useEffect(() => {
    const values = [];

    for (let index = 0; index < columns.length; index++) {
      const key = columns[index];

      const type = typeof options.items[key].header;

      switch (type) {
        case "string":
          values[key] = {
            value: options.items[key].header
          };
          break;

        case "function":
          values[key] = options.items[key].header(options.items[key].column, refresh, loader);
          break;

        default:
          values[key] = {
            ...options.items[key].header
          };
          break;
      }
    }

    setValues(values);
  }, [JSON.stringify(columns)]);

  return (
    <Fragment>
      <tr>
        {Object.entries(values).map(([key, { value, ...props }]) => (
          <th key={`header-${key}`} {...props}>
            {value}
          </th>
        ))}
      </tr>
    </Fragment>
  );
};

const Row = ({ item, columns, refresh, loader, options }) => {
  const [values, setValues] = useState([]);

  useEffect(() => {
    const values = [];

    for (let index = 0; index < columns.length; index++) {
      const key = columns[index];
      const type = typeof options.items[key].column;

      switch (type) {
        case "string":
          values[key] = {
            value: getProp(item, options.items[key].column)
          };
          break;

        case "function":
          values[key] = options.items[key].column(item, refresh, loader);
          break;

        default:
          values[key] = {
            ...options.items[key].column,
            value: getProp(item, options.items[key].column.value)
          };
          break;
      }

      if (typeof options.items[key].parse != "undefined") {
        values[key].value = options.items[key].parse(values[key].value, item);
      }
    }

    setValues(values);
  }, [JSON.stringify(item)]);

  return (
    <Fragment>
      <tr>
        {Object.values(values).map(({ value, parse, ...props }, index) => (
          <td key={"column" + index} {...props}>
            {value}
          </td>
        ))}
      </tr>
    </Fragment>
  );
};

const EmptyRow = ({ columns, ...props }) => {
  return (
    <tr>
      <td colSpan={columns.length}> </td>
    </tr>
  );
};

const NotFoundRow = ({ columns, ...props }) => {
  return (
    <tr>
      <td colSpan={columns.length}>Nenhum registro encontrado.</td>
    </tr>
  );
};

const List = ({ filter, list, page, limit, options, ...props }) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(Object.keys(options.items));
  }, [options.items]);

  return (
    <Fragment>
      {options && options.filter && options.filter.fields ? (
        <div className="uk-card uk-card-default uk-card-body uk-padding-small uk-margin-bottom">
          <h3>Filtros</h3>
          <form className="uk-grid-small" onSubmit={e => e.preventDefault()} data-uk-grid>
            {Object.keys(filter.fields).map(name => (
              <FieldLabelWrapper
                key={"field-" + name}
                name={name}
                fields={filter.fields}
                values={filter.values}
              />
            ))}
          </form>
        </div>
      ) : null}
      <div className="uk-card uk-card-default uk-card-body uk-padding-small">
        <LoaderBooleanComponent show={page.isLoading} />
        <div className="uk-overflow-auto">
          <table className="uk-table uk-table-small uk-table-striped uk-margin-remove">
            {options && options.items ? (
              <thead>
                <Header
                  columns={items}
                  options={options}
                  refresh={list.refresh}
                  loader={list.loader}
                />
              </thead>
            ) : null}
            <tbody>
              {list.items.length > 0 ? (
                list.items.map((item, index) => (
                  <Row
                    key={"row" + index}
                    item={item}
                    columns={items}
                    options={options}
                    refresh={list.refresh}
                    loader={list.loader}
                  />
                ))
              ) : (
                <Fragment>
                  {page.isLoading ? <EmptyRow columns={items} /> : <NotFoundRow columns={items} />}
                </Fragment>
              )}
            </tbody>
          </table>
        </div>
        {(options && options.limit && options.limit.limits) || (options && options.pagination) ? (
          <div className="uk-margin uk-flex-middle uk-grid" data-uk-grid="">
            {options && options.limit && options.limit.limits ? (
              <div className="uk-width-small">
                <Limit
                  options={options}
                  page={page}
                  onChange={e => limit.updater(Number(e.target.value))}
                />
              </div>
            ) : null}
            <div className="uk-width-auto">
              {options && options.pagination ? (
                <Pagination
                  options={options}
                  page={page}
                  totalItems={list.count}
                  limit={limit.limit}
                />
              ) : null}
            </div>
            {options && options.pagination && options.pagination.total ? (
              <div className="uk-width-expand@m">
                <Total count={list.count} />
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </Fragment>
  );
};

export { List, Row, Header, Limit, Numbers, Pagination, Step };
