import { schema, types } from "@cthulhi/pack-schm";
import { types as fields } from "@cthulhi/comp-form";

const schm = schema("discount", {
  description: {
    type: types.string.type,
    field: fields.textarea.type,
    required: true
  },
  type: {
    type: types.string.type,
    field: fields.select.type,
    required: true
  },
  quantity: {
    type: types.string.type,
    field: fields.number.type,
  },
  hasCashback: {
    type: types.boolean.type,
    field: fields.checkbox.type
  },
  cashbackType: {
    type: types.string.type,
    field: fields.select.type,
  },
  cashbackQuantity: {
    type: types.string.type,
    field: fields.number.type,
  },
  cashbackLimit: {
    type: types.string.type,
    field: fields.number.type,
  },
  discountImage: {
    type: types.string.type,
    field: fields.fileimagecrop.type
  },
  refoundCodeFile: {
    type: types.string.type,
    field: fields.file.type
  },
  refoundUrl: {
    type: types.string.type,
    field: fields.textfield.type
  },
  refoundCode: {
    type: types.string.type,
    field: fields.textfield.type
  },
  refoundType: {
    type: types.string.type,
    field: fields.select.type
  },
  limitType: {
    type: types.string.type,
    field: fields.select.type,
  },
  limitQuantity: {
    type: types.string.type,
    field: fields.number.type,
  },
  limitDaysQuantity: {
    type: types.string.type,
    field: fields.number.type,
  },
  howToUse: {
    type: types.string.type,
    field: fields.textarea.type
  },
  spotlight: {
    type: types.boolean.type,
    field: fields.checkbox.type,
    required: true
  },
  siteBanner: {
    type: types.boolean.type,
    field: fields.checkbox.type,
    required: true
  },
  available: {
    type: types.boolean.type,
    field: fields.checkbox.type,
    required: true
  },
  shelfLifeStart: {
    type: types.datetime.type,
    field: types.datetime.type,
    required: { contains: true }
  },
  shelfLifeEnd: {
    type: types.datetime.type,
    field: types.datetime.type,
    required: { contains: true }
  },
  userTypes: {
    type: types.array.type,
    field: "ant-select",
    required: { contains: true },
    items: {
        type: types.string.type
    }
  },
  branches: {
    type: types.array.type,
    field: "ant-select",
    required: { contains: true },
    items: {
        type: types.string.type
    }
  },
});

export { schm as schema };
