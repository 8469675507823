import React, { Fragment } from 'react'

import Context from './../../../../context'
import ht from './../../../../constants/http.constant'

import Access from '@cthulhi/comp-accs'
import permissions from "@perm/index"

import PermissionListItemSelect from './PermissionListItemSelect.react'
import PermissionListItemInput from './PermissionListItemInput.react'

const PermissionListItem = (props) => {
  
  const { state: ctxState, action: ctxAction } = Context.useState();

  const items = ctxState.permission.list.items[props.index[0]][props.index[1]]

  const permission = items.list[props.index[2]]

  const contexts = items.context ? items.context.list : []
  
  const [ prn, blank, version, id, slug, op, compare, left, right, ...trash ] = permission.split(':')

  const [ leftType, ...leftValue ] = left.split(".")
  const [ rightType, ...rightValue ] = right.split(".")

  const onChange = (e) => {
    ctxAction('permission').updateField(e.target.name, e.target.value.replace(":", ""), props.index)
  }

  const onRemove = () => {
    ctxAction('permission').removeItem(props.index)
  }

  return (
    <Fragment>
      <tr>
        <td>
          <input 
            name="id" 
            value={id}
            placeholder="..."
            className="uk-input uk-form-small" 
            onChange={onChange} /> 
        </td>
        <td>
          <PermissionListItemSelect 
            name="op" 
            value={op} 
            options={ctxState.permission.list.options.ops} 
            className="uk-select uk-form-small"
            onChange={onChange} 
            />
        </td>
        <td>
          <PermissionListItemSelect 
            name="leftType" 
            value={leftType} 
            options={ctxState.permission.list.options.types} 
            className="uk-select uk-form-small"
            onChange={onChange} 
            />
        </td>
        <td>

          {!leftType ? (
            <select
              name="leftTypeSub" 
              disabled={true}
              className="uk-select uk-form-small"
              required
              >
                <option value="-">Selecione...</option>
              </select>
          ) : null}

          {leftType == 'schema' ? (
            <PermissionListItemSelect 
              name="leftTypeSub" 
              value={leftValue[0]} 
              options={ctxState.permission.list.options.schemas} 
              onChange={onChange} 
              disabled={!leftType || leftType == '-'}
              className="uk-select uk-form-small"
              required
              />
          ) : null}
 
          {leftType == 'custom' ? (
            <PermissionListItemSelect 
              name="leftTypeSub" 
              value={leftValue[0]} 
              options={ctxState.permission.list.options.customs} 
              disabled={!leftType || leftType == '-'} 
              className="uk-select uk-form-small"
              required
              />
          ) : null}

          {leftType == 'context' ? (
            <PermissionListItemSelect 
              name="leftTypeSub" 
              value={leftValue[0]} 
              options={contexts ? contexts : [] } 
              onChange={onChange} 
              disabled={!leftType || leftType == '-'}
              className="uk-select uk-form-small"
              required
              />
          ) : null}

        </td>
        <td>
          <PermissionListItemInput 
            name="leftValue" 
            value={leftValue.splice(1).join(".")} 
            onChange={onChange}
            disabled={!leftValue || leftValue.length < 1}
            className="uk-input uk-form-small" 
            required
            />
        </td>
        <td>
          <PermissionListItemSelect 
            name="compare" 
            value={compare} 
            options={ctxState.permission.list.options.comparings}
            className="uk-select uk-form-small"
            onChange={onChange}
            />
        </td>
        <td>
          <PermissionListItemSelect 
            name="rightType" 
            value={rightType} 
            options={ctxState.permission.list.options.types}
            className="uk-select uk-form-small"
            onChange={onChange} 
            required
            />
        </td>
        <td>

          {!rightType ? (
            <select
              name="rightTypeSub" 
              disabled={true}
              className="uk-select uk-form-small"
              required
              >
                <option value="-">Selecione...</option>
              </select>
          ) : null}

          {rightType == 'schema' ? (
            <PermissionListItemSelect 
              name="rightTypeSub" 
              value={rightValue[0]} 
              options={ctxState.permission.list.options.schemas} 
              onChange={onChange} 
              disabled={!rightType || rightType == '-'}
              className="uk-select uk-form-small"
              required
              />
          ) : null}

          {rightType == 'custom' ? (
            <PermissionListItemSelect 
              name="rightTypeSub" 
              value={rightValue[0]} 
              options={ctxState.permission.list.options.customs} 
              onChange={onChange} 
              disabled={!rightType || rightType == '-'}
              className="uk-select uk-form-small"
              required
              />
          ) : null}

          {rightType == 'context' ? (
            <PermissionListItemSelect 
              name="rightTypeSub" 
              value={rightValue[0]} 
              options={contexts ? contexts : [] }
              onChange={onChange} 
              disabled={!rightType || rightType == '-'}
              className="uk-select uk-form-small"
              required
              />
            ) : null}
            
        </td>
        <td>
          <PermissionListItemInput 
            name="rightValue" 
            value={rightValue.splice(1).join(".")} 
            onChange={onChange}
            disabled={!rightValue || rightValue.length < 1}
            className="uk-input uk-form-small" 
            required
            />
        </td>
        <td>
          <button 
            className="uk-icon-button uk-button-danger" 
            uk-icon="trash" 
            data-uk-toggle={"#delete-" + props.index.join("-") } 
          ></button>
          <div id={ "delete-" + props.index.join("-") } data-uk-modal>
            <div className="uk-modal-dialog">
              <div className="uk-modal-header">
                <h2 className="uk-modal-title">Remover</h2>
              </div>
              <div className="uk-modal-body">
                <p>
                  Tem certeza que deseja remover esta permissão?
                </p>
              </div>
              <div className="uk-modal-footer uk-text-right">
                <button 
                  className="uk-button uk-button-small uk-button-default uk-border-pill uk-modal-close"
                  type="button">Cancelar</button>
                <button 
                  className="uk-button uk-button-small uk-button-danger uk-border-pill uk-modal-close"
                  onClick={onRemove}
                  type="button">Remover</button>
              </div>
            </div>
            </div>
        </td>
      </tr>

    </Fragment>
  )
} 

export default PermissionListItem