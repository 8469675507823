import React, { Fragment } from 'react'

import PermissionList from './PermissionList.react'


const PermissionPage = (props) => {

  return (
    <Fragment>
      <PermissionList />
    </Fragment>
  )
} 

export default PermissionPage