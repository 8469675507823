import banner from "./banner.perms.js";
import benefit from "./benefit.perms.js";
import bonus from "./bonus.perms.js";
import branch from "./branch.perms.js";
import business from "./business.perms.js";
import category from "./category.perms.js";
import faq from "./faq.perms.js";
import perm from "./perm.perms.js";
import subscription from "./subscription.perms.js";
import user from "./user.perms.js";
import userType from "./userType.perms.js";
import redemption from "./redemption.perms.js";
import voucher from "./voucher.perms.js";
import userVip from "./userVip.perms.js";
import content from "./content.perms.js";
import voucherCode from "./voucherCode.perms.js";
import discount from "./discount.perms.js";
import partner from "./partner.perms.js";
import event from "./event.perms.js";
import coupon from "./coupon.perms.js";


export default {
  userType,
  event,
  coupon,
  banner,
  benefit,
  bonus,
  branch,
  business,
  category,
  faq,
  perm,
  subscription,
  user,
  redemption,
  voucher,
  userVip,
  content,
  voucherCode,
  discount,
  partner
};
