import React, { Component, Fragment, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Route, Switch, Redirect } from "react-router-dom";
import Access from "@cthulhi/comp-accs";
import Context from "@html-context";

import Menu from "./pages/menu/Menu.react";
import IntranetHeader from "./pages/IntranetHeader.react";

import BannerPage from "./pages/banner/BannerPage.react";
import BenefitPage from "./pages/benefit/BenefitPage.react";
import BranchPage from "./pages/branch/BranchPage.react";
import BusinessPage from "./pages/business/BusinessPage.react";
import CategoryPage from "./pages/category/CategoryPage.react";
import EventPage from "./pages/event/EventPage.react";
import PartnerDiscountPage from "./pages/discount/partner/DiscountPage.react";
import EventDiscountPage from "./pages/discount/events/DiscountPage.react";
import FaqPage from "./pages/faq/FaqPage.react";
import HelpPage from "./pages/help/HelpPage.react";
import NotFoundPage from "./pages/error/NotFoundPage.react";
import PermissionPage from "./pages/permission/PermissionPage.react";
import PartnerPage from "./pages/partner/PartnerPage.react";
import RedemptionPage from "./pages/redemption/RedemptionPage.react";
// import SubscriberPage from "./pages/subscriber/SubscriberPage.react";
import UserPage from "./pages/user/UserPage.react";
import UserPasswordPage from "./pages/user/UserPasswordPage.react";
import VoucherValidatePage from "./pages/voucher/VoucherValidatePage.react";
import UserVipPage from "./pages/userVip/UserVipPage.react";
import UserTypePage from "./pages/userType/UserTypePage.react";
import ContentPage from "./pages/content/ContentPage.react";

import permissions from "@perm";

import LoaderWindow from "./../../views/utils/LoaderWindowComponent.react";
import IntranetFooter from "./pages/IntranetFooter.react";

const IntranetView = ({ match, history, location }) => {
  const { action: accAction, consts: accConts } = Access.useState();

  let status = accAction("access").getPermissionListStatus([
    permissions.redemption.view,
    permissions.redemption.viewAny
  ]);

  useEffect(() => {
    if (location.pathname == `${match.path}` || location.pathname == `${match.path}/`) {
      if (status != accConts.STS_PERMS_LOADING) {
        if (status == accConts.STS_PERMS_ALLOWED) {
          history.push(`${match.path}/redemption/consumer`);
        } else {
          history.push(`${match.path}/voucher/validate`);
        }
      }
    }
  }, [status]);

  return (
    <Fragment>
      <LoaderWindow />
      <Helmet>
        <link href="https://cdnjs.cloudflare.com/ajax/libs/antd/4.15.2/antd.compact.min.css" rel="stylesheet" />
        <link href={`${process.env.domain}assets/intranet/css/uikit.min.css`} rel="stylesheet" />
        <link href={`${process.env.domain}assets/intranet/css/main.css`} rel="stylesheet" />
        <link rel="icon" href={`${process.env.domain}assets/intranet/images/favicon.ico`} />

        <script src={`${process.env.domain}assets/intranet/js/uikit.min.js`}></script>
        <script src={`${process.env.domain}assets/intranet/js/uikit-icons.min.js`}></script>

        <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-NS5NCW5');`}</script>
      </Helmet>
      <IntranetHeader path={`${match.path}`} />
      <section className="dashboard">
        <aside>
          <Menu path={`${match.path}`} />
        </aside>
        <main>
          <div className="uk-container uk-container uk-position-relative">
            <Switch>

              {/* AssinantesGroup */}
              <Access.RestrictRoute
                onAccess={[
                  permissions.partner.view,
                  permissions.partner.viewAny,
                  permissions.partner.edit,
                  permissions.partner.editAny
                ]}
                path={`${match.path}/partner`}
                onAllow={PartnerPage}
              />
              {/*
              <Access.RestrictRoute
                onAccess={[
                  permissions.subscription.view,
                  permissions.subscription.viewAny,
                  permissions.subscription.edit,
                  permissions.subscription.editAny
                ]}
                path={`${match.path}/subscriber`}
                onAllow={SubscriberPage}
              /> */}
              <Access.RestrictRoute
                onAccess={[permissions.redemption.view, permissions.redemption.viewAny]}
                path={`${match.path}/redemption`}
                onAllow={RedemptionPage}
              />

              <Access.RestrictRoute
                onAccess={[permissions.userVip.view, permissions.userVip.viewAny]}
                path={`${match.path}/user-vip`}
                onAllow={UserVipPage}
              />

               <Access.RestrictRoute
                onAccess={[permissions.userType.view, permissions.userType.viewAny]}
                path={`${match.path}/user-type`}
                onAllow={UserTypePage}
              />
              {/* ComunicaçãoGroup */}
              {/* <Access.RestrictRoute
                onAccess={[
                  permissions.benefit.view,
                  permissions.benefit.edit,
                  permissions.benefit.viewAny,
                  permissions.benefit.editAny
                ]}
                path={`${match.path}/benefit`}
                onAllow={BenefitPage}
              /> */}
              <Access.RestrictRoute
                onAccess={[
                  permissions.banner.view,
                  permissions.banner.viewAny,
                  permissions.banner.edit,
                  permissions.banner.editAny
                ]}
                path={`${match.path}/banner`}
                onAllow={BannerPage}
              />
              <Access.RestrictRoute
                onAccess={[permissions.faq.viewAny, permissions.faq.editAny]}
                path={`${match.path}/faq`}
                onAllow={FaqPage}
              />
              <Access.RestrictRoute
                onAccess={[permissions.content.viewAny, permissions.content.editAny]}
                path={`${match.path}/content`}
                onAllow={ContentPage}
              />

              {/* NegocioGroup */}
              <Access.RestrictRoute
                onAccess={[permissions.voucher.validate]}
                path={`${match.path}/voucher/validate`}
                onAllow={VoucherValidatePage}
              />

              {/* BusinessGroup */}
              <Route path={`${match.path}/business`} component={BusinessPage} />
              <Route path={`${match.path}/branch`} component={BranchPage} />

              <Access.RestrictRoute
                onAccess={[
                  permissions.category.view,
                  permissions.category.viewAny,
                  permissions.category.edit,
                  permissions.category.editAny
                ]}
                path={`${match.path}/category`}
                onAllow={CategoryPage}
              />

              <Access.RestrictRoute
                onAccess={[
                  permissions.event.view,
                  permissions.event.viewAny,
                  permissions.event.edit,
                  permissions.event.editAny
                ]}
                path={`${match.path}/event`}
                onAllow={EventPage}
              />

              <Access.RestrictRoute
                onAccess={[
                  permissions.discount.view,
                  permissions.discount.viewAny,
                  permissions.discount.edit,
                  permissions.discount.editAny
                ]}
                path={`${match.path}/discount/partner`}
                onAllow={PartnerDiscountPage}
              />

              <Access.RestrictRoute
                onAccess={[
                  permissions.discount.view,
                  permissions.discount.viewAny,
                  permissions.discount.edit,
                  permissions.discount.editAny
                ]}
                path={`${match.path}/discount/event`}
                onAllow={EventDiscountPage}
              />


              {/* SuporteGroup */}
              <Access.RestrictRoute
                onAccess={[permissions.faq.view]}
                path={`${match.path}/help`}
                onAllow={HelpPage}
              />

              {/* AdminGroup */}
              <Access.RestrictRoute
                onAccess={[
                  permissions.perm.view,
                  permissions.perm.viewAny,
                  permissions.perm.edit,
                  permissions.perm.editAny
                ]}
                path={`${match.path}/permission`}
                onAllow={PermissionPage}
              />
              <Access.RestrictRoute
                onAccess={[
                  permissions.user.view,
                  permissions.user.viewAny,
                  permissions.user.edit,
                  permissions.user.editAny
                ]}
                path={`${match.path}/user`}
                onAllow={UserPage}
              />

              <Access.RestrictRoute
                onAccess={[permissions.user.viewPassword, permissions.user.editPassword]}
                path={`${match.path}/user/password`}
                onAllow={UserPasswordPage}
              />

              <Route component={NotFoundPage} />
            </Switch>
          </div>
        </main>
      </section>
      <IntranetFooter />
    </Fragment>
  );
};

export default IntranetView;
