import React, { Component, Fragment, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";

const AccessHeader = (props) => {

  return (
    <Fragment>
      <header className="header uk-visible@m">
        <div className="uk-container">
          <div className="uk-flex uk-flex-middle uk-flex-between">
            <div className="uk-hidden@m">
              <canvas className="canvas-toggle uk-hidden@m" width="28" height="0"></canvas>
              <button className="toggle" uk-toggle="target: #my-id" type="button">
                <i className="icon icon-toggle" aria-hidden="true" />
              </button>
            </div>
            <div>
              <div className="uk-grid uk-flex-middle">
                <div className="logo">
                  <img src={`${process.env.domain}assets/access/images/logo-nsc.svg`} alt="NSC Parceiro App" />
                </div>
                <div className="label uk-visible@m">
                  <h1>Parceiro App</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </Fragment>
  )
}

export default AccessHeader