import { schema, types } from "@cthulhi/pack-schm";
import { types as fields } from "@cthulhi/comp-form";

const schm = schema("faq", {
  title: {
    type: types.string.type,
    field: fields.textfield.type,
    required: { contains: true },
    label: "Título",
    placeholder: "Título"
  },
  description: {
    type: types.string.type,
    field: fields.textarea.type,
    required: { contains: true },
    label: "Descrição",
    placeholder: "Descrição"
  },
  linkTo: {
    type: types.string.type,
    field: fields.textfield.type,
    label: "Link de Destino",
    placeholder: "Link de Destino"
  },
  weight: {
    type: types.integer.type,
    field: fields.textfield.type,
    required: { contains: true },
    label: "Ordem",
    placeholder: "Ordem"
  },
  status: {
    type: types.boolean.type,
    field: fields.checkbox.type,
    required: { contains: true },
    label: "Ativo",
    placeholder: "Ativo"
  }
});

export { schm as schema };
