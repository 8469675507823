import React from "react";
import Overlay from "./../../../utils/overlay/Overlay.react";

const ModalValidationError = ({ onClose, msg }) => {
  return (
    <Overlay backgroundColor={"#ff4343"} backgroundOpacity={"0.9"} onClick={onClose}>
      <div id="voucher-error" className="voucher voucher-error">
        <div className="voucher-card">
          <div className="voucher-icon voucher-error"></div>
          <h3 className="voucher-title uk-text-uppercase">
            Ooops!
            <br />
            Voucher inválido
          </h3>

          {msg ? (
            <p>{msg}</p>
          ) : (
            <p>Solicite ao cliente que gere um novo voucher e tente novamente.</p>
          )}
        </div>
        <button
          className="uk-button uk-button-large uk-button-secondary uk-align-center"
          onClick={onClose}
        >
          Validar outro voucher
        </button>
      </div>
    </Overlay>
  );
};

export default ModalValidationError;
