import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import Access from "@cthulhi/comp-accs";
import Context from "@context";
import { useFields, useForms } from "@cthulhi/comp-form";

import { schema as userFormSchema } from "@schm/user/create.form.schema";

import {
  events as userFormEvents,
  values as userFormValues,
  actions as userFormActions
} from "./components/UserProfileForm.config";

import { FieldLabelWrapper } from "../../../wrappers/FormWrappers.react";

import Notification from "@cthulhi/comp-noty";
import NotificationComponent from "../../../utils/Notification.react";

import LoaderComponent from "../../../utils/LoaderCustomComponent.react";

import permissions from "@perm";

const UserPage = ({ match, parentPath, history, ...props }) => {
  const { action: ctxAction, state: ctxtState } = Context.useState();
  const { state: accState, action: accAction, consts: accConts } = Access.useState();
  const { state: ntfState, action: ntfAction } = Notification.useState();
  const [validation, setValidation] = useState({})

  const { form, fields, values, updater, cleaner } = useFields({
    schema: userFormSchema,
    events: userFormEvents({ ctxAction }),
    values: userFormValues({
      ctxAction,
      accAction,
      token: `Bearer ${accState.access.session.context.token}`
    })
  });

  const { find, update, create } = useForms(
    userFormActions({
      ctxAction,
      ntfAction,
      updater,
      history,
      path: parentPath,
      token: `Bearer ${accState.access.session.context.token}`
    })
  );

  const userFormSubmit = () => {
    let vs = {};
    setValidation({})

    Object.entries(values).map(([key, value]) => {
      const hasValue = typeof value.value != "undefined" && value.value !== ""
      const hasRawValue = typeof value.rawValue != "undefined" && value.rawValue !== ""    
      
      vs[key] = hasRawValue ? value.rawValue
      : (hasValue ? value.value : value.defaultValue)
    });

    let required = []

    if (!vs.email) required.push('email')
    if (!vs.name) required.push('name')
    
    if (required.length) {

      setValidation(required.reduce((obj, f) => {
        return {...obj, [f]: false}
      }, {}))

      ntfAction("notification").add(
        1,
        "userForm",
        "error",
        `Houve um erro ao ${match.params.id ? "atualizar" : "salvar"} o usuário. Os seguintes campos são obrigatórios: ${required.join(', ')}`,
        undefined,
        process.env.notificationTimeOut
      )
      return;
    }

    vs["businessId"] = accState.access.session.context.payload.businessId;

    if(!Array.isArray(vs["roles"])) vs["roles"] = [vs["roles"]]

    match.params.id ? update(vs) : create(vs);
  };

  useEffect(() => {
    if (match.params.id) {
      find({ filters: { _id: match.params.id } }, { projection: { password: 0 } });
    }

    return () => {
      cleaner();
    };
  }, [match.params.id]);

  return (
    <Fragment>
      <h2 className="title">{match.params.id ? "Editar usuário" : "Novo usuário"}</h2>
      <div className="uk-margin-top">
        <ul data-uk-accordion="multiple: true">
          <li className="uk-open">
            <a className="title title-lead-small title-accordion uk-accordion-title">
              Dados do usuário
            </a>
            <div className="uk-accordion-content">
              <Notification.NotificationWrapper
                group="userForm"
                component={NotificationComponent}
              />
              <div className="uk-card uk-card-default uk-card-body uk-padding-small">
                <LoaderComponent from="userForm" />
                <form className="uk-grid-small" data-uk-grid>
                  <FieldLabelWrapper name="name" fields={fields} values={values} validation={validation.name}/>
                  <FieldLabelWrapper name="password" fields={fields} values={values} validation={validation.password}/>
                  <FieldLabelWrapper name="email" fields={fields} values={values} validation={validation.email}/>
                  <FieldLabelWrapper name="roles" fields={fields} values={values} validation={validation.roles}/>
                  <FieldLabelWrapper name="status" fields={fields} values={values} validation={validation.status}/>
                </form>
              </div>
            </div>
          </li>
        </ul>
        <div className="uk-margin-top">
          <button
            onClick={userFormSubmit}
            className="uk-button uk-button-large uk-button-secondary"
            style={{ float: "right" }}
            disabled={typeof ctxtState.loader.list["userForm"] != "undefined" && ctxtState.loader.list["userForm"].length > 0}
          >
            {match.params.id ? "Atualizar" : "Salvar"}
          </button>
        </div>
      </div>
    </Fragment>
  );
};

// @ts-ignore
export default withRouter(UserPage);
