const view = {
  group: {
    title: "Sócio Beneficiado",
    slug: "partner"
  },
  slug: "partner-view",
  title: "Ver partners",
  description: "Ver os próprios sócios beneficiados",
  list: [
    "prn::1:partner-view-roles:partner-view:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

const viewAny = {
  group: {
    title: "Sócio Beneficiado",
    slug: "partner"
  },
  slug: "partner-view-any",
  title: "Ver qualquer partner",
  description: "Ver todos os sócios beneficiados",
  list: [
    "prn::1:partner-view-any-roles:partner-view-any:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

const edit = {
  group: {
    title: "Sócio Beneficiado",
    slug: "partner"
  },
  slug: "partner-edit",
  title: "Editar partners",
  description: "Editar os próprios sócios beneficiados",
  list: [
    "prn::1:partner-edit-roles:partner-edit:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

const editAny = {
  group: {
    title: "Sócio Beneficiado",
    slug: "partner"
  },
  slug: "partner-edit-any",
  title: "Editar qualquer partner",
  description: "Editar todos os sócios beneficiados",
  list: [
    "prn::1:partner-edit-any-roles:partner-edit-any:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

const remove = {
  group: {
    title: "Sócio Beneficiado",
    slug: "partner"
  },
  slug: "partner-remove",
  title: "Remover partners",
  description: "Remover os próprios sócios beneficiados"
}

const removeAny = {
  group: {
    title: "Sócio Beneficiado",
    slug: "partner"
  },
  slug: "partner-remove-any",
  title: "Remover qualquer partner",
  description: "Remover todos os sócios beneficiados"
}

const create = {
  group: {
    title: "Sócio Beneficiado",
    slug: "partner"
  },
  slug: "partner-create",
  title: "Criar partners",
  description: "Criar os próprios sócios beneficiados"
}

const createAny = {
  group: {
    title: "Sócio Beneficiado",
    slug: "partner"
  },
  slug: "partner-create-any",
  title: "Criar qualquer partner",
  description: "Criar todos os sócios beneficiados"
}

module.exports = {
  view,
  viewAny,
  edit,
  editAny,
  remove,
  removeAny,
  create,
  createAny
}