import React, { useState, useEffect, Fragment } from "react";
import { types } from "@cthulhi/pack-schm";

import Mask from "./../comp-mask";

const upperCaseFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const lowerCaseFirstLetter = string => {
  return string.charAt(0).toLocaleLowerCase() + string.slice(1);
};

const parseFieldValue = (value, dataType) => {
  if (typeof value != "undefined") {
    switch (dataType) {
      case types.float.type:
        return !isNaN(value) ? parseFloat(value) : undefined;

      case types.integer.type:
        return !isNaN(value) ? parseInt(value) : undefined;

      case types.boolean.type:
        return value ? true : false;

      case types.object.type:
        return value;

      default:
        return value.toString();
    }
  }


  return value;
};

const updateFieldValue = (type, value, defaultValue, required, masker) => {
  if (value && value.target) {
    let updated = value.target.value;

    switch (type) {
      case "select:multiple":
        updated = [];
        for (var i = 0, l = value.target.options.length; i < l; i++) {
          if (value.target.options[i].selected) {
            updated.push(value.target.options[i].value);
          }
        }
        break;
      case "checkbox":
        updated = value.target.checked ? true : false;
        break;

      case "datetime":
        updated = value.format();
        break;

      case "textfield":
        if (masker && masker.m && updated) {
          updated = masker.m[masker.a](updated);
        }
        break;
    }

    if (required && value.target.checkValidity) value.target.checkValidity();

    return updated;
  }

  return typeof value != "undefined" ? value : defaultValue;
};

export const useFields = ({ schema, events, values: initValues = {}, ...formProps }) => {
  const [values, setValues] = useState(initValues);
  const [fields, setFields] = useState({});

  const updateValue = (name, value, rawValue) => {
    setValues(vs => ({
      ...vs,
      [name]: {
        ...vs[name],
        ...value,
        rawValue: typeof rawValue != "undefined" ? rawValue : typeof value != "undefined" ? value.value : ""
      }
    }));

    return value;
  };

  const getValue = (name) => {
    if (document) {
      const element = document.getElementById(name);

      if (element.target && element.target.value) {
        return element.target.value;
      }
    }

    return "";
  }

  const processFields = (fields = schema.schema.props) => {
    let fs = {};

    Object.entries(fields).map(([key, prop]) => {
      fs[key] = {
        functs: {},
        events: events && events[key] ? events[key] : {},
        attrs: {
          ...prop
        }
      };

      if (values[key] && values[key].masks) {
        fs[key].functs.masker = new Mask(values[key].masks);
      }

      switch (fs[key].attrs.field) {
        case "checkbox":
          if (typeof fs[key].events["onClick"] == "undefined") {
            fs[key].events["onClick"] = (value, rawValue, update) => {
              return update(key, { value }, rawValue);
            };
          }
          break;

        default:
          if (typeof fs[key].events["onChange"] == "undefined") {
            fs[key].events["onChange"] = (value, rawValue, update) => {
              return update(key, { value }, rawValue);
            };
          }

          break;
      }

      Object.entries(fs[key].events).map(([ek, ev]) => {
        fs[key].events[ek] = (e, v) => {
          const m = fs[key].functs.masker ? fs[key].functs.masker : undefined;
          const t = fs[key].attrs.field + (fs[key].attrs.multiple ? ":multiple" : "");
          const r = fs[key].attrs.required ? fs[key].attrs.required : false;
          const d = values[key] && values[key].defaultValue ? values[key] && values[key].defaultValue : "";

          return ev(
            updateFieldValue(t, e, d, r, { m, a: "applyMask" }),
            parseFieldValue(
              updateFieldValue(t, e, d, r, { m, a: "getUnmasked" }),
              fs[key].attrs.type
            ),
            updateValue,
            getValue,
            v,
            e.currentTarget
          );
        };

        if (values[key] && values[key] && typeof values[key].options === "function") {
          const fct = values[key].options;
          values[key].options = () => {
            return fct(updateValue);
          };
        }

        if (values[key] && values[key] && typeof values[key].search === "function") {
          const fct = values[key].search;
          values[key].search = fct(updateValue);
        }
      });
    });

    Object.entries(fs).map(([key, field]) => {
      Object.entries(field.events).map(([ek, ev]) => {
        const keySplited = ek.split("From");
        const keyOriginal = keySplited.length > 1 && keySplited[1];

        if (keyOriginal) {
          const fct = fs[lowerCaseFirstLetter(keyOriginal)].events[keySplited[0]];
          fs[lowerCaseFirstLetter(keyOriginal)].events[keySplited[0]] = (e, v) => {
            return ev(fct(e, v), v);
          };

          delete fs[key].events[ek];
        }
      });
    });

    return fs;
  }

  const clearValues = () => {
    setValues(initValues);
    setFields({});
  };

  useEffect(() => {
    setFields(processFields());

    return () => {
      setFields({});
    };
  }, [JSON.stringify(schema.schema.props)]);

  const addFields = (form, fieldValues = {}) => {
    const props = {};
    Object.keys(schema.schema.props).forEach(key => {
      props[`${form}.${key}`] = schema.schema.props[key]
    })
    
    const values = {};
    Object.keys(initValues).forEach(key => {
      values[`${form}.${key}`] = initValues[key]
    })

    setFields(p => ({ ...p, ...processFields(props) }));
    setValues(v => ({ ...v, ...values }));

    Object.keys(fieldValues).forEach(key => {
      updateValue(`${form}.${key}`, { value: fieldValues[key]}, fieldValues[key]); 
    })
  }

  const removeFields = form => {
    const cloneFields = Object.assign(fields, {});
    const cloneValues = Object.assign(values, {});

    Object.keys(schema.schema.props).forEach(key => {
      delete cloneFields[`${form}.${key}`];
      delete cloneValues[`${form}.${key}`];
    });

    setFields(cloneFields);
    setValues(cloneValues);
  }

  return {
    form: schema.schema.props,
    fields,
    values,
    getter: getValue,
    updater: updateValue,
    cleaner: clearValues,
    addFields: addFields,
    removeFields: removeFields
  };
};

export default {
  useFields
};
