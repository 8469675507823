import React, { useState, Fragment } from "react";
import ReactQrReader from "react-qr-reader";
import PropTypes from "prop-types";
import styled, { css, keyframes } from "styled-components";

import ReactLoader from "../../../utils/loader/Loader.react";

const blinkOut = keyframes`
  0% {
    opacity: 1;
    visibility: visible;
    z-index: 0;
  }

  100% {
    opacity: 0;
    visibility: hidden;
    z-index: -1;
  }
`;

const animationBlinkOut = css`
  animation: ${blinkOut} 0.5s linear;
`;

const animationType = type => {
  switch (type) {
    case "blinkOut":
      return animationBlinkOut;
    default:
      return null;
  }
};

const QrReaderContainerProps = {
  types: {
    animationType: PropTypes.string,
    backgroundColor: PropTypes.string
  },
  default: {
    animationType: "blinkOut",
    backgroundColor: "white"
  }
};

const QrReaderContainer = ({ className, children }) => {
  return <div className={className}>{children}</div>;
};

QrReaderContainer.propTypes = QrReaderContainerProps.types;
QrReaderContainer.defaultProps = QrReaderContainerProps.default;

const QrReaderContainerStyled = styled(QrReaderContainer)`
  background-color: ${props => props.backgroundColor};
  position: relative;
  width: ${props => props.width};
  max-width: 400px;
`;

const QrReaderOverlayProps = { ...QrReaderContainerProps };

const QrReaderOverlay = ({ className, children, ...props }) => {
  return <div className={className}>{children}</div>;
};

QrReaderOverlay.propTypes = QrReaderOverlayProps.types;
QrReaderOverlay.defaultProps = QrReaderOverlayProps.default;

const QrReaderOverlayStyled = styled(QrReaderOverlay)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0;
  background-color: ${props => props.backgroundColor};
  ${props => (props.animationType ? animationType(props.animationType) : null)}
`;

const QrReaderOverlayError = ({ className, error }) => {
  const Code = styled.h4`
    color: #ffffff;
  `;

  const Title = styled.h2`
    color: #ffffff;
    margin: 0;
  `;

  const Message = styled.p`
    color: #ffffff;
    margin: 0;
    padding; 0; 
  `;

  if (error.error == null) return null;

  return (
    <div className={className}>
      <div>
        <Code>{error.code}</Code>
        <Title>{error.name}</Title>
        <Message>{error.error}</Message>
      </div>
    </div>
  );
};

const QrReaderOverlayErrorStyled = styled(QrReaderOverlayError)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  z-index: ${props => (props.error.name ? "0" : "-1")};
  opacity: ${props => (props.error.name ? "1" : "0")};
  background-color: #000000;
`;

const QrReaderOverlayLoad = ({ className, load }) => {
  const Message = styled.h2`
    color: #ffffff;
  `;

  return (
    <div className={className}>
      <ReactLoader
        type="doubleRing"
        height="200"
        width="200"
        isFadeOut={false}
        color1="#1a65bb"
        color2="#03aeff"
        backgroundColor={"#000000"}
      />
    </div>
  );
};

const QrReaderOverlayLoadStyled = styled(QrReaderOverlayLoad)`
  position: absolute;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${props => (props.load ? "0" : "-1")};
  opacity: ${props => (props.load ? "1" : "0")};
  background-color: #000000;
`;

const QrReaderComponent = ({ animationType, width, backgroundColor, ...props }) => {
  const HeaderStyled = styled.div`
    z-index: 0;
    color: white;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    padding: 10px 15px;
    top: 0;
    opacity: 0.7;
    background-color: ${props => props.backgroundColor};
  `;

  return (
    <Fragment>
      <ReactQrReader {...props} />
      <HeaderStyled backgroundColor={backgroundColor}>
        <div>Escanneie seu QR code aqui.</div>
      </HeaderStyled>
    </Fragment>
  );
};

const QrReader = ({ onError, load, ...props }) => {
  const [error, setError] = useState({ error: null });

  const onErrorOverrider = e => {
    return setError(onError(e));
  };

  return (
    <QrReaderContainerStyled {...props}>
      <QrReaderComponent onError={onErrorOverrider} {...props} />
      <QrReaderOverlayStyled {...props} />
      <QrReaderOverlayLoadStyled load={load} {...props} />
      <QrReaderOverlayErrorStyled error={error} {...props} />
    </QrReaderContainerStyled>
  );
};

QrReader.propTypes = QrReaderContainerProps.types;
QrReader.defaultProps = QrReaderContainerProps.default;

export default QrReader;
