import React, { Component, Fragment, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";

const Help = props => {
  return (
    <Fragment>
      <h2 className="title">Ajuda</h2>
      <div className="uk-margin-top help">
        <ul className="help-nav uk-list" data-uk-scrollspy-nav="closest: li; scroll: true">
          <li>
            <a href={`${process.env.domainApp}sobre`}>Sobre o clube</a>
          </li>
          <li>
            <a href={`${process.env.domainApp}duvidas`}>Dúvidas frequentes</a>
          </li>
          <li>
            <a href={`${process.env.domainApp}regulamento`}>Regulamento</a>
          </li>
          <li>
            <a href="https://negociossc.com.br/politica-de-privacidade/">Política de privacidade</a>
          </li>
        </ul>
        <h2 className="subtitle uk-margin-medium-top">Suporte:</h2>

        <nav className="help-nav">
          <a href="mailto:guilherme.duarte@somosnsc.com.br">guilherme.duarte@somosnsc.com.br</a>
        </nav>

        <h2 id="sobre" className="subtitle uk-margin-medium-top">
          Sobre o clube
        </h2>
        <p>
          O Clube NSC é um clube de vantagens exclusivas para os assinantes NSC Total, jornais Diário Catarinense, 
          Jornal de Santa Catarina e A Notícia da NSC Comunicação. Conta com um portfólio com mais de 500 parceiros 
          que oferecem experiências e descontos de até 50% em categorias como cinemas, entretenimento, gastronomia, 
          lojas, saúde & bem-estar e serviços de Santa Catarina.
        </p>

        <h2 className="subtitle uk-margin-medium-top">Saiba como resgatar vantagens</h2>

        <p>
          <h4 className="uk-margin-remove-bottom">Voucher</h4>
          Baixe o aplicativo Clube NSC nas lojas <a target="_blank" href="https://play.google.com/store/apps/details?id=br.com.nsccomunicacao.clubensc&hl=pt_BR">Google Play</a>{' '} 
          ou <a href="https://apps.apple.com/br/app/clube-nsc/id1451592115" target="_blank">Apple Store</a>, faça o login, escolha o benefício, gere o QR Code ou código alfanumérico e 
          apresente ao parceiro.
        </p>

        <p>
          <h4 className="uk-margin-remove-bottom">Como ter acesso aos Benefícios</h4>
          Acesse o aplicativo Clube NSC disponível nas lojas Google Play ou Apple Store, faça o login, escolha o benefício, 
          gere o QR Code ou código alfanumérico e apresente ao parceiro.
        </p>

        <h2 className="subtitle uk-margin-medium-top">Faça parte do Clube NSC</h2>
        <p>
          Assine agora os jornais da NSC Comunicação - Diário Catarinense, Jornal de Santa Catarina e A Notícia e aproveite os 
          benefícios do Clube NSC.
        </p>

        <p>
          <h4 className="uk-margin-remove-bottom">Dependentes</h4>
          Titulares da assinatura que são sócios do Clube NSC podem ter até cinco dependentes. A taxa de administração mensal 
          é de R$5,00 por dependente, cobrada juntamente a fatura da assinatura. Para adicionar dependentes, você deve acessar 
          ao <a href="www.assinantensc.com.br" target="_blank">Portal do Assinante</a>, entrar com seu e-mail e senha (mesmo utilizado para o NSC Total e Clube NSC) e clicar 
          em cadastrar dependente ou, entrar em contato com nossa central de atendimento pelo número <strong>0800 644 4001</strong> ou através do e-mail{' '} 
          <a href="mailto:atendimento.nsc@somosnsc.com.br">atendimento.nsc@somosnsc.com.br</a>.
        </p>
      </div>
    </Fragment>
  );
};

export default Help;
