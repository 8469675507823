import React, { Fragment } from "react";

import Access from "@cthulhi/comp-accs";

import permissions from "@perm"

const CadastroGroup = ({ path, toggleAsideMenu }) => {
  return (
    <Access.RestrictComponent
      onDeny={() => null}
      onLoading={() => null}
      onAccess={[
        permissions.business.edit,
        permissions.branch.view,
        permissions.benefit.view,
        permissions.benefit.edit
      ]}
    >
      <Fragment>
        <li>
          <span className="menu-title uk-margin-top">Cadastro</span>
        </li>
        <li>
          <Access.RestrictNavLink
            onAccess={[permissions.business.edit]}
            onDeny={() => null}
            to={`${path}/business/profile`}
            className="menu-link"
            onClick={toggleAsideMenu}
          >
            <i className="icon icon-document" aria-hidden="true"></i>
            <span>Meu cadastro</span>
          </Access.RestrictNavLink>
        </li>
        <li>
          <Access.RestrictNavLink
            onAccess={[permissions.benefit.view, permissions.benefit.edit]}
            onDeny={() => null}
            to={`${path}/benefit/profile`}
            className="menu-link"
            onClick={toggleAsideMenu}
          >
            <i className="icon icon-percentage" aria-hidden="true"></i>
            <span>Meus benefícios</span>
          </Access.RestrictNavLink>
        </li>
        <li>
          <Access.RestrictNavLink
            onAccess={[permissions.branch.view]}
            onDeny={() => null}
            to={`${path}/branch/profile`}
            className="menu-link"
            onClick={toggleAsideMenu}
          >
            <i className="icon icon-store" aria-hidden="true"></i>
            <span>Minhas lojas</span>
          </Access.RestrictNavLink>
          <Access.RestrictNavLink
            onAccess={[permissions.branch.create]}
            onDeny={() => null}
            to={`${path}/branch/profile/create`}
            className="menu-link"
            activeClassName="active"
            data-uk-tooltip="title: Adicionar Unidade; pos: right"
            onClick={toggleAsideMenu}
          >
            <i className="icon icon-plus-circle" aria-hidden="true"></i>
          </Access.RestrictNavLink>
        </li>
      </Fragment>
    </Access.RestrictComponent>
  );
};

export default CadastroGroup;
