import React, { Fragment } from "react";


const Regulamento = (props) => {
  return (
    <Fragment>

      <main id="rules">

        <div className="cnt cnt-small">

          <h1>Regulamento</h1>
          <p>
            O Clube NSC é um clube de vantagens exclusivo para os sócios do NSC Total, jornais (Diário Catarinense, Jornal de Santa Catarina e A Notícia). Oferece benefícios exclusivos em gastronomia, entretenimento, saúde e bem-estar, serviços, lojas, cinema, eventos infantis e muito mais.
          </p>

          <h2>Condições de participação</h2>
          <p>
            Basta ser assinante de um dos produtos acima para poder participar do Clube NSC.
          </p>
          <p>
            Titulares da assinatura que são sócios do Clube podem adicionar dependentes. Para cada dependente será acrescido o valor de R$ 5,00 na sua mensalidade.
          </p>
          <p>
            A associação ao Clube NSC também pode ser feita por procurador (es) de pessoa jurídica que seja assinante de algum dos jornais da NSC Comunicação.
          </p>
          <p>
            Para dúvidas, contatar as nossas Centrais de Atendimento:
          </p>
          <p>
            <strong>Central de Atendimento: 0800 644 4001</strong><br />
            Horário de atendimento: Segunda a sexta-feira, das 8h às 16h. Feriados das 8h ao 12h.
          </p>
          <h2>Benefícios</h2>
          <p>
            Todos os parceiros do Clube NSC, as programações, descontos e demais vantagens, bem como datas e horários das
            programações estão disponíveis no site do Clube NSC (<a
              href="https://www.clubensc.com.br">www.clubensc.com.br</a>).
          </p>
          <p>
            Para utilizar os benefícios, você deve:
          </p>
          <p>
            Baixar o aplicativo Clube NSC nas lojas Google Play ou Apple Store, fazer o login, escolher o benefício e gerar o QR Code ou código alfanumérico e apresentar ao parceiro.<br/>
            As vantagens do Clube NSC são intransferíveis, de uso pessoal e exclusivo do sócio, seus dependentes ou representante do mesmo, no caso de pessoa jurídica.
          </p>

          <h2>Desligamento do Clube NSC</h2>
          <p>
            O sócio poderá a qualquer momento requerer o desligamento do Clube NSC por meio do telefone das Centrais de Atendimento.
          </p>
          <p>
            Considerando que o Clube NSC é um benefício oferecido em caráter de exclusividade aos assinantes, ao cancelar a assinatura, o associado perderá automaticamente os direitos de utilização dos seus benefícios e de seus dependentes.
          </p>
          <p>
            O direito ao uso dos benefícios do Clube NSC pode ser suspenso, ainda, nas seguintes situações:
          </p>
          <p>
            a) Inadimplemento do associado<br />
            b) Uso do benefício por terceiros<br />
            c) Fornecimento de informações inverídicas ou documentos falsos pelo associado.
          </p>
          <p>
            O associado se responsabiliza por qualquer alteração de informação cadastral, sendo o responsável por qualquer prejuízo ou danos ocasionados em decorrência da omissão ou não veracidade dessas informações. O presente regulamento pode vir a ser modificado, sem aviso prévio e exclusivamente por critérios estabelecidos pelo Clube NSC.
          </p>
          <p>
            * Valores mensais sujeitos à reajustes.
          </p>

        </div>

      </main>

    </Fragment >
  );
}

export default Regulamento;