import React, { useState, useEffect, Fragment } from "react";

const InputSearch = ({ name, value, placeholder, values, optionSchema, onChange, search, rawValue, validaton, ...props }) => {
  const [userInput, setUserInput] = useState("");
  const [isOptionOpen, setIsOptionOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [options, setOptions] = useState([]);
  const [filtered, setFiltered] = useState([]);

  const onChangeHandler = e => {
    if (e.target) {
      setUserInput(e.target.value);
      setFiltered(options.filter(item => item.label.toUpperCase().includes(e.target.value.toUpperCase())));
    }
  };

  // prevents onBlur ocurring before onClick handler finishes
  const onMouseDownHandler = e => e.preventDefault();

  const onBlurHandler = e => setIsOptionOpen(false);
  
  const onClickOptionHandler = (option, label) => {
    setUserInput(label);
    onChange(option.value, undefined);
    setIsOptionOpen(false);
  };

  useEffect(() => {
    search().then(res => {
      setOptions(res.map(optionSchema));
      setFiltered(res.map(optionSchema));
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    const key = value.length > 0 ? value[0]._id : null
    if (key) {
      const found = options.find(item => item.value == key);
      if (found) {
        setUserInput(found.label);
        setFiltered(options.filter(item => item.value == key));
      }
    } else {
      const found = options.find(item => item.value == value);
      if (found) {
        setUserInput(found.label);
        onChange(found.value, undefined);
      }
    }
  }, [options, value])

  return (
    <Fragment>
      <input
        name={name}
        autoComplete="off"
        id={name}
        value={userInput}
        placeholder={placeholder}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
        onFocus={() => setIsOptionOpen(true)}
        type="text"
        onClick={() => setIsOptionOpen(true)}
        disabled={isLoading}
        {...props}
      />
      <div className={isOptionOpen ? "input-search-options" : "input-search-options hidden"}>
        {filtered.length !== 0 ? (
          filtered.map(({ label, ...props }) => (
            <option
              className="input-search-item"
              key={"options-" + props.value}
              {...props}
              onMouseDown={onMouseDownHandler}
              onClick={() => onClickOptionHandler(props, label)}
            >
              {label}
            </option>
          ))
        ) : (
          <option className="input-search-item" key={"options-" + props.value}>
            Nenhum encontrado
          </option>
        )}
      </div>
    </Fragment>
  );
};

export default InputSearch;
