import React, { Component, Fragment, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Route, Switch } from "react-router-dom";

import AccessHeader from "./components/AccessHeader.react";
import LoginForm from "./components/LoginForm.react";
import Recover from "./components/Recover.react";
import RecoverVerify from "./components/RecoverVerify.react";
import RecoverUpdate from "./components/RecoverUpdate.react";

import Context from "./../../context";
import Access from "@cthulhi/comp-accs";
import AccessFooter from "./components/AccessFooter.react";

const AccessView = ({ match }) => {
  const { state, action } = Context.useState();

  useEffect(() => {
    if (document) {
      const html = document.getElementsByTagName("html")[0];
      const body = document.getElementsByTagName("body")[0];
      const aside = document.getElementById("aside-menu");
      html.removeAttribute("class");
      html.removeAttribute("style");

      body.removeAttribute("class");
      if (aside) {
        document.getElementById("aside-menu").remove();
      }
    }
  }, []);

  return (
    <Fragment>
      <Helmet>
        <link href={`${process.env.domain}assets/access/css/uikit.min.css`} rel="stylesheet" />
        <link href={`${process.env.domain}assets/access/css/main.css`} rel="stylesheet" />
        <link rel="icon" href={`${process.env.domain}assets/access/images/favicon.ico`} />
        <script src={`${process.env.domain}assets/access/js/uikit.min.js`} defer></script>
        <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-NS5NCW5');`}</script>
      </Helmet>
      <AccessHeader />
      <main className={`auth${state.background.isHome ? "" : " auth-pages"}`}>
        <div className="uk-container">
          <div className="uk-grid-large uk-flex-center" data-uk-grid>
            <div className="uk-width-2-5@m">
              <div className="auth-content">
                <img
                  className="logo"
                  src={`${process.env.domain}assets/access/images/logo-nsc.svg`}
                  alt="NSC Parceiro App"
                />
                <h2 className="title">Parceiro App</h2>
                <p className="subtitle uk-text-center uk-text-left@l">
                  O melhor clube de vantagens e serviços para o seu negócio.
                </p>
                <p className="lead uk-text-center uk-text-right@l">
                  Entre e conheça a performance do seu Negócio!
                </p>
              </div>
            </div>
            <Switch>
              <Route path={`${match.path}`} exact component={LoginForm} />
              <Route path={`${match.path}/recover`} component={Recover} />
              <Route path={`${match.path}/verify/:_id`} component={RecoverVerify} />
              <Route path={`${match.path}/update/:_id/:code`} component={RecoverUpdate} />
            </Switch>
          </div>
        </div>
      </main>
      <AccessFooter />
    </Fragment>
  );
};

export default AccessView;
