import React, { Fragment } from "react";

const Footer = props => {
  return (
    <Fragment>
      <footer id="ftr">
        <div className="cnt">
          <ul className="ftr-navigation">
            <li>
              <a href="https://negociossc.com.br/">Anuncie</a>
            </li>
            <li>
              <a href="https://www.nsccomunicacao.com.br/trabalhe-conosco/#trabalhe-conosco">
                Trabalhe conosco
              </a>
            </li>
          </ul>
          <div className="ftr-logo">
            <a href="https://www.nsccomunicacao.com.br/#home">
              <img
                src={`${process.env.domain}assets/website/images/nsc-comunicacao.png`}
                alt="NSC Comunicação"
              />
            </a>
          </div>
        </div>
      </footer>
    </Fragment>
  );
};

export default Footer;
