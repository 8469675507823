import React, { Component, Fragment, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Route, Switch } from "react-router-dom";

import Access from "@cthulhi/comp-accs";
import Context from "@html-context";

import WelcomePwa from "./Welcome.pwa.react";
import IntranetHeaderPwa from "./IntranetHeader.pwa.react";

import BusinessProfileFormPage from "../../../intranet/pages/business/BusinessProfileFormPage.react";
import VoucherValidatePage from "../../../intranet/pages/voucher/VoucherValidatePage.react";
import RedemptionConsumerReportPage from "../../../intranet/pages/redemption/RedemptionConsumerReportPage.react";
import HelpPage from "../../../intranet/pages/help/HelpPage.react";
import NotFoundPage from "../../../intranet/pages/error/NotFoundPage.react";

import BranchProfileListPage from "./../../../intranet/pages/branch/BranchProfileListPage.react";
import BranchProfileFormPage from "./../../../intranet/pages/branch/BranchProfileFormPage.react";

import BenefitProfileListPage from "./../../../intranet/pages/benefit/BenefitProfileListPage.react";
import BenefitProfileFormPage from "./../../../intranet/pages/benefit/BenefitProfileFormPage.react";

const IntranetView = ({ match, location, history }) => {

  return (
    <Fragment>
      <div className="dash">
        <IntranetHeaderPwa path={match.path} showBackButton={match.path !== location.pathname} history={history}/>
        <section className="dashboard">
          <main>
            <div className="uk-container uk-container-large ">
              <Switch>
                <Route path={`${match.path}`} exact component={WelcomePwa} />
                <Route
                  key="business-profile"
                  path={`${match.path}/business/profile`}
                  component={BusinessProfileFormPage}
                />
                <Route
                  key="voucher-validate"
                  path={`${match.path}/voucher/validate`}
                  component={VoucherValidatePage}
                />

                <Route
                  key="branch-create-profile"
                  path={`${match.path}/branch/profile/create`}
                  exact
                  component={BranchProfileFormPage}
                />
                <Route
                  key="branch-edit-profile"
                  path={`${match.path}/branch/profile/edit/:id`}
                  component={BranchProfileFormPage}
                />
                <Route
                  key="branch-profile"
                  exact
                  path={`${match.path}/branch/profile`}
                  component={BranchProfileListPage}
                />

                <Route
                  key="benefit-view-profile"
                  path={`${match.path}/benefit/profile/view/:id`}
                  component={BenefitProfileFormPage}
                />

                <Route
                  key="benefit-profile"
                  path={`${match.path}/benefit/profile`}
                  exact
                  component={BenefitProfileListPage}
                />

                <Route
                  key="redemption-consumer"
                  path={`${match.path}/redemption/consumer`}
                  component={RedemptionConsumerReportPage}
                />
                <Route key="help" path={`${match.path}/help`} component={HelpPage} />
                <Route component={NotFoundPage} />
              </Switch>
            </div>
          </main>
        </section>
      </div>
    </Fragment>
  );
};

export default IntranetView;
