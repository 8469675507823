import { schema, types } from "@cthulhi/pack-schm";
import { schema as commonSchema } from "./common.schema"

import { types as fields } from "@cthulhi/comp-form"

const schm = schema("business", {
  ...commonSchema.schema.props,
  alias: {
    type: types.string.type,
    field: fields.textfield.type,
    required: { contains: true }
  },
  stage: {
    type: types.string.type,
    field: fields.select.type,
    required: { contains: true }
  },
  name: {
    type: types.string.type,
    field: fields.textfield.type,
    required: { contains: true }
  },
  cnpj: {
    type: types.string.type,
    field: fields.textfield.type,
    required: { contains: true }
  },
  zipcode: {
    type: types.string.type,
    field: fields.textfield.type,
    required: { contains: true }
  },
  district: {
    type: types.string.type,
    field: fields.textfield.type,
    required: { contains: true }
  },
  address: {
    type: types.string.type,
    field: fields.textfield.type,
    required: { contains: true }
  },
  number: {
    type: types.integer.type,
    field: fields.textfield.type
  },
  complement: {
    type: types.string.type,
    field: fields.textfield.type
  },
  state: {
    type: types.integer.type,
    field: fields.select.type,
    required: { contains: true }
  },
  city: {
    type: types.string.type,
    field: fields.select.type,
    required: { contains: true }
  },
  phone: {
    type: types.string.type,
    field: fields.textfield.type,
    required: { contains: true }
  },
  contact: {
    type: types.string.type,
    field: fields.textfield.type,
    required: { contains: true }
  },
  email: {
    type: types.string.type,
    field: fields.textfield.type,
    required: { contains: true }
  },
  site: {
    type: types.string.type,
    field: fields.textfield.type,
    required: { contains: true }
  }
});

export { schm as schema };
