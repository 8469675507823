
const applyEllipsis = (text, length) => {
  if (text) {
    return text.length > length ? `${text.substring(0, length)}...` : text;
  }

  return "";
};

export default applyEllipsis;
