import React, { Fragment, useState, useEffect } from "react";

import ModalValidationSuccess from "./ModalValidationSuccess.react";
import ModalValidationError from "./ModalValidationError.react";

import Context from "@context";
import Access from "@cthulhi/comp-accs";

import LoaderComponent from "../../../utils/LoaderCustomComponent.react";

let QrCode = () => <div>loading</div>;
let QrReader = () => <div>loading</div>;

// TO DO - Arrumar validação de QRCode

const ValidateVoucher = () => {
  const { action: ctxAction } = Context.useState();
  const { state: accState } = Access.useState();
  const [scanResult, setScanResult] = useState({ result: "" });
  const [scanResultBlink, setScanResultBlink] = useState("");
  const [scanResultError, setScanResultError] = useState({
    code: null,
    name: null,
    error: null
  });
  const [scanLoad, setScanLoad] = useState(true);

  const [modal, setModal] = useState({ show: false, Content: () => null });

  const [qrLoaded, setQrLoaded] = useState(false);

  useEffect(() => {
    if (window) {
      QrCode = require("./QrCode.react").default;
      QrReader = require("./QrReader.react").default;
      setQrLoaded(true);
    }
  }, []);

  const onQrReaderError = e => {
    let error = {
      code: null,
      name: null,
      error: null
    };

    switch (e.name) {
      case "NotAllowedError":
        error = {
          code: "QRC51",
          name: "Permissão da câmera",
          error: "A solicitação de acesso a câmera não foi permitido pelo dispositivo."
        };
        break;

      default:
        error = {
          code: "QRC50",
          name: "Houve um erro desconhecido.",
          error: "Tente recarregar a página ou app."
        };
        break;
    }

    setScanResultError(error);
    setScanLoad(false);
    return error;
  };

  const onQrReaderLoad = e => {
    setScanLoad(false);
  };

  const onQrReaderResult = e => {
    if (e) {
      tryBlinkOut();
      setScanLoad(false);
      validate(e);
    }
  };

  const tryBlinkOut = () => {
    setScanResultBlink("blinkOut");
    setTimeout(() => {
      setScanResultBlink("");
    }, 800);
  };

  const onClickValidateQrCode = () => {
    validate(scanResult.result);
  };

  const validate = async value => {
    if (value) {
      const token = `Bearer ${accState.access.session.context.token}`;
      const resultValidation = await ctxAction("voucher")
        .consume(value, token, {
          start: () => ctxAction("loader").addLoading("consume", "voucherValidation"),
          finish: () => ctxAction("loader").removeLoading("consume", "voucherValidation")
        })
        .then(voucher => {
          if (voucher.result.error) {
            return voucher.result;
          } else {
            return ctxAction("redemption")
              .create(
                {
                  userId: voucher.result.userId,
                  businessId: voucher.result.businessId,
                  benefitId: voucher.result.benefitId,
                  branchId: voucher.result.branchId,
                  voucherId: voucher.result._id
                },
                token,
                {
                  start: () => ctxAction("loader").addLoading("consume", "voucherValidation"),
                  finish: () => ctxAction("loader").removeLoading("consume", "voucherValidation")
                }
              )
              .then(res => res);
          }
        });

      if (!resultValidation.error) {
        setScanResult({ result: "" });
        setModal({
          show: true,
          Content: props => {
            return (
              <ModalValidationSuccess
                result={{ trophy: { percent: 0, title: "" } }} //mock data
                onClose={() => setModal({ show: false, Content: () => null })}
              />
            );
          }
        });
      } else {
        setScanResult({ result: "" });
        setModal({
          show: true,
          Content: props => {
            return (
              <ModalValidationError
                msg={resultValidation.error}
                onClose={() => setModal({ show: false, Content: () => null })}
              />
            );
          }
        });
      }
    }
  };

  return (
    <Fragment>
      <LoaderComponent from="voucherValidation" height="175" width="175" />
      <h2 className="title">Validar voucher</h2>
      <div className="uk-margin-top">
        <QrReader
          delay={2000}
          load={scanLoad}
          onScan={e => onQrReaderResult(e)}
          onError={e => onQrReaderError(e)}
          onLoad={e => onQrReaderLoad(e)}
          animationType={scanResultBlink}
          backgroundColor={"#000000"}
          showViewFinder={true}
        />
      </div>
      <div className="uk-margin-top uk-flex" style={{ maxWidth: "400px" }}>
        <span className="uk-align-center uk-margin-remove-bottom">Ou</span>
      </div>
      <div>
        <form
          className="uk-form-large uk-padding-remove"
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          <input
            type="text"
            className="uk-input"
            placeholder="Digite aqui o código do voucher"
            value={scanResult.result}
            onChange={e => setScanResult({ result: e.target.value })}
            style={{ maxWidth: "400px" }}
          />
        </form>
      </div>
      <div className="uk-margin-top">
        <button
          className="uk-button uk-button-large uk-button-secondary uk-width1-1"
          style={{ maxWidth: "400px" }}
          onClick={onClickValidateQrCode}
        >
          VALIDAR CÓDIGO
        </button>

        <div className="container-modal">{modal.show ? <modal.Content /> : null}</div>
      </div>
    </Fragment>
  );
};

export default ValidateVoucher;
