const view = {
  group: {
    title: "Coupons",
    slug: "coupon"
  },
  slug: "coupon-view",
  title: "Ver cupons",
  description: "Ver os próprios cupons",
  list: ["prn::1:coupon-view-roles:coupon-view:allow:lcnt:schema.user.roles:custom.static.admin"]
};

const viewAny = {
  group: {
    title: "Coupons",
    slug: "coupon"
  },
  slug: "coupon-view-any",
  title: "Ver qualquer cupom",
  description: "Ver todos os cupons",
  list: [
    "prn::1:coupon-view-any-roles:coupon-view-any:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
};

const edit = {
  group: {
    title: "Coupons",
    slug: "coupon"
  },
  slug: "coupon-edit",
  title: "Editar cupons",
  description: "Editar os próprios cupons",
  list: ["prn::1:coupon-edit-roles:coupon-edit:allow:lcnt:schema.user.roles:custom.static.admin"]
};

const editAny = {
  group: {
    title: "Coupons",
    slug: "coupon"
  },
  slug: "coupon-edit-any",
  title: "Editar qualquer tipo de usuário",
  description: "Editar todos os cupons",
  list: [
    "prn::1:coupon-edit-any-roles:coupon-edit-any:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
};

const remove = {
  group: {
    title: "Coupons",
    slug: "coupon"
  },
  slug: "cupon-remove",
  title: "Remover os cupons",
  description: "Remover os próprios cupons"
};

const removeAny = {
  group: {
    title: "Coupons",
    slug: "coupon"
  },
  slug: "coupon-remove-any",
  title: "Remover qualquer cupom",
  description: "Remover todos os cupons"
};

const create = {
  group: {
    title: "Coupons",
    slug: "coupon"
  },
  slug: "coupon-create",
  title: "Criar cupons",
  description: "Criar os próprios cupons"
};

const createAny = {
  group: {
    title: "Coupons",
    slug: "coupon"
  },
  slug: "coupon-create-any",
  title: "Criar qualquer cupom",
  description: "Criar todos os cupons"
};

module.exports = {
  view,
  viewAny,
  edit,
  editAny,
  remove,
  removeAny,
  create,
  createAny
};
