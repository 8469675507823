const view = {
  group: {
    title: "Permissões",
    slug: "perm"
  },
  slug: "perm-view",
  title: "Ver permissões",
  description: "Ver as próprias permissões",
  list: [
    "prn::1:perm-view-roles:perm-view:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

const viewAny = {
  group: {
    title: "Permissões",
    slug: "perm"
  },
  slug: "perm-view-any",
  title: "Ver qualquer permissão",
  description: "Ver todas as permissões",
  list: [
    "prn::1:perm-view-any-roles:perm-view-any:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

const edit = {
  group: {
    title: "Permissões",
    slug: "perm"
  },
  slug: "perm-edit",
  title: "Editar permissões",
  description: "Editar as próprias permissões",
  list: [
    "prn::1:perm-edit-roles:perm-edit:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

const editAny = {
  group: {
    title: "Permissões",
    slug: "perm"
  },
  slug: "perm-edit-any",
  title: "Editar qualquer permissão",
  description: "Editar todas as permissões",
  list: [
    "prn::1:perm-edit-any-roles:perm-edit-any:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

const remover = {
  group: {
    title: "Permissões",
    slug: "perm"
  },
  slug: "perm-remove",
  title: "Remover permissões",
  description: "Remover as próprias permissões"
}

const removeAny = {
  group: {
    title: "Permissões",
    slug: "perm"
  },
  slug: "perm-remove-any",
  title: "Remover qualquer permissão",
  description: "Remover todas as permissões"
}

const create = {
  group: {
    title: "Permissões",
    slug: "perm"
  },
  slug: "perm-create",
  title: "Create permissões",
  description: "Create as próprias permissões"
}

const createAny = {
  group: {
    title: "Permissões",
    slug: "perm"
  },
  slug: "perm-create-any",
  title: "Create qualquer permissão",
  description: "Create todas as permissões"
}

module.exports = {
  view,
  viewAny,
  edit,
  editAny,
  remover,
  removeAny,
  create,
  createAny
}