import React, { Fragment, useEffect, useState } from 'react'
import { Link, Switch, Route, withRouter, Redirect } from "react-router-dom"

import Access from "@cthulhi/comp-accs"

import EventFormPage from "./EventFormPage.react";
import EventListPage from "./EventListPage.react";

import permissions from "@perm"

const EventPage = ({ match, ...props }) => {
  return (
    <Switch>
      <Access.RestrictRoute
        key="event-list"
        onAccess={[
          permissions.event.viewAny,
          permissions.event.editAny
        ]} 
        parentPath={match.path}
        path={`${match.path}`} exact
        onAllow={EventListPage} 
      />
      <Access.RestrictRoute
        key="event-create"
        onAccess={[
          permissions.event.viewAny,
          permissions.event.editAny
        ]} 
        parentPath={match.path}
        path={`${match.path}/create`} 
        onAllow={EventFormPage} 
      />
      <Access.RestrictRoute
        key="event-edit"
        onAccess={[
          permissions.event.editAny,
          permissions.event.edit
        ]} 
        parentPath={match.path}
        path={`${match.path}/edit/:id`} 
        onAllow={EventFormPage} 
      />
    </Switch>
  )

} 

export default withRouter(EventPage)