import React, { Fragment, useState } from "react";
import { withRouter } from "react-router-dom";

import { List } from "../../../wrappers/ListWrappers.react";

import { useFilter } from "@cthulhi/comp-list";

import Access from "@cthulhi/comp-accs";
import Context from "@context";

import Notification from "@cthulhi/comp-noty";
import NotificationComponent from "../../../utils/Notification.react";
import ModalConfirmRemoval from "../../../wrappers/ModalConfirmRemoval.react";

import {
  events as userTypeListEvents,
  values as userTypeListValues,
  actions as userTypeListActions,
  options as userTypeListOptions,
  schema as userTypeListSchema
} from "./components/UserTypeList.config";

const UserTypeListPage = ({ match, history, ...props }) => {
  const { state: accState, action: accAction } = Access.useState();
  const { action: ctxAction } = Context.useState();
  const { state: ntfState, action: ntfAction } = Notification.useState();
  const [modal, setModal] = useState({ show: false, Content: () => null });
  const token = `Bearer ${accState.access.session.context.token}`;


  const createFilter = () => {
    if (filter.values) {
      const searchFields = filter.values;
      const searchTerms = Object.keys(searchFields).reduce((filter, key) => {
        if (searchFields[key].value) {
          filter[key] = {
            $regex: searchFields[key].value,
            $options: "i"
          };
          return filter;
        }
        return filter;
      }, {});
      return searchTerms;
    }
    return {};
  };

  const onEdit = value => {
    history.push(`${match.path}/edit/${value._id}`);
  };

  const onRemoveAction = (value, refresh, loader) => {
    loader(true);
    ctxAction("userType")
      .remove({ _id: value._id }, token)
      .then(res => {
        if (res.name != "UserTypeDeleteResult") throw res;

        refresh();

        ntfAction("notification").add(
          res.code,
          "userTypeListPage",
          "success",
          "Tipo de usuário removido com sucesso.",
          undefined,
          process.env.notificationTimeOut
        );
      })
      .catch(err => {
        loader(false);

        ntfAction("notification").add(
          err.code,
          "userTypeListPage",
          "danger",
          "Houve um erro ao remover o tipo de usuário.",
          undefined,
          process.env.notificationTimeOut
        );
      });
  };

  const onRemove = (value, refresh, loader) => {
    setModal({
      show: true,
      Content: props => {
        return (
          <ModalConfirmRemoval
            onConfirm={() => onRemoveAction(value, refresh, loader)}
            onClose={() => setModal({ show: false, Content: () => null })}
          />
        );
      }
    });
  };

  const { filter, list, page, limit, options } = useFilter({
    schema: userTypeListSchema,
    events: userTypeListEvents({ ctxAction }),
    options: userTypeListOptions({ onEdit, onRemove }),
    values: userTypeListValues({ ctxAction, token }),
    actions: userTypeListActions({ ctxAction, token })
  });

  return (
    <Fragment>
      <h2 className="title">Listar tipos de usuário</h2>
      <div className="uk-margin-top">
        <Notification.NotificationWrapper
          group="userTypeListPage"
          component={NotificationComponent}
        />
        <List {...{ filter, list, page, limit, options }} />
        <div className="container-modal">{modal.show ? <modal.Content /> : null}</div>
      </div>
    </Fragment>
  );
};

export default withRouter(UserTypeListPage);
