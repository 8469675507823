import React, { Fragment, useEffect, useState, useRef } from "react";
import { Link, Switch, Route, withRouter } from "react-router-dom";
import { useFields, useForms } from "@cthulhi/comp-form";
import Access from "@cthulhi/comp-accs";
import Notification from "@cthulhi/comp-noty";
import Context from "@context";
import { schema as contactFormSchema } from "@schm/html/send.contact.schema";

import Loader from "@html/views/utils/LoaderSiteComponent.react";

import {
  events as contactFormEvents,
  values as contactFormValues,
  actions as contactFormActions
} from "../utils/ContactForm.config";

import NotificationComponent from "../../utils/NotificationFrontForm.react";

import { FieldLabelWrapper } from "../../wrappers/FormWrappers.react";
import LoaderComponent from "../../utils/LoaderCustomComponent.react";

const Contato = ({ match, parentPath, history, ...props }) => {

  const { state: accState, action: accAction } = Access.useState();
  const { action: ctxAction, state: ctxtState } = Context.useState();
  const { state: ntfState, action: ntfAction } = Notification.useState();
  const [disableButton, setDisableButton] = useState(false);

  const { form, fields, values, updater, cleaner } = useFields({
    schema: contactFormSchema,
    events: contactFormEvents({ ctxAction }),
    values: contactFormValues({ ctxAction })
  });


  const { sendMessage } = useForms(
    contactFormActions({
      ctxAction,
      ntfAction,
      updater,
      history,
      path: parentPath,
      token: `Bearer ${accState.access.session.context.token}`
    })
  );

  const contactFormSubmit = () => {
    let vs = {};

    window.scrollTo({
      top: 180,
      behavior: 'smooth'
    });

    Object.entries(values).map(([key, value]) => {
      const hasValue = typeof value.value != "undefined" && value.value !== ""
      const hasRawValue = typeof value.rawValue != "undefined" && value.rawValue !== ""

      vs[key] = hasRawValue ? value.rawValue
        : (hasValue ? value.value : value.defaultValue)
    });

    if (!vs.name) return;
    if (!vs.title) return;
    if (!vs.cpf) return;
    if (!vs.email) return;
    if (!vs.phone) return;
    if (!vs.subject) return;
    if (!vs.news) return;
    if (!vs.message) return;

    sendMessage(vs).then(res => {
      if (res) {
        updater("name", { value: "" }, "")
        updater("title", { value: "" }, "")
        updater("cpf", { value: "" }, "")
        updater("email", { value: "" }, "")
        updater("phone", { value: "" }, "")
        updater("subject", { value: "" }, "")
        updater("news", { value: "" }, "")
        updater("message", { value: "" }, "")
      }

      setDisableButton(false)
    }).catch(err => {
      setDisableButton(false)
    });
  };

  const isLoading = (
    typeof ctxtState.loader.list["websiteForm"] != "undefined" && ctxtState.loader.list["websiteForm"].length > 0
  )

  return (
    <Fragment>
      <main id="partner">
        <Loader show={isLoading} height="120" width="120" />


        <div className="cnt cnt-small">

          <h1>Fale conosco</h1>
          <h3>
            Tem alguma dúvida? Já tentou esclarecê-la em <Link to="/duvidas">Dúvidas Frequentes</Link>?<br />
            Queremos te ouvir. Horário de atendimento: De Segunda a Sexta, das 9h às 19h
          </h3>
          <Notification.NotificationWrapper group="websiteForm" component={NotificationComponent} />
          <form className="form" onSubmit={e => e.preventDefault()}>
            <FieldLabelWrapper name="name" fields={fields} values={values} />
            <FieldLabelWrapper name="title" fields={fields} values={values} />
            <FieldLabelWrapper name="cpf" fields={fields} values={values} />
            <FieldLabelWrapper name="email" fields={fields} values={values} />

            <FieldLabelWrapper name="phone" fields={fields} values={values} />
            <FieldLabelWrapper name="subject" fields={fields} values={values} />
            <FieldLabelWrapper name="news" fields={fields} values={values} />
            <FieldLabelWrapper name="message" fields={fields} values={values} />


            <div className="submit">
              <button
                onClick={contactFormSubmit}
                className="btn"
                disabled={disableButton}
              >
                {isLoading ? "Enviando" : "Enviar"}
              </button>
            </div>
          </form>

        </div>

        <div id="adv">

          <div className="cnt">
            <h2>Como posso usar os Benefícios?</h2>

            <div id="adv-grid">

              <div className="adv-grid-item">
                <i className="icn icn-voucher" aria-hidden="true"></i>
                <div className="content">
                  <h3>Voucher</h3>
                  <p>
                    Acesse a página do benefício, gere o voucher e apresente-o no estabelecimento impresso ou na tela do
                    seu celular.
                  </p>
                  <p>
                    Após a utilização, você também pode avaliar o benefício.
                  </p>
                </div>
              </div>

              <div className="adv-grid-item">
                <i className="icn icn-virtual-card" aria-hidden="true"></i>
                <div className="content">
                  <h3>Cartão Virtual</h3>
                  <p>
                    Basta fazer o login no aplicativo ou no site do Clube NSC para que sócios e dependentes tenham acesso
                    ao cartão virtual.
                  </p>
                </div>
              </div>

              <div className="adv-grid-item">
                <i className="icn icn-card" aria-hidden="true"></i>
                <div className="content">
                  <h3>Cartão Físico</h3>
                  <p>
                    O seu cartão atual é válido até a data que consta impressa no cartão. Novos cartões não serão enviados
                    automaticamente.
                  </p>
                  <p>
                    Caso deseje receber o seu, solicite através da sua Central de Atendimento.
                  </p>
                </div>
              </div>

              <div className="adv-grid-item">
                <i className="icn icn-user" aria-hidden="true"></i>
                <div className="content">
                  <h3>Login e Conta</h3>
                  <p>
                    Para resgatar os benefícios no portal do Clube, você precisa estar logado.
                  </p>
                  <p>
                    O seu usuário e senha são os mesmos que você já utiliza na edição digital do seu jornal e nos serviços
                    do Portal do Assinante.
                  </p>
                </div>
              </div>
            </div>

          </div>

        </div>

      </main>

    </Fragment >
  );
}

export default Contato;