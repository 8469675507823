import { schema, types } from "@cthulhi/pack-schm";
import { types as fields } from "@cthulhi/comp-form";

const schm = schema("content", {
  body: {
    type: types.string.type,
    field: fields.textarea.type,
    required: { contains: true }
  },
  component: {
    type: types.string.type,
    field: fields.textfield.type
  },
  identifier: {
    type: types.string.type,
    field: fields.textfield.type
  },
  name: {
    type: types.string.type,
    field: fields.textfield.type
  }
});

export { schm as schema };
