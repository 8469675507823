const optionLoading = { value: "", label: "Carregando..." };

const events = ({ ctxAction, ...props }) => {
  return {
    categories: {
      onChange: (value, rawValue, update) => {
        update("categories", { value, rawValue });
      }
    }
  };
};

const values = ({ ctxAction, token, ...props }) => {
  const initValues = {
    startDate: {
      className: "datepicker uk-margin-small-right",
      inputProps: {
        className: "uk-input",
        placeholder: "Válido até"
      },
      dateFormat: "DD / MM / YYYY",
      value: new Date(),
      rawValue: new Date(),
      label: {
        text: "Início",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      }
    },
    endDate: {
      className: "datepicker uk-margin-small-right",
      inputProps: {
        className: "uk-input",
        placeholder: "Válido até"
      },
      dateFormat: "DD / MM / YYYY",
      value: new Date(),
      rawValue: new Date(),
      label: {
        text: "Fim",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      }
    },
    categories: {
      className: "uk-select",
      placeholder: "Sub-Categorias",
      label: {
        text: "Sub-Categorias",
        className: "uk-form-label"
      },
      wrapper: {
        className: "uk-width-1-1@m"
      },
      default: "",
      find: () => {
        return ctxAction("category").find(
          {},
          { sort: { title: 1 } },
          token,
        )
        .then((res) => {
          if (Array.isArray(res.result.values)) {
            return res.result.values.map(({ _id, name }) => ({
              value: _id, 
              label: name
            }))
          }
          return [];
        });
      },
      options: [
        { value: "category-1", label: "Categoria 1" },
        { value: "category-2", label: "Categoria 2" },
        { value: "category-3", label: "Categoria 3" }
      ]
    },
    name: {
      className: "uk-input",
      placeholder: "Nome",
      label: {
        text: "Nome",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      },
      default: "",
    },
    site: {
      className: "uk-input",
      placeholder: "Site",
      label: {
        text: "Site",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      },
      default: "",
    },
    about: {
      className: "uk-textarea",
      placeholder: "Sobre",
      label: {
        text: "Sobre",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-1"
      }
    },
    available: {
      className: "uk-checkbox",
      value: true,
      label: {
        position: "below",
        text: "Disponível",
        className: "uk-form-label uk-margin-small-left"
      },
      wrapper: {
        className: "field-checkbox uk-width-1-5@m"
      }
    },
  };

  return initValues;
};

const actions = ({ token, ctxAction, ntfAction, updater, history, path, ...props }) => {
  return {
    entity: {
      name: "event",
      token,
      form: ctxAction("form")
    },
    actions: {
      entity: ctxAction,
      notification: ntfAction,
      updater
    },
    find: {
      success: {
        name: "EventFoundResult",
        action: { notify: false, redirect: false }
      },
      error: {
        name: "EventErrorResult",
        message: "Houve um erro ao buscar o evento",
        action: { notify: "eventListPage", redirect: false }
      }
    },
    create: {
      success: {
        name: "EventCreateResult",
        message: "Evento adicionada com sucesso",
        action: { notify: "eventListPage", redirect: () => history.push(path) }
      },
      error: {
        name: "EventErrorResult",
        message: "Houve um erro ao adicionar o evento",
        action: { notify: "eventForm", redirect: false }
      }
    },
    update: {
      success: {
        name: "EventUpdateResult",
        message: "Evento atualizado com sucesso",
        action: { notify: "eventListPage", redirect: () => history.push(path) }
      },
      error: {
        name: "EventErrorResult",
        message: "Houve um erro ao atualizar o evento",
        action: { notify: "eventForm", redirect: false }
      }
    }
  };
};

export { events, values, actions };
