import React, { Fragment, useState } from "react";
import { withRouter, Link } from "react-router-dom";

import { useFilter } from "@cthulhi/comp-list";
import Access from "@cthulhi/comp-accs";
import Context from "@context";
import Notification from "@cthulhi/comp-noty";

import { List } from "../../../wrappers/ListWrappers.react";
import NotificationComponent from "../../../utils/Notification.react";

import {
  events as voucherListEvents,
  values as voucherListValues,
  actions as voucherListActions,
  options as voucherListOptions,
  schema as voucherListSchema
} from "./components/RedemptionList.config";

const RedemptionListPage = ({ match, history, ...props }) => {
  const { state: accState, action: accAction } = Access.useState();
  const { action: ctxAction } = Context.useState();
  const { state: ntfState, action: ntfAction } = Notification.useState();
  const [modal, setModal] = useState({ show: false, Content: () => null });
  const [downloadStatus, setDownloadStatus] = React.useState("waiting");
  const [downloadLinkAttributes, setDownloadLinkAttributes] = React.useState({
    download: "",
    href: ""
  });
  const token = `Bearer ${accState.access.session.context.token}`;

  const createFilter = () => {
    if (filter.values) {
      const searchFields = filter.values;
      const searchTerms = Object.keys(searchFields).reduce((filter, key) => {
        if (searchFields[key].value) {
          filter[key] = {
            $regex: searchFields[key].value,
            $options: "i"
          };
          return filter;
        }
        return filter;
      }, {});
      return searchTerms;
    }
    return {};
  };

  const downloadFile = e => {
    e.preventDefault();

    setDownloadStatus("downloading");

    ntfAction("notification").add(
      1,
      "voucherListPage",
      "success",
      "Preparando arquivo...",
      undefined,
      process.env.notificationTimeOut
    );

    voucherListActions({ ctxAction, token })
      .downloadCSV({ filters: createFilter() }, {})
      .then(blob => {
        const href = window.URL.createObjectURL(blob);
        setDownloadLinkAttributes({
          href: href,
          download: "vouchers-export.csv"
        });
        setDownloadStatus("finished");
      })
      .catch(e => {
        console.log(e);
        setDownloadStatus("error");

        ntfAction("notification").add(
          500,
          "voucherListPage",
          "error",
          "Não foi possível gerar o arquivo.",
          undefined,
          process.env.notificationTimeOut
        );
      });
  };

  const onEdit = value => {
    history.push(`${match.path}/view/${value._id}`);
  };

  const { filter, list, page, limit, options } = useFilter({
    schema: voucherListSchema,
    events: voucherListEvents({ ctxAction }),
    options: voucherListOptions({ onEdit }),
    values: voucherListValues({ ctxAction, token }),
    actions: voucherListActions({ ctxAction, token })
  });

  return (
    <Fragment>
      <h2 className="title">Listar resgates</h2>
      <div className="uk-margin-top">
        <div className="uk-margin-top uk-margin-bottom">
          <div className="uk-text-nowrap">
            <button className="uk-button-primary uk-margin-right" onClick={downloadFile}>
              Baixar conteúdo em formato CSV
            </button>
            {downloadStatus == "finished" && (
              <a {...downloadLinkAttributes}>Click aqui para baixar o arquivo</a>
            )}
          </div>
        </div>
        <Notification.NotificationWrapper
          group="voucherListPage"
          component={NotificationComponent}
        />
        <List {...{ filter, list, page, limit, options }} />
        <div className="container-modal">{modal.show ? <modal.Content /> : null}</div>
      </div>
    </Fragment>
  );
};

export default withRouter(RedemptionListPage);
