import React, { Component, Fragment, useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";

import Access from "@cthulhi/comp-accs";
import Notification from "@cthulhi/comp-noty";

import { schema as userRecoverVerify } from "@schm/access/recoverVerify.schema";

import Context from "@context";

import BackButton from "../../../access/components/BackButton.react";
import NotificationComponent from "@html/views/utils/Notification.react";

const RecoverVerify = ({ match, history }) => {
  const { state: ctxState, action: ctxAction, dispatch: ctxDispatch } = Context.useState();
  const { state: ntfState, action: ntfAction } = Notification.useState();
  const { state: accState, action: accAction, consts: accConts } = Access.useState();

  const isLoading =
    accState.access.recover.verifyCode.status == accConts.STS_REC_COD_VERIFY_REQUEST ||
    accState.access.recover.verifyCode.status == accConts.STS_REC_COD_VERIFY_RESPONSE;
  let isSuccessfull =
    accState.access.recover.verifyCode.status == accConts.STS_REC_COD_VERIFY_SUCCESS;

  const [ userLoginFields, setUserLoginFields ] = useState({ code: "" })

  const onChangeField = (name, value) => {
    setUserLoginFields({
      ...userLoginFields,
      [name]: value
    })
  }

  const onSubmit = async e => {
    e.preventDefault();

    ntfAction("notification").removeGroup("verify");

    const form = userLoginFields;
    const valid = await userRecoverVerify.validate({ ...form, _id: match.params._id });

    if (valid.result) {
      accAction("access")
        .verifyRecoverCode({
          _id: match.params._id,
          code: form.code
        })
        .then(res => {
          if (!res || res.code != "AA22") {
            ntfAction("notification").add(
              res.code,
              "verify",
              "danger",
              "O código é invalido ou expirou."
            );
          } else {
            history.push(`/parceiroapp/access/update/${match.params._id}/${form.code}`);
          }
        });
    } else {
      ctxAction("login").updateRecoverCodeFormValidations(valid.errors);
      ntfAction("notification").add("EO52", "verify", "danger", "Código incorreto.");
    }
  };

  const setValidationClass = (validations, field) => {
    if (typeof validations != "undefined") {
      if (typeof validations[field] != "undefined") {
        return validations[field] ? " uk-form-success" : " uk-form-danger";
      }
    }

    return "";
  };

  const isSessionSuccessfull = accState.access.session.status == accConts.STS_SESSION_SUCCESS;

  useEffect(() => {
    accAction("access").verifySession();
  }, [isSessionSuccessfull]);

  if (isSessionSuccessfull) {
    return <Redirect to="/parceiroapp/home" />;
  }

  return (
    <Fragment>
      <div className="uk-flex uk-flex-column uk-padding-large">
        <img
          src={`${process.env.domain}assets/pwa/images/logo-color.svg`}
          alt="NSC"
          className="auth-logo"
          aria-hidden="true"
        />
        <h1 className="auth-title">Inserir Código</h1>
      </div>
      <div
        className="uk-flex uk-flex-middle uk-padding uk-padding-remove-top"
      >
        <div className="uk-width-1-1">
          <Notification.NotificationWrapper group="verify" component={NotificationComponent} />
          <form action="" className="form">
            <div className="uk-margin">
              <input
                className={
                  "uk-input uk-width-1-1" +
                  setValidationClass(ctxState.login.recoverCode.form.validations, "code")
                }
                id="code"
                name="code"
                onChange={(e) => onChangeField("code", e.target.value)}
                value={userLoginFields.code}
                placeholder="Insira o código"
                type="text"
              />
            </div>
            <div className="uk-margin uk-text-center">
              <span className="uk-text-small">Insira o código que você recebeu por e-mail.</span>
            </div>
            <div className="uk-margin-medium-top">
              <button className="uk-button uk-button-primary uk-button-large uk-width-1-1" onClick={e => onSubmit(e)}>
                {isLoading ? <span>Verificando...</span> : <span>Inserir</span>}
              </button>
            </div>
            <BackButton isPwa={true} />
          </form>
        </div>
      </div>
    </Fragment>
  );
};
export default RecoverVerify;
