import moment from "moment";

const optionLoading = { value: "", label: "Carregando...", disabled: true };
const optionSelectUserVip = { value: "", label: "Selecione um benefício..." };

const events = ({ ctxAction, ...props }) => {
  return {};
};

const values = ({ ctxAction, token, ...props }) => {
  let initValues = {
    name: {
      className: "uk-input",
      placeholder: "Nome",
      value: "",
      label: {
        text: "Nome",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-1"
      }
    },
    email: {
      className: "uk-input",
      placeholder: "E-mail",
      value: "",
      label: {
        text: "E-mail",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-1"
      }
    },
    expiracy: {
      className: "datepicker uk-margin-small-right",
      inputProps: {
        className: "uk-input",
        placeholder: "Válido até"
      },
      dateFormat: "DD / MM / YYYY",
      value: new Date(),
      rawValue: new Date(),
      label: {
        text: "Válido até",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      }
    },
    cpf: {
      className: "uk-input",
      placeholder: "CPF",
      masks: ["999.999.999-99"],
      value: "",
      label: {
        text: "CPF",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      }
    },
    perfil: {
      className: "uk-select",
      label: {
        text: "Perfil",
        className: "uk-form-label"
      },
      wrapper: {
        className: "field-wrapper uk-width-1-2@m"
      },
      value: "",
      options: [
        { value: "", label: "Selecione um perfil..." },
        { value: "ARBS", label: "Associado ANSC" },
        { value: "VITALICIO", label: "Sócio vitalício" },
        { value: "FUNCIONARIO", label: "Funcionário Clube NSC" }
      ]
    },
    status: {
      className: "uk-checkbox",
      value: true,
      label: {
        text: "Beneficiado ativo",
        className: "uk-form-label uk-margin-small-left",
        position: "below"
      },
      wrapper: {
        className: "field-checkbox uk-width-1-2@m"
      }
    }
  };

  return initValues;
};

const actions = ({ token, ctxAction, ntfAction, updater, history, path, ...props }) => {
  return {
    entity: {
      name: "userVip",
      token: token,
      form: ctxAction("form")
    },
    actions: {
      entity: ctxAction,
      notification: ntfAction,
      updater: updater
    },
    find: {
      success: {
        name: "UserVipFoundResult",
        action: { notify: false, redirect: false }
      },
      error: {
        name: "UserVipErrorResult",
        message: "Houve um erro ao buscar o beneficiado",
        action: { notify: "userVipListPage", redirect: () => history.push(path) }
      }
    },
    create: {
      success: {
        name: "UserVipCreateResult",
        message: "Beneficiado adicionado com sucesso",
        action: { notify: "userVipListPage", redirect: () => history.push(path) }
      },
      error: {
        name: "UserVipErrorResult",
        message: "Houve um erro ao adicionar o beneficiado",
        action: { notify: "userVipForm", redirect: false }
      }
    },
    update: {
      success: {
        name: "UserVipUpdateResult",
        message: "Beneficiado atualizado com sucesso",
        action: { notify: "userVipListPage", redirect: () => history.push(path) }
      },
      error: {
        name: "UserVipErrorResult",
        message: "Houve um erro ao atualizar o beneficiado",
        action: { notify: "userVipForm", redirect: false }
      }
    }
  };
};

export { events, values, actions };
