import pc from '../constants/permission.constant'

const list = []

const state = {
  list: {
    status: pc.STS_IDLE,
    options: { 
      ops: [
        { label: "Allow", value: "allow" },
        { label: "Deny", value: "deny" }
      ],
      types: [
        { label: "Context", value: "context" },
        { label: "Schema", value: "schema" },
        { label: "Custom", value: "custom" },
      ],
      customs: [
        { label: "Static", value: "static" },
      ],
      schemas: [
        { label: "User", value: "user" },
        { label: "Identity", value: "identity" }
      ],
      comparings: [
        { label: "Equal", value: "eq" },
        { label: "Not Equal", value: "neq" },
        { label: "Greater Than", value: "gt" },
        { label: "Greater Than or Equal", value: "gte" },
        { label: "Less Than", value: "lt" },
        { label: "Less Than or Equal", value: "lte" },
        { label: "Left Contains", value: "lcnt" },
        { label: "Left Not Contains", value: "lncnt" },
        { label: "Right Contains", value: "rcnt" },
        { label: "Right Not Contains", value: "rncnt" }
      ]
    },
    items: [ ...list ],
    item: [ "prn", "", "1", "", "", "", "", "", ""],
  }
};

const reducer = (state, action) => {
  switch (action.type) {

    case pc.ACT_DELETE_ITEM:

      const sadi = { ...state }
      const list = sadi.list.items[action.data.index[0]][action.data.index[1]].list

      sadi.list.items[action.data.index[0]][action.data.index[1]].list = list.filter((value, index) => index != action.data.index[2])

      return sadi

    case pc.ACT_PUSH_ITEM:

      const sapi = { ...state }
      const perm = [ ...state.list.item ]

      perm[4] = sapi.list.items[action.data.index[0]][action.data.index[1]].slug

      const hasList = sapi.list.items[action.data.index[0]][action.data.index[1]].list

      if(!hasList) sapi.list.items[action.data.index[0]][action.data.index[1]].list = []

      sapi.list.items[action.data.index[0]][action.data.index[1]].list.push(perm.join(':'))

      return sapi
      
    case pc.ACT_SET_FIELD:

      const sapf = { ...state }

      const prn = sapf.list.items[action.data.index[0]][action.data.index[1]].list[action.data.index[2]].split(':')

      switch (action.data.field.name) {
        case 'id':
          prn[3] = action.data.field.value 
          break;
        case 'op':
            prn[5] = action.data.field.value 
            break;
        case 'compare':
            prn[6] = action.data.field.value 
            break;
        case 'leftType':
            prn[7] = action.data.field.value
            break;
        case 'leftTypeSub':
            let leftTypeStartSub = prn[7].split(".").slice(0,1).join('.')
            prn[7] = leftTypeStartSub + '.' + action.data.field.value
            break;
        case 'leftValue':
            let leftTypeStartValue = prn[7].split(".").slice(0,2).join('.')
            prn[7] = leftTypeStartValue + '.' + action.data.field.value
            break;
        case 'rightType':
            prn[8] = action.data.field.value
            break;
        case 'rightTypeSub':
            let rightTypeStartSub = prn[8].split(".").slice(0,1).join('.')
            prn[8] = rightTypeStartSub + '.' + action.data.field.value
            break;
        case 'rightValue':
            let rightTypeStartValue = prn[8].split(".").slice(0,2).join('.')
            prn[8] = rightTypeStartValue + '.' + action.data.field.value
            break;
      
        default:
          break;
      }

      sapf.list.items[action.data.index[0]][action.data.index[1]].list[action.data.index[2]] = prn.join(':')

      return sapf

    case pc.ACT_SET_LIST_STATUS:
      const sals = { ...state }
      
      sals.list.status = action.data

      return sals

    case pc.ACT_SET_LIST:
      
      const salt = { ...state }
      salt.list.items = action.data
      return salt
      
    default:
      return state
  }
};

export default { state, reducer }