import React, { Fragment, useState } from "react";
import { withRouter } from "react-router-dom";

import { List } from "./../../../wrappers/ListWrappers.react";

import { useFilter } from "@cthulhi/comp-list";

import Access from "@cthulhi/comp-accs";
import Context from "@context";

import Notification from "@cthulhi/comp-noty";
import NotificationComponent from "../../../utils/Notification.react";
import ModalConfirmRemoval from "./../../../wrappers/ModalConfirmRemoval.react";

import {
  events as faqListEvents,
  values as faqListValues,
  actions as faqListActions,
  options as faqListOptions,
  schema as faqListSchema
} from "./components/FaqList.config";

const FaqListPage = ({ match, history, ...props }) => {
  const { state: accState, action: accAction } = Access.useState();
  const { action: ctxAction } = Context.useState();
  const { state: ntfState, action: ntfAction } = Notification.useState();
  const [modal, setModal] = useState({ show: false, Content: () => null });

  const token = `Bearer ${accState.access.session.context.token}`;

  const onEdit = value => {
    history.push(`${match.path}/edit/${value._id}`);
  };

  const onRemoveAction = (value, refresh, loader) => {
    loader(true);
    ctxAction("faq")
      .remove({ _id: value._id }, token)
      .then(res => {
        if (res.name != "FaqDeleteResult") throw res;

        refresh();

        ntfAction("notification").add(
          res.code,
          "faqListPage",
          "success",
          "Faq removido com sucesso.",
          undefined,
          process.env.notificationTimeOut
        );
      })
      .catch(err => {
        loader(false);

        ntfAction("notification").add(
          err.code,
          "faqListPage",
          "danger",
          "Houve um erro ao remover o Faq.",
          undefined,
          process.env.notificationTimeOut
        );
      });
  };

  const onRemove = (value, refresh, loader) => {
    setModal({
      show: true,
      Content: props => {
        return (
          <ModalConfirmRemoval
            onConfirm={() => onRemoveAction(value, refresh, loader)}
            onClose={() => setModal({ show: false, Content: () => null })}
          />
        );
      }
    });
  };

  const { filter, list, page, limit, options } = useFilter({
    schema: faqListSchema,
    events: faqListEvents({ ctxAction }),
    options: faqListOptions({ onEdit, onRemove }),
    values: faqListValues({ ctxAction, token }),
    actions: faqListActions({ ctxAction, token })
  });

  return (
    <Fragment>
      <h2 className="title">Listar FAQs</h2>
      <div className="uk-margin-top">
        <Notification.NotificationWrapper group="faqListPage" component={NotificationComponent} />
        <List {...{ filter, list, page, limit, options }} />
        <div className="container-modal">{modal.show ? <modal.Content /> : null}</div>
      </div>
    </Fragment>
  );
};

export default withRouter(FaqListPage);
