import React, { Fragment, useEffect, useState } from "react";
import { Link, Switch, Route, withRouter } from "react-router-dom";

import Access from "@cthulhi/comp-accs";
import Context from "@context";
import { useFields, useForms } from "@cthulhi/comp-form";

import { schema as businessFormSchema } from "@schm/business/create.form.schema";

import {
  events as businessFormEvents,
  values as businessFormValues,
  actions as businessFormActions
} from "./components/BusinessForm.config";

import { FieldLabelWrapper } from "../../../wrappers/FormWrappers.react";

import Notification from "@cthulhi/comp-noty";
import NotificationComponent from "../../../utils/Notification.react";

import LoaderComponent from "../../../utils/LoaderCustomComponent.react";

import BranchRelationList from "../branch/components/BranchRelationList.react";
import UserRelationList from "../user/components/UserRelationList.react";

const BusinessFormPage = ({ match, parentPath, history, ...props }) => {
  const { state: accState, action: accAction } = Access.useState();
  const { action: ctxAction, state: ctxtState } = Context.useState();
  const { state: ntfState, action: ntfAction } = Notification.useState();

  const [oldUserList, setOldUserList] = useState([{ name: "", email: "" }]);
  const [userList, setUserList] = useState([{ name: "", email: "" }]);
  const [branchList, setBranchList] = useState([]);
  const [validation, setValidation] = useState({})

  const { form, fields, values, updater, cleaner } = useFields({
    schema: businessFormSchema,
    events: businessFormEvents({ ctxAction }),
    values: businessFormValues({ ctxAction })
  });

  const { find, update, createWithUserAndBranch } = useForms(
    businessFormActions({
      ctxAction,
      ntfAction,
      updater,
      history,
      path: parentPath,
      token: `Bearer ${accState.access.session.context.token}`
    })
  );

  useEffect(() => {
    if (match.params.id) {
      find({
        filters: { _id: match.params.id },
        aggregates: [
          {
            lookup: {
              from: "branch",
              field: "branches",
              match: "_id",
              result: "branchesList"
            }
          },
          {
            lookup: {
              from: "user",
              field: "_id",
              match: "businessId",
              result: "usersList",
              op: "$eq",
              projection: { password: 0, recover: 0 }
            }
          }
        ]
      });
    }

    return () => {
      cleaner();
    };
  }, [match.params.id]);

  const onBranchRemove = value => {
    setBranchList([...branchList.filter(b => b.value != value.value)]);
  };

  const onBranchAdd = value => {
    setBranchList([...branchList, value]);
  };

  const onBranchInit = values => {
    setBranchList([...values]);
  };

  const onBranchFind = () => {
    return ctxAction("branch")
      .find({ filters: {} }, undefined, `Bearer ${accState.access.session.context.token}`, {
        start: () => ctxAction("loader").addLoading("find", "branchRelationList"),
        finish: () => ctxAction("loader").removeLoading("find", "branchRelationList")
      })
      .then(res => {
        return res.result.values.map(item => {
          return { value: item._id, label: item.name };
        });
      });
  };

  const onUserRemove = value => {
    setUserList([...userList.filter(b => b.value != value.value)]);
  };

  const onUserAdd = value => {
    setUserList([...userList, value]);
  };

  const onUserFind = () => {
    return ctxAction("user")
      .find(
        { filters: { $or: [{ businessId: null }, { businessId: { $exists: false } }, { businessId: "" }] } },
        undefined,
        `Bearer ${accState.access.session.context.token}`,
        {
          start: () => ctxAction("loader").addLoading("find", "userRelationList"),
          finish: () => ctxAction("loader").removeLoading("find", "userRelationList")
        }
      )
      .then(res => {
        return res.result.values.map(item => {
          return { value: item._id, label: item.name };
        });
      });
  };

  const onUserInit = values => {
    setUserList([...values]);
    setOldUserList([...values]);
  };

  // TO DO: load from db agregate
  const branches =
    // @ts-ignore
    values && values.branchesList
      ? // @ts-ignore
        values.branchesList.rawValue.map(branch => {
          return { value: branch._id, label: branch.name };
        })
      : [];

  const users =
    // @ts-ignore
    values && values.usersList
      ? // @ts-ignore
        values.usersList.rawValue
          .filter(user => user.deletedAt == null)
          .map(user => {
            return { value: user._id, label: user.name };
          })
      : [];

  const businessFormSubmit = () => {
    let vs = {};

    Object.entries(values).map(([key, value]) => {
      const hasValue = typeof value.value != "undefined" && value.value !== "";
      const hasRawValue = typeof value.rawValue != "undefined" && value.rawValue !== "";

      vs[key] = hasRawValue ? value.rawValue : hasValue ? value.value : value.defaultValue;
    });

    vs["oldUsers"] = oldUserList.map(item => item.value);
    vs["users"] = userList.map(item => item.value);
    vs["branches"] = branchList.map(item => item.value);


    const validate = action => {
      action.then(() => {}).catch(setValidation)
    }


    match.params.id ? validate(update(vs)) : validate(createWithUserAndBranch(vs));
  };

  const getTitle = id => {
    return id ? "Editar parceiro" : "Novo parceiro";
  };

  return (
    <Fragment>
      <h2 className="title">{getTitle(match.params.id)}</h2>
      <div className="uk-margin-top">
        <ul data-uk-accordion="multiple: true">
          <li className="uk-open">
            <a className="title title-lead-small title-accordion uk-accordion-title">
              Dados do parceiro
            </a>
            <div className="uk-accordion-content">
              <Notification.NotificationWrapper
                group="businessForm"
                component={NotificationComponent}
              />
              <div className="uk-card uk-card-default uk-card-body uk-padding-small">
                <LoaderComponent from="businessForm" />
                <div className="uk-grid-small uk-grid">
                  <FieldLabelWrapper name="alias" fields={fields} values={values} validation={validation.alias}/>
                  <FieldLabelWrapper name="stage" fields={fields} values={values} validation={validation.stage}/>

                  <FieldLabelWrapper name="name" fields={fields} values={values} validation={validation.name}/>
                  <FieldLabelWrapper name="cnpj" fields={fields} values={values} validation={validation.cnpj}/>
                  <FieldLabelWrapper name="zipcode" fields={fields} values={values} validation={validation.zipcode}/>
                  <FieldLabelWrapper name="address" fields={fields} values={values} validation={validation.address}/>

                  <FieldLabelWrapper name="district" fields={fields} values={values} validation={validation.district}/>
                  <FieldLabelWrapper name="number" fields={fields} values={values} validation={validation.number}/>
                  <FieldLabelWrapper name="complement" fields={fields} values={values} validation={validation.complement}/>
                  <FieldLabelWrapper name="state" fields={fields} values={values} validation={validation.state}/>
                  <FieldLabelWrapper name="city" fields={fields} values={values} validation={validation.city}/>

                  <FieldLabelWrapper name="phone" fields={fields} values={values} validation={validation.phone}/>
                  <FieldLabelWrapper name="email" fields={fields} values={values} validation={validation.email}/>
                  <FieldLabelWrapper name="site" fields={fields} values={values} validation={validation.site}/>
                  <FieldLabelWrapper name="contact" fields={fields} values={values} validation={validation.contact}/>
                </div>
              </div>
            </div>
          </li>
          <li>
            <a className="title title-lead-small title-accordion uk-accordion-title">
              Relacionar unidades
            </a>
            <div className="uk-accordion-content">
              <LoaderComponent from="businessForm" />
              <BranchRelationList
                onFind={onBranchFind}
                onAdd={onBranchAdd}
                onRemove={onBranchRemove}
                onInit={onBranchInit}
                values={branches}
              />
            </div>
          </li>
          <li>
            <a className="title title-lead-small title-accordion uk-accordion-title">
              Relacionar usuários
            </a>
            <div className="uk-accordion-content">
              <LoaderComponent from="businessForm" />
              <UserRelationList
                onFind={onUserFind}
                onAdd={onUserAdd}
                onRemove={onUserRemove}
                onInit={onUserInit}
                values={users}
              />
            </div>
          </li>
        </ul>
      </div>
      <div className="uk-margin-top">
        <button
          onClick={businessFormSubmit}
          className="uk-button uk-button-large uk-button-secondary"
          style={{ float: "right" }}
          disabled={
            typeof ctxtState.loader.list["businessForm"] != "undefined" &&
            ctxtState.loader.list["businessForm"].length > 0
          }
        >
          {match.params.id ? "Atualizar" : "Salvar"}
        </button>
      </div>
    </Fragment>
  );
};

// @ts-ignore
export default withRouter(BusinessFormPage);
