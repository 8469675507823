import lc from '../constants/login.constant'

const state = {
  login: {
    form: {
      status: lc.STS_IDLE,
      fields: { email: "", password: "" },
      validations: undefined
    }
  },
  recoverEmail: {
    form: {
      status: lc.STS_IDLE,
      fields: { email: "" },
      validations: undefined
    }
  },
  recoverCode: {
    form: {
      status: lc.STS_IDLE,
      fields: { code: 0 },
      validations: undefined
    }
  },
  recoverUpdate: {
    form: {
      status: lc.STS_IDLE,
      fields: { password: "", check: "" },
      validations: undefined
    }
  }
};

const reducer = (state, action) => {
  switch (action.type) {

    case lc.SET_LOGIN_FORM_VALIDATIONS:
      
      return {
        ...state,
        login: {
          ...state.login,
          form: { ...state.login.form, validations: action.data }
        }
      }

    case lc.SET_RECOVER_EMAIL_FORM_VALIDATIONS:
      
      return {
        ...state,
        recoverEmail: {
          ...state.recoverEmail,
          form: { ...state.recoverEmail.form, validations: action.data }
        }
      }

    case lc.SET_RECOVER_CODE_FORM_VALIDATIONS:
      
        return {
          ...state,
          recoverCode: {
            ...state.recoverCode,
            form: { ...state.recoverCode.form, validations: action.data }
          }
        }

    case lc.SET_RECOVER_UPDATE_FORM_VALIDATIONS:
    
      return {
        ...state,
        recoverUpdate: {
          ...state.recoverUpdate,
          form: { ...state.recoverUpdate.form, validations: action.data }
        }
      }
      
    default:
        return state;
  }
};

export default { state, reducer }