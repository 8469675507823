import React, { Fragment, useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";

import Context from "@html-context";
import Access from "@cthulhi/comp-accs";

import LoaderComponent from "../../../../utils/LoaderCustomComponent.react";

const BranchAndTypeRelationList = ({ onRemove, onFind, onAdd, onInit, values = [], ...props }) => {
  const [items, setItems] = useState({ events: [], types: [] });
  const [selectedItems, setSelectedItems] = useState(values);
  const [selectedItem, setSelectedItem] = useState({ value: "-" });
  const [selectedType, setSelectedType] = useState({ value: "-" });

  const types = [
    { value: "salespoint", label: "Ponto de Venda" },
    { value: "usepoint", label: "Ponto de Uso" } 
  ]

  useEffect(() => {
    const find = async () => {
      setItems({ events: await onFind(), types });
    };

    find();
  }, []);

  useEffect(() => {
    onInit(values);
    setSelectedItems(values);
  }, [JSON.stringify(values)]);

  const onChangeUpdateItem = e => {
    setSelectedItem(items.events.filter(i => i.value == e.target.value)[0]);
  };

  const onChangeUpdateType = e => {
    setSelectedType(items.types.filter(i => i.value == e.target.value)[0]);
  };

  const onClickAdd = () => {

    const exists = selectedItems ? selectedItems.filter(i => i.event == selectedItem && i.type == selectedType) : []

    if (selectedItem.value != "-" && selectedType.value != "-" && exists.length == 0) {
      onAdd(selectedItem, selectedType);
      setSelectedItems([...selectedItems, { event: selectedItem, type: selectedType }]);
      setSelectedItem({ value: "-" });
      setSelectedType({ value: "-" });
    }
  };

  const onClickRemove = item => {
    onRemove(item);
    setSelectedItems([...selectedItems.filter(i => (i.event.value + i.type.value) != (item.event.value + item.type.value) )]);
  };

  return (
    <Fragment>
      <div className="uk-card uk-card-default uk-card-body uk-padding-small">
        <LoaderComponent from="branchRelationList" />
        <div className="uk-grid-small" data-uk-grid>
          <div className="uk-width-expand@m">
            <select className="uk-select" value={selectedItem.value} onChange={onChangeUpdateItem}>
              <option value="-" disabled>Selecione uma unidade...</option>
              {items.events.map((item, index) => (
                <option key={"brl-item-event-" + index} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
          </div>
          <div className="uk-width-expand@m">
            <select className="uk-select" value={selectedType.value} onChange={onChangeUpdateType}>
              <option value="-" disabled>Selecione um tipo...</option>
              {items.types.map((item, index) => (
                <option key={"brl-item-type-" + index} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
          </div>
          <div className="uk-width-auto@m">
            <button
              className="uk-button uk-button-secondary"
              onClick={onClickAdd}
            >
              Adicionar
            </button>
          </div>
          <div className="uk-width-1-1">
            {selectedItems.length > 0 ? (
              <table className="uk-table uk-table-small uk-table-striped uk-margin-remove">
                <thead>
                  <tr>
                    <td>Unidade</td>
                    <td>Tipo</td>
                    <td with="20%">Ação</td>
                  </tr>
                </thead>
                <tbody>
                  {selectedItems.map((item, index) => (
                    <tr key={"brl-item-" + index}>
                      <td>{item.event.label}</td>
                      <td>{item.type.label}</td>
                      <td>  
                        <button
                          className="uk-icon-button uk-button-danger"
                          onClick={e => onClickRemove(item)}
                        >
                          <i className="icon icon-delete" aria-hidden="true"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : null}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default BranchAndTypeRelationList;
