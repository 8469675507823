import websiteConstant from "../constants/website.constant"


const state = {
  drawerOpen: false,
  activeSlider: 0,
  toggleDropdown: false,
  accordion: false,
  sliders: [
    {
      text: 1,
    },
    {
      text: 2,
    },
    {
      text: 3,
    },
    {
      text: 4,
    },
    {
      text: 5,
    },
  ],
  categorias: [],
  newList: [],
  accordionDrop: [
    {
      _id: "1",
      title: "O que é o Clube NSC?",
      paragraph: "É um clube de vantagens exclusivas para os assinantes dos jornais da NSC. Ele oferece descontos em gastronomia, entretenimento, lojas, saúde, bem-estar e muito mais do entretenimento, lazer e saúde em Santa Catarina.",
      open: false
    },
    {
      _id: "2",
      title: "Quem pode se associar?",
      paragraph: "Os assinantes do NSC Total, DC, AN ou Santa que optarem por se associar ao Clube, ao fazer a assinatura em lojansc.com.br ou assinensc.com.br, ou entrando em contato com a Central de Atendimento em (48) 3216.3366",
      open: false
    },
    {
      _id: "3",
      title: "Como usar os benefícios do Clube?",
      paragraph: "Através dos vouchers e do cartão do Clube NSC você valida sua associação e garante os descontos. Ao gerar o voucher, você pode imprimi-lo e o apresentar no estabelecimento ou pelo celular, além de também poder informar ao estabelecimento apenas o código promocional gerado. Procure se atentar ao prazo de validade impresso em cada voucher.",
      open: false
    },
    {
      _id: "4",
      title: "O que o aplicativo Clube NSC oferece?",
      paragraph: "Você vai encontrar descontos por geolocalização, terá o seu cartão virtual sempre disponível, mesmo sem conexão com a internet, poderá favoritar seus benefícios preferidos e os utilizar quantas vezes quiser",
      open: false
    },
    {
      _id: "5",
      title: "Qual o custo para se associar ao Clube?",
      paragraph: "Novos sócios do Clube pagam a taxa administrativa de R$ 5,00. O valor da taxa administrativa é mensal e fixo, independente da quantidade de vezes que os benefícios sejam utilizados e é enviado ao assinante na mesma fatura que a assinatura do Jornal.",
      open: false
    },
    {
      _id: "6",
      title: "Se cancelada a assinatura do jornal, o meu Clube NSC ainda estará ativo?",
      paragraph: "Considerando que o Clube NSC é um benefício oferecido em caráter de exclusividade aos assinantes, ao cancelar a assinatura do jornal, o associado perderá automaticamente os direitos de utilização dos seus benefícios e de seus dependentes.",
      open: false
    },
    {
      _id: "7",
      title: "Como fazer o login para acessar o portal do Clube?",
      paragraph: "Se você já é sócio e possui login cadastrado no Portal do Assinante, basta utilizar o mesmo usuário e senha. Caso contrário, sócios podem se cadastrar pelo site. Caso tenha esquecido sua senha, é possível receber uma nova, clicando em “Não consigo entrar/Esqueci minha senha”.",
      open: false
    },
    {
      _id: "8",
      title: "Como baixar o app Clube NSC?",
      paragraph: "Baixe o aplicativo nas lojas Google Play e App Store e viva uma nova experiência!",
      open: false
    },
    {
      _id: "9",
      title: "Com qual tipo de cartão é possível comprovar a associação ao clube?",
      paragraph: "Tanto o cartão virtual quanto o cartão impresso são válidos para comprovar a associação de sócios e dependentes.",
      open: false
    },
    {
      _id: "10",
      title: "Mesmo tendo o cartão virtual, é possível continuar utilizando o cartão físico?",
      paragraph: "Sim. Para sócios que desejem solicitar o cartão físico, o pedido deve ser feito por meio das Centrais de Atendimento e o prazo de entrega é de até 30 (trinta) dias corridos - após o pagamento de taxa administrativa - no endereço informado pelo assinante.",
      open: false
    },
    {
      _id: "11",
      title: "Os sócios podem incluir dependentes?",
      paragraph: "Titulares da assinatura que são sócios do Clube podem ter até quatro dependentes. Para cada associação adicional também será necessário o pagamento da taxa de administração mensal no valor de R$ 5,00 cada. A taxa administrativa mensal dos dependentes é cobrada do assinante na mesma fatura que a assinatura do Jornal.",
      open: false
    },
    {
      _id: "12",
      title: "Como um dependente deve fazer o acesso ao Clube?",
      paragraph: "Para obter o cartão virtual, o dependente deve ter o login de acesso com seu usuário e senha próprios, independente do titular da assinatura. Ele deve clicar em 'Entrar' e informar usuário e senha. Caso ele ainda não possua login, deve preencher o campo 'Insira seu CPF'.",
      open: false
    },
    {
      _id: "13",
      title: "Pessoa Jurídica pode ser um associado?",
      paragraph: "Sim. A associação ao Clube NSC também pode ser feita por procurador(es) de pessoa jurídica que sejam assinantes de algum dos jornais identificados pela marca “NSC Comunicação”.",
      open: false
    },
    {
      _id: "14",
      title: "Como fazer a renovação do cartão do clube?",
      paragraph: "A renovação é automática, mas o novo cartão físico do Clube NSC deve ser solicitado. Você pode utilizar as formas digitais de comprovação da sua associação: cartão virtual ou voucher dos benefícios.",
      open: false
    },
    {
      _id: "15",
      title: "Como solicito um novo cartão do Clube?",
      paragraph: "O uso do cartão físico depende do prazo de validade. Se quiser um novo cartão, deverá solicitar através do Fale Conosco.",
      open: false
    },
    {
      _id: "16",
      title: "Em caso de perda do cartão do Clube NSC, como solicitar um novo?",
      paragraph: "No caso de extravio, furto ou roubo do cartão, o associado deve comunicar a Central de Atendimento do seu jornal e solicitar uma 2ª via. O novo cartão do Clube será entregue em até 30 (trinta) dias corridos, contados da data da solicitação.",
      open: false
    },
    {
      _id: "17",
      title: "Quais os contatos das Centrais de atendimento?",
      paragraph: "O atendimento é realizado de segunda a sexta-feira, das 8h às 16h e sábado, das 8h às 12h nos números de contato abaixo:",
      paragraph2: "Diário Catarinense      (48) 3216-3366 – Grande Florianópolis e celulares | 0800.48.1400 – Demais cidades       A Notícia:      (47) 3419-2020          Jornal de Santa Catarina:      (47) 3221.1510 - Blumenau e celulares | 0800.47.1112 - Demais cidades",
      open: false
    },
    {
      _id: "18",
      title: "Como melhorar as atrações e parcerias do clube?",
      paragraph: "Após a utilização dos vouchers você também pode avaliar o benefício pelo próprio aplicativo ou site, ajudando-nos a avaliar as parcerias.",
      open: false
    }
  ],
};

const reducer = (state, action) => {
  switch (action.type) {

    case websiteConstant.UPDATE_STATE:
      return {
        ...state,
        ...action.data
      };

    case websiteConstant.UPDATE_MENU_ITEM_STATE:
      return {
        ...state,
        categorias: state.categorias.map(categoria => {
          if (categoria._id == action.data._id) {
            return {
              ...categoria,
              open: !categoria.open
            }
          }
          return categoria;
        })
      }

    case websiteConstant.UPDATE_ACCORDION:
      return {
        ...state,
        newList: state.newList.map(accordion => {
          if (accordion._id == action.data._id) {
            return {
              ...accordion,
              open: !accordion.open
            }
          }
          return accordion;
        })
      }

    default:
      return state;
  }
};

export default { state, reducer }
