
const events = ({ ctxAction, ...props }) => {
  return {};
};

const values = ({ ctxAction, ...props }) => {
  let initValues = {
    company_name: {
      className: "input",
      placeholder: "Nome da empresa",
      value: "",
      label: {
        text: "Nome da empresa",
        className: "input"
      },
      wrapper: {
        className: "input"
      }
    },
    name: {
      className: "input",
      placeholder: "Nome da pessoa de contato",
      value: "",
      label: {
        text: "Nome da pessoa de contato",
        className: "input"
      },
      wrapper: {
        className: "input"
      }
    },
    phone: {
      className: "input",
      placeholder: "Telefone de contato",
      masks: ["(99) 9999-9999", "(99) 99999-9999", "+99 (99) 9999-9999", "+99 (99) 99999-9999"],
      value: "",
      label: {
        text: "Telefone",
        className: "input"
      },
      wrapper: {
        className: "input"
      }
    },
    email: {
      className: "input",
      placeholder: "E-mail de contato",
      value: "",
      label: {
        text: "E-mail de contato",
        className: "input"
      },
      wrapper: {
        className: "input"
      }
    }    
  };

  return initValues;
};

const actions = ({ token, ctxAction, ntfAction, updater, history, path, ...props }) => {
  return {
    entity: {
      name: "business",
      token: token,
      form: ctxAction("form")
    },
    actions: {
      entity: ctxAction,
      notification: ntfAction,
      updater: updater
    },
    find: {},
    create: {},
    sendEmail: {
      action: "submit",
      success: {
        name: "EmailSentSuccessResult",
        message: "Sua solicitação foi enviada com sucesso!",
        action: { notify: "businessForm", redirect: () => { history.push(path); } }
      },
      error: {
        name: "EmailErrorResult",
        message: "Houve um erro ao enviar o e-mail de contato",
        action: { notify: "businessForm", redirect: false }
      }
    },
    update: {}
  };
};

export { events, values, actions };
