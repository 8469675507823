import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import applyEllipsis from "@html/views/website/utils/applyEllipsis";

const BenefitCard = ({ benefit }) => {
  const badgeType = (discountType, discount, badgeColor = "black") => {
    if (discount) {
      return (
        <span className={`badge badge-${badgeColor}`}>
          <small>
            {`${discountType == "plain" ? "R$ " : ""}`}
          </small>

          {`${discount.toString().length > 6 ? discount.toString().substring(0, 5) : discount}`}

          <small>
            {`${discountType == "percent" || !discountType ? "%" : ""}`}
          </small>
        </span>
      );
    }
    return null;
  };

  return (
    <Fragment>
      <Link to={`/beneficio/${benefit.slug}`} className="dsp-grid-item">
        <div className="dsp-grid-banner">
          <img
            src={
              benefit.bannerUrl
                ? `${process.env.bannerUrl + benefit.bannerUrl.replace("-full", "-large")}`
                : `${process.env.bannerUrl}large_beneficio20190220035556.png`
            }
            alt="A dummy image"
          />
          {(benefit.discountType && benefit.discountType === "percent" && +benefit.discount === 100) ?
            (
              <span className={`badge badge-orange`}>
                <strong>FREE</strong>
              </span>
            ) :
            badgeType(benefit.discountType, benefit.discount)
          }
        </div>
        <div className="dsp-grid-title">
          <h4>{applyEllipsis(benefit.title, 40)}</h4>
        </div>
        <div className="dsp-grid-content">
          <p>{applyEllipsis(benefit.resume, 85)}</p>
        </div>
        <div className="dsp-grid-location">
          <i className={benefit.locationInfo || benefit.locationInfo == "UNIDADE VIRTUAL" ? "icn icn-map-pin" : ""} aria-hidden="true" ></i>
          <span>{benefit.locationInfo}</span>
        </div>
      </Link>
    </Fragment>
  );
};

const BenefitList = ({ benefitList }) => {
  if (!benefitList) {
    return (
      <Fragment>
        <h3>Carregando...</h3>
      </Fragment>
    );
  } else if (benefitList.length === 0) {
    return (
      <Fragment>
        <h3>Nenhum benefício encontrado</h3>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        {benefitList.map(benefit => (
          <BenefitCard key={benefit.key || benefit._id} benefit={benefit} />
        ))}
      </Fragment>
    );
  }
};

export default BenefitList;
