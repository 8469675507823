import React, { Fragment, useEffect, useState } from "react";
import { types, Input, Select, TextArea, Checkbox, DateTime, FileImageCrop, GoogleMaps, GoogleMapsApiWrapper, InputSearch, FileImage } from "@cthulhi/comp-form";
import {Select as AntSelect} from 'antd';
const {Option: AntOption} = AntSelect;

import moment from "moment"

const GoogleMapsWrapper = GoogleMapsApiWrapper({
  // apiKey: ("AIzaSyCbjxVC4vCyEaq2qfz6iaTI3vk9RTq_gck")
  apiKey: (process.env.googleMapsApiKey)
})(GoogleMaps)

const GoogleMapsField = ({ name, value, rawValue, options, masker, ...props }) => {
  return(
    <div style={{position: "relative", height: "300px", marginTop: "20px"}}>
      <GoogleMapsWrapper state={value} { ...props } />
    </div>
  )
}

const Wrapper = ({ name, fields = {}, values = {}, children, ...props }) => {
  if (!fields || (!fields[name] && !props.f)) return null;

  const wrapper =
    props.v && props.v.wrapper
      ? props.v.wrapper
      : values[name] && values[name].wrapper
      ? values[name].wrapper
      : { className: "field-wrapper" };

  let { ...prop } = wrapper;

  return <div {...(prop ? prop : {})}>{children}</div>;
};

const FieldLabelWrapper = ({ name, fields, values, ...props }) => {
  if (!fields || (!fields[name] && !props.f)) return null;

  return (
    <Wrapper
      name={name}
      f={props.f ? props.f : fields[name]}
      v={props.v ? props.v : values[name]}
      {...props}
    >
      <Label
        name={name}
        f={props.f ? props.f : fields[name]}
        v={props.v ? props.v : values[name]}
        {...props}
      >
        <Field
          name={name}
          fields={fields}
          values={values}
          f={props.f ? props.f : fields[name]}
          v={props.v ? props.v : values[name]}
          {...props}
        />
      </Label>
      <Description name={name} v={props.v ? props.v : values[name]} {...props} />
    </Wrapper>
  );
};

const Label = ({ name, fields = {}, values = {}, children, ...props }) => {
  if (!fields || (!fields[name] && !props.f)) return null;

  const { ...vals } = props.v
    ? props.v
    : values[name]
    ? values[name]
    : { defaultValue: "", options: [] };

  const { attrs: { ...attrs } } = props.f ? props.f : fields[name];

  const label =
    props.v && props.v.label
      ? props.v.label
      : values[name] && values[name].label
      ? values[name].label
      : { className: "field-label", position: "above", text: name };

  let { position, text, ...prop } = label;

  let value = vals ? vals.value ? vals.value : vals.defaultValue : ""

  const labelText = attrs.required ? text + " *" : text;

  const labelShow = (vals.label && vals.label.show) || value;

  switch (position) {
    case "inner-above":
      return (
        <label
          {...(prop ? prop : {})}
          className={label.className + (labelShow ? " show" : "") + (props.validation === false ? " invalid-input-label" : "")}
        >
          {labelText}
          {children}
        </label>
      );
    case "inner-below":
      return (
        <label
          {...(prop ? prop : {})}
          className={label.className + (labelShow ? " show" : "") + (props.validation === false ? " invalid-input-label" : "")}
        >
          {labelText}
          {children}
        </label>
      );
    case "below":
      return (
        <Fragment>
          {children}
          <label
            {...(prop ? prop : {})}
            className={label.className + (labelShow ? " show" : "") + (props.validation === false ? " invalid-input-label" : "")}
          >
            {labelText}
          </label>
        </Fragment>
      );

    default:
      return (
        <Fragment>
          <label
            {...(prop ? prop : {})}
            className={label.className + (labelShow ? " show" : "") + (props.validation === false ? " invalid-input-label" : "")}
          >
            {labelText}
          </label>
          {children}
        </Fragment>
      );
  }
};

const Description = ({ name, values = {}, v = undefined, ...props }) => {

  const { description, ...vals } = v
    ? v
    : values[name]
    ? values[name]
    : { text: "" };

  if(!description) return null;

  return (
    <span {...description}>{description.text}</span>
  )
}

const FieldLabel = ({ name, fields, values, className, ...props }) => {
  if (!fields || (!fields[name] && !props.f)) return null;

  return (
    <Label name={name} className={className} f={props.f ? props.f : fields[name]} {...props}>
      <Field
        name={name}
        f={props.f ? props.f : fields[name]}
        v={props.v ? props.v : values[name]}
        {...props}
      />
    </Label>
  );
};

const Field = ({ name, fields = {}, values = {}, f = undefined, v = undefined, ...props }) => {
  if (!fields || (!fields[name] && !f)) return null;

  const { value: val, options, label, wrapper, dateFormat, find, ...vals } = v
    ? v
    : values[name]
    ? values[name]
    : { defaultValue: "", options: [] };
  const {
    attrs: { type, field, ...attrs },
    events,
    functs
  } = f ? f : fields[name];

  const placeholder =
    attrs.required && vals.placeholder
      ? vals.placeholder + " *"
      : vals.placeholder
      ? vals.placeholder
      : "";

  useEffect(() => {
    if (options && typeof options === "function") {
      options();
    }
  }, [options]);

  switch (field) {
    case types.textfield.type:
    case types.number.type:
    case types.password.type:
      return (
        <Input
          {...vals}
          {...attrs}
          {...events}
          {...functs}
          {...props}
          className={`${vals.className ? vals.className : ""} ${props.validation === false ? "invalid-input-field" : ""}`}
          name={name}
          type={field}
          value={val}
          values={values}
          placeholder={placeholder}
        />
      );
    case types.checkbox.type:
      return (
        <Checkbox
          {...attrs}
          {...events}
          {...functs}
          {...vals}
          {...props}
          className={`${vals.className ? vals.className : ""} ${props.validation === false ? "invalid-input-field" : ""}`}
          name={name}
          type={field}
          value={val}
        />
      );
    case types.datetime.type:
      return (
        <>
          {
            (!placeholder && val == undefined)
            ? <label
                {...label}
              >
                {label.text}
              </label>
            : null
          }
          <DateTime
            {...attrs}
            {...events}
            {...functs}
            {...vals}
            {...props}
            className={`${vals.className ? vals.className : ""} ${props.validation === false ? "invalid-input-field" : ""}`}
            name={name}
            type={field}
            value={val ? moment(val) : val}
            placeholder={placeholder}
          />
        </>
      );
    case types.textarea.type:
      return (
        <TextArea
          {...attrs}
          {...events}
          {...functs}
          {...vals}
          {...props}
          className={`${vals.className ? vals.className : ""} ${props.validation === false ? "invalid-input-field" : ""}`}
          name={name}
          value={val}
          placeholder={placeholder} />
      );
    case types.select.type:
      return (
        <Select
          {...attrs}
          {...events}
          {...functs}
          {...props}
          {...vals}
          className={`${vals.className ? vals.className : ""} ${props.validation === false ? "invalid-input-field" : ""}`}
          name={name}
          value={val}
          options={options}
        />
      );
    case types.fileimagecrop.type:
      return <FileImageCrop name={name} { ...vals } { ...attrs } { ...events } { ...functs } { ...props } />
    case types.fileimage.type:
      return <FileImage name={name} { ...vals } { ...attrs } { ...events } { ...functs } { ...props } />
    case types.googlemaps.type:
      return <GoogleMapsField name={name} value={val} { ...vals } { ...attrs } { ...events } { ...functs } { ...props } />
    case types.inputSearch.type:
      return (
        <InputSearch
          {...attrs}
          {...events}
          {...functs}
          {...props}
          {...vals}
          className={`${vals.className ? vals.className : ""} ${
            props.validation === false ? "invalid-input-field" : ""
          }`}
          name={name}
          value={val}
          options={options}
        />
      );
    case "ant-select":
      return <MultipleSelect
        attrs={attrs}
        events={events}
        functs={functs}
        vals={vals}
        val={val}
        find={find} 
        options={options}  
      />;
    case types.radio.type:
      return (
        <div>
          {
            options.map(option => 
              <Fragment>
                <input 
                  {...attrs}
                  {...events}
                  {...functs}
                  {...props}
                  {...vals}
                  type="radio" 
                  className="input-radio"
                  id={`${name}.${option.id}`} 
                  name={name} 
                  defaultChecked={option.checked}
                  value={option.val} 
                />
                <label htmlFor={`${name}.${option.id}`}>{option.label}</label>
              </Fragment>
            )
          }
        </div>
      );
    case types.file.type:
      return (
        <input 
          {...attrs}
          {...events}
          {...functs}
          {...props}
          {...vals}
          type="file" 
          // className="input-radio"
          id={name} 
          name={name} 
        />
      );
  }

  return null;
};

const MultipleSelect = ({
  options,
  attrs,
  events,
  find,
  val
}) => {
  const [antOptions, setOptions] = useState(options);
  const [value, setValue] = useState();

  useEffect(() => {
    if (Array.isArray(val)) {
      if (antOptions.find(option => val.includes(option.value))) {
        setValue(val);
      }
    } else {
      if (antOptions.find(option => val == option.value)) {
        setValue(val);
      }
    }
  }, [antOptions, val])

  useEffect(() => {
    if (find) {
      setOptions([{ value: null, placeholder: "Carregando", label: "Carregando..." }]);

      find().then(res => {
        if (Array.isArray(res)) {
          setOptions(res)
        }
      })
    }
  }, [find])

  return (
    <AntSelect
      {...attrs}
      {...events}
      value={value}
      onChange={v => {
        setValue(v);
        events.onChange && events.onChange(v);
      }}
      mode="multiple"
      allowClear
      style={{width: "100%"}}
    >
      {antOptions.map(option => <AntOption key={option.value} value={option.value}>{option.label}</AntOption>)}
    </AntSelect>
  )
}

const SelectWrapper = ({
  data = [],
  value = "",
  keyBy = "",
  content = "",
  selectedValue,
  setSelectedValue
}) => {

  useEffect(() => {
    // default value selected is the first item on the data list
    if (data.length && !selectedValue) {
      setSelectedValue(data[0][value])
    }
  }, [data])

  const updateSelectedVal = (event) => {
    const selected = data[event.currentTarget.value]
    setSelectedValue(selected[value])
  }

  return (
    <select
      className="uk-select"
      onChange={updateSelectedVal}
    >
      {
        data.map((element, index) => {
          return (
            <option
              key={index}
              value={index}
            >
              {element[content]}
            </option>
          )
        })
      }
    </select>
  )
}

export { Field, Label, Wrapper, FieldLabel, FieldLabelWrapper, SelectWrapper };
