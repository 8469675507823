const view = {
  group: {
    title: "Voucher Codes",
    slug: "voucherCode"
  },
  slug: "voucherCode-view",
  title: "Ver Voucher Codes",
  description: "Ver os próprios Voucher Code",
  list: [
    "prn::1:voucherCode-view-roles:voucherCode-view:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

const viewAny = {
  group: {
    title: "Voucher Codes",
    slug: "voucherCode"
  },
  slug: "voucherCode-view-any",
  title: "Ver qualquer Voucher Code",
  description: "Ver todos os Voucher Codes",
  list: [
    "prn::1:voucherCode-view-any-roles:voucherCode-view-any:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

const edit = {
  group: {
    title: "Voucher Codes",
    slug: "voucherCode"
  },
  slug: "voucherCode-edit",
  title: "Editar Voucher Codes",
  description: "Editar os próprios Voucher Code",
  list: [
    "prn::1:voucherCode-edit-roles:voucherCode-edit:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

const editAny = {
  group: {
    title: "Voucher Codes",
    slug: "voucherCode"
  },
  slug: "voucherCode-edit-any",
  title: "Editar qualquer Voucher Code",
  description: "Editar todos os Voucher Codes",
  list: [
    "prn::1:voucherCode-edit-any-roles:voucherCode-edit-any:allow:lcnt:schema.user.roles:custom.static.admin"
  ]
}

const create = {
  group: {
    title: "Voucher Codes",
    slug: "voucherCode"
  },
  slug: "voucherCode-create",
  title: "Criar Voucher Code",
  description: "Criar os próprios Voucher Codes"
}

const createAny = {
  group: {
    title: "Voucher Codes",
    slug: "voucherCode"
  },
  slug: "voucherCode-create-any",
  title: "Criar qualquer Voucher Code",
  description: "Criar todos os Voucher Codes"
}

const remove = {
  group: {
    title: "Voucher Codes",
    slug: "voucherCode"
  },
  slug: "voucherCode-remove",
  title: "Remover Voucher Code",
  description: "Remover os próprios Voucher Code"
}

const removeAny = {
  group: {
    title: "Voucher Codes",
    slug: "voucherCode"
  },
  slug: "voucherCode-remove-any",
  title: "Remover qualquer Voucher Code",
  description: "Remover todos os Voucher Codes"
}

module.exports = {
  view,
  viewAny,
  edit,
  editAny,
  create,
  createAny,
  remove,
  removeAny,
}