import pc from './../constants/permission.constant'

export default (dispatch, state) => {

  const setListStatus = (data) => {
    return {
      type: pc.ACT_SET_LIST_STATUS,
      data: data
    }
  }

  const setList = (data) => {
    return {
      type: pc.ACT_SET_LIST,
      data: data
    }
  }

  const setField = (data) => {
    return {
      type: pc.ACT_SET_FIELD,
      data: data
    }
  }

  const pushItem = (data) => {
    return {
      type: pc.ACT_PUSH_ITEM,
      data: data
    }
  }

  const deleteItem = (data) => {
    return {
      type: pc.ACT_DELETE_ITEM,
      data: data
    }
  }

  const groupList = (arr) => {
    let groups = {};

    arr.map(perm => {
      if(typeof groups[perm.group.slug] == 'undefined') groups[perm.group.slug] = []
      groups[perm.group.slug].push(perm)
    })

    return groups
  }

  const getList = () => {
    var args = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'same-origin'
    };

    dispatch(setListStatus(pc.STS_GET_REQUEST))

    
    return fetch(process.env.domainPermission + '/v1/list', args)
    .then(res => res.json())
    .then(res => {

        dispatch(setListStatus(pc.STS_GET_RESPONSE))
        dispatch(setListStatus(pc.STS_GET_SUCCESS))

        setTimeout(function () {
          dispatch(setListStatus(pc.STS_IDLE))
        }, 1000);

        dispatch(setList(groupList(res.result)))

      })
      .catch(error => {

        dispatch(setListStatus(pc.STS_GET_ERROR));

        setTimeout(function () {
          dispatch(setListStatus(pc.STS_IDLE));
        }, 1000);

        return error

      })
  }

  const updateList = () => {
    var args = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ list: Object.values(state.list.items).reduce((a, b) => [ ...a , ...b ] , []) }),
      credentials: 'same-origin'
    };

    dispatch(setListStatus(pc.STS_PATCH_REQUEST))

    return fetch(process.env.domainPermission + '/v1/update', args)
    .then(res => { 
      return res.json()
    })
    .then(res => {

        dispatch(setListStatus(pc.STS_PATCH_RESPONSE))
        
        if(res.code == 'PM22') {
          dispatch(setListStatus(pc.STS_PATCH_SUCCESS))
        } else {
          dispatch(setListStatus(pc.STS_PATCH_ERROR))
        }

        setTimeout(function () {
          dispatch(setListStatus(pc.STS_IDLE))
        }, 1000);

      })
      .catch(error => {

        dispatch(setListStatus(pc.STS_PATCH_ERROR));

        setTimeout(function () {
          dispatch(setListStatus(pc.STS_IDLE));
        }, 1000);

        return error

      })
  }

  const updateField = (name, value, index) => {
    dispatch(setField({ field: { name, value }, index }))
  }
  
  const addItem = (index) => {
    dispatch(pushItem({ index }))
  }

  const removeItem = (index) => {
    dispatch(deleteItem({ index }))
  }

  return {
    getList,
    addItem,
    updateList,
    updateField,
    removeItem
  }

}