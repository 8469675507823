import React, { useState, Fragment, useEffect } from "react";

const FileImage = ({
  name,
  value,
  rawValue,
  options,
  ...props
}) => {
  const [src, setSrc] = useState(null);

  const onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("loadend", () => {
        setSrc(reader.result)
        props.onChange
          ? props.onChange({ target: { value: reader.result }, checkValidity: () => true })
          : null;
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  useEffect(() => {
    if (!src && rawValue) {
      setSrc(rawValue);
    }
  }, [rawValue]);

  return (
    <Fragment>
      <div {...props.input.wrapper} >
        <input type="file" name={name} onChange={onSelectFile} {...props.input.field}
          className={`${props.input.field.className ? props.input.field.className : ""} ${props.validation === false ? "invalid-input-field" : ""}`}
        />
      </div>
      <div {...props.wrapper}>
        <img src={src} {...props.image} />
      </div>
    </Fragment>
  );
};

export default FileImage;
