class Mask {
  specialCharRegex = /\W/g;
  tests = { "9": /\d/, a: /[a-z]/, A: /[A-Z]/, "*": /\w/ };

  constructor(masks) {
    if (masks) {
      if (typeof masks === "string") {
        this.masks = [masks];
      } else {
        this.masks = this.orderMasks(masks);
      }
    }
  }

  /**
   *
   * @param {array} masks
   */
  orderMasks(masks) {
    return masks.sort((a, b) => (a.length > b.length ? 1 : -1));
  }

  updateMask(masks) {
    this.masks;
  }

  getIndexFirstDiffChar(mask, value) {
    for (let i = 0; i < value.length; i++) {
      if (mask[i] !== value[i] && !this.isValidCharAt(mask, value, i)) return i;
    }
    return -1;
  }

  isValidCharAt(mask, value, index) {
    const maskChar = mask[index];
    const regex = this.tests[maskChar];
    if (!regex) return false;
    return regex.test(value[index]);
  }

  isSpecialChar(value, index) {
    return /\W/.test(value[index]);
  }

  removeLast(value) {
    return value.slice(0, -1);
  }

  applyMask(value) {
    const mask = this.masks.find(
      mask => this.getUnmasked(value).length <= this.getUnmasked(mask).length
    );

    if (!mask) return this.removeLast(value);

    value = this.getUnmasked(value);

    return this.apply(value, mask);
  }

  apply(value, mask) {
    const indexDiff = this.getIndexFirstDiffChar(mask, value);

    if (indexDiff < 0) {
      if (value.length > 0 && !this.isValidCharAt(mask, value, value.length - 1)) {
        return this.apply(this.removeLast(value), mask);
      }
      return value;
    } else if (
      !this.isSpecialChar(mask, indexDiff) &&
      !this.isValidCharAt(mask, value, value.length - 1)
    ) {
      return this.apply(this.removeLast(value), mask);
    }

    if (/\W/.test(value[indexDiff])) {
      return this.removeLast(value);
    } else {
      if (this.isValidCharAt(mask, value, indexDiff)) {
        return value;
      } else if (this.isSpecialChar(mask, indexDiff)) {
        const masked = `${value.slice(0, indexDiff)}${mask[indexDiff]}${value.slice(indexDiff)}`;
        return this.apply(masked, mask);
      }
    }
    return value;
  }

  getUnmasked(value) {
    return value ? value.replace(/\W/g, "") : value
  }
}

export default Mask;